import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Repository } from 'src/app/interface/Repository';
import { ApiService } from 'src/app/service/api.service';
import { environment } from 'src/environments/environment';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { Util } from 'src/app/service/utils';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss'],
})
export class CreateProjectComponent implements OnInit {
  @ViewChild('webhookInput') webhookInput?: ElementRef<HTMLInputElement>;
  @ViewChild('webhookSecretInput')
  webhookSecretInput?: ElementRef<HTMLInputElement>;
  @ViewChild('modalWebhookUrl') modalWebhookUrl: BsModalRef | undefined;
  @ViewChild('modalWebhookUrlAzure') modalWebhookUrlAzure?: ModalDirective;
  @ViewChild('webhookAzureInput') webhookAzureInput?: ElementRef<HTMLInputElement>;
  title = 'Create New';
  appLoader = false;
  repoLoader = false;
  connections: any = [];
  repos = [];
  branches = [];
  groups = [];
  updateData: Repository | any;
  id: any;
  response: any;
  groupId: any;
  serverUrl = environment.apiUrl;
  beforeProjectId: any;
  webhookSecret: any;
  hashingOption = ['sha256', 'sha384', 'sha512'];
  // hashingOption = ['md5', 'sha256', 'sha384', 'sha512'];
  provider: any = '';
  contributorsList = [];
  selectedAzureConnection: any;
  projectForm = new UntypedFormGroup({
    name: new UntypedFormControl(null, Validators.required),
    group_id: new UntypedFormControl(null),
    connection: new UntypedFormControl(null, Validators.required),
    // listener_branch: new FormControl(null, Validators.required),
    commitId: new UntypedFormControl(null),
    repository_full_name: new UntypedFormControl(null, Validators.required),
    repository_name: new UntypedFormControl(null, Validators.required),
    provider: new UntypedFormControl(null, Validators.required),
    repository_id: new UntypedFormControl(null, Validators.required),
    repository_type: new UntypedFormControl(null, Validators.required),
    hash_function_type: new UntypedFormControl(null, Validators.required),
    hash_function: new UntypedFormControl(null, Validators.required),
    lines: new UntypedFormControl(10, Validators.required),
    contributors: new UntypedFormControl([]),
  });
  constructor(
    private api: ApiService,
    private toaster: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private util: Util
  ) {}

  ngOnInit(): void {
    if (
      this.util.isPermission('isAddProject') ||
      this.util.isPermission('isEditProject')
    ) {
      this.getAllConnection();

      this.route.paramMap.subscribe((param) => {
        this.id = param.get('id');
        if (this.id && this.id !== '') {
          this.title = 'Edit';
          this.editPatchData();
        } else {
          this.getProjectId();
        }
      });
      this.route.queryParamMap.subscribe((queryParam) => {
        this.groupId = queryParam.get('group_id');
        this.provider = queryParam.get('provider');
        if (this.groupId && this.groupId !== '') {
          this.projectForm.patchValue({ group_id: this.groupId });
        }
        if (this.provider && this.provider !== '') {
          this.projectForm.patchValue({ provider: this.provider });
          this.projectForm.controls['provider']?.disable();
          this.getAllConnection();
        }
        // ? decodeURIComponent(queryParam?.get('id'))
        // : null;
      });
    } else {
      this.location.back();
    }
  }
  getProjectId(): void {
    this.api.getProjectIdBefore().subscribe((resp) => {
      if (resp.code === 0) {
        this.beforeProjectId = resp.result._id;
      }
    });
  }
  onBack(): void {
    this.location?.back();
  }
  editPatchData(): void {
    this.api.getProjectDetails(this.id).subscribe(
      (resp) => {
        // //console.log('edit>>', resp);
        if (resp.code === 0) {
          if (resp.result.provider == 'Azure') {
            this.selectedAzureConnection = { url: resp.result.azureRepoUrl };
          }
          this.projectForm.patchValue({
            name: resp.result.name,
            connection: resp.result.connection,
            // listener_branch: resp.result.listener_branch,
            repository_full_name: resp.result.repository_full_name,
            repository_name: resp.result.repository_name,
            provider: resp.result.provider,
            repository_id: resp.result.repository_id,
            repository_type: resp.result.repository_type,
            hash_function_type: resp.result.hash_function_type,
            hash_function: resp.result.hash_function,
            lines: resp.result.lines,
            commitId: resp.result.commitId,
            contributors: resp.result.contributors,
            group_id: resp.result.group_id,
          });
          this.updateData = resp.result;
          this.getAllConnection();
          this.getAllRepo();
          this.getAllBranches();
          if (resp.result.provider === 'GitHub') {
            this.getAllContributors();
          }
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  getAllConnection(): void {
    this.api
      .getAllConnectionByProvider(this.projectForm.get('provider')?.value)
      .subscribe(
        (resp) => {
          if (resp.code === 0) {
            // //console.log('connection');
            this.connections = resp.result;
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
  }
  getAllRepo(): void {
    // //console.log(this.projectForm.get('connection')?.value);

    this.api.getAllRepo(this.projectForm.get('connection')?.value).subscribe(
      (resp) => {
        // //console.log('inside');

        // //console.log(resp.result);
        // this.repos = resp.result;
        if (resp.code === 0) {
          this.repos = resp.result;
        } else if (resp.code === 10) {
          this.toaster.error(resp.result);
          this.projectForm.get('connection')?.reset();
        }
        // else if (resp.code === 100) {
        //   if (resp.result.status === 401) {
        //     this.toaster.error('Github app uninstalled or access removed');
        //   }
        // }
      },
      (error) => {
        // //console.log(error);

        if (error.error.code === 100) {
          if (error.error.result.status === 401) {
            this.toaster.error('Github app uninstalled or access removed');
            this.appLoader = false;
          }
        } else {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
          this.appLoader = false;
        }
      },
      () => {
        this.appLoader = false;
      }
    );
  }
  getAllBranches(): void {
    if (this.projectForm.get('provider')?.value === 'Gitlab') {
      this.getGitlabBranch();
      return;
    }
    if (this.projectForm.get('provider')?.value === 'Azure') {
      this.getAzureBranch();
      return;
    }

    this.api
      .getAllBranchs(
        this.projectForm.get('repository_full_name')?.value,
        this.projectForm.get('provider')?.value,
        this.projectForm.get('connection')?.value
      )
      .subscribe(
        (resp) => {
          if (resp.code === 0) {
            this.branches = resp.result;
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        },
        () => {
          this.repoLoader = false;
        }
      );
  }
  getAzureBranch(): void {
    this.api
      .getAllBranchsForAzure(
        this.projectForm.get('repository_id')?.value,
        this.projectForm.get('provider')?.value,
        this.projectForm.get('connection')?.value
      )
      .subscribe(
        (resp) => {
          if (resp.code === 0) {
            this.branches = resp.result;
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        },
        () => {
          this.repoLoader = false;
        }
      );
  }
  getGitlabBranch(): void {
    this.api
      .getAllBranchsForGitlab(
        this.projectForm.get('repository_id')?.value,
        this.projectForm.get('provider')?.value,
        this.projectForm.get('connection')?.value
      )
      .subscribe(
        (resp) => {
          if (resp.code === 0) {
            this.branches = resp.result;
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        },
        () => {
          this.repoLoader = false;
        }
      );
  }
  changeProvider(): void {
    this.getAllConnection();
  }
  selectConnection(connectionName: any): void {
    // //console.log(connectionName);
    const selectedConnection: any = this.connections.find(
      (ele: any) => ele._id === connectionName._id
    );
    if (selectedConnection.provider === 'BitbucketServer') {
      this.provider = 'BitbucketServer';
    }
    else if(selectedConnection.provider === 'Bitbucket'){
      this.provider = 'Bitbucket';
    }
    this.projectForm.patchValue({
      repository_type: selectedConnection.connection_type,
      // provider: selectedConnection.provider,
    });
    this.appLoader = true;
    this.getAllRepo();
  }
  selectRepo(repoName: any): void {
    // //console.log('selectRepo', repoName);
    const selectedRepo: any = this.repos.find(
      (ele: any) => ele.full_name === repoName
    );
    this.projectForm.patchValue({
      repository_name: selectedRepo.name,
      repository_id: selectedRepo.id,
    });
    this.repoLoader = true;
    this.getAllBranches();
    if (this.projectForm.get('provider')?.value === 'Azure') {
      this.selectedAzureConnection = selectedRepo;
      // //console.log('selectedRepo', this.selectedAzureConnection);
    }
    if (this.projectForm.get('provider')?.value === 'GitHub') {
      this.getAllContributors();
    }
  }
  onSelectHashFUnctionType(e: Event): void {
    // @ts-ignore
    if (e === 'Independent') {
      const random = this.getRandomInt(0, 3);
      this.projectForm.patchValue({
        hash_function: this.hashingOption[random],
      });
    } else {
      this.projectForm.patchValue({ hash_function: null });
    }
  }
  onConnect(): void {
    this.router.navigate(['/manage/create-connection'], {
      queryParams: { provider: this.provider },
    });
  }
  onSelectBranch(branch: any): void {
    if (this.projectForm.get('provider')?.value === 'Gitlab') {
      const commitId: any = this.branches.find(
        (e: any) => e.name === branch.name
      );
      if (commitId) {
        this.projectForm.patchValue({ commitId: commitId.id });
      }
    }
  }
  onSave(): void {
    if (this.projectForm.valid) {
      let data =
        this.title === 'Create New'
          ? {
              _id: this.beforeProjectId,
              group_id: this.groupId,
              type: 'Create New',
              ...this.projectForm.getRawValue(),
            }
          : { ...this.updateData, ...this.projectForm.getRawValue() };
      if (this.projectForm.get('provider')?.value == 'Azure') {
        data.azureRepoUrl = this.selectedAzureConnection?.url;
      }
      // //console.log('submittedData>>', data);
      // return;
      this.api.createNewRepository(data).subscribe(
        (resp) => {
          if (resp.code == 0) {
            this.toaster.success(
              this.title === 'Edit' ? 'Project Updated' : 'Project Created'
            );
            if (this.projectForm.get('provider')?.value == 'Azure') {
              this.modalWebhookUrlAzure?.hide();
            } else this.modalWebhookUrl?.hide();
            this.router.navigate([`/dashboard/projects`], {
              queryParams: {
                id: this.groupId ? this.groupId : '',
                provider: this.projectForm.get('provider')?.value
                  ? this.projectForm.get('provider')?.value
                  : '',
              },
            });
          } else {
            if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
              this.toaster.error(resp.result);
            } else {
              this.toaster.error('Internal Server Error');
            }
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
    } else {
      this.modalWebhookUrl?.hide();
      this.markTouch();
    }
  }
  markTouch(): void {
    Object.keys(this.projectForm.controls).forEach((field) => {
      const control = this.projectForm.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
  }
  onGenerateSecret(): void {
    this.api
      .generateWebHookSecret(
        this.beforeProjectId ? this.beforeProjectId : this.updateData._id
      )
      .subscribe(
        (resp) => {
          if (resp.code === 0) {
            this.webhookSecret = resp.result;
          } else {
            this.toaster.error('Failed to Generate Webhook Secret Code');
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
  }
  onCopyWebHookSecret(): void {
    this.webhookSecretInput?.nativeElement.select();
    document.execCommand('copy');
    this.toaster.success('Secret Code is Copied');
  }
  onCopy(): void {
    this.webhookInput?.nativeElement?.select();
    document.execCommand('copy');
    this.toaster.success('URL is Copied');
  }
  onCopyAzure(): void{
    this.webhookAzureInput?.nativeElement?.select();
    document.execCommand('copy')
    this.toaster.success('URL is Copied');
  }
  getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getAllContributors(): void {
    this.api
      .getAllContributors(
        this.projectForm.get('repository_full_name')?.value,
        this.projectForm.get('provider')?.value,
        this.projectForm.get('connection')?.value
      )
      .subscribe(
        (resp) => {
          if (resp.code === 0) {
            this.contributorsList = resp.result;
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        },
        () => {
          this.repoLoader = false;
        }
      );
  }
}
