import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inDays',
})
export class InDaysPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    if (value) {
      const seconds = Math.floor(+new Date(value) - +new Date()) / 1000;
      if (seconds < 0) {
        // less than 30 seconds ago will show as 'Just now'
        return 'Expired';
      }
      const intervals: any = {
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
      };
      let counter;
      // tslint:disable-next-line:forin
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0) {
          if (counter === 1) {
            return 'In ' + counter + ' ' + i; // singular (in 1 day)
          } else {
            return 'In ' + counter + ' ' + i + 's'; // plural (in 2 days)
          }
        }
      }
    }
    return value;
  }
}
