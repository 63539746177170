import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-vulnerability',
  templateUrl: './vulnerability.component.html',
  styleUrls: ['./vulnerability.component.scss']
})
export class VulnerabilityComponent implements OnInit {

  @Input() url: string = '';
  data: any;
  allLoaded: boolean = false;
  filteredVulnerabilities: any[] = [];
  selectedSeverity: string = '';
  dataSource: MatTableDataSource<any>;

  columnsToDisplay = ['package', 'version', 'cvss', 'score', 'severity'];
  expandedElement: any | null = null;

  constructor(private http: HttpClient, private api: ApiService) {
    this.dataSource = new MatTableDataSource(this.filteredVulnerabilities);
  }

  ngOnInit(): void {
    this.fetchJsonFromUrl(this.url);
  }

  fetchJsonFromUrl(url: string): void {
    this.http.get(url).subscribe(
      (data: any) => {
        if (data) {
          this.data = data;
          this.fetchGitHubAdvisories();
          this.processVulnerabilities();
        } else {
          console.error('No data found in JSON data.');
        }
      },
      (error: any) => {
        console.error('Error fetching JSON data:', error);
      }
    );
  }

  async processVulnerabilities() {
    this.filterVulnerabilities();
  }

  async fetchGitHubAdvisories() {
    try {
      console.log('Fetching GitHub Advisory...');
      this.api.fetchGithubAdvisories(this.data.matches).subscribe(
        (response) => {
          this.data.matches = response;
          this.processVulnerabilities();
          console.log('Response data:', response); // Log the response data
          // You can now handle further logic with the response data here
        },
        (error) => {
          console.error('Error fetching advisories:', error); // Log any errors
        }
      );
      
     return true
    } catch (error) {
      console.error('Error fetching GitHub Advisory:', error);
      return null;
    }
  }

  private extractContent(doc: Document, selector: string): string {
    const element = doc.querySelector(selector);
    //@ts-ignore
    return element ? element.textContent.trim() : '';
  }

  filterVulnerabilities(): void {
    if (this.selectedSeverity) {
      this.filteredVulnerabilities = this.data.matches.filter(
        (vuln: any) => vuln.vulnerability.severity === this.selectedSeverity
      );
    } else {
      this.filteredVulnerabilities = this.data.matches;
    }
    this.dataSource.data = this.filteredVulnerabilities;
    console.log('Filtered vulnerabilities:', this.filteredVulnerabilities);
  }

  loadMore(): void {
    console.log('Load more clicked');
    // Implement logic to load more vulnerabilities, e.g., pagination
  }
}