import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function CommaSeperateEmailsValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    var emails = control.value;

    // Split string by comma into an array
    emails = emails && emails.split(',');

    var regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    var invalidEmails = [];

    for (var i = 0; i < emails.length; i++) {
      // Trim whitespaces from email address
      emails[i] = emails[i].trim();

      // Check email against our regex to determine if email is valid
      if (emails[i] == '' || !regex.test(emails[i])) {
        invalidEmails.push(emails[i]);
      }
    }

    return invalidEmails.length > 0
      ? { emails: { value: control.value } }
      : null;
  };
}
