import jwt_decode from 'jwt-decode';
export const jsonToQueryString = (options: any): string => {
  let params = new URLSearchParams();
  for (let key in options) {
    params.set(key, options[key]);
  }
  return params.toString();
};

export const decodeToken = (token: string): Object | null => {
  try {
    return jwt_decode(token);
  } catch (Error) {
    return null;
  }
};

export const onlycharector = (event: any): boolean => {
  return (
    (event.charCode > 64 && event.charCode < 91) ||
    (event.charCode > 96 && event.charCode < 123) ||
    event.charCode == 32
  );
};
