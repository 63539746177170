import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { AuthenticationService } from './service/authentication.service';
import { Location } from '@angular/common';
import { interval, Subscription } from 'rxjs';
import { ApiService } from './service/api.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { UpdateAll } from './store/user.action';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // @HostListener('window:beforeunload', [ '$event' ])
  title = 'codelock-ui';
  timeout: any;
  sub: any;
  ip: any;
  intervalSeq: any;
  constructor(
    private auth: AuthenticationService,
    private location: Location,
    private router: Router,
    private api: ApiService,
    private toaster: ToastrService,
    private store: Store
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          /* Do something here */
          const currentLocation = this.location.path();
          if (currentLocation == '/auth/login') {
            // //console.log('logout');
            this.auth.logout();
          }
        }
      }
    });
  }
  // unloadHandler(event: any) {
  //   // ...
  //   this.auth.logout();
  // }


  // onWindowClose(event: any): void {
  //   // Do something

  //   //  event.preventDefault();
  //    this.auth.logout();
  //   //  event.returnValue = false;

  // }
  ngOnInit(): void {
    this.checkClick();
    let time = localStorage.getItem("time")
    if(!time) {
      this.auth.logout()
    } else if (moment(time).isBefore(moment())) {
      this.auth.logout()
    } else {
      this.auth.autoLogin(this.location.path());
      this.checkAuthSession();
      // 
    }
    this.checkAuthSession();
    this.sub = setInterval(() => {
      this.checkAuthSession();
    }, 300000)
    

    this.intervalSeq = setInterval(() => {
      let time = localStorage.getItem("time");
      if (!time) {
        if (
          this.location.path() !== '/auth/login' &&
          this.location.path() !== '/' &&
          this.location.path() !== '/auth/signup'
        ) {
          
          this.auth.logout();
        }
      } else if (moment(time).isBefore(moment())) {
        if (
          this.location.path() !== '/auth/login' &&
          this.location.path() !== '/' &&
          this.location.path() !== '/auth/signup'
        ) {
        }
      }
    }, 1000);
    //300000
  }
  getIp(): void {
    // this.api.ipInfo().subscribe((resp)=>{
    //   // //console.log(resp);

    //   this.ip = resp;
    // })
  }

  checkAuthSession(): void {
    this.getIp();
    this.api.checkActiveSession().subscribe(
      (resp) => {
        // //console.log(resp);

        //@ts-ignore
        if (!resp.success) {
          this.auth.logout();
          this.toaster.error('Session Expired Please Login');
          // this.auth.clearsessionStorage();
          // this.store.dispatch(
          //   new UpdateAll({
          //     token: null,
          //     first_name: null,
          //     last_name: null,
          //     type: null,
          //     accountId: null,
          //     two_step_on: false,
          //     org_id: null,
          //     permissions: null,
          //   })
          // );
          // this.router.navigate(['/auth/login']);
        }
      },
      (error: HttpErrorResponse) => {
        // //console.log(error);
        if (error.status === 400) {
          this.toaster.error('Session Expired Please Login');
          this.auth.logout();
          // this.auth.clearsessionStorage();
          // this.store.dispatch(
          //   new UpdateAll({
          //     token: null,
          //     first_name: null,
          //     last_name: null,
          //     type: null,
          //     accountId: null,
          //     two_step_on: false,
          //     org_id: null,
          //     permissions: null,
          //   })
          // );
          // this.router.navigate(['/auth/login']);
        }
      }
    );
  }
  ngOnDestroy(): void {
    clearInterval(this.sub)
    this.auth.login({}).subscribe((resp) => { });
    clearInterval(this.intervalSeq)
  }

  checkTimeout(): void {
    //   this.timeout = setTimeout(() => {
    //   this.auth.logout();
    // }, 900000);
    let time = localStorage.getItem("time")
    if(!time) {
      this.auth.logout()
    } else if (moment(time).isBefore(moment())) {
      this.auth.logout()
    } else {

      let date = moment().add(15, "m");
      // this.auth.saveToken({time:  date})
      sessionStorage.setItem('time', date.toISOString());
      localStorage.setItem('time', date.toISOString());
    }

    // 120000
  }
  checkClick(): void {
    const currentPath = this.location.path();

    if (currentPath !== '/auth/login' && currentPath !== '/' && currentPath !== '/auth/signup') {
    clearTimeout(this.timeout)
    this.checkTimeout();
    }
  }
}

