<div class="at-selectrepo">
  <div class="at-selectrepohead">
    <figure class="at-bucketicon">
      <img
        *ngIf="selectedFormat === 'github'"
        src="/assets/images/github-w.png"
        alt="Gitlab Icon"
      />
      <img
        *ngIf="selectedFormat === 'gitlab'"
        src="/assets/images/gitlab.png"
        alt="Gitlab Icon"
      />
      <img
        *ngIf="selectedFormat === 'bitbucket'"
        src="/assets/images/bitbucket.svg"
        alt="Gitlab Icon"
      />
      
    </figure>
    <div class="at-selectcontentholder">
      <h3>Please select the repository</h3>
      <span>Select one of the repositories to get started</span>
    </div>
  </div>
  <div class="at-repostries">
    <div *ngFor="let item of fetchProjects" class="at-repostry">
      <input
        type="radio"
        name="respo"
        id="`${{ item?.repoName }}`"
        (change)="radioChanged(item)"
      />
      <label for="`${{ item?.repoName }}`">
        <h4>{{ item?.repoName }}</h4>
        <span>Last commit: {{ item.createdAt }}</span>
      </label>
    </div>
  </div>
  <button
  href="javascript:void(0)"
  [disabled]="!selectRepo || selectRepoLoading"
  class="button button--primary AtBtnUpgrade AtbtnGetProject"
  (click)="openBranchModal()"
  style="width: 100%;" >
Select Branch</button>
<div *ngIf="realBranch" class="selected-branch-display">
  Selected Branch: {{ realBranch }}
</div>
  <button
    href="javascript:void(0)"
    [disabled]="!selectRepo || selectRepoLoading"
    class="button button--primary AtBtnUpgrade AtbtnGetProject"
    (click)="fetchPreviousProjects()"
  >
    <!-- {{ selectRepoLoading ? "Loading" : "Sync Data" }} -->
    <div *ngIf="selectRepoLoading; else syncData">
      <div class="at-tableloader">
        <div class="lds-dual-ring"></div>
      </div>
    </div>
    <ng-template #syncData> Sync Data </ng-template>
  </button>
</div>

<div class="modal" *ngIf="showBranchModal">
  <div class="modal-content">
    <span class="close-button" (click)="closeBranchModal()">&times;</span>
    <h3>Select a Branch</h3>
    <ng-select [(ngModel)]="selectedBranch">
      <ng-option *ngFor="let branch of branches" [value]="branch">{{ branch }}</ng-option>
    </ng-select>
    <button class="button button--primary" (click)="lockBranch()">Select Branch</button>
  </div>
</div>



<style>
  /* CSS for the modal container */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Add a semi-transparent background overlay */
}

/* CSS for the modal content */
.modal-content {
  background-color: rgb(23, 17, 112);
  padding: 20px;
  margin: auto;
  width: 50%;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}
.selected-branch-display {
  text-align: center;
  color: white;
  margin-top: 10px; /* Add some space between the button and the text */
}

</style>