import { Component, OnInit } from '@angular/core';
// import { RouterLinkActive, Router, NavigationEnd } from '@angular/router';
import {
  Router,
  NavigationStart,
  Event as NavigationEvent,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { data } from 'jquery';
import { SideFilter } from 'src/app/interface/SideFilter';
import { MonitorApiService } from 'src/app/service/monitor-api.service';
import {
  UpdateDateRange,
  UpdateGroups,
  UpdateRepos,
  ResetAllFilters,
  ResetCalendarFilter,
} from 'src/app/store/sidebar-filter.action';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
import { Util } from 'src/app/service/utils';

@Component({
  selector: 'app-analytics-component',
  templateUrl: './analytics-component.component.html',
  styleUrls: ['./analytics-component.component.scss']
})
export class AnalyticsComponentComponent implements OnInit {
  date: any;
  repos: any = [];
  moreRepos: any = [];
  groups: any = [];
  moreGroups: any = [];
  selectedRepo: string[] = [];
  selectedGroup: string[] = [];
  moreRepoOpen = false;
  moreGroupOpen = false;
  filters: boolean = true;
  url: any;
  // mapCss: String = 'col-md-10'
  disputes = 0;
  serverDisputes = 0;
  users = 0;
  commits = 0;
  showStatus = false;
  totalDisputes = 0;
  defaultStartDate: any;
  orgStartDate: any;
  // datePipe: DatePipe = new DatePipe('Date');
  today: Date = new Date();
  public currentYear: number = this.today.getFullYear();
  public currentMonth: number = this.today.getMonth();
  public currentDay: number = this.today.getDate();
  public maxDate: any = new Date(
    this.currentYear,
    this.currentMonth,
    this.currentDay
  );
  currentDate = this.maxDate;
  constructor(
    private store: Store<{ sideBarFilter: SideFilter }>,
    private api: MonitorApiService
  ) {}

  ngOnInit(): void {
    this.getCurrentUser()
    // this.getCurrentUser();
    // this.getCurrentUser();
    this.getAllGroups(), this.getAllRepository();
  }

  onDateChange(): void {
    // this.getAllDataForMonitor();
    const start =  moment(this.date[0])
    const end =  moment(this.date[1])
    this.store.dispatch(
      new UpdateDateRange({ start_date: start.format('MM/DD/YYYY'), end_date: !end.isSame(start) ? this.date[1] : end.add(1,'days').format('MM/DD/YYYY')})
    );
    // this.bsDatePicker.nativeElement.classList.remove('active');
  }

  getAllGroups(): void {
    this.api.getAllGroups().subscribe((resp) => {
      if (resp.result.length > 5) {
        this.groups = [...resp.result.slice(0, 5)];
        this.moreGroups = [...resp.result.slice(5)];
      } else {
        this.groups = resp.result;
      }
    });
  }
  getAllRepository(): void {
    this.api.getAllRepo().subscribe((resp) => {
      if (resp.result.length > 5) {
        this.repos = [...resp.result.slice(0, 5)];
        this.moreRepos = [...resp.result.slice(5)];
      } else {
        this.repos = resp.result;
      }
    });
  }

  selectGroup(id: string): void {
    if (this.selectedGroup.includes(id)) {
      this.selectedGroup = this.selectedGroup.filter((e: string) => e !== id);
    } else {
      this.selectedGroup = [...this.selectedGroup, id];
    }
    // //console.log('id>>', id, this.selectedGroup);
    this.store.dispatch(new UpdateGroups({ groups: this.selectedGroup }));
    // this.getAllDataForMonitor();
  }
  selectRepos(id: string): void {
    if (this.selectedRepo.includes(id)) {
      this.selectedRepo = this.selectedRepo.filter((e) => e !== id);
    } else {
      this.selectedRepo = [...this.selectedRepo, id];
    }
    // this.getAllDataForMonitor();
    // //console.log('selectRepos');
    this.store.dispatch(new UpdateRepos({ repos: this.selectedRepo }));
  }
  resetFilter(): void {
    const date = [new Date(this.defaultStartDate), new Date(this.currentDate)];
    this.date = date;
    this.selectedGroup = [];
    this.selectedRepo = [];
    this.store.dispatch(
      new ResetAllFilters({
        start_date: new Date(this.defaultStartDate),
        end_date: !moment(this.currentDate).isSame(moment(this.defaultStartDate)) ? new Date(this.currentDate) : moment(this.currentDate).add(1,'days').format('MM/DD/YYYY'),
        repos: [],
        groups: [],
      })
    );
  }

  getCurrentUser(): void {
    this.api.getUser().subscribe(
      (resp: any) => {
        if (resp.code === 0) {
          // this.defaultStartDate = resp.result.orgStartDate;
          // this.defaultStartDate = this.datePipe.transform(
          //   this.defaultStartDate,
          //   'yyyy-MM-dd'
          // );
          this.currentDate = new Date();
          // this.datePipe.transform(
          //   this.currentDate,
          //   'yyyy-MM-dd'
          // );
          this.orgStartDate = new Date(resp.result.orgStartDate);

          // //console.log(this.defaultStartDate);
          // new Date(this.defaultStartDate);
          const date = [
            new Date(this.orgStartDate),
            // moment(resp.result.orgStartDate).utcOffset(new Date().getTimezoneOffset()),
            new Date(this.currentDate),
          ];
          this.date = date;
          this.defaultStartDate = this.date[0]
          this.store.dispatch(
            new ResetAllFilters({
              start_date: this.date[0],
              end_date: this.date[1],
              repos: [],
              groups: [],
            })
          );
        }
      },
      (error: HttpErrorResponse) => {
        // //console.log('error>>', error);
      }
    );
  }
  
  clearCalendar(): void {
    const date = [new Date(this.defaultStartDate), new Date(this.currentDate)];
    this.date = date;

    this.store.dispatch(
      new ResetCalendarFilter({
        start_date: new Date(this.defaultStartDate),
        end_date: moment(this.currentDate).isSame(moment(this.defaultStartDate)) ? new Date(this.currentDate) : moment(this.currentDate).add(1,'days').format('MM/DD/YYYY'),
      })
    );
  }
  

}
