import { Component, OnInit } from '@angular/core';
import { auth } from 'src/app/config/firebase';
import { signInWithPopup, GoogleAuthProvider, getAuth, GithubAuthProvider } from 'firebase/auth';
import { ApiService } from 'src/app/service/api.service';
import * as moment from 'moment';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-sso-signup',
  templateUrl: './sso-signup.component.html',
  styleUrls: ['./sso-signup.component.scss']
})
export class SsoSignupComponent implements OnInit {
  email: string = ""
  orgId: string = ""
  userName: string = ""
  userEmail: string = ""
  constructor(
    private API: ApiService,
    private auth: AuthenticationService,
    private router: Router,

  ) { 
    
  }
  ngOnInit(): void {
  }

  signUpWithGoogle() {
    const provider = new GoogleAuthProvider();
    const login = signInWithPopup(auth, provider).then((result) => {
      if (!result.user.email){
        return
      }
      this.email = result.user.email
      this.API.firebaseSignup({
        email: result.user.email,
        displayName: result.user.displayName,
        provider: "Google"
      }).subscribe(
        (resp) => {
          if (resp.code === 0){
            let date = moment().add(15, "m");
            this.auth.saveToken(
              resp.result.token,
              resp.result.first_name,
              resp.result.last_name,
              resp.result.accountId,
              resp.result.org_id,
              resp.result.org_name,
              resp.result.type,
              resp.result.two_step_on,
              resp.result.permissions,
              date.toISOString()
            );
            this.orgId = resp.result.org_id;
            this.auth.setUserData(resp.result);
            this.checkSubStatus();
          }
          else {
          }
        }
      )

    }).catch((error) => {
      console.log(error);
    });

  }

  signUpWithGithub() {
    const provider = new GithubAuthProvider();
    const login = signInWithPopup(auth, provider).then((result) => {
      if (!result.user.email){
        return
      }
      this.email = result.user.email
      this.API.firebaseSignup({
        email: result.user.email,
        displayName: result.user.displayName,
        provider: "Github"
      }).subscribe(
        (resp) => {
          if (resp.code === 0){
            let date = moment().add(15, "m");
            this.auth.saveToken(
              resp.result.token,
              resp.result.first_name,
              resp.result.last_name,
              resp.result.accountId,
              resp.result.org_id,
              resp.result.org_name,
              resp.result.type,
              resp.result.two_step_on,
              resp.result.permissions,
              date.toISOString()
            );
            this.orgId = resp.result.org_id;
            this.auth.setUserData(resp.result);
            this.checkSubStatus();
          }
          else {
            console.log("Sign in failed")
          }
        }
      )

    }).catch((error) => {
      console.log(error);
    });
  }

  signUpWithEmail() {
    const name = this.userName;
    const email = this.userEmail;

    // Your sign-up with email logic here
    console.log('Sign up with Email');
    console.log('Name:', name);
    console.log('Email:', email);



    this.API.firebaseSignup({
        email: email,
        displayName: name,
        provider: "Google"
      }).subscribe(
        (resp) => {
          if (resp.code === 0){
            let date = moment().add(15, "m");
            this.auth.saveToken(
              resp.result.token,
              resp.result.first_name,
              resp.result.last_name,
              resp.result.accountId,
              resp.result.org_id,
              resp.result.org_name,
              resp.result.type,
              resp.result.two_step_on,
              resp.result.permissions,
              date.toISOString()
            );
            this.orgId = resp.result.org_id;
            this.auth.setUserData(resp.result);
            this.checkSubStatus();
          }
          else {
            console.log("Sign in failed")
          }
        }
      )
  }



  async checkSubStatus(): Promise<void> {
    const subStatus = await this.auth.checkSubStatus();
    this.router.navigate(['/home']);
  }
  

}
