import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-verify-questioner',
  templateUrl: './verify-questioner.component.html',
  styleUrls: ['./verify-questioner.component.scss'],
})
export class VerifyQuestionerComponent implements OnInit {
  constructor(
    private api: ApiService,
    private router: Router,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {}
  onResend(): void {
    this.api.resendQuestionerLink().subscribe(
      (resp: any) => {
        if (resp.code == 0) {
          this.toaster.success(resp.result);
          this.router.navigate(['/auth/login']);
        } else {
          this.toaster.error(resp.result);
        }
      },
      (error: HttpErrorResponse) => {
        this.toaster.error('Something error! Please try after sometime.');
      }
    );
    // this.router.navigate(['/auth/login']);
  }
}
