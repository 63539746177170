<div class="site__authentication site__signin">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-5">
        <form
          *ngIf="!showOtp"
          [formGroup]="signupForm"
          autocomplete="disabled"
          class="mb-5"
        >
          <div class="logo">
            <img src="/assets/images/logo__icon.svg" alt="" />
          </div>
          <div class="mb-4">
            <div class="row">
              <div class="col-md-6 pe-2">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="First Name"
                    autocomplete="off"
                    formControlName="first_name"
                    onkeypress="return (
    (event.charCode > 64 && event.charCode < 91) ||
    (event.charCode > 96 && event.charCode < 123) ||
    event.charCode == 32
  )"
                  />
                  <span class="icon">
                    <img src="/assets/images/icon__name.svg" alt="" />
                  </span>
                </div>
                <span
                  class="form-text form-error"
                  *ngIf="
                    signupForm.get('first_name')?.getError('required') &&
                    signupForm.get('first_name')?.touched
                  "
                  >This is a required field.</span
                >
              </div>
              <div class="col-md-6 ps-2">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Last Name"
                    autocomplete="off"
                    formControlName="last_name"
                    onkeypress="return (
    (event.charCode > 64 && event.charCode < 91) ||
    (event.charCode > 96 && event.charCode < 123) ||
    event.charCode == 32
  )"
                  />
                  <span class="icon">
                    <img src="/assets/images/icon__name.svg" alt="" />
                  </span>
                </div>
                <span
                  class="form-text form-error"
                  *ngIf="
                    signupForm.get('last_name')?.getError('required') &&
                    signupForm.get('last_name')?.touched
                  "
                  >This is a required field.</span
                >
              </div>
            </div>
          </div>
          <div class="mb-4">
            <div class="row">
              <div class="col-md-12">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Software Producer Company Name"
                    autocomplete="off"
                    formControlName="company_name"
                    (blur)="checkAvailability()"
                  />
                  <span class="icon">
                    <img src="/assets/images/icon__company.svg" alt="" />
                  </span>
                </div>
                <span
                  class="form-text form-error"
                  *ngIf="
                    signupForm.get('company_name')?.getError('required') &&
                    signupForm.get('company_name')?.touched
                  "
                  >This is a required field.</span
                >
                <span class="form-text form-error" *ngIf="!orgAvailability"
                  >This Company is already registered</span
                >
              </div>
              <!-- <div class="col-md-4 ps-2">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Company Code"
                    autocomplete="off"
                  />
                  <span class="icon">
                    <img src="/assets/images/icon__company.svg" alt="" />
                  </span>
                </div>
              </div> -->
            </div>
          </div>
          <div class="mb-4">
            <div class="row">
              <div class="col-md-12">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="User License Company Name"
                    autocomplete="off"
                    formControlName="user_company_name"
                  />
                  <span class="icon">
                    <img src="/assets/images/icon__company.svg" alt="" />
                  </span>
                </div>
                <span
                  class="form-text form-error"
                  *ngIf="
                    signupForm.get('user_company_name')?.getError('required') &&
                    signupForm.get('user_company_name')?.touched
                  "
                  >This is a required field.</span
                >
                <!-- <span class="form-text form-error" ></span> -->
              </div>
            </div>
          </div>
          <div class="mb-4">
            <div class="position-relative">
              <input
                type="email"
                class="form-control"
                placeholder="Email"
                autocomplete="off"
                formControlName="email"
              />
              <span class="icon">
                <img src="/assets/images/icon__email.svg" alt="" />
              </span>
            </div>
            <span
              class="form-text form-error"
              *ngIf="
                signupForm.get('email')?.getError('required') &&
                signupForm.get('email')?.touched
              "
              >This is a required field.</span
            >
            <span
              class="form-text form-error"
              *ngIf="
                signupForm.get('email')?.getError('pattern') &&
                signupForm.get('email')?.touched
              "
              >Please Enter a organization email.</span
            >
          </div>
          <div class="mb-4">
            <div class="row">
              <div class="col-md-4 pe-2">
                <div class="position-relative">
                  <!-- <select
                    class="form-control form-select"
                    formControlName="countryCode"
                  >
                    <option *ngFor="let cc of countryCodes" [value]="cc.code">
                      {{ cc.code }} ({{ cc.name }})
                    </option>
                  </select> -->
                  <ng-select
                    [items]="countryCodes"
                    bindLabel="label"
                    bindValue="code"
                    placeholder="Select country code"
                    formControlName="countryCode"
                  ></ng-select>
                  <span class="icon">
                    <img src="/assets/images/icon__country.svg" alt="" />
                  </span>
                </div>
              </div>
              <div class="col-md-8 ps-2">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter phone number"
                    autocomplete="new-phoneNo"
                    formControlName="phoneNo"
                    (keypress)="numberOnly($event)"
                    (input)="onPastePhoneNumber()"
                  />
                  <span class="icon">
                    <img src="/assets/images/icon__phone.svg" alt="" />
                  </span>
                </div>
                <span
                  class="form-text form-error"
                  *ngIf="
                    signupForm.get('phoneNo')?.getError('required') &&
                    signupForm.get('phoneNo')?.touched
                  "
                  >This is a required field.</span
                >
                <span
                  class="form-text form-error"
                  *ngIf="
                    signupForm.get('phoneNo')?.getError('pattern') &&
                    signupForm.get('phoneNo')?.touched
                  "
                  >Please enter a valid Phone No.</span
                >
              </div>
            </div>
          </div>
          <div class="col-md-12 mb-4">
            <div class="position-relative">
              <ng-select
                [items]="nationalitys"
                bindLabel="label"
                bindValue="country"
                placeholder="Select Nationality"
                formControlName="nationality"
              ></ng-select>
              <span class="icon">
                <img src="/assets/images/icon__flag.svg" alt="" />
              </span>
            </div>
            <span
              class="form-text form-error"
              *ngIf="
                signupForm.get('nationality')?.getError('required') &&
                signupForm.get('nationality')?.touched
              "
              >This is a required field.</span
            >
          </div>
          <div class="mb-4">
            <div class="position-relative">
              <input
                type="password"
                class="form-control"
                placeholder="Password"
                autocomplete="new-password"
                formControlName="password"
                (input)="
                  signupForm.get('confirm_password')?.updateValueAndValidity()
                "
              />
              <span class="icon icon--lg">
                <img src="/assets/images/icon__password.svg" alt="" />
              </span>
            </div>
            <span
              class="form-text form-error"
              *ngIf="
                signupForm.get('password')?.getError('required') &&
                signupForm.get('password')?.touched
              "
              >This is a required field.</span
            >
            <div
              *ngIf="
                signupForm.get('password')?.getError('pattern') &&
                signupForm.get('password')?.touched
              "
            >
              <p class="form-text form-info mt-3 mb-0">
                <span>Use at least 10 characters</span>
                <span>Use upper and lower case characters</span>
                <span>Use 1 or more numbers</span>
                <span>Use 1 special characters</span>
              </p>
            </div>
          </div>
          <div class="mb-4">
            <div class="position-relative">
              <input
                type="password"
                class="form-control"
                placeholder="Confirm Password"
                autocomplete="off"
                formControlName="confirm_password"
                appConfirmEqualValidator="password"
              />
              <span class="icon icon--lg">
                <img src="/assets/images/icon__password.svg" alt="" />
              </span>
            </div>
            <span
              class="form-text form-error"
              *ngIf="
                signupForm.get('confirm_password')?.getError('required') &&
                signupForm.get('confirm_password')?.touched
              "
              >This is a required field.</span
            >
            <span
              class="form-text form-error"
              *ngIf="
                signupForm.get('confirm_password')?.getError('notEqual') &&
                !signupForm.get('confirm_password')?.getError('required')
              "
              >Password & Confirm Password don't match</span
            >
          </div>
          <!-- <div>
            <input
              type="checkbox"
              id="checkbox"
              formControlName="acceptTerms"
              (change)="toggleButton()"
            />
            <span class="icon"></span>
            <a
              href="https://www.codelock.it/end-user-license-agreement.html"
              for="checkbox"
              target="_blank"
              >End User Lisence Aggrement</a
            >
          </div> -->
          <div
            class="agreement justify-content-center gap-2 mb-2"
          >
            <div class="form-checkbox">
              <input
                type="checkbox"
                name="checkbox_manage-account"
                id="ma_showinactive"
                (change)="toggleButton()"
              />
              <span class="icon"></span>
            </div>
            <label class="form-label mb-0 ms-3">
              <a
                href="https://www.codelock.it/terms-and-conditions"
                target="_blank"
              >
                Accept End User License Agreement
              </a>
            </label>
          </div>
          
          <div
          class="agreement justify-content-center"
        >
          <div class="form-checkbox">
            <input
              type="checkbox"
              name="checkbox_manage-account"
              id="ma_showinactive"
              (change)="toggleButton2()"
            />
            <span class="icon"></span>
          </div>
          <label class="form-label mb-0 ms-3">
            <a
              href="https://www.codelock.it/master-service-agreement"
              target="_blank"
            >
              Accept Customer Master Service Agreement
            </a>
          </label>
        </div>
        
          
          <div class="button-group mt-4">
            <button
              (click)="onSignup()"
              type="submit"
              class="button button--primary w-100"
              *ngIf="this.switchCase && this.switchCase2"
            >
              Sign Up
            </button>
          </div>
        </form>
        <form *ngIf="showOtp" autocomplete="off" class="mb-5">
          <div class="logo">
            <img src="/assets/images/logo__icon.svg" alt="" />
          </div>
          <div class="mb-4">
            <label for="" class="form-label d-block text-center mb-3"
              >Please enter your 6 digit OTP</label
            >
            <div class="form-otp">
              <ng-otp-input
                [config]="{ length: 6 , allowNumbersOnly : true }"
                containerClass="true"
                inputClass="true"
                (onInputChange)="onOtpChange($event)"
                #ngOtpInput
              ></ng-otp-input>
            </div>
            <span
              class="form-text d-block text-center mt-3 weight-600 text-warning"
              *ngIf="timer > 0"
            >
              <i
                class="fa fa-hourglass-half me-2 fa-spin"
                aria-hidden="true"
              ></i>
              OTP valid until {{ timer }}s
            </span>
            <span
              class="form-text d-block text-center mt-3 weight-600 text-warning"
              *ngIf="timer <= 0"
              >Haven't received the OTP?
              <a
                href="javascript:void(0)"
                *ngIf="timer <= 0"
                (click)="resendOtp()"
              >
                Resend OTP</a
              ></span
            >
          </div>
          <div class="button-group justify-content-center">
            <button
              href="javascript:void(0)"
              class="button button--primary w-50"
              (click)="onVerifyOtp()"
            >
              Verify OTP
            </button>
          </div>
        </form>
        <p class="text-center m-0">
          Already have an account?
          <a href="javascript:void(0)" routerLink="/auth/login">Sign In</a>
        </p>
      </div>
    </div>
  </div>
</div>
