import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Permission } from 'src/app/interface/permission';
import { ApiService } from 'src/app/service/api.service';
import { Util } from 'src/app/service/utils';
import { starter_tier_permissions } from 'src/app/constants';
@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.scss'],
})
export class EditRoleComponent implements OnInit {
  roleForm = new UntypedFormGroup({
    name: new UntypedFormControl(null, Validators.required),
    permissionList: new UntypedFormControl(null, Validators.required),
  });
  id: any = '';
  total: number = 0;
  isEdit: boolean = false;
  permissions: Permission[] = [];
  constructor(
    private api: ApiService,
    private toaster: ToastrService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private util: Util
  ) {}

  ngOnInit(): void {
    this.activeRoute.params.subscribe((v: any) => {
      if (Object.keys(v).length > 0) {
        // //console.log('v>>', v);
        this.isEdit = true;
        this.id = v.id;
        this.getRole(this.id);
      } else {
        this.isEdit = false;
        this.id = null;
      }
    });
    this.getPermissionList();
  }
  getRole(id: string): void {
    this.api.getRole(this.id).subscribe(
      (resp) => {
        if (resp.code === 0) {
          // this.roles = resp.result.data;
          this.roleForm.patchValue(resp.result);
          this.total = resp.result.total;
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  getPermissionList(): void {
    this.api.getPermissions().subscribe(
      (resp: any) => {
        // if (resp.code === 0) {
          //@ts-ignore
        if (this.util.getSubscriptionTier() <= 1) {
          // console.log('starter tier permissions', starter_tier_permissions);
          //@ts-ignore
          this.permissions = resp.data.filter(permission => starter_tier_permissions.includes(permission.key));
        }else{
          // console.log(this.util.getSubscriptionTier());
          // console.log("permissions not working")
          this.permissions = resp.data;
        }
        // this.total = resp.result.total;
        // }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  onSubmit(): void {
    if (this.roleForm.valid) {
      let data = this.roleForm.getRawValue();
      if (this.isEdit) {
        data._id = this.id;
      }
      this.api.createUpdateRole(data).subscribe(
        (resp: any) => {
          if (resp.code === 0) {
            this.toaster.success(
              `Role has been ${
                this.isEdit ? 'updated' : 'created'
              } successfully`
            );
            this.router.navigate(['/dashboard/manage/roles']);
          } else {
            this.toaster.error(resp?.result);
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
    } else {
      this.roleForm.markAllAsTouched();
    }
  }
}
