export interface User {
  token: null | string;
  first_name: null | string;
  last_name: null | string;
  org_id: null | string;
  accountId: null | string;
  type: null | string;
  two_step_on: boolean;
  permissions: string[] | null;
  // roles: [];
}

export interface UserState extends User {
  expired: boolean;
  hasSubscription: boolean;
  subscriptionType: string;
  paymentFailure: boolean;
}
