<div class="w-100">
  <div class="AtStartProject">
    <h3>Introducing our latest solution. The sBOM Document Creator.</h3>
    <p>
      Seamlessly generating Software Bill of Materials (SBOM) documents, His innovative tool transforms source code into comprehensive documentation highlighting dependencies, origins, versions, and licenses. Experience efficiency and accuracy in managing your software components while ensuring compliance
    </p>
    <a href="javascript:void(0)" class="button button--primary AtBtnUpgrade" (click)="navigateHandler()">Start your first project</a>
  </div>
  <!-- <div *ngFor="let option of sourceOptions">
    <label>
      <input
        type="radio"
        name="options"
        [(ngModel)]="selectedSource"
        [value]="option"
      />
      {{ option }}
    </label>
  </div>
  <h1></h1> -->

  <!-- <div *ngIf="selectedSource !== 'Bitbucket'" style="margin: 40px 0">
    <input
      type="text"
      class="form-control"
      placeholder="Access Token"
      autocomplete="off"
      formControlName="access_token"
      (keyup)="accessTokenHandler($event)"
    />
  </div> -->

  

  <!-- <div class="button-group" style="margin: 40px 0">
    <button
      href="javascript:void(0)"
      class="button button--primary w-20"
      (click)="repoProjectsHandler()"
    >
      Get Repo Projects
    </button>
  </div> -->

  <!-- <table class="table table-borderless m-0">
    <thead>
      <tr>
        <th scope="col">Username</th>
        <th scope="col">Repo Name</th>
        <th scope="col">Repo URL</th>

        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="fetchProjects.length === 0">
        <td class="text-center" colspan="7">No Repo Found</td>
      </tr>
      <tr *ngFor="let project of fetchProjects">
        <td>
          <a class="weight-600" href="javascript:void(0)">{{
            project.owner.login
          }}</a>
        </td>
        <td>{{ project.name }}</td>
        <td>{{ project.url }}</td>

        <td class="text-center">
          <div class="d-flex justify-content-center">
            <a
              class="action action--sml"
              href="javascript:void(0)"
              tooltip="Action"
              (click)="repoSaveHandler(project)"
            >
              <img src="/assets/images/icon__overview.svg" alt="" />
            </a>
          </div>
        </td>
      </tr>
    </tbody>
  </table> -->
</div>
