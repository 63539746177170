import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { ToastrService } from 'ngx-toastr';
import { Logs } from '../../../../interface/Logs';
import { ApiService } from '../../../../service/api.service';

@Component({
  selector: 'app-view-more-logs',
  templateUrl: './view-more-logs.component.html',
  styleUrls: ['./view-more-logs.component.scss'],
})
export class ViewMoreLogsComponent implements OnInit {
  @Input() id: any | undefined;
  @Input() total: any | undefined;
  @Input() eventData: any | undefined;
  @Input() logId: any | undefined;
  term = '';
  page = 1;
  per_page: number = 10;
  // total = 0;
  // logId: any;
  logTime: any;
  // logoutTime: any;
  logs: any[] = [];
  list: any;
  moreLogs: any[] = [];
  x: any[] = [];
  permissionLable = 'isActivityDownload';
  permissionGranted = false;

  isProcessing = false;
  @Output() closeModal = new EventEmitter<void>();
  viewLogsForm = new UntypedFormGroup({
    action: new UntypedFormControl(null, Validators.required),
    browser: new UntypedFormControl(null, Validators.required),
    ip: new UntypedFormControl(null, Validators.required),
    org_id: new UntypedFormControl(null, Validators.required),
    resource: new UntypedFormControl(null, Validators.required),
    resourceType: new UntypedFormControl(null, Validators.required),
    resource_id: new UntypedFormControl(null, Validators.required),
    user: new UntypedFormControl(null, Validators.required),
    _id: new UntypedFormControl(null, Validators.required),
  });
  value: any;
  constructor(
    private api: ApiService,
    private toaster: ToastrService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // this.route.params.subscribe((param) => {

    //   this.value = param.id;

    // });
    // this.searchLogEvent();
    // //console.log('oninit', this.eventData);
    this.getData();

    this.getLogs();
  }
  ngOnChanges(): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    // //console.log('onChange', this.eventData);

    this.getLogs();
    // this.pagination();
  }

  searchLogEvent(): void {
    // this.api.getSearchLogEvent(this.logId).subscribe((resp) => {
    //   //console.log('search log', resp);
    // });
  }
  // pagination(): void {
  //   this.copyLogs = this.eventData.slice(
  //     (this.page - 1) * this.pagesize,
  //     this.page * this.pagesize
  //   );
  //   // //console.log(this.copyLogs);
  // }

  downloadUserLog(): void {
    // //console.log(this.logId);

    this.api.downloadUserLog(this.logId, this.term).subscribe((resp) => {
      // //console.log(resp);
      // //console.log(resp.result.data);
      for(let i = 0; i< resp.result.data.length; i++){
        // //console.log(resp.result.data[i].Login_Time);
        // //console.log(resp.result.data[i].Logout_Time);
        if(resp.result.data[i].Log_Time != ''){
          this.logTime = new Date(resp.result.data[i].Log_Time);
          resp.result.data[i].Log_Time = this.logTime;
        }
        // if(resp.result.data[i].Logout_Time != ''){
        //   this.logoutTime = new Date(resp.result.data[i].Logout_Time);
        //   resp.result.data[i].Logout_Time = this.logoutTime;
        // }
        // //console.log(this.loginTime);
        // //console.log(this.logoutTime);
      }
      var options = {
        // fieldSeparator: ',',
        // quoteStrings: '"',
        // decimalseparator: '.',
        // showLabels: true,
        // showTitle: true,
        // title: 'Your title',
        // useBom: true,
        // noDownload: true,
        headers: Object.keys(resp.result.data[0]).map((data) =>
          data.replace('_', ' ')
        ),
        // useHeader: false,
        // nullToEmptyString: true,
      };
      new AngularCsv(resp.result.data, resp.result.fileName, options);
    });
  }
  getData() {
    this.list = JSON.parse(sessionStorage.getItem('permissions') || '{}');
    // //console.log(list?.length);
    // //console.log(this.list);

    for (let i = 0; i < this.list.length; i++) {
      if (this.permissionLable === this.list[i]) {
        this.permissionGranted = true;
      }
    }
  }
  getLogs(): void {
    // this.ngOnInit();
    if (this.logId) {
      this.isProcessing = false;

      // this.total = this.id?.length;

      // for (let i = 0; i < this.id.length; i++) {

      // }
      // this.total = this.id.length;
      this.api.searchEvent(this.logId, this.page, this.term).subscribe(
        (resp) => {
          // //console.log('resp event', resp);
          this.isProcessing = true;
          if (resp.code === 0) {
            if (resp.result.count > 0) {
              this.logs = resp.result.events;
            } else {
              this.logs = [];
            }
            this.total = resp.result.count;

            // //console.log('this.logs>>>', this.logs, this.total);
          } else {
            if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
              this.toaster.error(resp.result);
            } else {
              this.toaster.error('Internal Server Error');
            }
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
      // //console.log(this.total);
    }
  }
  onClearSearch(): void {
    if (this.term === '') {
      this.getLogs();
    }
  }
  onChangePage(page: number) {
    // //console.log('onchange>>', page);
    if (this.page === page) return;
    this.page = page;
    this.getLogs();
  }
  onCloseModal(): void {
    this.closeModal.emit();
    this.term = '';
  }
}
