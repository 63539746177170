<ul class="pagination" *ngIf="count > 0">
  <li class="page-item" [class.disabled]="activePage === 1">
    <a
      class="page-link"
      href="javascript:void(0)"
      aria-label="Previous"
      (click)="backwardPagenator()"
    >
      <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M39.3756,48.0022l30.47-25.39a6.0035,6.0035,0,0,0-7.6878-9.223L26.1563,43.3906a6.0092,6.0092,0,0,0,0,9.2231L62.1578,82.615a6.0035,6.0035,0,0,0,7.6878-9.2231Z"
        />
      </svg>
    </a>
  </li>
  <li
    class="page-item"
    *ngFor="let p of viewPages"
    [class.current]="p === activePage"
  >
    <a
      class="page-link"
      href="javascript:void(0);"
      (click)="setActivePage(p)"
      >{{ p }}</a
    >
  </li>
  <!-- <li class="page-item"><a class="page-link" href="#">2</a></li>
    <li class="page-item"><a class="page-link" href="#">3</a></li>
    <li class="page-item"><a class="page-link" href="#">...</a></li>
    <li class="page-item"><a class="page-link" href="#">10</a></li> -->
  <li
    class="page-item"
    [class.disabled]="activePage === viewPages[viewPages.length - 1]"
  >
    <a
      class="page-link"
      href="javascript:void(0)"
      aria-label="Next"
      (click)="forwardPaginator()"
    >
      <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M69.8437,43.3876,33.8422,13.3863a6.0035,6.0035,0,0,0-7.6878,9.223l30.47,25.39-30.47,25.39a6.0035,6.0035,0,0,0,7.6878,9.2231L69.8437,52.6106a6.0091,6.0091,0,0,0,0-9.223Z"
        />
      </svg>
    </a>
  </li>
</ul>
