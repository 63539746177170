import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { Util } from 'src/app/service/utils';
import { Store } from '@ngrx/store';
import { UpdateSubscriptionStatus } from '../../../store/user.action';
import { Location, DecimalPipe } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class BillingComponent implements OnInit {
  subscriptions: any;
  permission = true;
  currentData = new Date().getTime();
  maxSeat: number = 0;
  usedSeat: number = 0;
  remainSeat: number = 0;
  plans: any = [];
  trialPlan: any;
  isProcessing: boolean = false;
  selectedPlanIndex: number = 0;
  subsPlanName: string = '';
  subsPlanId : string = '';
  constructor(
    private api: ApiService,
    private toaster: ToastrService,
    private util: Util,
    private router: Router,
    private store: Store,
    private location: Location,
    private decimalPipe: DecimalPipe,
    private route: ActivatedRoute

  ) {}

  oneAtATime = true;

  ngOnInit(): void {
    this.checkRedirectState();
    if (this.util.isPermission('isViewBill')) {
      this.getAllPlans();
      this.getSubscription();
    } else {
      this.location.back();
    }
  }
  checkRedirectState(): void {
    this.route.queryParams.subscribe(params => {
      const state = params['state'];
      if (state) {
        if (state === 'succeeded') {
          this.toaster.success('Payment Succeeded! Please login again to see the changes');
        } else if (state === 'failed') {
          this.toaster.error('Payment Failed');
        }
      }
    });
  }
  getSubscription(): void {
    this.api.getAllSubscriptionForOrg().subscribe(
      (resp: any) => {
        if (resp.code === 0) {
          console.log(resp.result);
          this.maxSeat = resp?.result?.max_seat;
          this.remainSeat = resp?.result?.max_seat_remaining;
          this.usedSeat = this.maxSeat - this.remainSeat;
          this.subscriptions = resp.result.subs 
          this.isProcessing = true;

          this.subsPlanId = resp?.result.subs.plan_id
          // //console.log(this.subsPlanId)
          var subsPlan = this.plans.filter((plan: any) => plan.plan_id == this.subsPlanId)[0] || this.trialPlan 
          // //console.log(subsPlan)
          this.subsPlanName = subsPlan.invoice_name
          // //console.log(this.subsPlanName)
         
          this.getSelectedPlanIndex();
          // //console.log('sub>>>', this.subscriptions);
          // .map((e: any) => {
          //   return {
          //     ...e,
          //     subs_end_date: new Date(e.sub_end_date).getTime(),
          //   };
          // });
        } else if (resp.code === 97) {
          this.permission = false;
          this.toaster.error('You are Not Authorized to View This Page');
        } else {
          if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
            this.toaster.error(resp.result);
          } else {
            this.toaster.error('Internal Server Error');
          }
        }
      },
      (error: HttpErrorResponse) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  onDownloadInvoice(id: string): void {}
  getAllPlans(): void {
    this.api.getAllPlan().subscribe(
      (resp) => {
        // //console.log('all plans>>', resp);
        
        if (resp.code === 0) {
          this.trialPlan = resp.result[0];
          this.plans = resp.result.slice(1);
          // this.plans = resp.result.map((p: any, index: number) => {
          //   if (index == 0) {
          //     return {
          //       ...p,
          //       features: [
          //         'All stags of the DevOps lifecycle',
          //         'Bring your own Cl runners',
          //         'Bring your own production environment',
          //         '400 CI/CD minutes',
          //       ],
          //     };
          //   } else if (index == 1) {
          //     return {
          //       ...p,
          //       features: [
          //         'Cross-team project management',
          //         'Multiple approval rules',
          //         'Multi-region support',
          //         'Priority support',
          //         '1000 CI/CD minutes',
          //       ],
          //     };
          //   } else if (index == 2) {
          //     return {
          //       ...p,
          //       features: [
          //         'Company wide portfolio management',
          //         'Advanced application security',
          //         'Executive level insights',
          //         'Compliance automation',
          //         'Free guest users',
          //         '50000 CI/CD minutes',
          //       ],
          //     };
          //   } else {
          //     return { ...p };
          //   }
          // });
          // const localPlan = localStorage.getItem('plan');
          // //console.log(localPlan);
          // if (localPlan) {
          //   // this.selectedPlan = JSON.parse(localPlan);
          //   this.plan = this.selectedPlan.planId;
          //   // this.changeValues();
          // } else {
          //   this.plan = resp.result[0].planId;
          //   this.selectedPlan = resp.result[0];
          //   this.additionalDeveloper = 0;
          //   this.additionalProject = 0;
          //   this.totalAmount = 15000;
          // }
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  startTrial(): void {
    this.api.feeSubsciption({ plan_id: this.trialPlan.plan_id }).subscribe(
      (resp: any) => {
        if (resp.code === 0) {
          this.toaster.success('Free Plan Active');
          this.store.dispatch(
            new UpdateSubscriptionStatus({
              expired: false,
              hasSubscription: true,
              paymentFailure: false,
              subscriptionType: 'trial',
            })
          );
          setTimeout(() => {
            this.ngOnInit();
          }, 1000);
        } else {
          this.toaster.error('Subscription Failed');
        }
      },
      (error: HttpErrorResponse) => {
        this.toaster.error('Something error! Please try after sometime.');
      }
    );
  }
  upgradePlan(plan: any): void {
    if (!this.util.isPermission('isUpgradePlan')) return;
    const url = environment.CHARGEBEE_PORTAL_URL; // Construct the URL with the plan as a query parameter
    const windowName = 'PopupWindow';
    const width = 600; // Define the width of the popup
    const height = 400; // Define the height of the popup
    // Calculate the left and top positions to center the popup
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);
    const windowFeatures = `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes,status=yes`;
  
    window.open(url, windowName, windowFeatures);
  }
  getSelectedPlanIndex() {
    const selectedPlan = this.plans.filter(
      (p: any) => p?.plan_id == this.subscriptions?.plan_id
    );
    if (selectedPlan.length) {
      this.selectedPlanIndex = selectedPlan[0].order;
    } else {
      this.selectedPlanIndex = 0;
    }
  }
  format(num: number) {
    return this.decimalPipe.transform(num, '2.0-2');
  }
}
