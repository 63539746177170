import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { Util } from 'src/app/service/utils';

@Component({
  selector: 'app-manage-repo-connection',
  templateUrl: './manage-repo-connection.component.html',
  styleUrls: ['./manage-repo-connection.component.scss'],
})
export class ManageRepoConnectionComponent implements OnInit {
  page = 1;
  total = 0;
  connections: any = [];
  deleteModalShow = false;
  selectedConnection: any;
  @ViewChild('confirmModal', { static: false })
  confirmModal?: ModalDirective;
  selectedConnectionIndex: number | undefined;

  constructor(
    private api: ApiService,
    private toaster: ToastrService,
    private router: Router,
    private location: Location,
    private util: Util
  ) {}

  ngOnInit(): void {
    if (this.util.isPermission('isViewConnection')) {
      this.getAllConnection();
    } else {
      this.location.back();
    }
  }
  getAllConnection(): void {
    this.api.getAllConnections(this.page).subscribe(
      (resp) => {
        if (resp.code === 0) {
          this.connections = resp.result.connections;
          this.total = resp.result.total;
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  onCreate(): void {
    if (this.util.isPermission('isAddConnection'))
      this.router.navigate(['/manage/create-connection']);
  }
  selectDelete(): void {
    if (this.util.isPermission('isDeleteConnection')) {
      if (!this.selectedConnection) {
        this.toaster.error('Please select a connection');
        return;
      }
      this.confirmModal?.show();
    }
  }
  onDelete(): void {
    if (!this.selectedConnection) {
      return;
    }
    this.api.deleteConnection(this.selectedConnection).subscribe(
      (resp) => {
        if (resp.code === 0) {
          this.toaster.success(resp.result);
          this.confirmModal?.hide();
          this.selectedConnection = null;
          this.getAllConnection();
        }
      },
      (error: HttpErrorResponse) => {
        if (error.error.code === 97) {
          this.toaster.error(error.error.result);
          this.confirmModal?.hide();
        } else {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      }
    );
  }

  onSelectedConnection(id: string): void {
    this.selectedConnection = id;
  }
  onChangePage(page: number): void {
    if (page !== this.page) {
      this.page = page;
      this.getAllConnection();
    }
  }
  goToProjectList(): void {
    this.router.navigate(['/dashboard/groups']);
  }
  onBack(): void {
    this.location.back();
  }
  deactivateConnection(): void {
    if (!this.selectedConnection) {
      return;
    }
    if (!this.selectedConnectionIndex) return;
    this.api.getConnectionById(this.selectedConnection).subscribe((resp) => {
      if (resp.code === 0) {
        if (resp.result.status === 'Active') {
          if (resp.result.provider === 'GitHub') {
            window.open(
              `https://github.com/apps/codelock-connector-for-github/installations/${
                // @ts-ignore
                this.connections[this.selectedConnectionIndex]
                  .app_installation_id
              }`,
              '_blank'
            );
          }
          if (resp.result.provider === 'Bitbucket') {
            window.open(
              `https://bitbucket.org/${
                // @ts-ignore
                this.connections[this.selectedConnectionIndex]?.workspace_uuid
              }/workspace/settings/addon-management`,
              '_blank'
            );
          }
        } else {
          this.getAllConnection();
        }
      }
    });
  }
}
