<div
  bsModal
  #addFaceModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title color-white">
          <img src="/assets/images/icon__delete.svg" alt="" />
          Add Face
        </h4>
        <a
          class="modal-close"
          href="javascript:void(0)"
          (click)="closeModal()"
          aria-label="Close"
        >
          <img src="/assets/images/icon__close.svg" alt="" />
        </a>
      </div>
      <div class="modal-body text-center">
        <video
          [class.show]="!isCaptured"
          [class.d-none]="isCaptured"
          #video
          id="video"
          [width]="WIDTH"
          [height]="HEIGHT"
          autoplay
        ></video>
        <canvas
          [class.show]="isCaptured"
          [class.d-none]="!isCaptured"
          #canvas
          id="canvas"
          [width]="WIDTH"
          [height]="HEIGHT"
        ></canvas>
        <div class="button-group justify-content-center pt-2">
          <a
            href="javascript:void(0)"
            class="button button--primary"
            (click)="onFaceAdd()"
          >
            Add This Face
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
