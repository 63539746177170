import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as socketIo from 'socket.io-client';
import {environment} from "../../environments/environment";
@Injectable({
  providedIn: 'root',
})
export class SocketIoService {
  private clientSocket;
  private backendUrl = environment.socketUrl;
  constructor(
  ) {
    this.clientSocket = socketIo.connect(this.backendUrl);
  }

  listenToServer(connection: any): Observable<any> {
    return new Observable((subscribe) => {
      this.clientSocket.on(connection, (data: any) => {
        subscribe.next(data);
      });
    });
  }

  emitToServer(connection: any, data: any): void {
    this.clientSocket.emit(connection, data);
  }
}

