// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'https://ec17-122-163-120-241.ngrok.io/api/v1/',
  // apiUrl: 'https://demo.api.codelock.ai/api/v1/', //dev00
  // apiUrl: 'http://localhost:3000/api/v1/', //pre prod
  // socketUrl: 'http://localhost:3000', //Socket URL local
  socketUrl: 'https://demo.api.codelock.ai', //Socket URL dev
  apiUrl: 'https://demo.api.codelock.ai/api/v1/', //dev
  // apiUrl: 'https://1060-122-163-120-241.ngrok.io/api/v1/',
  // apiUrl: 'https://api.codelock.ai/api/v1/', //prod
  // apiUrl: 'http://localhost:3000/api/v1/',
  GUIHUB_CLIENT_ID: 'Iv1.4d535ccd4cba7bfb',

  // GUIHUB_CLIENT_ID: 'Iv1.a8a20cffcfe024e5',
  BITBUCKET_CLIENT_ID: 'tbM7bmcyyKjR7SwZxC',
  // BITBUCKET_CLIENT_ID: 'GQH9RjJsNuucEpJ9sJ',
  GUIHUB_APP_URL: 'https://github.com/apps/codelock-demo',
  CHARGEBEE_PORTAL_URL: 'https://codelock-test.chargebeeportal.com',
  uiUrl: 'https://demo.codelock.ai/',
  // uiUrl: 'http://localhost:4200/',
  // supportApiUrl: 'http://localhost:3000/api/v1/'
  supportApiUrl:
    'http://codelocksupportbackend-env.eba-gyaxrec8.ap-south-1.elasticbeanstalk.com/api/v1/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

