<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>SSO Sign In</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            background-color: #f5f5f5;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            margin: 0;
        }
        .sso-container {
            background-color: #fff;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            text-align: center;
            width: 300px;
            margin-top: 50px; /* Add margin to move the container down */
        }
        .sso-container .logo {
            display: block;
            margin: 0 auto 10px; /* Reduced margin below the logo */
            width: 200px; /* Adjust the size of the logo as needed */
            height: auto; /* Maintain aspect ratio */
        }
        .sso-container h1 {
            margin-bottom: 10px; /* Reduced margin below the heading */
            font-size: 24px;
        }
        .sso-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 10px;
            margin-bottom: 10px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
        }
        .sso-button img {
            margin-right: 10px;
            width: 20px;
            height: 20px;
        }
        .sso-google {
            color: rgb(0, 0, 0);
        }
        .separator {
            margin: 10px 0; /* Reduced margin for the separator */
            font-size: 14px;
            color: #999;
            position: relative;
        }
        .separator:before,
        .separator:after {
            content: "";
            position: absolute;
            top: 50%;
            width: 40%;
            height: 1px;
            background: #ccc;
        }
        .separator:before {
            left: 0;
        }
        .separator:after {
            right: 0;
        }
        .input-group {
            margin-bottom: 10px;
        }
        .input-group label {
            display: block;
            margin-bottom: 5px;
            font-size: 14px;
            color: #333;
        }
        .input-group input {
            width: calc(100% - 20px);
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
            font-size: 16px;
        }
        .submit-button {
            width: 100%;
            padding: 10px;
            border: none;
            border-radius: 5px;
            background-color: #4CAF50;
            color: white;
            font-size: 16px;
            cursor: pointer;
        }
        .submit-button:hover {
            background-color: #45a049;
        }
    </style>
</head>
<body>
    <div class="sso-container">
        <img src="https://github.com/CodeLockOfficial/CLHooks/blob/main/Code%20lock%20logo-04.png?raw=true" alt="CodeLock Logo" class="logo">
        <h1>Sign In</h1>
        <button class="sso-button sso-google" (click)="signUpWithGoogle()">
            <img src="https://upload.wikimedia.org/wikipedia/commons/7/7e/Gmail_icon_%282020%29.svg" alt="Google Logo">
            Sign in with Google
        </button>
        <button class="sso-button sso-github disabled" (click)="signUpWithGithub()">
            <img src="https://github.githubassets.com/assets/GitHub-Mark-ea2971cee799.png" alt="GitHub Logo">
            Sign in with Github
        </button>

        <div class="separator">OR</div>
        <div class="input-group">
            <label for="name">Name</label>
            <input type="text" id="name" name="name" placeholder="Enter your name" [(ngModel)]="userName">
        </div>
        <div class="input-group">
            <label for="email">Email</label>
            <input type="email" id="email" name="email" placeholder="Enter your email" [(ngModel)]="userEmail">
        </div>
        <button class="submit-button" (click)="signUpWithEmail()">Submit</button>
    </div>
</body>
</html>
