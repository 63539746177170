<div class="vulnerabilities-container">
    <h2 class="centered-title">Vulnerabilities</h2>
    <h4 class="centered-subtitle">Total Vulnerabilities: {{ filteredVulnerabilities.length }}</h4>

    <!-- Severity Filter with mat-button-toggle-group -->
    <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="selectedSeverity" (change)="filterVulnerabilities()" appearance="legacy">
        <mat-button-toggle class="all_filter" value="">All</mat-button-toggle>
        <mat-button-toggle class="low_filter" value="Low">Low</mat-button-toggle>
        <mat-button-toggle class="medium_filter" value="Medium">Medium</mat-button-toggle>
        <mat-button-toggle class="high_filter" value="High">High</mat-button-toggle>
        <mat-button-toggle class="critical_filter" value="Critical">Critical</mat-button-toggle>
    </mat-button-toggle-group>

    <div class="column-headers">
        <span class="column-header package-name">Package Name</span>
        <span class="column-header version">Version</span>
        <span class="column-header cvss">CVSS</span>
        <span class="column-header score">Score</span>
        <span class="column-header column-header-severity">Severity</span>
    </div>
    
    <mat-accordion class="vulnerability-accordion">
        <mat-expansion-panel *ngFor="let vuln of filteredVulnerabilities">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <span class="package-name">{{ vuln.artifact.name }}</span>
                </mat-panel-title>
                <mat-panel-description>
                    <span class="version">{{ vuln.artifact.version }}</span>
                    <span class="cvss">{{ vuln.vulnerability.cvss[0]?.vector.split('/')[0] }}</span>
                    <span class="score">{{ vuln.vulnerability.cvss[0]?.metrics?.exploitabilityScore }}</span>
                    <mat-chip-list>
                        <mat-chip [ngClass]="{
                            'low-severity': vuln.vulnerability.severity === 'Low',
                            'medium-severity': vuln.vulnerability.severity === 'Medium',
                            'high-severity': vuln.vulnerability.severity === 'High',
                            'critical-severity': vuln.vulnerability.severity === 'Critical'
                        }">
                            {{ vuln.vulnerability.severity }}
                        </mat-chip>
                    </mat-chip-list>
                </mat-panel-description>
            </mat-expansion-panel-header>
            
            <mat-card class="vulnerability-card">
                <mat-card-content>
                    <h3>Description</h3>
                    <p [innerHTML]="vuln.vulnerability.description | markdown"></p>

                    <!-- Only render the Impact section if it doesn't contain the default message -->
                    <div *ngIf="vuln.details?.impact && vuln.details.impact !== 'No impact information found.'">
                        <h3>Impact</h3>
                        <p [innerHTML]="vuln.details.impact | markdown"></p>
                    </div>

                    <!-- Only render the Patches section if it doesn't contain the default message -->
                    <div *ngIf="vuln.details?.patches && vuln.details.patches !== 'No patch information found.'">
                        <h3>Patches</h3>
                        <p [innerHTML]="vuln.details.patches | markdown"></p>
                    </div>

                    <!-- Only render the Workarounds section if it doesn't contain the default message -->
                    <div *ngIf="vuln.details?.workarounds && vuln.details.workarounds !== 'No workaround information found.'">
                        <h3>Workarounds</h3>
                        <p [innerHTML]="vuln.details.workarounds | markdown"></p>
                    </div>

                    <!-- Always show these basic details -->
                    <p><strong>Package:</strong> {{ vuln.artifact.name }}</p>
                    <p><strong>Version:</strong> {{ vuln.artifact.version }}</p>
                    <p *ngIf="vuln.artifact.locations?.length">
                        <strong>Location:</strong> {{ vuln.artifact.locations[0].path }}
                    </p>
                    <p><strong>CVSS v2:</strong> {{ vuln.vulnerability.cvss[0]?.metrics?.baseScore }}</p>
                    <p><strong>Exploitability Score:</strong> {{ vuln.vulnerability.cvss[0]?.metrics?.exploitabilityScore }}</p>
                    
                    <a mat-button color="primary" [href]="vuln.vulnerability.dataSource" target="_blank">View Advisory</a>
                    <a mat-button color="accent" *ngIf="vuln.githubAdvisory?.url" [href]="vuln.githubAdvisory.url" target="_blank">GitHub Advisory</a>
                </mat-card-content>
            </mat-card>
        </mat-expansion-panel>
    </mat-accordion>
</div>
