<h4 class="mb-4">Edit Profile</h4>
<form [formGroup]="editProfileForm">
  <div class="row justify-content-end align-items-start">
    <div class="col-md-4">
      <div class="profile__pic">
        <div class="upload">
          <input
            type="file"
            (change)="onFilePick($any($event).target.files)"
            accept="image/png, image/jpeg"
          />
          <img src="/assets/images/icon__camera.svg" alt="" />
        </div>
        <img *ngIf="url" [src]="url" alt="" />
      </div>
    </div>
    <div class="col-md-8">
      <div class="row">
        <div class="col-md-6">
          <div class="mb-4">
            <label for="" class="form-label">First Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter First name"
              value="Angela"
              formControlName="first_name"
            />
            <span
              class="form-error"
              *ngIf="
                editProfileForm.get('first_name')?.getError('required') &&
                editProfileForm.get('first_name')?.touched
              "
              >The field is required.</span
            >
            <span
              class="form-error"
              *ngIf="
                editProfileForm.get('first_name')?.getError('pattern') &&
                editProfileForm.get('first_name')?.touched
              "
              >Please Enter A valid First Name</span
            >
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-4">
            <label for="" class="form-label">Last Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Last name"
              value="Jones"
              formControlName="last_name"
            />
            <span
              class="form-error"
              *ngIf="
                editProfileForm.get('last_name')?.getError('pattern') &&
                editProfileForm.get('last_name')?.touched
              "
              >Please Enter A valid Last Name</span
            >
          </div>
        </div>
        <div class="col-md-12">
          <div class="mb-4">
            <label for="" class="form-label">Email Id.</label>
            <input
              type="email"
              class="form-control"
              placeholder="Enter your email id"
              value="angela.jones@gmail.com"
              readonly
              formControlName="email"
            />
          </div>
        </div>
        <div class="col-md-12">
          <div class="mb-5">
            <label for="" class="form-label">Contact Number</label>
            <div class="row">
              <div class="col-md-4">
                <!-- <select
                  class="form-control form-select"
                  formControlName="country_code"
                >
                  <option *ngFor="let cc of countryCodes" [value]="cc.code">
                    {{ cc.code }} ({{ cc.name }})
                  </option>
                </select> -->
                <ng-select
                  [items]="countryCodes"
                  bindLabel="label"
                  bindValue="code"
                  placeholder="Select country code"
                  formControlName="country_code"
                ></ng-select>
              </div>
              <div class="col-md-8">
                <input
                  type="text"
                  class="form-control"
                  placeholder=""
                  autocomplete="new-phoneNo"
                  formControlName="phoneNo"
                  (keypress)="numberOnly($event)"
                  (input)="onPastePhoneNumber()"
                />
              </div>
              <span
                class="form-error"
                *ngIf="
                  editProfileForm.get('phoneNo')?.getError('required') &&
                  editProfileForm.get('phoneNo')?.touched
                "
                >The field is required.</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-12 mb-4 d-flex align-items-center">
          <div class="form-switcher me-3">
            <input
              type="checkbox"
              id="two_step_varification"
              name="twoStepOn"
              (change)="onChange2Step()"
              formControlName="twoStepOn"
            />
            <label for="two_step_varification"></label>
          </div>
          <label for="" class="form-label m-0"
            >Enable two step authentication</label
          >
        </div>
        <div class="col-md-12 mb-5 d-flex align-items-center">
          <div class="form-switcher me-3">
            <input
              type="checkbox"
              id="face_recognition"
              name="recognition"
              (change)="onChangeRecognition($event)"
              formControlName="recognition"
            />
            <label for="face_recognition"></label>
          </div>
          <label for="" class="form-label m-0">Enable Face Recognition</label>
        </div>
        <div class="button-group">
          <button
            type="submit"
            class="button button--primary"
            (click)="onSave()"
          >
            Save Profile
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
<app-otp-approval
  (otpEvent)="onVerifyOTP($event)"
  [isOpen]="isOpen"
></app-otp-approval>
<!-- <a
  href="javascript:void(0)"
  data-toggle="modal"
  data-target="#otp__approve-all"
  class="d-none"
  #otpModal
></a> -->
<!-- <a
  href="javascript:void(0)"
  id="add_face_button"
  data-toggle="modal"
  data-target="#authentication__face-addition"
  #addFace
></a> -->
<!-- <app-otp-approval
  (otpEvent)="onVerifyOTP($event)"
  [type]="'new'"
></app-otp-approval> -->
<!-- <app-add-face
  [full]="fullScreen"
  (onAdd)="afterAddFace()"
  (onClose)="afterClose()"
>
</app-add-face> -->
<!-- <app-face-recognition
  [full]="fullScreen"
  (onAdd)="afterAddFace()"
  (onClose)="afterClose()"
></app-face-recognition> -->
<div
  bsModal
  #addFaceModal="bs-modal"
  class="modal fade"
  id="modal__faceRecognize"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <a
        class="modal-close"
        href="javascript:void(0)"
        (click)="stopVideo()"
        aria-label="Close"
      >
        <img src="/assets/images/icon__close.svg" alt="" />
      </a>
      <div class="modal-body">
        <div class="inner">
          <video
            [class.show]="!isCaptured"
            [class.d-none]="isCaptured"
            #video
            id="video"
            [width]="WIDTH"
            [height]="HEIGHT"
            autoplay
          ></video>
          <canvas
            [class.show]="isCaptured"
            [class.d-none]="!isCaptured"
            #canvas
            id="canvas"
            [width]="WIDTH"
            [height]="HEIGHT"
          ></canvas>
          <div class="scan">
            <span class="corner corner__tl"></span>
            <span class="corner corner__tr"></span>
            <span class="corner corner__bl"></span>
            <span class="corner corner__br"></span>
          </div>
        </div>
        <div class="button-group justify-content-center">
          <a
            href="javascript:void(0)"
            class="button button--secondary"
            (click)="onFaceAdd()"
          >
            Add This Face
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
