import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SideFilter } from 'src/app/interface/SideFilter';
import { MonitorApiService } from 'src/app/service/monitor-api.service';
import { Util } from 'src/app/service/utils';
import {
  ResetAllFilters,
  UpdateDateRange,
  UpdateGroups,
  UpdateRepos,
} from 'src/app/store/sidebar-filter.action';

@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.scss'],
})
export class MonitorComponent implements OnInit {
  date: any;
  repos: any = [];
  moreRepos: any = [];
  groups: any = [];
  moreGroups: any = [];
  selectedRepo: string[] = [];
  selectedGroup: string[] = [];
  moreRepoOpen = false;
  moreGroupOpen = false;
  disputes = 0;
  serverDisputes = 0;
  users = 0;
  commits = 0;
  showStatus = false;
  totalDisputes = 0;

  today: Date = new Date();
  public currentYear: number = this.today.getFullYear();
  public currentMonth: number = this.today.getMonth();
  public currentDay: number = this.today.getDate();
  public maxDate: any = new Date(
    this.currentYear,
    this.currentMonth,
    this.currentDay
  );

  constructor(
    private api: MonitorApiService,
    private store: Store<{ filter: SideFilter }>,
    private util: Util,
    private router: Router,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (this.util.isPermission('isViewMonitor')) {
      this.getAllGroups(), this.getAllRepository();
    } else {
      this.location.back();
    }
  }
  ngAfterViewInit(): void {
    $(document).on('click', (event) => {
      if ($('#list__date').hasClass('active')) {
        $('#list__date').removeClass('active');
      }
    });
  }

  getAllGroups(): void {
    this.api.getAllGroups().subscribe((resp) => {
      if (resp.result.length > 5) {
        this.groups = [...resp.result.slice(0, 5)];
        this.moreGroups = [...resp.result.slice(5)];
      } else {
        this.groups = resp.result;
      }
    });
  }
  getAllRepository(): void {
    this.api.getAllRepo().subscribe((resp) => {
      if (resp.result.length > 5) {
        this.repos = [...resp.result.slice(0, 5)];
        this.moreRepos = [...resp.result.slice(5)];
      } else {
        this.repos = resp.result;
      }
    });
  }
  onDateChange(): void {
    // this.getAllDataForMonitor();
    this.store.dispatch(
      new UpdateDateRange({ start_date: this.date[0], end_date: this.date[1] })
    );
    // this.bsDatePicker.nativeElement.classList.remove('active');
  }
  // handlerDateRange(st: string): void {
  //   this.bsDatePicker.nativeElement.classList.remove('active');
  // }
  selectGroup(id: string): void {
    if (this.selectedGroup.includes(id)) {
      this.selectedGroup = this.selectedGroup.filter((e: string) => e !== id);
    } else {
      this.selectedGroup = [...this.selectedGroup, id];
    }
    // //console.log('id>>', id, this.selectedGroup);
    this.store.dispatch(new UpdateGroups({ groups: this.selectedGroup }));
    // this.getAllDataForMonitor();
  }
  selectRepos(id: string): void {
    if (this.selectedRepo.includes(id)) {
      this.selectedRepo = this.selectedRepo.filter((e) => e !== id);
    } else {
      this.selectedRepo = [...this.selectedRepo, id];
    }
    // this.getAllDataForMonitor();
    // //console.log('selectRepos');
    this.store.dispatch(new UpdateRepos({ repos: this.selectedRepo }));
  }
  resetFilter(): void {
    this.date = undefined;
    this.selectedGroup = [];
    this.selectedRepo = [];
    this.store.dispatch(
      new ResetAllFilters({
        start_date: '',
        end_date: '',
        repos: [],
        groups: [],
      })
    );
  }
  // toggleMoreRepo(): void {
  //   this.moreRepoOpen = !this.moreRepoOpen;
  // }

  // toggleGroupRepo(): void {
  //   this.moreGroupOpen = !this.moreGroupOpen;
  // }
  // onDatePickerBlur(): void {
  //   this.bsDatePicker.nativeElement.classList.remove('active');
  // }
  // getAllDataForMonitor(): void {
  //   let data = {
  //     start_date: this.date && this.date[0],
  //     end_date: this.date && this.date[1],
  //     groups: this.selectedGroup,
  //     repos: this.selectedRepo,
  //   };
  //   // //console.log('data>>', data);
  //   this.api.getAllDataForMonitor(data).subscribe(
  //     (resp) => {
  //       if (resp.code === 0) {
  //         this.commits = resp.result.commits;
  //         this.disputes = resp.result.disputes;
  //         this.users = resp.result.users;
  //         this.serverDisputes = resp.result.serverScan;
  //       }
  //     },
  //     (error) => {}
  //   );
  // }
}
