<div
  bsModal
  #modalOTP="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title color-white">
          <img src="/assets/images/icon__password.svg" alt="" />
          Enter OTP
        </h4>
        <a
          class="modal-close"
          href="javascript:void(0)"
          (click)="closeModal()"
          aria-label="Close"
        >
          <img src="/assets/images/icon__close.svg" alt="" />
        </a>
      </div>
      <div class="modal-body">
        <div class="mb-4">
          <label for="" class="form-label d-block text-center mb-3"
            >Please enter your 6 digit OTP</label
          >
          <div class="form-otp sml">
            <ng-otp-input
              [config]="{ length: 6 }"
              containerClass="true"
              inputClass="true"
              (onInputChange)="onOtpChange($event)"
              #ngOtpInput
              (keyup.enter)="onSubmit()"
            ></ng-otp-input>
          </div>
          <span
            class="form-text d-block text-center mt-3 weight-600 text-warning"
            *ngIf="timer > 0"
          >
            <i class="fa fa-hourglass-half me-2 fa-spin" aria-hidden="true"></i>
            OTP valid until {{ timer }}s
          </span>
          <span
            class="form-text d-block text-center mt-3 weight-600 text-warning"
            *ngIf="timer <= 0"
            >Haven't received the OTP?
            <a
              href="javascript:void(0)"
              *ngIf="timer <= 0"
              (click)="resendOTP()"
            >
              Resend OTP</a
            ></span
          >
        </div>
        <div class="button-group justify-content-end">
          <button
            type="submit"
            class="button button--primary"
            (click)="onSubmit()"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
