import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { API } from '../interface/api';
import { s3 } from '../interface/s3';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiUrl = environment.apiUrl;
  supportApiUrl = environment.supportApiUrl;
  headers = new HttpHeaders({
    'Content-Type': 'application/json; charset=utf-8',
  });
  constructor(private http: HttpClient) {}
  resendVerificationMail(header: string): Observable<API> {
    return this.http.get<API>(this.apiUrl + 'resend-verification-mail', {
      headers: {
        Authorization: `Bearer ${header}`,
      },
    });
  }
  connectGithub(code: { code: string }): Observable<API> {
    return this.http.post<API>(this.apiUrl + 'connect-github', code);
  }
  connectBitbucket(data: { code: string }): Observable<API> {
    return this.http.post<API>(this.apiUrl + `connect-bitbucket`, data);
  }
  getAllInstalledApp(provider: string): Observable<API> {
    return this.http.get<API>(
      this.apiUrl + `get-all-installed-app?provider=${provider}`
    );
  }
  getSetConnectionName(
    connectionName: string,
    provider: string,
    personalAccessToken: string,
    bitbucketUsername: string = ''
  ): Observable<API> {
    return this.http.get<API>(
      this.apiUrl +
        `get-set-connection-name?connectionname=${connectionName}&provider=${provider}&personalAccessToken=${personalAccessToken}&username=${bitbucketUsername}`
    );
  }

  getSetConnectionNameBitbucket(
    connectionName: string,
    provider: string,
    personalAccessToken: string,
    bitbucketUsername: string
  ): Observable<API> {
    return this.http.get<API>(
      this.apiUrl +
        `get-set-connection-name?connectionname=${connectionName}&provider=${provider}&personalAccessToken=${personalAccessToken}&username=${bitbucketUsername}`
    );
  }

  createConnection(data: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + `create-connection`, data);
  }

  getAllConnectionByProvider(provider: string): Observable<API> {
    return this.http.get<API>(this.apiUrl + `get-all-connection/${provider}`);
  }
  getAllRepo(connectionId: string): Observable<API> {
    return this.http.get<API>(
      this.apiUrl + `get-all-repository/${connectionId}`
    );
  }
  toggle2Step(): Observable<API> {
    return this.http.get<API>(this.apiUrl + 'on-two-step-authentication');
  }
  updateUserPhoneNo(data: {
    phone_no: any;
    country_code: any;
  }): Observable<API> {
    return this.http.post<API>(this.apiUrl + 'update-user-phone-no', data);
  }
  updateUser(data: {
    last_name: any;
    first_name: any;
    user_company: any;
    nationality: any;
    profile_pic_url: any;
  }): Observable<API> {
    return this.http.post<API>(this.apiUrl + 'update-user', data);
  }
  updateUserPhoneNoVerifyOTP(data: {
    phone_no: any;
    otp: any;
    country_code: any;
  }): Observable<API> {
    return this.http.post<API>(
      this.apiUrl + 'update-user-phone-no-verify-otp',
      data
    );
  }
  uploadFile(data: FormData): Observable<API> {
    return this.http.post<API>(this.apiUrl + 'file-upload', data);
  }

  approvedCli(data: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + 'approveCommit', data);
  }

  disableFaceRecognition(): Observable<API> {
    return this.http.get<API>(this.apiUrl + 'disable-face-recognition');
  }
  addNewFace(data: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + `add-new-face`, data);
  }
  detectFace(data: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + `detect-face`, data);
  }
  logout(): Observable<API> {
    return this.http.get<API>(this.apiUrl + `logout`);
  }
  getAllBranchs(
    repositoryFullName: string,
    provider: string,
    connection?: string
  ): Observable<API> {
    return this.http.get<API>(
      this.apiUrl +
        `get-all-branch/${repositoryFullName}?provider=${provider}&connection=${connection}`
    );
  }
  getAllBranchsForGitlab(
    repoId: string,
    provider: string,
    connection?: string
  ): Observable<API> {
    return this.http.get<API>(
      this.apiUrl +
        `get-all-gitlab-branch/${repoId}?provider=${provider}&connection=${connection}`
    );
  }
  getAllBranchsForAzure(
    repoId: string,
    provider: string,
    connection?: string
  ): Observable<API> {
    return this.http.get<API>(
      this.apiUrl +
        `get-all-azure-branch/${repoId}?provider=${provider}&connection=${connection}`
    );
  }
  getAllRepository(
    page: number,
    term: string = '',
    provider: string = '',
    groupId: string
  ): Observable<API> {
    return this.http.get<API>(
      this.apiUrl +
        `get-all-repository-list?page=${page}&term=${term}&provider=${provider}&groupId=${groupId}`
    );
  }
  getAllConnections(page: number): Observable<API> {
    return this.http.get<API>(this.apiUrl + `get-all-connection?page=${page}`);
  }
  deleteConnection(id: any): Observable<API> {
    return this.http.get<API>(this.apiUrl + `delete-connection/${id}`);
  }
  getProjectDetails(id: string): Observable<API> {
    return this.http.get<API>(this.apiUrl + `get-repository-details/${id}`);
  }
  getCountryCodes(): Observable<API> {
    return this.http.get<API>(this.apiUrl + `get-all-country-code`);
  }
  createNewRepository(data: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + `create-new-repository`, data);
  }
  getAllProjects(
    provider: string = '',
    term: string,
    page: number
  ): Observable<API> {
    return this.http.get<API>(
      this.apiUrl +
        `get-all-groups?page=${page}&term=${term}&provider=${provider}`
    );
  }
  deleteRepository(id: string): Observable<API> {
    return this.http.get<API>(this.apiUrl + `delete-repository/${id}`);
  }

  getAllScanInformation(id: string, page: number): Observable<API> {
    return this.http.get<API>(this.apiUrl + `get-all-scans/${id}?page=${page}`);
  }
  getAllServerScan(id: string, page: number): Observable<API> {
    return this.http.get<API>(
      this.apiUrl + `get-all-server-scans/${id}?page=${page}`
    );
  }
  getAllCliScan(id: string): Observable<API> {
    return this.http.get<API>(
      this.apiUrl + `fetchCommit/${id}`
      //:repoId
    );
  }
  getSingleScanDetails(id: string): Observable<API> {
    return this.http.get<API>(this.apiUrl + `get-scan-details/${id}`);
  }
  // resetPassword(data: any): Observable<API> {
  //   return this.http.post<API>(this.apiUrl + `reset-password`, data);
  // }
  triggerManualCodeScan(id: string): Observable<API> {
    return this.http.get<API>(this.apiUrl + `trigger-code-scan/${id}`);
  }
  // connectBitbucket(data: any): Observable<API> {
  //   return this.http.post<API>(this.apiUrl + `connect-bitbucket`, data);
  // }
  // getAllProjects(term: string, page: number): Observable<API> {
  //   return this.http.get<API>(
  //     this.apiUrl + `get-all-groups?page=${page}&term=${term}`
  //   );
  // }
  // getAllSubProjects(term: string, page: number, id: string): Observable<API> {
  //   return this.http.get<API>(
  //     this.apiUrl + `get-all-sub-groups/${id}?page=${page}&term=${term}`
  //   );
  // }
  // getProjectByProjectId(id): Observable<API> {
  //   return this.http.get<API>(this.apiUrl + `get-group/${id}`);
  // }
  createGroup(data: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + `create-new-group`, data);
  }
  updateGroup(data: any, id: string): Observable<API> {
    return this.http.post<API>(this.apiUrl + `update-group/${id}`, data);
  }
  getAllDiff(id: string, provider: string): Observable<API> {
    return this.http.get<API>(this.apiUrl + `get-all-diff/${provider}/${id}`);
    // else return this.http.get<API>(this.apiUrl + `get-all-diff/${id}`);
  }
  getOtpForApproveAll(
    id: string,
    data: { resource: string; id: string }
  ): Observable<API> {
    return this.http.post<API>(
      this.apiUrl + `get-otp-for-approve-all/${id}`,
      data
    );
  }
  getOtpForSingle(
    scanId: string,
    disputeId: string,
    data: { resource: string; id: string }
  ): Observable<API> {
    return this.http.post<API>(
      this.apiUrl + `get-otp-for-single-dispute/${scanId}/${disputeId}`,
      data
    );
  }
  approveAllDispute(
    id: string,
    type: string,
    data: { otp: number; resource: string; id: string }
  ): Observable<API> {
    if (type === 'Approved') {
      return this.http.post<API>(
        this.apiUrl + `approve-all-dispute/${id}`,
        data
      );
    } else {
      return this.http.post<API>(
        this.apiUrl + `disapprove-all-dispute/${id}`,
        data
      );
    }
  }

  saveContactUs(data: any): Observable<API> {
    return this.http.post<API>(`${this.apiUrl}create-enquiry`, data);
    // return this.http.post<API>(`${this.apiUrl}create-ticket`, data);
  }

  reportIssue(data: any): Observable<API> {
    return this.http.post<API>(`${this.apiUrl}create-ticket`, data);
    // return this.http.post<API>(`${this.apiUrl}create-ticket`, data);
  }
  // saveContactSales(data: any): Observable<API> {
  //   return this.http.post<API>(`${this.supportApiUrl}create-enquiry`, data);
  // }

  uploadSingleFile(file: FormData): Observable<API> {
    // return this.http.post<API>(`${this.supportApiUrl}upload-single-file`, file);
    return this.http.post<API>(`${this.apiUrl}file-upload`, file);
  }

  approveSingleDispute(
    scanId: string,
    disputeId: string,
    type: string,
    data: { otp: number; resource: string; id: string }
  ): Observable<API> {
    if (type === 'Approved') {
      return this.http.post<API>(
        this.apiUrl + `approve-single-dispute/${scanId}/${disputeId}`,
        data
      );
    } else {
      return this.http.post<API>(
        this.apiUrl + `disapprove-single-dispute/${scanId}/${disputeId}`,
        data
      );
    }
  }
  inviteOtherPerson(data: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + `invite-other-persons`, data);
  }
  getProjectPermission(
    id: string,
    term: string,
    permission: any
  ): Observable<API> {
    return this.http.get<API>(
      this.apiUrl +
        `get-all-project-persons/${id}?term=${term}&permission=${permission}`
    );
  }
  getGroupsPermission(
    id: string,
    term: string,
    permission: any
  ): Observable<API> {
    return this.http.get<API>(
      this.apiUrl +
        `get-all-group-persons/${id}?term=${term}&permission=${permission}`
    );
  }
  revokePermission(data: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + `delete-permission`, data);
  }
  searchUser(term: string): Observable<API> {
    return this.http.get<API>(this.apiUrl + `search-user?term=${term}`);
  }
  getCurrentUserPermission(id: string, type: string): Observable<API> {
    return this.http.get<API>(
      this.apiUrl + `get-current-user-permission/${id}/${type}`
    );
  }
  updateUserPermission(data: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + `update-permission`, data);
  }
  getResourceData(id: string, resource: any): Observable<API> {
    return this.http.get<API>(
      this.apiUrl + `get-resource-details/${id}/${resource}`
    );
  }
  sendOTP(data: { phone_no: string; email: string }): Observable<API> {
    return this.http.post<API>(this.apiUrl + 'send-otp', data);
  }
  resendOTP(data: { phone_no: string; email: string }): Observable<API> {
    return this.http.post<API>(this.apiUrl + 'resend-otp', data);
  }
  resendPhoneOTP(data: any): Observable<API> {
    // //console.log('resendPhoneOTP>>>', data);
    return this.http.post<API>(this.apiUrl + 'resend-phone-code', data);
  }
  verifyOTP(data: { email: string; otp: number }): Observable<API> {
    return this.http.post<API>(this.apiUrl + 'verify-otp', data);
  }
  // verifyPhoneNo(data): Observable<API> {
  //   return this.http.post<API>(this.apiUrl + `verify-phone-no`, data);
  // }
  getPaymentHostPage(data: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + `get-payment-subscription`, data);
  }
  checkForSubscription(): Observable<API> {
    return this.http.get<API>(this.apiUrl + `get-subscription-status`);
  }
  getOrganization(): Observable<API> {
    return this.http.get<API>(this.apiUrl + `get-organization`);
  }
  getOrganizationCredentials(): Observable<API> {
    return this.http.get<API>(this.apiUrl + `get-organization-credential`);
  }
  getOrganizationDevelopmentStatusParameters(): Observable<API> {
    return this.http.get<API>(
      this.apiUrl + `get-organization-development-status-parameters`
    );
  }
  updateOrganizationDevelopmentStatusParameters(data: any): Observable<API> {
    return this.http.post<API>(
      this.apiUrl + `update-organization-development-status-parameters`,
      data
    );
  }
  getSecretKey(): Observable<API> {
    return this.http.get<API>(this.apiUrl + `get-secret-access-key`);
  }
  getAllCountryCode(): Observable<API> {
    return this.http.get<API>(this.apiUrl + `get-all-country-code`);
  }
  checkOrgAvailability(data: { name: string }): Observable<API> {
    return this.http.post<API>(this.apiUrl + `check-org-availability`, data);
  }
  changePassword(data: {
    current_password: any;
    new_password: any;
  }): Observable<API> {
    return this.http.post<API>(this.apiUrl + `reset-password`, data);
  }
  changePasswordEula(data: {
    current_password: any;
    new_password: any;
  }): Observable<API> {
    return this.http.post<API>(this.apiUrl + `change-password-eula`, data);
  }
  getAllPlan(): Observable<API> {
    return this.http.get<API>(this.apiUrl + `get-all-plans`);
  }
  verifyCouponCode(coupon: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + `verify-coupon`, { coupon });
  }
  sendOtpForServerProjectApproval(data: any, id: string): Observable<API> {
    return this.http.post<API>(
      this.apiUrl + `send-otp-for-server-dispute-approval/${id}`,
      data
    );
  }
  approveServerProject(data: any, id: string): Observable<API> {
    return this.http.post<API>(
      this.apiUrl + `approve-server-dispute/${id}`,
      data
    );
  }
  notApproveServerProject(data: any, id: string): Observable<API> {
    return this.http.post<API>(
      this.apiUrl + `not-approve-server-dispute/${id}`,
      data
    );
  }
  // getAllMonitoringStats(): Observable<API> {
  //   return this.http.get<API>(
  //     `${this.apiUrl}get-organization-development-status-parameters`
  //   );
  // }
  getProjectIdBefore(): Observable<API> {
    return this.http.get<API>(`${this.apiUrl}get-project-id`);
  }
  generateWebHookSecret(id: string): Observable<API> {
    return this.http.get<API>(`${this.apiUrl}get-webhook-secret/${id}`);
  }
  requestResetPassword(data: { email: string }): Observable<API> {
    return this.http.post<API>(this.apiUrl + `request-reset-password`, data);
  }
  resetPassword(data: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + `change-password`, data);
  }
  getActivityLogs(term: string, page: number): Observable<API> {
    return this.http.get<API>(
      this.apiUrl + `get-all-logs?page=${page}&term=${term}`
    );
  }
  downloadUserLogs(term: String): Observable<API> {
    return this.http.get<API>(this.apiUrl + `download-logs?term=${term}`);
  }
  downloadUserLog(id: String, term: String): Observable<API> {
    return this.http.get<API>(
      `${this.apiUrl}download-events/${id}?term=${term}`
      // `${this.apiUrl}search-log-event/${id}?term=${term}&page=${page}`
    );
  }
  downloadUserOrganizationDetails(term: String): Observable<API> {
    return this.http.get<API>(this.apiUrl + `download-org-user?term=${term}`);
  }
  getSearchLogEvent(id: string): Observable<API> {
    return this.http.get<API>(this.apiUrl + `/search-log-event/${id}`);
  }
  getUser(): Observable<API> {
    return this.http.get<API>(this.apiUrl + 'get-user');
  }
  getAllOrganizationUser(
    term: string,
    page: number,
    isActive: boolean = true,
    isEnabled: boolean = true
  ): Observable<API> {
    return this.http.get<API>(
      this.apiUrl +
        `get-all-org-user?page=${page}&term=${term}&isActive=${isActive}&isEnabled=${isEnabled}`
    );
  }
  removeOrganizationUser(id: string): Observable<API> {
    return this.http.get<API>(this.apiUrl + `remove-org-user/${id}`);
  }
  addOrganizationUser(data: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + `create-org-user`, data);
  }
  getOrganizationUser(id: string): Observable<API> {
    return this.http.get<API>(this.apiUrl + `get-org-user/${id}`);
  }
  getOrganizationPers(): Observable<API> {
    return this.http.get<API>(this.apiUrl + `get-all-org-pers`);
  }
  updateOrganizationUser(data: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + `update-org-user`, data);
  }
  getPermissions(): Observable<API> {
    return this.http.get<API>(this.apiUrl + `permission-list`);
  }
  getRoles(page: number = 1, term: string = ''): Observable<API> {
    return this.http.get<API>(
      this.apiUrl + `get-all-roles?page=${page}&term=${term}`
    );
  }
  getRole(id: string): Observable<API> {
    return this.http.get<API>(this.apiUrl + `get-role/${id}`);
  }
  createUpdateRole(data: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + `create-update-role`, data);
  }
  deleteRole(id: string): Observable<API> {
    return this.http.delete<API>(this.apiUrl + `delete-role/${id}`);
  }
  addBulkUser(data: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + `create-bulk-org-user`, data);
  }
  resetOrgUserPassword(data: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + `reset-org-user-password`, data);
  }
  getAllSubscriptionForOrg(): Observable<API> {
    return this.http.get<API>(this.apiUrl + 'get-all-subscription');
  }
  feeSubsciption(data: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + 'subscribe-free', data);
  }
  resendQuestionerLink(): Observable<API> {
    return this.http.get<API>(this.apiUrl + 'resend-question-link');
  }
  updateOrg(data: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + 'update-org', data);
  }
  getConnectionById(id: string): Observable<API> {
    return this.http.get<API>(this.apiUrl + `get-connection/${id}`);
  }
  getAllContributors(
    repositoryFullName: string,
    provider: string,
    connection?: string
  ): Observable<API> {
    return this.http.get<API>(
      this.apiUrl +
        `get-all-contributer/${repositoryFullName}?provider=${provider}&connection=${connection}`
    );
  }

  getDataFromEmail(data: any): Observable<API> {
    // //console.log(data)
    return this.http.post<API>(`${this.apiUrl}fetch-email-data`, data);
  }

  checkActiveSession(): Observable<API> {
    const headers = new HttpHeaders({
      // 'Authorization': 'token 123',
      // 'Content-Type': 'application/json',
      // 'ip': JSON.stringify(ip)
    });
    return this.http.get<API>(
      `${this.apiUrl}check-status`
      // {
      //   headers:headers
      // }
    );
  }

  // ipInfo(): Observable<API> {
  //   return this.http.get<API>(`https://ipinfo.io/json?token=e3a13a2ea2f7cc`);
  // }
  searchEvent(
    id: string,
    page: number = 1,
    term: string = ''
  ): Observable<API> {
    return this.http.get<API>(
      `${this.apiUrl}search-log-event/${id}?term=${term}&page=${page}`
    );
  }

  fetchThreats(threatName: string): Observable<API> {
    // var data = {query : 1}
    return this.http.get<API>(`${this.apiUrl}threat/${threatName}`);
  }

  fetchCompliance(title: string): Observable<API> {
    var params = new HttpParams().set('title', title);
    return this.http.get<API>(`${this.apiUrl}fetch-compliance`, { params });
  }

  setCompliance(data: any): Observable<API> {
    return this.http.post<API>(`${this.apiUrl}compliance`, data);
  }
  muploadFile(data: any) {
    ////console.log(data)
    return this.http.post(`${this.apiUrl}upload-here`, data);
  }

  fetchFile(policy: string): Observable<any> {
    let params = new HttpParams().set('policy', policy);
    return this.http.get<any>(`${this.apiUrl}fetch-compliance-files`, {
      params,
    });
  }
  deleteComplianceFolder(data: any): Observable<any> {
    ////console.log(data);
    return this.http.post<any>(`${this.apiUrl}delete-compliance-folder`, data);
  }
  downloadComplianceFolder(policy: string, title: string): Observable<Blob> {
    let params = new HttpParams().set('policy', policy).set('title', title);
    const options = {
      headers: new HttpHeaders({ Accept: 'application/zip' }),
      responseType: 'blob' as 'json',
      params: params,
    };

    return this.http.get<Blob>(
      `${this.apiUrl}download-compliance-file`,
      options
    );
  }
  getUserPermissions(): Observable<API> {
    const cacheBuster = new Date().getTime();
    return this.http.post<API>(`${this.apiUrl}getpers?_=${cacheBuster}`, {});
  }
  isProd(): boolean {
    if (this.apiUrl.includes('dev') || this.apiUrl.includes('localhost')) {
      return false;
    } else {
      return true;
    }
  }
  getRepoPerms(data: any): Observable<API> {
    let params = new HttpParams()
      .set('repository_id', data.id)
      .set('user', data.user);
    return this.http.get<API>(`${this.apiUrl}get-repo-member-permissions`, {
      params,
    });
  }
  updateRepoPerms(data: any): Observable<any> {
    let params = new HttpParams()
      .set('repository_id', data.repository_id)
      .set('user', data.user);
    return this.http.put<any>(
      `${this.apiUrl}update-repo-member-permissions`,
      data.settings,
      { params }
    );
  }
  editConnection(data: any): Observable<API> {
    return this.http.post<API>(`${this.apiUrl}edit-connection-by-id`, data);
  }
  updateAlertSettings(data: any): Observable<any> {
    let params = new HttpParams()
      .set('repository_id', data.repository_id)
      .set('user', data.user);
    return this.http.post<API>(`${this.apiUrl}update-alert-settings`, data, {
      params,
    });
  }
  saveBitbucketServerData(data: any): Observable<API> {
    let params = new HttpParams()
      .set('access_token', data.access_token)
      .set('username', data.username)
      .set('url', data.url)
      .set('connection_name', data.connection_name);
    return this.http.post<API>(
      `${this.apiUrl}save-bitbucket-server-data`,
      data,
      { params }
    );
  }
  fetchComments(orgId: string, sliderId: string): Observable<any> {
    return this.http.get<API>(`${this.apiUrl}comment/${orgId}/${sliderId}`);
  }
  getCommentsLogs(orgId: string, sliderId: string): Observable<any> {
    return this.http.get<API>(
      `${this.apiUrl}comment/logs/${orgId}/${sliderId}`
    );
  }
  addComment(commentId: string): Observable<any> {
    return this.http.get<API>(`${this.apiUrl}comment/${commentId}`);
  }
  postComment(data: any): Observable<any> {
    return this.http.post<API>(`${this.apiUrl}comment`, data);
  }
  deleteComment(commentId: String): Observable<any> {
    return this.http.delete<API>(`${this.apiUrl}comments/${commentId}`);
  }
  updateComment(commentId: string, data: any): Observable<any> {
    return this.http.put<API>(`${this.apiUrl}comments/${commentId}`, data);
  }
  getDiffFromServerTesting(): Observable<API> {
    return this.http.get<API>(`${this.apiUrl}playground`);
  }
  getDiffFromCommitId(data: any): Observable<API> {
    let params = new HttpParams()
      .set('commitId', data.commitId)
      .set('repoId', data.repoId);
    return this.http.get<API>(`${this.apiUrl}get-diff-from-commit`, { params });
  }
  firebaseSignup(data: any): Observable<API> {
    let params = new HttpParams()
      .set('email', data.email)
      .set('provider', data.provider)
      .set('displayName', data.displayName);
    return this.http.post<API>(`${this.apiUrl}firebase-signup`, data, {
      params,
    });
  }
  sendOtpForMultiSelect(data: any): Observable<API> {
    return this.http.post<API>(`${this.apiUrl}send-otp-for-multi-select`, data);
  }
  approveMultiSelect(data: any): Observable<API> {
    return this.http.post<API>(`${this.apiUrl}approve-multiple-dispute`, data);
  }
  disapproveMultiSelect(data: any): Observable<API> {
    return this.http.post<API>(`${this.apiUrl}disapprove-multiple-dispute`, data);
  }
  fetchGithubAdvisories(data: any): Observable<API> {
    return this.http.post<API>(`${this.apiUrl}fetch-github-advisories`, data);
  }
  
}