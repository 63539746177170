import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './component/auth/auth.component';
import { ForgotPasswordComponent } from './component/auth/forgot-password/forgot-password.component';
import { LoginComponent } from './component/auth/login/login.component';
import { SignupComponent } from './component/auth/signup/signup.component';
import { ActivityComponent } from './component/dashboard/activity/activity.component';
import { BillingComponent } from './component/dashboard/billing/billing.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { GroupsComponent } from './component/dashboard/groups/groups.component';
import { CreateConnectionComponent } from './component/dashboard/manage/manage-repo-connection/create-connection/create-connection.component';
import { GithubConnectionSettingComponent } from './component/dashboard/manage/manage-repo-connection/github-connection-setting/github-connection-setting.component';
import { ManageRepoConnectionComponent } from './component/dashboard/manage/manage-repo-connection/manage-repo-connection.component';
import { EditRoleComponent } from './component/dashboard/manage/manage-role/edit-role/edit-role.component';
import { ManageRoleComponent } from './component/dashboard/manage/manage-role/manage-role.component';
import { EditUserComponent } from './component/dashboard/manage/manage-user/edit-user/edit-user.component';
import { ManageUserComponent } from './component/dashboard/manage/manage-user/manage-user.component';
import { ManageComponent } from './component/dashboard/manage/manage.component';
import { MembersComponent } from './component/dashboard/members/members.component';
import { MonitorAnalyticsPage1Component } from './component/dashboard/monitor/monitor-analytics-page1/monitor-analytics-page1.component';
import { MonitorAnalyticsPage2Component } from './component/dashboard/monitor/monitor-analytics-page2/monitor-analytics-page2.component';
import { MonitorDashboardComponent } from './component/dashboard/monitor/monitor-dashboard/monitor-dashboard.component';
import { MonitorComponent } from './component/dashboard/monitor/monitor.component';
import { ProfileComponent } from './component/dashboard/profile/profile.component';
import { CreateProjectComponent } from './component/dashboard/projects/create-project/create-project.component';
import { ProjectDetailsComponent } from './component/dashboard/projects/project-details/project-details.component';
import { ProjectsComponent } from './component/dashboard/projects/projects.component';
import { HomeComponent } from './component/home/home.component';
// import { ResetPasswordComponent } from './shared/Component/reset-password/reset-password.component';
import { ResetPasswordComponent } from './component/auth/reset-password/reset-password.component';
import { DisputeLogComponent } from './component/dashboard/dispute-log/dispute-log.component';
import { CartComponent } from './component/dashboard/cart/cart.component';
import { VerifyEmailComponent } from './component/auth/verify-email/verify-email.component';
import { SubscriptionGuard } from './service/subscription.guard';
import { QuestionerComponent } from './component/auth/questioner/questioner.component';
import { VerifyQuestionerComponent } from './component/auth/verify-questioner/verify-questioner.component';
import { QuestioneryComponent } from './component/auth/questionery/questionery.component';
import { AuthGuard } from './service/auth.guard';
import { PermissionGuard } from './guards/permission.guard';
import { ManageConfigurationComponent } from './component/dashboard/manage/manage-configuration/manage-configuration.component';
import { ManageApiKeysComponent } from './component/dashboard/manage/manage-api-keys/manage-api-keys.component';
import { ChangePasswordComponent } from './component/auth/change-password/change-password.component';
import { MaintenanceComponent } from './component/maintenance/maintenance.component';
import { NistComponent } from './component/dashboard/nist/nist.component';
import { ThreatComponent } from './component/dashboard/threat/threat.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { SbomComponent } from './component/dashboard/sbom/sbom.component';
import { RepoTableComponent } from './component/dashboard/repo-table/repo-table.component';
import { MapComponent } from './map/map.component';
import { ChangePasswordEulaComponent } from './component/auth/change-password-eula/change-password-eula.component';
import { SbomdocumentsComponent } from './component/dashboard/sbomdocuments/sbomdocuments.component';
import { SelectrepoComponent } from './component/dashboard/selectrepo/selectrepo.component';
import { FileviewerComponent } from './component/dashboard/fileviewer/fileviewer.component';
import { alertSettingsComponent } from './component/dashboard/alert-settings/alertSettings.component';
import { SsoSignupComponent } from './component/auth/sso-signup/sso-signup.component';
import { subtier } from './constants';
const COMPLIANCE = 1;
const COMPLIANCE_PREMIUM = 2;
const ULTIMATE = 3;

const routes: Routes = [
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'signup', component: SignupComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'change-password', component: ChangePasswordComponent },
      { path: 'change-password-eula', component: ChangePasswordEulaComponent },
      { path: 'email-verification', component: VerifyEmailComponent },
      { path: 'questioner', component: QuestionerComponent },
      { path: 'questionery', component: QuestioneryComponent },
      { path: 'questioner-verification', component: VerifyQuestionerComponent },
      { path: 'sso-signup', component: SsoSignupComponent },
    ],
  },
  { path: 'home', canActivate: [AuthGuard], component: HomeComponent },
  { path: 'under-maintenance', component: MaintenanceComponent },
  {
    path: 'fileViewer/:id/:format',
    component: FileviewerComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'create-connection',
        component: CreateConnectionComponent,
        canActivate: [SubscriptionGuard],
        data: { permission: 'isAddRepoConnection', requiredTier: subtier.ULTIMATE },
      },
      {
        path: 'activity',
        component: ActivityComponent,
        canActivate: [SubscriptionGuard],
        data: { permission: 'isViewLog', requiredTier: subtier.STARTER},
      },
      {
        path: 'projects',
        component: ProjectsComponent,
        canActivate: [SubscriptionGuard],
        data: { permission: 'isViewProject', requiredTier: subtier.ULTIMATE },
      },
      {
        path: 'create-project',
        component: CreateProjectComponent,
        canActivate: [SubscriptionGuard],
        data: { permission: 'isAddProject', requiredTier: subtier.ULTIMATE},
      },
      {
        path: 'update-project/:id',
        component: CreateProjectComponent,
        canActivate: [SubscriptionGuard],
        data: { permission: 'isEditProject', requiredTier: subtier.ULTIMATE},
      },
      {
        path: 'project-details/:id',
        component: ProjectDetailsComponent,
        canActivate: [SubscriptionGuard],
        data: { permission: 'isViewProject', requiredTier: subtier.ULTIMATE },
      },
      {
        path: 'groups',
        component: GroupsComponent,
        canActivate: [SubscriptionGuard],
        data: { permission: 'isViewGroup', requiredTier: subtier.ULTIMATE },
      },
      {
        path: 'billing',
        component: BillingComponent,
        data: { permission: 'isViewBill' },
      },
      {
        path: 'members',
        component: MembersComponent,
        canActivate: [SubscriptionGuard],
        data: { requiredTier: subtier.STARTER },
      },
      {
        path: 'profile',
        // loadChildren: () =>
        //   import('./component/dashboard/profile/profile.component').then(
        //     (m) => m.ProfileComponent
        //   ),
        component: ProfileComponent,
        canActivate: [SubscriptionGuard],
        data: { requiredTier: subtier.STARTER },
      },
      {
        path: 'dispute-log/:id',
        component: DisputeLogComponent,
        canActivate: [SubscriptionGuard],
        data: { requiredTier: subtier.ULTIMATE, permission: 'isApproveDispute' },
      },
      {
        path: 'alert-settings',
        component: alertSettingsComponent,
        canActivate: [SubscriptionGuard],
        data: { permission: 'isViewAlertSettings', requiredTier: subtier.ULTIMATE},
      },

      {
        path: 'cart',
        component: CartComponent,
        data: { permission: 'isViewBill' },
      },

      // {
      //   path: 'monitor',
      //   component: MonitorComponent,
      //   canActivate: [SubscriptionGuard],
      //   data: { permission: 'isViewMonitor' },
      //   children: [
      //     // { path: 'dashboard', component: MonitorDashboardComponent },
      //     {
      //       path: 'monitor',
      //       component: MonitorAnalyticsPage1Component,
      //       data: { permission: 'isViewMonitor' },
      //     },
      //     // {
      //     //   path: 'analytics',
      //     //   component: MonitorAnalyticsPage2Component,
      //     //   data: { permission: 'isViewMonitor' },
      //     // },
      //   ],
      // },
      {
        path: 'sbom',
        component: SbomComponent,
        canActivate: [SubscriptionGuard],
        data: { permission: 'isViewMonitor', requiredTier: subtier.COMPLIANCE},
      },
      {
        path: 'sbomdocument',
        component: SbomdocumentsComponent,
        canActivate: [SubscriptionGuard],
        data: { permission: 'isViewMonitor', requiredTier: subtier.COMPLIANCE},
      },
      {
        path: 'selectedrepo',
        component: SelectrepoComponent,
        canActivate: [SubscriptionGuard],
        data: { permission: 'isViewMonitor', requiredTier: subtier.COMPLIANCE},
      },

      // {
      //   path: 'fileViewer/:id/:format',
      //   component: FileviewerComponent,
      //   data: { permission: 'isViewMonitor' },
      // },
      {
        path: 'repo',
        component: RepoTableComponent,
        canActivate: [SubscriptionGuard],
        data: { permission: 'isViewMonitor', requiredTier: subtier.COMPLIANCE},
      },
      {
        path: 'analytics',
        component: AnalyticsComponent,
        canActivate: [SubscriptionGuard],
        data: { permission: 'isViewMonitor', requiredTier: subtier.ULTIMATE},
      },
      {
        path: 'map',
        component: MapComponent,
        canActivate: [SubscriptionGuard],
        data: { permission: 'isViewMonitor', requiredTier: subtier.ULTIMATE},
      },
      {
        path: 'manage',
        component: ManageComponent,
        // canActivate: [SubscriptionGuard],
        children: [
          {
            path: 'users',
            component: ManageUserComponent,
            data: { permission: 'isViewUser' },
          },
          {
            path: 'users/:id',
            component: EditUserComponent,
            data: { permission: 'isEditUser' },
          },
          {
            path: 'repo-connection',
            component: ManageRepoConnectionComponent,
            data: { permission: 'isViewConnection' },
          },
          {
            path: 'create-connection',
            component: CreateConnectionComponent,
            data: { permission: 'isAddConnection' },
          },
          {
            path: 'connection-settings',
            component: GithubConnectionSettingComponent,
            data: { permission: 'isViewConnection' },
          },
          {
            path: 'roles',
            component: ManageRoleComponent,
            data: { permission: 'isViewUser' },
          },
          {
            path: 'roles/add',
            component: EditRoleComponent,
            data: { permission: 'isAddUser' },
          },
          {
            path: 'roles/:id',
            component: EditRoleComponent,
            data: { permission: 'isEditUser' },
          },
          { path: 'configuration', component: ManageConfigurationComponent },
          { path: 'api-keys', component: ManageApiKeysComponent },
        ],
      },
      {
        path: 'nist',
        component: NistComponent,
        canActivate: [SubscriptionGuard],
        data: { permission: 'canViewCompliance', requiredTier: subtier.STARTER},
      },
      {
        path: 'threat',
        component: ThreatComponent,
        canActivate: [SubscriptionGuard],
        data: { requiredTier: subtier.COMPLIANCE},
      },
    ],
  },
  {
    path: 'profile',
    canActivate: [SubscriptionGuard],
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'manage',
    // canActivate: [SubscriptionGuard],
    loadChildren: () =>
      import('./manage/manage.module').then((m) => m.ManageModule),
  },
  {
    path: 'monitor',
    canActivate: [SubscriptionGuard],
    data: { requiredTier: subtier.ULTIMATE },
    loadChildren: () =>
      import('./monitor/monitor.module').then((m) => m.MonitorModule),
  },
  { path: '**', redirectTo: '/auth/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
