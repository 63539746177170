import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { EventService } from 'src/app/service/event.service';
import { User } from '../../../interface/user';
import { ApiService } from '../../../service/api.service';
import { UpdateName, UpdateTwoStep } from '../../../store/user.action';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @ViewChild('otpModal') otpModal: ElementRef | undefined;
  @ViewChild('addFaceModal') addFaceModal?: ModalDirective;
  @ViewChild('video') video!: ElementRef;
  @ViewChild('canvas') canvas!: ElementRef;
  nameValidationRegexp = "^[A-Z]'?[-'a-zA-Z]+$";
  url: any;
  fullScreen = false;
  isCaptured = false;
  WIDTH = 640;
  HEIGHT = 480;
  localstream: MediaStream | undefined;
  error: any;
  isOpen: boolean = false;
  editProfileForm = new UntypedFormGroup({
    first_name: new UntypedFormControl(null, [
      Validators.required,
      Validators.pattern(this.nameValidationRegexp),
    ]),
    last_name: new UntypedFormControl(
      null,
      Validators.pattern(this.nameValidationRegexp)
    ),
    email: new UntypedFormControl(null, Validators.required),
    phoneNo: new UntypedFormControl(null, Validators.required),
    twoStepOn: new UntypedFormControl(false, Validators.required),
    url: new UntypedFormControl(null),
    country_code: new UntypedFormControl('+1', Validators.required),
    recognition: new UntypedFormControl(false),
  });
  countryCodes: any[] = [];
  originalPhoneNo: any;
  updateAblePhoneNo: any;
  updateAbleCCode: any;
  constructor(
    private api: ApiService,
    private toaster: ToastrService,
    private store: Store<{ userData: User }>,
    private eventService: EventService
  ) {}

  ngOnInit(): void {
    this.getUserInfo();
    this.getAllCountryCode();
    this.eventService.otpEvent.subscribe((data: any) => {
      // //console.log('resend', this.editProfileForm.getRawValue());
      this.api.resendPhoneOTP({
        phone_no:
          this.editProfileForm.get('country_code')?.value +
          this.editProfileForm.get('phoneNo')?.value,
        type: 'update',
      });
    });
  }
  ngOnChanges(): void {
    // //console.log('ngOnChanges');
    this.isCaptured = false;
    if (this.fullScreen) {
      this.setupDevices();
    } else {
      this.stopVideo();
    }
  }
  getAllCountryCode(): void {
    this.api.getAllCountryCode().subscribe((resp) => {
      if (resp.code === 0) {
        this.countryCodes = resp.result.map((c: any) => {
          return { ...c, label: c.code + ' (' + c.name + ')' };
        });
      }
    });
  }
  getUserInfo(): void {
    this.api.getUser().subscribe(
      (resp) => {
        if (resp.code === 0) {
          this.editProfileForm.patchValue({
            first_name: resp.result.user.first_name,
            last_name: resp.result.user.last_name,
            email: resp.result.user.email,
            phoneNo: resp.result.user.phone_no
              ? resp.result.user.phone_no.replace(resp.result.user.country_code, '')
              : null,
            twoStepOn: resp.result.user.two_step_on,
            url: resp.result.user.profile_pic_url,
            recognition: resp.result.user.recognition,
            country_code: resp.result.user.country_code
              ? resp.result.user.country_code
              : '+1',
          });
          this.url = resp.result.user.profile_pic_url;
          this.originalPhoneNo = resp.result.user.phone_no;
          this.store.dispatch(
            new UpdateName({
              first_name: resp.result.user.first_name,
              last_name: resp.result.user.last_name,
            })
          );
          this.store.dispatch(
            new UpdateTwoStep({ two_step_on: resp.result.user.two_step_on })
          );
        } else {
          if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
            this.toaster.error(resp.result);
          } else {
            this.toaster.error('Internal Server Error');
          }
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  onChange2Step(): void {
    this.api.toggle2Step().subscribe(
      (resp) => {
        if (resp.code === 0) {
          this.toaster.success(resp.result);
          this.getUserInfo();
        } else if (resp.code === 3 || resp.code === 12) {
          this.editProfileForm.patchValue({ twoStepOn: false });
          this.toaster.error(resp.result);
        } else {
          if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
            this.toaster.error(resp.result);
          } else {
            this.toaster.error('Internal Server Error');
          }
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  ngAfterViewInit(): void {
    $(document).on('click', (event) => {
      if (
        $(event.target).closest(
          '#create_project_moadlbody, #add_face_area, #add_face_button'
        ).length === 0
      ) {
        this.fullScreen = false;
      }
    });
  }

  onSave(): void {
    if (this.editProfileForm.invalid) {
      this.markTouch();
      return;
    }
    if (
      this.originalPhoneNo ===
      this.editProfileForm.get('country_code')?.value +
        this.editProfileForm.get('phoneNo')?.value
    ) {
      this.saveBasicData('Profile');
    } else {
      this.updateAblePhoneNo =
        this.editProfileForm.get('country_code')?.value +
        this.editProfileForm.get('phoneNo')?.value;
      this.updateAbleCCode = this.editProfileForm.get('country_code')?.value;
      // this.saveBasicData();
      this.api
        .updateUserPhoneNo({
          phone_no:
            this.editProfileForm.get('country_code')?.value +
            this.editProfileForm.get('phoneNo')?.value,
          country_code: this.editProfileForm.get('country_code')?.value,
        })
        .subscribe(
          (resp) => {
            if (resp.code === 0) {
              // this.otpModal?.nativeElement.click();
              this.isOpen = true;
              this.toaster.success(resp.result);
              // this.getUserInfo();
            } else {
              if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
                this.toaster.error(resp.result);
              } else {
                this.toaster.error('Internal Server Error');
              }
            }
          },
          (error) => {
            if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
              this.toaster.error(error.error.result);
            } else {
              this.toaster.error('Something Went Wrong');
            }
          }
        );
    }
  }
  saveBasicData(type?: string | undefined): void {
    // this.api
    //   .updateUser({
    //     last_name: this.editProfileForm.get('last_name')?.value,
    //     first_name: this.editProfileForm.get('first_name')?.value,
    //     profile_pic_url: this.url,
    //   })
    //   .subscribe(
    //     (resp: { code: number }) => {
    //       if (resp.code === 0) {
    //         if (type && type === 'Profile') {
    //           this.toaster.success('Profile Updated Successfully');
    //           this.getUserInfo();
    //         } else {
    //           this.getUserInfo();
    //         }
    //       } else {
    //         this.toaster.error('Internal Server Error');
    //       }
    //     },
    //     (error: any) => {
    //       if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
    //         this.toaster.error(error.error.result);
    //       } else {
    //         this.toaster.error('Something Went Wrong');
    //       }
    //     }
    //   );
  }
  onVerifyOTP(otp: any): void {
    this.api
      .updateUserPhoneNoVerifyOTP({
        phone_no: this.updateAblePhoneNo,
        otp,
        country_code: this.updateAbleCCode,
      })
      .subscribe(
        (resp: { code: number }) => {
          if (resp.code === 0) {
            this.toaster.success('Profile Updated Successfully');
            this.saveBasicData();

            // this.getUserInfo();
          } else {
            this.toaster.error('Internal Server Error');
          }
        },
        (error: any) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
  }
  onFilePick(files: any[]): void {
    this.toaster.info('File Upload Started, Please Wait');
    const formData: FormData = new FormData();
    const fileToUpload = files[0];
    formData.append('image', fileToUpload, fileToUpload.name);
    this.api.uploadFile(formData).subscribe(
      (resp: { result: any }) => {
        this.url = resp.result;
        this.toaster.success('File Uploaded Successfully');
      },
      (error: any) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      },
      () => {}
    );
  }
  markTouch(): void {
    Object.keys(this.editProfileForm.controls).forEach((field) => {
      const control = this.editProfileForm.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
  }
  onChangeRecognition(e: any): void {
    if (e.target.checked) {
      ////console.log('checked');
      // this.api.enableFaceRecognition().subscribe(
      //   (resp: { code: number; result: string | undefined }) => {
      //     // //console.log('user>>>', resp);
      //     if (resp.code === 0) {
      //       this.toaster.success(resp.result);
      //       this.getUserInfo();
      //     } else if (resp.code === 12) {
      //       this.fullScreen = true;
      //       this.setupDevices();
      //       this.addFaceModal?.show();
      //     } else {
      //       if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
      //         this.toaster.error(resp.result);
      //       } else {
      //         this.toaster.error('Internal Server Error');
      //       }
      //     }
      //   },
      //   (error: any) => {
      //     if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
      //       this.toaster.error(error.error.result);
      //     } else {
      //       this.toaster.error('Something Went Wrong');
      //     }
      //   }
      // );
    }
    else if(e.target.checked === false){
      ////console.log('unchecked');
    }
  }
  // afterAddFace(): void {
  //   this.fullScreen = false;
  // }
  // afterClose(): void {
  //   this.fullScreen = false;
  // }
  async setupDevices(): Promise<void> {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (stream) {
          this.video.nativeElement.srcObject = stream;
          this.localstream = stream;
          this.video.nativeElement.play();
          this.error = null;
        } else {
          this.error = 'You have no output video device';
        }
      } catch (e) {
        this.error = e;
      }
    }
  }
  onFaceAdd(): void {
    this.drawImageToCanvas(this.video?.nativeElement);
    this.api
      .addNewFace({
        photo: this.canvas.nativeElement.toDataURL('image/png'),
      })
      .subscribe(
        (resp) => {
          if (resp.code === 0) {
            this.stopVideo();
            this.toaster.success(resp.result);
            this.addFaceModal?.hide();
            this.getUserInfo();
            // this.onAdd.emit();
            // this.closeModal?.nativeElement.click();
          } else {
            this.isCaptured = false;
            this.toaster.error(resp.result);
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
  }
  drawImageToCanvas(image: any): void {
    this.canvas?.nativeElement
      .getContext('2d')
      .drawImage(image, 0, 0, this.WIDTH, this.HEIGHT);
    this.isCaptured = true;
  }
  stopVideo(): void {
    this.localstream?.getTracks()[0].stop();
    this.addFaceModal?.hide();
  }

  numberOnly(event : any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  onPastePhoneNumber(){
    let pastedText = this.editProfileForm.get('phoneNo')?.value; //clipboardData?.getData('text');
    pastedText = pastedText?.replace(/[^0-9]/g, '');
    // //console.log(pastedText)
    // this.signupForm.reset({phoneNo: ''});
    this.editProfileForm.get('phoneNo')?.patchValue(pastedText)
  }
}
