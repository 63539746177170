<div class="table-main rounded-0 mb-4">
  <div class="block__title">
    <img class="icon me-3" src="/assets/images/icon__users.svg" alt="" />
    <h6>Manage Account</h6>
  </div>
  <div class="d-flex align-items-center py-3 px-0">
    <div class="button-group">
      <a
        href="javascript:void(0)"
        class="button button--open"
        (click)="onCreateNew()"
      >
        <i class="fa fa-plus me-2" aria-hidden="true"></i>
        Create New Account
      </a>
      <a
        href="javascript:void(0)"
        class="button button--open ms-4"
        (click)="onCreateBulkAccount()"
      >
        <i class="fa fa-plus me-2" aria-hidden="true"></i>
        Create Bulk Accounts
      </a>
      <a
        href="javascript:void(0)"
        class="button button--open ms-4"
        (click)="onImport()"
      >
        <i class="fa fa-plus me-2" aria-hidden="true"></i>
        Import User
      </a>
    </div>
    <div class="d-flex align-items-center ms-auto">
      <div class="form-checkbox has-label me-4" *ngIf="!isDisableStatus">
        <input
          type="checkbox"
          name="checkbox_manage-account"
          id="ma_showinactive"
          [(ngModel)]="isInactiveStatus"
          (change)="search()"
        />
        <span class="icon"></span>
        <label class="form-label ms-2" for="ma_showinactive"
          >Show Inactive</label
        >
      </div>
      <div class="form-checkbox has-label me-4">
        <input
          type="checkbox"
          name="checkbox_manage-account"
          id="ma_showdisabled"
          [(ngModel)]="isDisableStatus"
          (change)="search()"
        />
        <span class="icon"></span>
        <label class="form-label ms-2" for="ma_showdisabled"
          >Show Disabled</label
        >
      </div>
      <div class="form-search" style="width: 300px">
        <input
          type="text"
          class="form-control"
          placeholder="User name, Full name or E-mail"
          [(ngModel)]="term"
          (change)="search()"
        />
        <button type="submit">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 27.18 27.9"
            style="enable-background: new 0 0 27.18 27.9"
            xml:space="preserve"
          >
            <path
              d="M17.76,20.63c-5.5,3.82-12.7,1.62-15.85-3.11C-1.21,12.83-0.48,6.56,3.94,2.76 c4.09-3.51,10.17-3.77,14.64-0.19c4.26,3.4,6.04,10.45,1.52,15.96c0.36,0.37,0.72,0.75,1.08,1.12c1.83,1.88,3.67,3.76,5.49,5.65 c0.87,0.91,0.57,2.22-0.58,2.54c-0.64,0.18-1.14-0.07-1.58-0.52c-1.58-1.64-3.16-3.28-4.76-4.9C19.13,21.8,18.44,21.24,17.76,20.63z M11.32,19.59c4.54,0.07,8.36-3.63,8.44-8.19c0.08-4.58-3.62-8.37-8.26-8.44c-4.67-0.07-8.48,3.61-8.54,8.26 C2.9,15.75,6.65,19.51,11.32,19.59z"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
  <div class="table-responsive">
    <div class="alert alert-danger py-2" *ngIf="bulkUserAddErrors">
      <div *ngIf="bulkUserAddErrors?.existingOrgUsers">
        <span *ngFor="let p of bulkUserAddErrors?.existingOrgUsers?.users">{{
          p
        }}</span>
        {{ bulkUserAddErrors?.existingOrgUsers.msg }}
      </div>
      <div *ngIf="bulkUserAddErrors?.nonOrgUsers">
        <span *ngFor="let p of bulkUserAddErrors?.nonOrgUsers?.users">{{
          p
        }}</span
        >({{ bulkUserAddErrors?.nonOrgUsers.msg }})
      </div>
    </div>
    <table class="table table-borderless m-0">
      <thead>
        <tr>
          <th scope="col">Username</th>
          <th scope="col">Full Name</th>
          <th scope="col">Email</th>
          <th scope="col">User Type</th>
          <th scope="col" class="text-center">Account Status</th>
          <th scope="col">Date Created</th>
          <th scope="col">Last Visit</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="orgUsers.length === 0">
          <td class="text-center" colspan="7">No User Found</td>
        </tr>
        <tr *ngFor="let user of orgUsers">
          <td>
            <a class="weight-600" href="javascript:void(0)">{{
              user.username
            }}</a>
          </td>
          <td>{{ user.first_name }} {{ user.last_name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.type === "root" ? "Root User" : user.type }}</td>
          <td class="text-center">
            <img
              class="icon icon--sml"
              src="/assets/images/icon__tick--blue.svg"
              alt=""
            />
          </td>
          <td>{{ user.createdAt | date }}</td>
          <td>{{ user.lastLogin | date }}</td>
          <td class="text-center">
            <div class="d-flex justify-content-center">
              <a
                class="action action--sml"
                href="javascript:void(0)"
                tooltip="Edit"
                routerLink="/dashboard/manage/users/{{ user._id }}"
              >
                <img src="/assets/images/icon__edit.svg" alt="" />
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="d-flex justify-content-end">
  <app-pagination
    [count]="total"
    (changePage)="onChangePage($event)"
    [perPage]="10"
  ></app-pagination>
</div>

<div
  bsModal
  #modalCreateNewAccount="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title color-white">
          <img src="/assets/images/icon__account.svg" alt="" />
          Create New Account
        </h5>
        <a
          class="modal-close"
          href="javascript:void(0)"
          (click)="createUserModalClose()"
          aria-label="Close"
        >
          <img src="/assets/images/icon__close.svg" alt="" />
        </a>
      </div>
      <div class="modal-body">
        <form [formGroup]="addOrgUserForm" (submit)="onSubmit()">
          <div class="row align-items-center mb-4">
            <div class="col-md-4">
              <label for="" class="form-label m-0">Username</label>
            </div>
            <div class="col-md-8">
              <input
                type="text"
                class="form-control"
                formControlName="username"
              />
            </div>
            <div
              class="col-md-12"
              *ngIf="
                addOrgUserForm.get('username')?.hasError('required') &&
                addOrgUserForm.get('username')?.touched
              "
            >
              <div class="row justify-content-end">
                <div class="col-md-8">
                  <span class="form-text form-error">
                    This is a required field.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-4">
            <div class="col-md-4">
              <label for="" class="form-label m-0">First Name</label>
            </div>
            <div class="col-md-8">
              <input
                type="text"
                class="form-control"
                placeholder=""
                formControlName="first_name"
              />
            </div>
            <div
              class="col-md-12"
              *ngIf="
                addOrgUserForm.get('first_name')?.hasError('required') &&
                addOrgUserForm.get('first_name')?.touched
              "
            >
              <div class="row justify-content-end">
                <div class="col-md-8">
                  <span class="form-text form-error">
                    This is a required field.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-4">
            <div class="col-md-4">
              <label for="" class="form-label m-0">Last Name</label>
            </div>
            <div class="col-md-8">
              <input
                type="text"
                class="form-control"
                formControlName="last_name"
              />
            </div>
            <div
              class="col-md-12"
              *ngIf="
                addOrgUserForm.get('last_name')?.hasError('required') &&
                addOrgUserForm.get('last_name')?.touched
              "
            >
              <div class="row justify-content-end">
                <div class="col-md-8">
                  <span class="form-text form-error">
                    This is a required field.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-4">
            <div class="col-md-4">
              <label for="" class="form-label m-0">Email</label>
            </div>
            <div class="col-md-8">
              <input
                type="text"
                class="form-control"
                formControlName="email"
                [readonly]="isWhiteList"
              />
            </div>
            <div
              class="col-md-12"
              *ngIf="
                (addOrgUserForm.get('email')?.hasError('required') ||
                  addOrgUserForm.get('email')?.hasError('email')) &&
                addOrgUserForm.get('email')?.touched
              "
            >
              <div class="row justify-content-end">
                <div class="col-md-8">
                  <span
                    class="form-text form-error"
                    *ngIf="addOrgUserForm.get('email')?.hasError('required')"
                  >
                    This is a required field.
                  </span>
                  <span
                    class="form-text form-error"
                    *ngIf="addOrgUserForm.get('email')?.hasError('email')"
                  >
                    Invalid Email.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-4">
            <div class="col-md-4">
              <label for="" class="form-label m-0"
                >Auto-generate Password</label
              >
            </div>
            <div class="col-md-8">
              <div class="form-checkbox">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  formControlName="isAutoGenerate"
                />
                <span class="icon"></span>
              </div>
            </div>
          </div>
          <div
            class="row align-items-center mb-4"
            *ngIf="!addOrgUserForm.get('isAutoGenerate')?.value"
          >
            <div class="col-md-4">
              <label for="" class="form-label m-0">Password</label>
            </div>
            <div class="col-md-8">
              <input
                type="text"
                class="form-control"
                formControlName="password"
              />
            </div>
            <div
              class="col-md-12"
              *ngIf="
                (addOrgUserForm.get('password')?.hasError('required') ||
                  addOrgUserForm.get('password')?.hasError('pattern')) &&
                addOrgUserForm.get('password')?.touched
              "
            >
              <div class="row justify-content-end">
                <div class="col-md-8">
                  <span
                    class="form-text form-error"
                    *ngIf="addOrgUserForm.get('password')?.hasError('required')"
                  >
                    This is a required field.
                  </span>
                  <span
                    class="form-text form-error"
                    *ngIf="addOrgUserForm.get('password')?.hasError('pattern')"
                  >
                    Invalid password.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-4">
            <div class="col-md-4">
              <label for="" class="form-label m-0"
                >Change Password on First Login</label
              >
            </div>
            <div class="col-md-8">
              <div class="form-checkbox">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  formControlName="resetPassword"
                />
                <span class="icon"></span>
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-4">
            <div class="col-md-4">
              <label for="" class="form-label m-0">Notify user via email</label>
            </div>
            <div class="col-md-8">
              <div class="form-checkbox">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  formControlName="notifyUser"
                />
                <span class="icon"></span>
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-4">
            <div class="col-md-4">
              <label for="" class="form-label m-0">Notification</label>
            </div>
            <div class="col-md-8">
              <ng-select
                placeholder="Select Level"
                formControlName="notify"
                [items]="notifiedOptions"
                bindLabel="label"
                bindValue="key"
                (change)="onNotificationTypeChange($event)"
              >
              </ng-select>
            </div>
            <div
              class="col-md-12"
              *ngIf="
                addOrgUserForm.get('notify')?.hasError('required') &&
                addOrgUserForm.get('notify')?.touched
              "
            >
              <div class="row justify-content-end">
                <div class="col-md-8">
                  <span class="form-text form-error">
                    This is a required field.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row align-items-center mb-4"
            *ngIf="
              addOrgUserForm.get('notify')?.value == 'phone' ||
              addOrgUserForm.get('notify')?.value == 'both'
            "
          >
            <div class="col-md-4">
              <label for="" class="form-label m-0">Phone Number</label>
            </div>
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-4">
                  <ng-select
                    placeholder="Select country code"
                    [items]="countryCodes"
                    bindLabel="label"
                    bindValue="code"
                    placeholder="Select country code"
                    formControlName="country_code"
                  ></ng-select>
                </div>
                <div class="col-md-8">
                  <input
                    type="tel"
                    class="form-control"
                    name=""
                    id=""
                    autocomplete="off"
                    formControlName="phone_no"
                    (keypress)="numberOnly($event)"
                    (input)="onPastePhoneNumber()"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-4">
            <div class="col-md-4">
              <label for="" class="form-label m-0">Role</label>
            </div>
            <div class="col-md-8">
              <ng-select
                placeholder="Select Level"
                formControlName="role"
                [items]="roles"
                bindLabel="name"
                bindValue="_id"
              >
              </ng-select>
            </div>
            <div
              class="col-md-12"
              *ngIf="
                addOrgUserForm.get('role')?.hasError('required') &&
                addOrgUserForm.get('role')?.touched
              "
            >
              <div class="row justify-content-end">
                <div class="col-md-8">
                  <span class="form-text form-error">
                    This is a required field.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center mb-4">
            <div class="col-md-4">
              <label for="" class="form-label m-0">Enabled</label>
            </div>
            <div class="col-md-8">
              <div class="form-checkbox">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  formControlName="isEnabled"
                />
                <span class="icon"></span>
              </div>
            </div>
          </div>
          <div class="button-group">
            <button type="submit" class="button button--primary">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div
  bsModal
  #modalCreateBulkAccount="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title color-white">
          <img src="/assets/images/icon__users.svg" alt="" />
          Bulk User Create
        </h5>
        <a
          class="modal-close"
          href="javascript:void(0)"
          (click)="closeBulkUserCreateModal()"
          aria-label="Close"
        >
          <img src="/assets/images/icon__close.svg" alt="" />
        </a>
      </div>
      <form [formGroup]="bulkUserAddForm" (submit)="bulkSubmit()">
        <div class="modal-body">
          <div class="mb-3">
            <!-- <ng-select placeholder="Enter email of users" [multiple]="true">
            <ng-option>user1@gmail.com</ng-option>
            <ng-option>user2@gmail.com</ng-option>
            <ng-option>user3@gmail.com</ng-option>
          </ng-select> -->
            <textarea
              class="form-control form-textarea"
              placeholder="Enter multiple emails with comma seperated"
              formControlName="emails"
            ></textarea>
            <span
              class="form-error"
              *ngIf="
                bulkUserAddForm.get('emails')?.getError('required') &&
                bulkUserAddForm.get('emails')?.touched
              "
              >The field is required.</span
            >
            <span
              class="form-error"
              *ngIf="
                bulkUserAddForm.get('emails')?.getError('emails') &&
                bulkUserAddForm.get('emails')?.touched
              "
            >
              "Invalid email(s)"
            </span>
          </div>
          <div class="mb-4">
            <textarea
              class="form-control form-textarea"
              placeholder="Message"
              formControlName="message"
            ></textarea>
          </div>
          <div class="button-group">
            <button type="submit" class="button button--primary">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div
  bsModal
  #modalUserImport="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title color-white">
          <img src="/assets/images/icon__users.svg" alt="" />
          Bulk User Import
        </h5>
        <a
          class="modal-close"
          href="javascript:void(0)"
          (click)="modalUserImport.hide()"
          aria-label="Close"
        >
          <img src="/assets/images/icon__close.svg" alt="" />
        </a>
      </div>
      <form [formGroup]="bulkUserImportForm" (submit)="bulkUserImportSubmit()">
        <div class="modal-body">
          <div class="mb-4">
            <div class="form-files">
              <div class="names">
                <span class="label" *ngIf="!importFiles">Upload .csv file</span>
                <!-- Uploaded file name & remove uploaded file ui -->
                <span *ngIf="importFiles">
                  <a href="javascript:void(0)" (click)="removeImport()"
                    >&times;</a
                  >
                  {{ importFiles.name }}
                </span>
                <!-- Uploaded file name & remove uploaded file ui -->
              </div>
              <div class="action">
                <input
                  type="file"
                  (change)="onFileChange($event)"
                  formControlName="emails"
                  accept=".csv"
                />
                <a href="javascript:void(0)" class="button button--secondary"
                  >Browse</a
                >
              </div>
            </div>

            <!-- <input
              type="file"
              class="form-control"
              (change)="onFileChange($event)"
              formControlName="emails"
            /> -->
            <span
              class="form-error"
              *ngIf="
                bulkUserImportForm.get('emails')?.getError('required') &&
                bulkUserImportForm.get('emails')?.touched
              "
              >The field is required.</span
            >
          </div>

          <div class="button-group">
            <button type="submit" class="button button--primary">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
