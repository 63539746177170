import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[appEqualValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EqualValidatorDirective,
      multi: true,
    },
  ],
})
export class EqualValidatorDirective implements Validator {
  @Input() appEqualValidator: string | undefined;
  validate(control: AbstractControl): { [key: string]: any } | null {
    if (!this.appEqualValidator) return null;
    const controlToCompare = control.parent?.get(this.appEqualValidator);

    if (controlToCompare && controlToCompare.value == control.value) {
      // //console.log(
      //   'controlToCompare.value',
      //   controlToCompare?.value,
      //   control.value
      // );
      return { equal: true };
    } else {
      return null;
    }
  }
}
