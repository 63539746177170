<div class="d-flex align-items-center my-4">
  <h5 class="m-0">Development Environment Status</h5>
</div>
<div class="row mb-4">
  <form [formGroup]="developmentEnvironmentStatusForm">
    <div class="col-md-6">
      <label for="" class="form-label">Stable</label>
      <input
        type="number"
        class="form-control"
        value="0"
        min="0"
        formControlName="stable"
      />
    </div>
    <div class="col-md-6">
      <label for="" class="form-label">Warning</label>
      <input
        type="number"
        class="form-control"
        value="0"
        min="0"
        formControlName="warning"
      />
    </div>
  </form>
</div>
<div class="button-group">
  <button
    type="submit"
    class="button button--primary"
    (click)="updateDevelopmentStatus()"
  >
    Save
  </button>
</div>
