<div class="row">
  <div class="col-md-6 mb-4">
    <div class="monitor__graph">
      <h6>Team Level Disputes</h6>
      <div
        class="d-flex align-items-center justify-content-center inner"
        *ngIf="!isShowBubbleChartData"
      >
        <span>No data found.</span>
      </div>
      <plotly-plot
        [layout]="layout"
        [config]="config"
        [data]="bubbleChartData"
        (plotlyClick)="onPointClick($event)"
        (doubleClick)="(null)"
        *ngIf="isShowBubbleChartData"
      ></plotly-plot>
    </div>
  </div>
  <div class="col-md-6 mb-4">
    <div class="monitor__graph">
      <h6>Repository Based Commit vs Dispute</h6>
      <div
        class="d-flex align-items-center justify-content-center inner"
        *ngIf="!isShowLineChartData"
      >
        <span>No data found.</span>
      </div>
      <plotly-plot
        [config]="config"
        [data]="lineChartData.data"
        *ngIf="isShowLineChartData"
      ></plotly-plot>
    </div>
  </div>
  <div class="col-md-6 mb-4">
    <div class="monitor__graph">
      <h6>Approved vs Not Approved Code</h6>
      <div
        class="d-flex align-items-center justify-content-center inner"
        *ngIf="!isShowPieChartData"
      >
        <span>No data found</span>
      </div>
      <plotly-plot
        [config]="config"
        [data]="pieChartData.data"
        *ngIf="isShowPieChartData"
      ></plotly-plot>
    </div>
  </div>
  <div class="col-md-6 mb-4">
    <div class="monitor__graph">
      <h6>Commits Per User</h6>
      <div
        class="d-flex align-items-center justify-content-center inner"
        *ngIf="!isShowBarGraphData"
      >
        <span>No data found</span>
      </div>
      <plotly-plot
        [config]="config"
        [data]="barGraphData"
        *ngIf="isShowBarGraphData"
      ></plotly-plot>
    </div>
  </div>
  <div class="col-md-6 mb-4">
    <div class="monitor__graph">
      <h6>Repo Level</h6>
      <div
        class="d-flex align-items-center justify-content-center inner"
        *ngIf="!isShowLineChartDataRepoWiseUser"
      >
        <span>Graphs comes here soon.</span>
      </div>
      <plotly-plot
        [config]="config"
        [data]="lineChartDataRepoWiseUser"
        *ngIf="isShowLineChartDataRepoWiseUser"
      ></plotly-plot>
    </div>
  </div>
</div>
