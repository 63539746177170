<mat-toolbar color="primary">
  <span>Component Viewer</span>
</mat-toolbar>

<div class="main-container">
  <!-- Left and Middle section for SBOM details -->
  <div class="sbom-details">
    <h1 class="page-title">Software Bill of Materials (SBOM) Report</h1>
    
    <mat-card class="repository-card">
      <mat-card-content>
        <h2 class="section-title metadata-title">Metadata</h2>
        <p><strong>BOM Format:</strong> {{data.bomFormat}}</p>
        <p><strong>Specification Version:</strong> {{data.specVersion}}</p>
        <p><strong>Serial Number:</strong> {{data.serialNumber}}</p>
        <p><strong>Version:</strong> {{data.version}}</p>
        <p><strong>Timestamp:</strong> {{ generatedAt }}</p>
      </mat-card-content>
      
      <mat-divider></mat-divider>

      <mat-card-content>
        <h2 class="section-title">Tool Information</h2>
        <table mat-table [dataSource]="dataSource.data" class="mat-elevation-z8">
          <!-- Author Column -->
          <ng-container matColumnDef="author">
            <th mat-header-cell *matHeaderCellDef> Author </th>
            <td mat-cell *matCellDef="let element"> {{element.author}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <!-- Version Column -->
          <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef> Version </th>
            <td mat-cell *matCellDef="let element"> {{element.version}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </mat-card-content>

      <mat-divider></mat-divider>

      <mat-card-content>
        <h2 class="section-title">Author Information</h2>
        <p><strong>Name:</strong> CodeLock, Inc.</p>
        <p><strong>Email:</strong> support@codelock.it</p>
      </mat-card-content>

      <mat-divider></mat-divider>

      <mat-card-content class="info-container">
        <div class="component-info">
          <h2 class="section-title">Component Information</h2>
          <p><strong>Type:</strong> Application</p>
          <p><strong>Name:</strong> Certipath Application</p>
          <p><strong>Version:</strong> 2.1.0</p>
          <p><strong>Supplier:</strong> Certipath</p>
          <p><strong>Manufacturer:</strong> Certipath</p>
        </div>

        <div class="lifecycle-info">
          <h2 class="section-title lifecycle-title">Lifecycles</h2>
          <p><strong>Phase:</strong> Pre-Build</p>
          <p><strong>Repository:</strong> {{repositoryName}}</p>
          <p><strong>Project:</strong> {{repositoryName}}</p>
        </div>
      </mat-card-content>
      
      <app-vulnerability *ngIf="grypeUrl" [url]="grypeUrl"></app-vulnerability>
      <app-license *ngIf="grantUrl" [url]="grantUrl"></app-license>
    </mat-card>
  </div>

  <!-- Right section for Components -->
  <div class="component-container">
    <h2 class="section-title component-title">Component</h2>
    <mat-form-field class="search-bar" appearance="fill">
      <mat-label>Search by name or type</mat-label>
      <input matInput [(ngModel)]="searchTerm" placeholder="Search" (ngModelChange)="filterComponents()" />
    </mat-form-field>

    <div class="results-count">
      Showing {{ filteredComponents.length }} components
    </div>

    <cdk-virtual-scroll-viewport itemSize="150" class="viewport">
      <div *cdkVirtualFor="let component of filteredComponents" class="component-card">
        <mat-card class="component-card-content">
          <!-- Hyperlinking the component name to the BOM Reference -->
          <mat-card-title>
            <a [href]="getBomUrl(component['bom-ref'])" target="_blank">{{ component.name }}</a>
          </mat-card-title>
          <mat-card-content>
            <p>
              <strong>BOM Reference:</strong>
              <a [href]="getBomUrl(component['bom-ref'])" target="_blank">{{ component['bom-ref'] }}</a>
            </p>
            <p><strong>Type:</strong> {{ component.type }}</p>
            <p><strong>Version:</strong> {{ component.version }}</p>
            <p *ngFor="let prop of component.properties">
              <strong>{{ prop.name }}:</strong> {{ prop.value }}
            </p>
          </mat-card-content>
        </mat-card>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</div>

<style>
  body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    height: 100%;
    box-sizing: border-box;
  }

  .main-container {
    display: flex;
    height: calc(100vh - 64px); /* Full height minus the toolbar */
  }

  .sbom-details {
    flex: 2;
    padding: 20px;
    background-color: #f9f9f9;
    overflow-y: auto;
    border-right: 1px solid #ccc;
  }

  .repository-card {
    background-color: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .page-title {
    font-size: 28px;
    font-weight: bold;
    color: #000;
    margin-bottom: 20px;
    text-align: center; /* Center title with respect to the sbom-details */
    width: calc(100% - 40px); /* Account for padding */
    margin-left: auto;
    margin-right: auto;
  }

  .repository-title {
    font-size: 24px;
    font-weight: bold;
    color: #3f51b5;
  }

  .timestamp {
    font-size: 14px;
    color: #666;
  }

  .section-title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #3f51b5; /* CycloneDX Blue */
  }

  .metadata-title {
    font-size: 30px;
    color: #3f51b5; /* CycloneDX Blue */
  }

  .lifecycle-title {
    color: #3f51b5; /* CycloneDX Blue */
  }

  .vulnerabilities-title {
    color: #d32f2f; /* CycloneDX Red */
  }

  .component-title {
    color: #3f51b5; /* CycloneDX Blue */
  }

  .sbom-details p {
    margin: 0;
    padding: 5px 0;
    font-size: 14px;
    color: #333;
  }

  .sbom-details table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  .sbom-details th, .sbom-details td {
    padding: 8px;
    text-align: left;
  }

  .mat-header-cell, .mat-cell {
    color: #000; /* Ensure text color is black */
    background-color: #fff; /* Ensure background is white */
    font-size: 16px; /* Increase font size if necessary */
  }

  .component-container {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
  }

  .search-bar {
    width: 100%;
    margin-bottom: 10px;
  }

  .search-bar input {
    color: #333;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }

  .search-bar input::placeholder {
    color: #888;
  }

  .results-count {
    margin-bottom: 10px;
    font-size: 14px;
    color: #666;
  }

  .viewport {
    height: 100%;
    overflow-y: auto;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
  }

  .component-card {
    padding: 10px;
  }

  .component-card-content {
    padding: 15px;
    background-color: #fafafa;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }

  mat-card-title a, mat-card-content a {
    color: #3f51b5;
    text-decoration: none;
  }

  mat-card-title a:hover, mat-card-content a:hover {
    text-decoration: underline;
  }

  mat-card-title {
    font-size: 18px;
    font-weight: bold;
    color: #3f51b5;
  }

  mat-card-content p {
    margin: 0;
    padding: 5px 0;
    font-size: 14px;
    color: #333;
  }

  .mat-form-field {
    width: 100%;
    margin-bottom: 20px;
    display: block;
  }

  .mat-form-field-label {
    color: #333333 !important; /* Dark text color */
    font-weight: bold;
  }

  .mat-select-placeholder {
    color: #333333 !important; /* Dark text color */
  }

  .mat-select {
    color: #333333 !important; /* Ensure the selected text is dark */
  }

  .mat-select-panel {
    background-color: #ffffff; /* Light background for the dropdown */
    color: #333333; /* Dark text color */
  }

  .mat-option-text {
    color: #333333 !important; /* Dark text color for options */
  }

  h2 {
    color: #1e88e5; /* Ensures header color stands out */
    margin-bottom: 20px;
  }

  .info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .component-info, .lifecycle-info {
    flex: 1;
    padding-right: 20px;
  }

  .lifecycle-info {
    border-left: 1px solid #ccc;
    padding-left: 20px;
  }
</style>
