<div class="block">
  <div class="block__nav">
    <ul class="nav">
      <li>
        <a
          href="javascript:void(0)"
          routerLink="/manage/users"
          routerLinkActive="active"
          >Users</a
        >
      </li>
      <!-- <li><a href="javascript:void(0)">Projects</a></li>
      <li><a href="javascript:void(0)">Tags</a></li>
      <li><a href="javascript:void(0)">Email Template</a></li> -->
      <li>
        <a
          href="javascript:void(0)"
          routerLink="/manage/repo-connection"
          routerLinkActive="active"
          >Repo Connections</a
        >
      </li>
      <li>
        <a
          href="javascript:void(0)"
          routerLink="/manage/roles"
          routerLinkActive="active"
          >Role</a
        >
      </li>
      <li>
        <a
          href="javascript:void(0)"
          routerLink="/manage/configuration"
          routerLinkActive="active"
          >Configuration</a
        >
      </li>
      <li>
        <a
          href="javascript:void(0)"
          routerLink="/manage/api-keys"
          routerLinkActive="active"
          >API Keys</a
        >
      </li>
    </ul>
  </div>
  <div class="block__content p-0">
    <router-outlet></router-outlet>
  </div>
</div>
