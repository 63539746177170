<div class="diff-section">
  <div class="diff-header">
    <h2>Modified Files</h2>
    <p>Commit message: {{this.commitMessage}}</p>
  </div>
  
  <div *ngFor="let fileDiff of diffs" class="file-diff-container" [class.collapsed]="fileDiff.isCollapsed">
    <div class="file-header" (click)="toggleCollapse(fileDiff)">
      {{ fileDiff.filename }}
      <span class="collapse-icon"></span>
    </div>
    <div class="diff-container" *ngIf="!fileDiff.isCollapsed">
      <div *ngFor="let diffLine of fileDiff.diffLines" [ngClass]="diffLine.type" class="diff-line">
        <span class="line-number">
          <ng-container *ngIf="diffLine.type === 'context'">
            {{ diffLine.originalLineNumber }}
          </ng-container>
          <ng-container *ngIf="diffLine.type === 'deletion'">
            {{ diffLine.originalLineNumber }}
          </ng-container>
          <ng-container *ngIf="diffLine.type === 'addition'">
            {{ diffLine.modifiedLineNumber }}
          </ng-container>
        </span>
        <span class="line-content">{{ diffLine.line }}</span>
      </div>
    </div>
  </div>
</div>
