import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../../service/api.service';

@Component({
  selector: 'app-manage-api-keys',
  templateUrl: './manage-api-keys.component.html',
  styleUrls: ['./manage-api-keys.component.scss'],
})
export class ManageApiKeysComponent implements OnInit {
  // apiKey: any;
  // secretAccessKey: any;
  @ViewChild('secrectAccessKeyInput')
  secrectAccessKeyInput?: ElementRef<HTMLInputElement>;
  show: boolean = false;
  data: any;
  apiSecretKeyForm = new UntypedFormGroup({
    apiKey: new UntypedFormControl(null, Validators.required),
    secretAccessKey: new UntypedFormControl(null, Validators.required),
    // getSecretAccessKey: new FormControl(null, Validators.required),
  });
  constructor(private api: ApiService, private toaster: ToastrService) {}

  ngOnInit(): void {
    this.getApiKeys();
  }
  getApiKeys(): void {
    this.api.getOrganizationCredentials().subscribe(
      (resp) => {
        // //console.log('API keys', resp);
        if (resp.code === 0) {
          this.apiSecretKeyForm.patchValue({
            apiKey: resp.result.apiKey,
            secretAccessKey: resp.result.secretAccessKey,
          });
          // //console.log('API keys >>> ', this.apiSecretKeyForm.getRawValue());
          // //console.log('Secret keys >>> ', this.secretAccessKey);
        } else {
          if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
            this.toaster.error(resp.result);
          } else {
            this.toaster.error('Internal Server Error');
          }
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  getSecretKeyAccess(): void {
    this.api.getSecretKey().subscribe((resp) => {
      if (resp.code === 0) {
        this.show = true;
        this.apiSecretKeyForm.patchValue({
          secretAccessKey: resp.result.secretAccessKey,
        });
        this.data = document.getElementById('secretAccessKey');
        if (this.data.type === 'password') {
          this.data.type = 'text';
        } else {
          this.data.type = 'password';
          this.show = false;
        }
      }
    });
  }
  onCopy(): void {
    // this.data = document.getElementById('secretAccessKey');
    const data = this.secrectAccessKeyInput?.nativeElement?.value;
    this.secrectAccessKeyInput?.nativeElement?.select();
    //@ts-ignore
    navigator.clipboard.writeText(data).then(() => {
      // //console.log('Data');
    });
    document.execCommand('copy');
    // //console.log(data);

    this.toaster.success('Secret Access Key Copied');
  }
}
