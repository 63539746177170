<div class="d-flex align-items-center my-4">
  <h5 class="m-0">Connection List</h5>
  <div class="d-flex align-items-center ms-auto">
    <button
      class="button button--open me-4"
      type="button"
      routerLink="/dashboard/groups"
    >
      <svg
        version="1.1"
        class="icon icon--xsml icon--left"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 18.64 14.16"
        style="enable-background: new 0 0 18.64 14.16"
        xml:space="preserve"
      >
        <g>
          <g>
            <path
              d="M17.21,5.79c-3.33,0-6.66,0-9.99,0c-0.09,0-0.18,0-0.26,0.01C6.19,5.88,5.69,6.54,5.81,7.3 c0.1,0.65,0.63,1.07,1.36,1.07c1.68,0,3.36,0,5.04,0c0.49,0,0.97,0,1.46,0c1.21,0,2.43,0.01,3.64,0c0.94-0.01,1.56-0.87,1.26-1.72 C18.36,6.1,17.88,5.79,17.21,5.79z M7.19,3.22c3.2,0,6.41,0,9.61,0c0.19,0,0.39,0.01,0.58,0c0.74-0.04,1.27-0.63,1.25-1.35 c-0.02-0.71-0.58-1.22-1.36-1.23c-1.69-0.01-3.38,0-5.07,0c-0.94,0-1.88,0-2.82,0c-0.81,0-1.61-0.01-2.42,0.01 C6.24,0.68,5.7,1.36,5.8,2.08C5.9,2.78,6.42,3.22,7.19,3.22z M17.21,10.95c-3.33,0-6.66,0-9.99,0c-0.08,0-0.16,0-0.23,0.01 c-0.79,0.08-1.31,0.75-1.17,1.54c0.11,0.63,0.63,1.03,1.35,1.03c1.68,0.01,3.36,0,5.04,0c0.25,0,0.5,0,0.76,0 c1.48,0,2.97,0.01,4.45-0.01c0.86-0.01,1.43-0.9,1.15-1.7C18.36,11.26,17.88,10.95,17.21,10.95z M1.95,5.15 C0.88,5.14,0.01,6.01,0,7.06C-0.01,8.13,0.85,9,1.91,9.01C2.98,9.02,3.84,8.17,3.85,7.1C3.85,6.02,3.01,5.16,1.95,5.15z M1.93,0 C0.86,0,0,0.86,0,1.92c0,1.06,0.87,1.94,1.92,1.94c1.05,0,1.92-0.87,1.92-1.93C3.85,0.85,3,0,1.93,0z M1.94,10.3 C0.89,10.29,0,11.17,0,12.23c0,1.07,0.85,1.93,1.91,1.94c1.08,0,1.93-0.84,1.93-1.91C3.85,11.18,2.99,10.31,1.94,10.3z"
            />
          </g>
        </g>
      </svg>
      Project List
    </button>
    <button
      class="button button--open me-4"
      type="button"
      (click)="selectDelete()"
    >
      <svg
        version="1.1"
        class="icon icon--sml icon--left"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 15.38 19.23"
        style="enable-background: new 0 0 15.38 19.23"
        xml:space="preserve"
      >
        <g>
          <g>
            <path
              d="M13.87,5.28c-0.58-0.14-1.06,0.27-1.06,0.93c0,3.16,0,6.33,0,9.49c0,1.06-0.69,1.77-1.72,1.77 c-2.26,0-4.53,0-6.79,0c-1.05,0-1.74-0.71-1.74-1.79c0-1.56,0-3.12,0-4.68c0-1.6,0-3.21,0-4.81c0-0.49-0.25-0.82-0.66-0.91 c-0.6-0.13-1.05,0.27-1.05,0.98c0,3.18,0,6.35,0,9.53c0,0.24,0.02,0.48,0.07,0.71c0.34,1.61,1.64,2.71,3.28,2.72 c2.32,0.02,4.63,0.01,6.95,0c0.24,0,0.49-0.03,0.73-0.08c1.59-0.37,2.63-1.7,2.64-3.4c0.01-3.14,0-6.29,0-9.43 c0-0.13-0.01-0.26-0.04-0.39C14.42,5.57,14.21,5.36,13.87,5.28z M14.87,2.71c-0.16-0.06-0.35-0.08-0.53-0.08 c-0.97-0.01-1.94-0.01-2.91,0c-0.26,0-0.33-0.08-0.32-0.34c0.02-0.46,0.01-0.92,0-1.37c-0.01-0.55-0.32-0.9-0.85-0.91 C8.55,0,6.84,0,5.13,0.01c-0.53,0-0.84,0.36-0.85,0.9c-0.01,0.44-0.02,0.87,0,1.31C4.3,2.54,4.21,2.64,3.9,2.63 C2.93,2.61,1.95,2.63,0.98,2.62c-0.39,0-0.72,0.13-0.9,0.51c-0.28,0.6,0.15,1.24,0.85,1.24c2.24,0,4.48,0,6.72,0 c2.08,0,4.16,0,6.24,0c0.22,0,0.45,0.01,0.67,0c0.4-0.02,0.73-0.33,0.8-0.73C15.43,3.26,15.23,2.85,14.87,2.71z M8.71,2.62 c-0.79,0-1.58-0.01-2.36,0.01C6.06,2.64,5.94,2.55,5.98,2.25c0.02-0.15,0-0.3,0-0.47c1.14,0,2.26,0,3.38,0 C9.5,2.62,9.5,2.62,8.71,2.62z M10.25,13.89c0-0.98,0-1.96,0-2.95c0-0.99,0-1.99,0-2.98c0-0.56-0.34-0.95-0.83-0.97 C8.93,6.98,8.55,7.35,8.55,7.91c-0.01,2.01-0.01,4.02,0,6.02c0,0.56,0.38,0.93,0.87,0.92C9.91,14.84,10.25,14.45,10.25,13.89z M6.82,7.78c-0.04-0.49-0.44-0.81-0.9-0.78c-0.44,0.03-0.79,0.4-0.79,0.87c-0.01,2.04-0.01,4.08,0,6.12c0,0.43,0.3,0.78,0.7,0.86 c0.37,0.07,0.78-0.14,0.91-0.5c0.07-0.18,0.09-0.38,0.09-0.57c0.01-0.95,0-1.9,0-2.85c0-0.64,0-1.29,0-1.93 C6.84,8.58,6.86,8.18,6.82,7.78z"
            />
          </g>
        </g>
      </svg>
      Delete
    </button>
    <button class="button button--open" type="button" (click)="onCreate()">
      <i class="fa fa-plus me-2" aria-hidden="true"></i>
      Create Connection
    </button>
  </div>
</div>
<div class="table-main mb-4">
  <div class="table-responsive">
    <table class="table table-borderless table-vmiddle text-center m-0">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col" class="text-start">Connection Name</th>
          <th scope="col">Status</th>
          <th scope="col">Provider</th>
          <th scope="col">App Installation ID</th>
          <th scope="col">Connection Type</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="connections.length === 0">
          <td class="text-center" colspan="6">No Connections Found</td>
        </tr>
        <tr *ngFor="let connection of connections">
          <td>
            <div class="form-radio">
              <input
                type="radio"
                name="id"
                id=""
                (change)="onSelectedConnection(connection._id)"
                [checked]="selectedConnection === connection?._id"
              />
              <span class="icon"></span>
            </div>
          </td>
          <td class="text-start">
            <div class="d-flex align-items-center">
              <span class="weight-600">{{ connection.connection_name }}</span>
            </div>
          </td>
          <td>
            <span
              class="badge px-2"
              [class]="
                connection.status === 'Active'
                  ? 'status__success bg-success'
                  : 'bg-danger status__inactive'
              "
            >
              {{ connection.status }}
            </span>
          </td>
          <td>{{ connection.provider }}</td>
          <td>{{ connection.app_installation_id }}</td>
          <td>{{ connection.connection_type }}</td>
        </tr>
        <!-- <tr>
          <td>
            <div class="form-radio">
              <input type="radio" name="" id="" />
              <span class="icon"></span>
            </div>
          </td>
          <td class="text-start">
            <div class="d-flex align-items-center">
              <a href="javascript:void(0)" class="weight-600"
                >Connection_Name</a
              >
            </div>
          </td>
          <td>
            <span class="badge status__success bg-success px-2"> Active </span>
          </td>
          <td>Gitlab</td>
          <td>---</td>
          <td>User</td>
        </tr>
        <tr>
          <td>
            <div class="form-radio">
              <input type="radio" name="" id="" />
              <span class="icon"></span>
            </div>
          </td>
          <td class="text-start">
            <div class="d-flex align-items-center">
              <a href="javascript:void(0)" class="weight-600"
                >Connection_Name</a
              >
            </div>
          </td>
          <td>
            <span class="badge status__success bg-success px-2"> Active </span>
          </td>
          <td>Gitlab</td>
          <td>---</td>
          <td>User</td>
        </tr>
        <tr>
          <td>
            <div class="form-radio">
              <input type="radio" name="" id="" />
              <span class="icon"></span>
            </div>
          </td>
          <td class="text-start">
            <div class="d-flex align-items-center">
              <a href="javascript:void(0)" class="weight-600"
                >Connection_Name</a
              >
            </div>
          </td>
          <td>
            <span class="badge status__success bg-success px-2"> Active </span>
          </td>
          <td>Gitlab</td>
          <td>---</td>
          <td>User</td>
        </tr>
        <tr>
          <td>
            <div class="form-radio">
              <input type="radio" name="" id="" />
              <span class="icon"></span>
            </div>
          </td>
          <td class="text-start">
            <div class="d-flex align-items-center">
              <a href="javascript:void(0)" class="weight-600"
                >Connection_Name</a
              >
            </div>
          </td>
          <td>
            <span class="badge status__success bg-success px-2"> Active </span>
          </td>
          <td>Gitlab</td>
          <td>---</td>
          <td>User</td>
        </tr>
        <tr>
          <td>
            <div class="form-radio">
              <input type="radio" name="" id="" />
              <span class="icon"></span>
            </div>
          </td>
          <td class="text-start">
            <div class="d-flex align-items-center">
              <a href="javascript:void(0)" class="weight-600"
                >Connection_Name</a
              >
            </div>
          </td>
          <td>
            <span class="badge status__success bg-success px-2"> Active </span>
          </td>
          <td>Gitlab</td>
          <td>---</td>
          <td>User</td>
        </tr>
        <tr>
          <td>
            <div class="form-radio">
              <input type="radio" name="" id="" />
              <span class="icon"></span>
            </div>
          </td>
          <td class="text-start">
            <div class="d-flex align-items-center">
              <a href="javascript:void(0)" class="weight-600"
                >Connection_Name</a
              >
            </div>
          </td>
          <td>
            <span class="badge status__success bg-success px-2"> Active </span>
          </td>
          <td>Gitlab</td>
          <td>---</td>
          <td>User</td>
        </tr>
        <tr>
          <td>
            <div class="form-radio">
              <input type="radio" name="" id="" />
              <span class="icon"></span>
            </div>
          </td>
          <td class="text-start">
            <div class="d-flex align-items-center">
              <a href="javascript:void(0)" class="weight-600"
                >Connection_Name</a
              >
            </div>
          </td>
          <td>
            <span class="badge status__success bg-success px-2"> Active </span>
          </td>
          <td>Gitlab</td>
          <td>---</td>
          <td>User</td>
        </tr>
        <tr>
          <td>
            <div class="form-radio">
              <input type="radio" name="" id="" />
              <span class="icon"></span>
            </div>
          </td>
          <td class="text-start">
            <div class="d-flex align-items-center">
              <a href="javascript:void(0)" class="weight-600"
                >Connection_Name</a
              >
            </div>
          </td>
          <td>
            <span class="badge status__success bg-success px-2"> Active </span>
          </td>
          <td>Gitlab</td>
          <td>---</td>
          <td>User</td>
        </tr> -->
      </tbody>
    </table>
  </div>
</div>
<div class="d-flex justify-content-end">
  <app-pagination
    [count]="total"
    (changePage)="onChangePage($event)"
  ></app-pagination>
</div>
<div
  bsModal
  #confirmModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title color-white">
          <img src="/assets/images/icon__delete.svg" alt="" />
          Remove Connection
        </h4>
        <a
          class="modal-close"
          href="javascript:void(0)"
          (click)="confirmModal.hide()"
          aria-label="Close"
        >
          <img src="/assets/images/icon__close.svg" alt="" />
        </a>
      </div>
      <div class="modal-body text-center">
        <div class="mb-4">
          <h4>Are you sure?</h4>
          <p>
            Do you really want to delete these records? <br />This process can't
            be undone.
          </p>
        </div>
        <div class="button-group justify-content-center">
          <button
            type="submit"
            class="button button--primary button--sml"
            (click)="confirmModal.hide()"
            aria-label="Close"
          >
            Cancel
          </button>
          <button
            type="button"
            class="button button--primary button--sml"
            (click)="onDelete()"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
