<h4 class="mb-4">My Cart</h4>
<div class="cart">
  <div class="row">
    <div class="col-md-6">
      <div class="block p-4">
        <h5 class="mb-4">Your Plan</h5>
        <div class="row text-uppercase mb-3">
          <div class="col-md-6">
            <h5 class="m-0">{{ selectedPlan ? selectedPlan.name : "" }}</h5>
          </div>
          <div class="col-md-6 text-end">
            <h5 class="m-0 color-primary">$ {{ numberWithCommas((totalAmount / 100).toFixed(2)) }}</h5>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-6">
            <label for="" class="form-label">Additional Developers</label>
            <input
              type="number"
              class="form-control"
              step="1"
              [min]="0"
              [(ngModel)]="additionalDeveloper"
              (ngModelChange)="changeValues()"
              (keypress)="onlyNumber($event)"
              value={{additionalDeveloper}}
            />
          </div>
          <!-- <div class="col-md-6">
            <label for="" class="form-label">Additional Projects</label>
            <input
              type="number"
              class="form-control"
              min="0"
              step="1"
              [max]="maxProject"
              [(ngModel)]="additionalProject"
              (ngModelChange)="changeValues()"
            />
          </div> -->
        </div>
        <!-- <div class="row mb-4" *ngIf="selectedPlan?.order <= 2">
          <div class="col-md-6">
            <label for="" class="form-label">Developers</label>
            <input
              type="number"
              class="form-control"
              [value]="developer"
              readonly
            />
          </div>
          <div class="col-md-6">
            <label for="" class="form-label">Projects</label>
            <input
              type="number"
              class="form-control"
              [value]="projects"
              readonly
            />
          </div>
        </div> -->
        <div class="row">
          <div class="col-md-6">
            <h5 class="m-0">Subtotal</h5>
          </div>
          <div class="col-md-6 text-end">
            <h5 class="m-0 color-primary">$ {{ numberWithCommas((totalAmount / 100).toFixed(2)) }}</h5>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="block">
        <div class="pt-3 ps-3 pe-4">
          <div class="row align-items-center">
            <div class="col-md-8 d-flex align-items-center">
              <img class="logo" src="/assets/images/logo__icon.svg" alt="" />
              <div class="ps-2">
                <h6 class="m-0">Choose your plan</h6>
                <label for="" class="form-label color-primary m-0"
                  >Do you have a coupon?</label
                >
              </div>
            </div>
            <div class="col-md-4">
              <ng-select
                [(ngModel)]="plan"
                [multiple]="false"
                [items]="plans"
                class="w-100"
                bindLabel="invoice_name"
                bindValue="plan_id"
                [disabled]="true"
              >
                <!-- (ngModelChange)="onPlanChange($event)" -->
                <!-- <ng-option [value]="'open'">Base</ng-option>
                <ng-option>Base ++</ng-option>
                <ng-option>Premium</ng-option> -->
              </ng-select>
            </div>
          </div>
        </div>
        <div class="p-4 pt-2">
          <div class="row">
            <div class="col-md-8">
              <input
                type="text"
                class="form-control"
                placeholder="Enter your coupon code"
                [(ngModel)]="coupon"
                (keyup.enter)="applyPromo()"
              />
            </div>
            <div class="col-md-4">
              <button
                class="button button--primary w-100"
                type="submit"
                (click)="applyPromo()"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
        <div class="cart__total px-4 py-3">
          <div class="row mb-1">
            <div class="col-md-6">
              <h5 class="m-0 weight-500">Monthly Payment</h5>
            </div>
            <div class="col-md-6 text-end">
              <h5 class="m-0 weight-500">$ {{ numberWithCommas((totalAmount / 100).toFixed(2)) }}</h5>
            </div>
          </div>
          <div class="row color-primary">
            <div class="col-md-6">
              <h5 class="m-0">Total</h5>
            </div>
            <div class="col-md-6 text-end">
              <h5 class="m-0">
                $ {{ numberWithCommas((totalAmount / 100 - totalDiscount / 100).toFixed(2)) }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 mt-5">
      <div class="button-group justify-content-end">
        <button
          type="button"
          class="button button--primary"
          (click)="onSubscribe()"
        >
          Pay now
        </button>
      </div>
    </div>
  </div>
</div>
