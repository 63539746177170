<div class="d-flex align-items-center mb-4">
  <h4 class="m-0">Total Team {{ total }}</h4>
  <div class="d-flex align-items-center ms-auto">
    <div class="form-search me-4">
      <input
        type="text"
        class="form-control"
        placeholder="Filter by Name..."
        [(ngModel)]="term"
        (keyup.enter)="searchTerm()"
        (ngModelChange)="searchTermChange()"
      />
      <button type="submit">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 27.18 27.9"
          style="enable-background: new 0 0 27.18 27.9"
          xml:space="preserve"
        >
          <path
            d="M17.76,20.63c-5.5,3.82-12.7,1.62-15.85-3.11C-1.21,12.83-0.48,6.56,3.94,2.76 c4.09-3.51,10.17-3.77,14.64-0.19c4.26,3.4,6.04,10.45,1.52,15.96c0.36,0.37,0.72,0.75,1.08,1.12c1.83,1.88,3.67,3.76,5.49,5.65 c0.87,0.91,0.57,2.22-0.58,2.54c-0.64,0.18-1.14-0.07-1.58-0.52c-1.58-1.64-3.16-3.28-4.76-4.9C19.13,21.8,18.44,21.24,17.76,20.63z M11.32,19.59c4.54,0.07,8.36-3.63,8.44-8.19c0.08-4.58-3.62-8.37-8.26-8.44c-4.67-0.07-8.48,3.61-8.54,8.26 C2.9,15.75,6.65,19.51,11.32,19.59z"
          />
        </svg>
      </button>
    </div>
    <button class="button button--open" type="button" (click)="addGroup()">
      <i class="fa fa-plus me-2" aria-hidden="true"></i>
      Create Team
    </button>
  </div>
</div>
<div class="table-main mb-4">
  <div class="table-responsive">
    <table class="table table-vmiddle table-groups text-center m-0">
      <thead>
        <tr>
          <th scope="col" class="text-start">Your Teams</th>
          <th scope="col" style="width: 200px">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let group of groups; index as i">
          <td class="text-start">
            <div class="d-flex align-items-center">
              <i
                class="fa fa-folder-open-o me-3 color-white"
                aria-hidden="true"
              ></i>
              <span class="label me-3">G</span>
              <a
                href="javascript:void(0)"
                class="weight-600 me-3"
                routerLink="/dashboard/projects"
                [queryParams]="{ id: group?._id, provider: provider }"
              >
                {{ group?.name | uppercase }}</a
              >
              <a
                class="edit me-3"
                href="javascript:void(0)"
                (click)="onEdit(group)"
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 15.75 15.75"
                  style="enable-background: new 0 0 15.75 15.75"
                  xml:space="preserve"
                >
                  <path
                    d="M15.75,3.17c-0.02,0.7-0.3,1.43-0.9,2.02c-3.13,3.13-6.27,6.27-9.41,9.4
                                    c-0.11,0.11-0.26,0.2-0.41,0.23c-1.42,0.32-2.84,0.62-4.26,0.92c-0.51,0.11-0.87-0.28-0.76-0.79c0.3-1.39,0.59-2.78,0.9-4.17
                                    c0.04-0.19,0.16-0.39,0.3-0.53c3.09-3.1,6.19-6.2,9.29-9.3c0.91-0.91,2.2-1.19,3.37-0.71C15.02,0.69,15.75,1.8,15.75,3.17z
                                    M1.4,14.33c0.15-0.02,0.25-0.03,0.34-0.05c0.88-0.19,1.76-0.37,2.63-0.58c0.18-0.04,0.38-0.15,0.51-0.28
                                    c2.24-2.23,4.47-4.46,6.71-6.69c0.08-0.08,0.2-0.14,0.26-0.18c-0.91-0.91-1.75-1.75-2.6-2.61C9.24,3.96,9.19,3.99,9.14,4.04
                                    c-2.3,2.3-4.6,4.6-6.9,6.9C2.18,11,2.1,11.09,2.08,11.18C1.85,12.21,1.63,13.24,1.4,14.33z M10.23,3.01
                                    c0.84,0.84,1.68,1.68,2.52,2.53c0.43-0.42,0.89-0.85,1.32-1.3c0.64-0.67,0.62-1.77-0.01-2.45c-0.64-0.68-1.74-0.79-2.44-0.18
                                    C11.11,2.04,10.68,2.55,10.23,3.01z"
                  />
                </svg>
              </a>
              <a
                href="javascript:void(0)"
                class="button button--primary button--xsml"
                routerLink="/dashboard/members"
                [queryParams]="{ id: group?._id, resource: 'Group' }"
                >{{ group.permission.length }} Members</a
              >
            </div>
          </td>
          <td>
            <div
              class="status d-flex align-items-center justify-content-center"
            >
              <a
                href="javascript:void(0)"
                class="d-flex align-items-center"
                tooltip="Sub Groups"
                placement="top"
              >
                <i aria-hidden="true" class="fa fa-folder-o me-2"></i>
                {{ group.sub_groups.length }}
              </a>
              <a
                href="javascript:void(0)"
                class="d-flex align-items-center ms-3"
                tooltip="Projects"
              >
                <i aria-hidden="true" class="fa fa-bookmark-o me-2"></i>
                {{ group.repository.length }}
              </a>
            </div>
          </td>
        </tr>
        <tr *ngIf="groups.length === 0 && isProcessing && isGroupComming">
          <td colspan="2" class="text-center">No records found</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="d-flex justify-content-end">
  <app-pagination
    [count]="total"
    (changePage)="onChangePage($event)"
  ></app-pagination>
</div>
<div
  bsModal
  #modalEditGroup="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title color-white">
          <img src="/assets/images/icon__users.svg" alt="" />
          Edit Team
        </h4>
        <a
          class="modal-close"
          href="javascript:void(0)"
          (click)="modalEditGroup.hide()"
          aria-label="Close"
        >
          <img src="/assets/images/icon__close.svg" alt="" />
        </a>
      </div>
      <div class="modal-body">
        <div class="mb-4">
          <label for="" class="form-label">Enter Team Name</label>
          <input type="text" class="form-control" />
        </div>
        <div class="button-group justify-content-end">
          <button type="submit" class="button button--primary">Edit</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  bsModal
  #modalCreateGroup="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  id="modal___create_group_form"
>
  <div class="modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title color-white">
          <img src="/assets/images/icon__users.svg" alt="" />
          Create Team
        </h4>
        <a
          class="modal-close"
          href="javascript:void(0)"
          aria-label="Close"
          (click)="modalCreateGroup.hide()"
        >
          <img src="/assets/images/icon__close.svg" alt="" />
        </a>
      </div>

      <div class="modal-body">
        <form [formGroup]="groupAddUpdateForm" (submit)="submitGroup()">
          <div class="mb-4">
            <label for="" class="form-label">Enter Team Name</label>
            <input type="text" class="form-control" formControlName="name" />
            <span
              class="form-error"
              *ngIf="
                groupAddUpdateForm.get('name')?.getError('required') &&
                groupAddUpdateForm.get('name')?.touched
              "
              >The field is required.</span
            >
          </div>
          <div class="button-group justify-content-end">
            <button type="submit" class="button button--primary">Create</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
