import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ContentChild,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { updateOrgDate } from 'src/app/store/sidebar-filter.action';
import { ApiService } from '../../../service/api.service';
import { AuthenticationService } from '../../../service/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  data: any;
  org_id: any;
  fileList: string[] | undefined = [];
  currentUser: any;

  contactUsForm = new UntypedFormGroup({
    user: new UntypedFormControl(null),
    details: new UntypedFormControl(null, Validators.required),
    topic: new UntypedFormControl(null, Validators.required),
    question: new UntypedFormControl(null, Validators.required),
    // file: new FormControl(null),
  });
  constructor(
    private auth: AuthenticationService,
    private api: ApiService,
    private toaster: ToastrService,
    private router: Router,
    private store: Store,

  ) {}

  ngOnInit(): void {
    this.data = this.auth.getUserData();
    // //console.log('user>>', this.data);
    this.getCurrentUser();
    // this.auth.login(data)
  }
  onLogout(): void {
    sessionStorage.clear();
    this.auth.logout();
  }
  getCurrentUser(): void {
    this.api.getUser().subscribe(
      (resp: any) => {
        if (resp.code === 0) {
          this.currentUser = resp.result;
          ////console.log('currentUser>>', this.currentUser.user.profile_pic_url);
          if (this.currentUser?.user.profile_pic_url === undefined) {
            ////console.log('undefined')
            document.getElementById('headerpfp')?.setAttribute('src', '/assets/images/icon__arrow-down.svg');
          }
          // const date = resp.result.orgStartDate 
          // //console.log(date);
          
          // this.store.dispatch(new updateOrgDate({date: date
          // }))
        }
      },
      (error: HttpErrorResponse) => {
        // //console.log('error>>', error);
      }
    );
  }

  onFileSelect(event: any[]): void {
    const formData: FormData = new FormData();
    const fileToUpload = event[0];

    const files = fileToUpload.name.split('.');
    const fileExt = files[files.length - 1];
    // //console.log(fileExt);
    if (
      fileExt === 'pdf' ||
      fileExt === 'png' ||
      fileExt === 'jpg' ||
      fileExt === 'jpeg'
    ) {
      formData.append('file', fileToUpload, fileToUpload.name);

      this.api.uploadSingleFile(formData).subscribe(
        (resp) => {
          // this.contactUsForm.patchValue({ file: resp.result });
          this.fileList?.push(resp.result);
        },
        (error) => {},
        () => {}
      );
    } else {
      this.toaster.error('Please upload a Valid File.');
    }
  }

  saveContactUs(): void {
    if (this.contactUsForm.valid) {
      this.api
        .saveContactUs(this.contactUsForm.getRawValue())
        .subscribe((resp) => {
          if (resp.code === 0) {
            this.toaster.success('Enquiry submitted successfully.');
            this.contactUsForm.reset();
            //this.closeContactUsModal.nativeElement.click();
          } else {
            this.toaster.error('Something Went Wrong');
          }
        });
    } else {
      this.markTouchContactUs();
    }
  }

  markTouchContactUs(): void {
    Object.keys(this.contactUsForm.controls).forEach((field) => {
      const control = this.contactUsForm.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
  }
  onRemoveFile(index: number): void {
    this.fileList?.splice(index, 1);
  }
}
