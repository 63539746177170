<div class="mt-4">
  <div class="mb-4 pb-1 d-flex align-items-start">
    <a
      class="back me-3"
      href="javascript:void(0)"
      routerLink="/manage/repo-connection"
    >
      <img src="/assets/images/icon__arrow--left.svg" alt="" />
    </a>
    <div>
      <h4 class="mb-0">Create Connection</h4>
    </div>
  </div>
  <form [formGroup]="connectionForm" (ngSubmit)="onConnect()">
    <div class="row">
      <div class="col-md-5 pe-5">
        <h4 class="mb-4">Select a Repository</h4>
        <ul class="repositories">
          <li (click)="changeProvider('GitHub')">
            <input
              type="radio"
              name="provider"
              id="radio_github"
              value="GitHub"
              formControlName="provider"
            />
            <label for="radio_github">GitHub or GitHub Enterprise Cloud</label>
          </li>
          <li (click)="changeProvider('Bitbucket')">
            <input
              type="radio"
              name="provider"
              id="radio_bitbucket"
              value="Bitbucket"
              formControlName="provider"
            />
            <label for="radio_bitbucket">Bitbucket</label>
          </li>
          <li (click)="changeProvider('Gitlab')">
            <input
              type="radio"
              name="provider"
              id="radio_gitlab"
              value="Gitlab"
              formControlName="provider"
            />
            <label for="radio_gitlab">Gitlab</label>
          </li>
          <li (click)="changeProvider('Azure')">
            <input
              type="radio"
              name="provider"
              id="radio_gitazure"
              value="Azure"
              formControlName="provider"
            />
            <label for="radio_gitazure">Azure</label>
          </li>
        </ul>
      </div>
      <div class="col-md-7">
        <h4 class="mb-4">
          Create {{ connectionForm.get("provider")?.value }} Connection
        </h4>
        <div class="mb-4">
          <label for="" class="form-label">Connection Name</label>
          <input
            type="text"
            class="form-control"
            formControlName="connection_name"
          />
          <span
            class="form-text form-error"
            *ngIf="
              connectionForm.get('connection_name')?.getError('required') &&
              connectionForm.get('connection_name')?.touched
            "
            >This is a required field.</span
          >
        </div>
        <div
          class="mb-4"
          *ngIf="connectionForm.get('provider')?.value === 'Gitlab'"
        >
          <div class="d-flex align-items-center mb-2">
            <label for="" class="form-label mb-0">Gitlab Url</label>
            <span class="form-text d-block m-0 ms-auto color-primary"
              >* <span class="color-white text-">https://gitlab.com</span> if
              Using Gitlab on Cloud</span
            >
          </div>
          <input type="text" class="form-control" formControlName="gitlabUrl" />
          <span
            class="form-text form-error"
            *ngIf="
              connectionForm.get('gitlabUrl')?.getError('required') &&
              connectionForm.get('gitlabUrl')?.touched
            "
            >This is a required field.</span
          >
        </div>
        <div
          class="mb-5"
          *ngIf="connectionForm.get('provider')?.value === 'Azure'"
        >
          <label for="" class="form-label">Azure organization name</label>
          <input
            type="text"
            class="form-control"
            formControlName="azureOrgName"
          />
        </div>
        <div
          class="mb-5"
          *ngIf="
            connectionForm.get('provider')?.value === 'Gitlab' ||
            connectionForm.get('provider')?.value === 'Azure'
          "
        >
          <label for="" class="form-label">Gitlab Personal Access Token</label>
          <input
            type="password"
            class="form-control"
            formControlName="personalAccessToken"
          />
        </div>
        <div class="button-group">
          <button type="submit" class="button button--primary">
            Create Connection
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
