import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Role } from 'src/app/interface/role';
import { ApiService } from 'src/app/service/api.service';
import { AuthenticationService } from 'src/app/service/authentication.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
  @ViewChild('modalRemoveProject', { static: false })
  modalRemoveProject?: ModalDirective;
  countryCodes: any = [];
  notifiedOptions: any = [
    { key: 'email', label: 'Email' },
    { key: 'phone', label: 'Phone' },
    { key: 'both', label: 'Both' },
  ];
  editOrgUserForm = new UntypedFormGroup({
    username: new UntypedFormControl(null, Validators.required),
    first_name: new UntypedFormControl(null, Validators.required),
    last_name: new UntypedFormControl(null, Validators.required),
    email: new UntypedFormControl(null, [Validators.required, Validators.email]),
    isAutoGenerate: new UntypedFormControl(true),
    notifyUser: new UntypedFormControl(true),
    notify: new UntypedFormControl('email'),
    phone_no: new UntypedFormControl(null),
    country_code: new UntypedFormControl(null),
    role: new UntypedFormControl(null),
    resetPassword: new UntypedFormControl(true),
    isEnabled: new UntypedFormControl(true),
    isActive: new UntypedFormControl(true),
  });
  id: any = '';
  roles: Role[] = [];
  currentUser: any;
  constructor(
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private toaster: ToastrService,
    private location: Location,
    private auth: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((param) => {
      this.id = param.get('id');
      if (this.id && this.id !== '') {
        this.getUserData();
      } else {
        // this.getProjectId();
      }
    });
    this.getRoles();
    this.getCurrentUserData();
    this.getAllCountryCode();
  }
  updateUser(): void {
    // if(this.currentUser)
    if (this.currentUser._id == this.id) {
      this.toaster.error("Self user can't do this operation");
    } else {
      if (this.editOrgUserForm.valid) {
        let data = this.editOrgUserForm.getRawValue();
        data.userId = this.id;
        this.api.updateOrganizationUser(data).subscribe(
          (resp) => {
            if (resp.code === 0) {
              this.toaster.success('User Updated');
              this.router.navigate(['/dashboard/manage/users']);
            } else if (resp.code === 4) {
              this.toaster.error(resp.result);
              this.getUserData();
            } else {
              if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
                this.toaster.error(resp.result);
              } else {
                this.toaster.error('Internal Server Error');
              }
              this.getUserData();
            }
          },
          (error) => {
            if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
              this.toaster.error(error.error.result);
            } else {
              this.toaster.error('Something Went Wrong');
            }
            this.getUserData();
          }
        );
      } else {
        this.editOrgUserForm.markAllAsTouched();
      }
    }
  }
  getRoles(): void {
    this.api.getRoles().subscribe(
      (resp: any) => {
        if (resp.code === 0) {
          this.roles = resp.result.data;
        }
      },
      (error: HttpErrorResponse) => {
        // //console.log('error>>', error);
      }
    );
  }
  getUserData(): void {
    this.api.getOrganizationUser(this.id).subscribe(
      (resp) => {
        // //console.log('resp>>', resp);
        if (resp.code === 0) {
          this.editOrgUserForm.patchValue({
            username: resp.result.username,
            role: resp?.result.role,
            first_name: resp.result.first_name,
            last_name: resp.result.last_name,
            email: resp.result.email,
            resetPassword: resp.result.reset_password_on_next_signup,
            isEnabled: resp.result.isEnabled,
            isActive: resp.result.isActive,
            notify: resp.result.notify,
            phone_no: resp.result.phone_no,
            country_code: resp.result.country_code,
          });
        } else {
          this.toaster.error('Failed to Load User Data');
        }
      },
      (error) => {
        this.toaster.error('Failed to Load User Data');
      }
    );
  }
  resetPasswordRequest(): void {
    if (this.currentUser._id == this.id) {
      this.toaster.error("Self user can't do this operation");
    } else {
      this.api.resetOrgUserPassword({ userId: this.id }).subscribe(
        (resp) => {
          if (resp.code === 0) {
            this.toaster.success('Email has been sent with new password');
          } else {
            this.toaster.error(
              'Failed to reset password. Please try after sometime.'
            );
          }
        },
        (error) => {
          this.toaster.error('Failed to Load User Data');
        }
      );
    }
  }
  back(): void {
    this.location.back();
  }
  check() {
    // //console.log('check>>', this.editOrgUserForm.get('isEnabled')?.value);
    if (!this.editOrgUserForm.get('isEnabled')?.value) {
      this.modalRemoveProject?.show();
    } else {
      this.modalRemoveProject?.hide();
    }
  }
  getCurrentUserData(): void {
    this.api.getUser().subscribe(
      (resp: any) => {
        // //console.log('respCurrnt>>', resp);
        if (resp.code === 0) {
          this.currentUser = resp.result.user;
        } else {
        }
      },
      (error: HttpErrorResponse) => {
        // //console.log('error>>', error);
      }
    );
  }
  onNotAgree(): void {
    this.getUserData();
    this.modalRemoveProject?.hide();
  }
  getAllCountryCode(): void {
    this.api.getAllCountryCode().subscribe((resp) => {
      if (resp.code === 0) {
        this.countryCodes = resp.result.map((c: any) => {
          return { ...c, label: c.code + ' (' + c.name + ')' };
        });
      }
    });
  }
  onNotificationTypeChange(e: any): void {
    if (e.key == 'email') {
      this.editOrgUserForm.patchValue({ phone_no: null, country_code: null });
      this.editOrgUserForm.controls['phone_no'].clearValidators();
      this.editOrgUserForm.controls['country_code'].clearValidators();
    } else {
      this.editOrgUserForm.controls['phone_no'].addValidators([
        Validators.required,
      ]);
      this.editOrgUserForm.controls['country_code'].addValidators([
        Validators.required,
      ]);
    }
    this.editOrgUserForm.controls['country_code'].updateValueAndValidity();
    this.editOrgUserForm.controls['phone_no'].updateValueAndValidity();
  }

  numberOnly(event : any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  onPastePhoneNumber(){
    let pastedText = this.editOrgUserForm.get('phone_no')?.value; //clipboardData?.getData('text');
    pastedText = pastedText?.replace(/[^0-9]/g, '');
    // //console.log(pastedText)
    // this.signupForm.reset({phone_no: ''});
    this.editOrgUserForm.get('phone_no')?.patchValue(pastedText)
  }
}
