import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Role } from 'src/app/interface/role';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-manage-role',
  templateUrl: './manage-role.component.html',
  styleUrls: ['./manage-role.component.scss'],
})
export class ManageRoleComponent implements OnInit {
  @ViewChild('modalRemoveProject', { static: false })
  modalRemoveProject?: ModalDirective;
  constructor(private api: ApiService, private toaster: ToastrService) {}

  total = 0;
  page: number = 1;
  roles: Role[] = [];
  term: string = '';
  selectedRole: string | undefined;

  ngOnInit(): void {
    this.getRoles();
  }
  getRoles(): void {
    this.api.getRoles(this.page, this.term).subscribe(
      (resp) => {
        if (resp.code === 0) {
          this.roles = resp.result.data;
          this.total = resp.result.total;
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  search(): void {
    // //console.log('this', this.term);
    this.getRoles();
  }
  onChangePage(page: number): void {
    if (page !== this.page) {
      this.page = page;
      this.getRoles();
    }
  }
  onDeleteRequest(id: string): void {
    this.modalRemoveProject?.show();
    this.selectedRole = id;
  }
  onDelete(): void {
    if (this.selectedRole) {
      this.api.deleteRole(this.selectedRole).subscribe(
        (resp: any) => {
          // //console.log('resp>>', resp);
          if (resp.code === 0) {
            this.selectedRole = undefined;
            this.modalRemoveProject?.hide();
            this.getRoles();
            this.toaster.success('Role has been deleted successfully.');
          } else {
            this.toaster.error(resp.result);
          }
        },
        (error: HttpErrorResponse) => {
          this.toaster.error(error.error.message);
        }
      );
    }
  }
}
