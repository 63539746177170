<div class="site__authentication site__signin">
  <div class="container-fluid align-self-center">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <form [formGroup]="resetPasswordForm" autocomplete="off" class="mb-5">
          <div class="logo">
            <img src="/assets/images/logo__icon.svg" alt="" />
          </div>
          <div class="mb-4">
            <div class="position-relative">
              <input
                type="password"
                class="form-control"
                placeholder="Current Password..."
                autocomplete="off"
                formControlName="current_password"
              />
              <span class="icon icon--lg">
                <img src="/assets/images/icon__password.svg" alt="" />
              </span>
            </div>
            <div
              class="form-text form-error"
              *ngIf="
                resetPasswordForm
                  .get('current_password')
                  ?.getError('required') &&
                resetPasswordForm.get('current_password')?.touched
              "
            >
              This is a required field.
            </div>
          </div>
          <div class="mb-4">
            <div class="position-relative">
              <input
                type="password"
                class="form-control"
                placeholder="Enter new password"
                autocomplete="off"
                formControlName="new_password"
                appEqualValidator="current_password"
                (input)="
                  resetPasswordForm
                    .get('confirm_password')
                    ?.updateValueAndValidity()
                "
              />
              <span class="icon icon--lg">
                <img src="/assets/images/icon__password.svg" alt="" />
              </span>
            </div>
            <div
              class="form-text form-error"
              *ngIf="
                resetPasswordForm.get('new_password')?.getError('required') &&
                resetPasswordForm.get('new_password')?.touched
              "
            >
              This is a required field.
            </div>
            <div
              class="form-text form-error"
              *ngIf="
                resetPasswordForm.get('new_password')?.touched &&
                resetPasswordForm.get('new_password')?.getError('equal')
              "
            >
              New password should not be the same as current password.
            </div>
            <div
              *ngIf="
                resetPasswordForm.get('new_password')?.getError('pattern') &&
                resetPasswordForm.get('new_password')?.touched
              "
            >
              <p class="form-text form-info mt-3 mb-0">
                <span>Use at least 10 characters</span>
                <span>Use upper and lower case characters</span>
                <span>Use 1 or more numbers</span>
                <span>Use 1 special characters</span>
              </p>
            </div>
          </div>
          <div class="mb-4">
            <div class="position-relative">
              <input
                type="password"
                class="form-control"
                placeholder="Confirm password"
                autocomplete="off"
                formControlName="confirm_password"
                appConfirmEqualValidator="new_password"
              />
              <span class="icon icon--lg">
                <img src="/assets/images/icon__password.svg" alt="" />
              </span>
            </div>
            <div
              class="form-text form-error"
              *ngIf="
                resetPasswordForm
                  .get('confirm_password')
                  ?.getError('required') &&
                resetPasswordForm.get('confirm_password')?.touched
              "
            >
              This is a required field.
            </div>
            <div
              class="form-text form-error"
              *ngIf="
                resetPasswordForm
                  .get('confirm_password')
                  ?.getError('notEqual') &&
                !resetPasswordForm.get('confirm_password')?.getError('required')
              "
            >
              New Password & Confirm Password should match
            </div>
          </div>
          <div class="button-group">
            <button
              type="submit"
              class="button button--primary w-100"
              (click)="changePassword()"
            >
              Change Password
            </button>
          </div>
        </form>
        <!-- <p class="text-center m-0">
          Already have an account?
          <a href="javascript:void(0)" routerLink="/auth/login">Sign In</a>
        </p> -->
      </div>
    </div>
  </div>
</div>
