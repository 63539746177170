import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { API } from '../interface/api';

@Injectable({
  providedIn: 'root',
})
export class MonitorApiService {
  apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}
  getAllRepo(): Observable<API> {
    return this.http.get<API>(this.apiUrl + 'get-all-repos');
  }
  getAllIpLocation(start_date:any, end_date: any, term: any): Observable<API>{
    return this.http.get<API>(this.apiUrl + `fetch-ip-locations?start_date=${start_date}&end_date=${end_date}&term=${term}`)
  }
  getAllGroups(): Observable<API> {
    return this.http.get<API>(this.apiUrl + 'get-all-group');
  }

  getAllDataForMonitor(data: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + 'get-all-data-for-monitor', data);
  }
  // getAllDataForMonitor(data): Observable<API> {
  //   return this.http.post<API>(this.apiUrl + 'get-all-dummy-data', data);
  // }
  getAllDataForPieChart(data: any): Observable<API> {
    return this.http.post<API>(
      this.apiUrl + 'get-all-data-for-pie-chart',
      data
    );
  }
  getUser(): Observable<API> {
    return this.http.get<API>(this.apiUrl + 'get-user');
  }
  getAllDataForBarChart(data: any): Observable<API> {
    return this.http.post<API>(
      this.apiUrl + 'get-all-data-for-bar-chart',
      data
    );
  }
  getAllDataForLineChart(data: any): Observable<API> {
    return this.http.post<API>(
      this.apiUrl + 'get-all-data-for-line-chart',
      data
    );
  }
  getAllDataForLineChartRepoWise(data: any): Observable<API> {
    return this.http.post<API>(
      this.apiUrl + 'get-all-repo-users',
      data
    );
  }
  getAllDataForBubbleChart(data: any): Observable<API> {
    return this.http.post<API>(
      this.apiUrl + 'get-all-data-for-bubble-chart',
      data
    );
  }
  getAllMonitoringStats(): Observable<API> {
    return this.http.get<API>(
      `${this.apiUrl}get-organization-development-status-parameters`
    );
  }
  getAllDisputeCount(): Observable<API> {
    return this.http.get<API>(`${this.apiUrl}get-all-dispute-count`);
  }

  getCountryData(): Observable<API>{
    return this.http.get<API>(`${this.apiUrl}fetch-ip-countries`)
  }
}
