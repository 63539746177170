
import { Component, OnInit } from '@angular/core';
import { ConnectableObservable } from 'rxjs';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-threat',
  templateUrl: './threat.component.html',
  styleUrls: ['./threat.component.scss']
})
export class ThreatComponent implements OnInit {
  constructor(private api: ApiService) { }
  result : any = []
  alertButtonClick: string = ''
  bulletinsButtonClick: string = ''
  analysis_reportsButtonClick: string = ''
  monitorsButtonClick: string = ''
  ngOnInit(): void {
    this.alertButtonClick = "button--primary"
    this.bulletinsButtonClick = "button--secondary"
    this.analysis_reportsButtonClick = "button--secondary"
    this.monitorsButtonClick = "button--secondary"
    this.getThreat("alerts")
   
    // //console.log('xfdgfghfh',this.json)
  }

  getThreat(threatName: string){
    this.api.fetchThreats(threatName).subscribe((resp:any) => {
    ////console.log('fgcfxfx',resp)
    this.result = []
    if(threatName == 'analysis-reports'){
      for(let i of resp.result){
        ////console.log(i.title[0])
        this.result.push({
          title : i.title[0],
          link : i.link[0],
          name : i.link[0].split('/').pop().toUpperCase(),
          pubDate : i.pubDate[0]
        })
      }
    }else if(threatName == 'alerts'){
      for(let i of resp.result){
        this.result.push({
          title : i.title[0],
          link : i.link[0],
          name : i.link[0].split('/').pop().toUpperCase(),
          pubDate : i.pubDate[0]
        })
      }
    }
    else{
      for(let i of resp.result){
        this.result.push({
          title : i.title[0],
          link : i.link[0],
          name : i.link[0].split('/').pop().toUpperCase(),
          pubDate : i.pubDate[0]
        })
      }
    }
    
    })
    // //console.log(this.result)
  }

  onClick(threatName: string){
    // this.buttonClick = [false,false,false,false]
    this.getThreat(threatName)
    if(threatName == 'alerts'){
      this.alertButtonClick = "button--primary"
      this.bulletinsButtonClick = "button--secondary"
      this.analysis_reportsButtonClick = "button--secondary"
      this.monitorsButtonClick = "button--secondary"
    }else if(threatName == 'bulletins'){
      this.alertButtonClick = "button--secondary"
      this.bulletinsButtonClick = "button--primary"
      this.analysis_reportsButtonClick = "button--secondary"
      this.monitorsButtonClick = "button--secondary"
    }else if(threatName == 'analysis-reports'){
      this.alertButtonClick = "button--secondary"
      this.bulletinsButtonClick = "button--secondary"
      this.analysis_reportsButtonClick = "button--primary"
      this.monitorsButtonClick = "button--secondary"
    }else{
      this.alertButtonClick = "button--secondary"
      this.bulletinsButtonClick = "button--secondary"
      this.analysis_reportsButtonClick = "button--secondary"
      this.monitorsButtonClick = "button--primary"
    }
    
  }



  
}
