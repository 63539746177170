import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
})
export class DeleteModalComponent implements OnInit {
  @Input('deleteModalText') deleteModalText?: any;
  constructor() {}
  @ViewChild('closeModal') closeModal?: ElementRef;
  @Output() onDeleteCalled = new EventEmitter<boolean>();
  @Output() onDeleteCanceled = new EventEmitter<boolean>();
  ngOnInit(): void {}
  onDelete(): void {
    this.closeModal?.nativeElement.click();
    this.onDeleteCalled.emit(true);
  }
  onCancelOrClosed(): void {
    this.closeModal?.nativeElement.click();
    this.onDeleteCanceled.emit(true);
  }
}
