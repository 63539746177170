<div class="modal-container">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Upload SBOM</h4>
        <button type="button" class="close" (click)="closeModal()">×</button>
      </div>
  
      <div class="modal-body">
        <form (ngSubmit)="onSubmit()" #uploadForm="ngForm">
          <div class="form-group">
            <label for="projectName">Project Name:</label>
            <input
              type="text"
              id="projectName"
              [(ngModel)]="projectName"
              name="projectName"
              class="form-control"
              required
            />
          </div>
  
          <div class="form-group">
            <label for="repositoryName">Repository Name:</label>
            <input
              type="text"
              id="repositoryName"
              [(ngModel)]="repositoryName"
              name="repositoryName"
              class="form-control"
              required
            />
          </div>
  
          <div class="form-group">
            <label for="branchName">Branch Name:</label>
            <input
              type="text"
              id="branchName"
              [(ngModel)]="branchName"
              name="branchName"
              class="form-control"
              required
            />
          </div>
  
          <div class="form-group">
            <label for="jsonFile">Select JSON file:</label>
            <input
              type="file"
              id="jsonFile"
              (change)="onFileSelected($event)"
              accept=".json"
              class="form-control"
              required
            />
          </div>
  
          <div *ngIf="fileName" class="file-info">
            <p>Selected file: {{ fileName }}</p>
          </div>
  
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="!file || isSubmitting"
          >
            Upload
          </button>
        </form>
  
        <div *ngIf="uploadError" class="alert alert-danger mt-2">
          {{ uploadError }}
        </div>
      </div>
    </div>
  </div>
  