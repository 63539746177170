import * as UserAction from './user.action';
import { UserState } from '../interface/user';
import { ActionReducer } from '@ngrx/store';

const initialState: UserState = {
  token: null,
  first_name: null,
  last_name: null,
  org_id: null,
  accountId: null,
  type: null,
  two_step_on: true,
  hasSubscription: true,
  expired: false,
  paymentFailure: false,
  subscriptionType: '',
  permissions: null,
};
export function userDataReducer(
  state = initialState,
  action: UserAction.UserAction
): any {
  switch (action.type) {
    case UserAction.UPDATE_ALL:
      // //console.log(action.payload);
      
      return {
        ...state,
        token: action.payload?.token,
        first_name: action.payload?.first_name,
        last_name: action.payload?.last_name,
        org_id: action.payload?.org_id,
        accountId: action.payload?.accountId,
        type: action.payload?.type,
        //@ts-ignore
        two_step_on: action.payload?.two_step_on || action.payload?.twoStepOn,
        permissions: action.payload?.permissions,
      };
    case UserAction.UPDATE_NAME:
      sessionStorage.setItem('first_name', action.payload.first_name);
      sessionStorage.setItem('last_name', action.payload.last_name);
      return {
        ...state,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
      };
    case UserAction.UPDATE_TWO_STEP:
      sessionStorage.setItem('two_step_on', String(action.payload.two_step_on));
      return {
        ...state,
        two_step_on: action.payload.two_step_on,
      };
    case UserAction.UPDATE_SUBSCRIPTION_STATUS:
      // console.log('payload', action.payload);
      return {
        ...state,
        hasSubscription: action.payload.hasSubscription,
        expired: action.payload.expired,
        subscriptionType: action.payload.subscriptionType,
        paymentFailure: action.payload.paymentFailure,
      };
    default:
      return state;
  }
}
