<div class="d-flex align-items-center mb-4">
  <h4 class="m-0">Intelligence Threat</h4>
  <div class="button-group ms-auto">
    <button type="button" class="button button--sml ms-3" [class] = "alertButtonClick"  (click)="onClick('alerts')">
      <span>Alerts</span>
    </button>
    <button type="button" class="button button--sml ms-3" [class] = "bulletinsButtonClick" (click)="onClick('bulletins')">
      <span>Bulletins</span>
    </button>
    <button type="button" class="button button--sml ms-3" [class] = "analysis_reportsButtonClick" (click)="onClick('analysis-reports')">
      <span>Analysis-Reports</span>
    </button>
    <button type="button" class="button button--sml ms-3 disabled" [class] = "monitorsButtonClick" (click)="onClick('monitors')">
      <span>Monitor Newsletters</span>
    </button>
  </div>
</div>
<div class="table-main mb-4">
  <div class="table-responsive">
    <table class="table table-nowrap m-0">
      <thead>
        <tr>
          <th scope="col">Title</th>
          <th scope="col">Link</th>
          <th scope="col">Published Date</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="result.length === 0">
          <td colspan="9" class="text-center">No record found</td>
        </tr>
        <tr *ngFor="let i of result">
          <td scope="col">{{i.name}}</td>
          <td scope="col"><a href="{{i.link}}" target="_blank">{{i.title}}</a></td>
          <td scope="col">{{i.pubDate}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>






<!-- <div>
    <li>
        <a href="">Alerts</a>
    </li>
    <li>
        <a href="">bulletins</a>
    </li>
    <li>
        <a href="">Announcements</a>
    </li>
    <li>
        <a href="">Monitor Newsletters</a>
    </li>
</div> -->