import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs';
import { ApiService } from 'src/app/service/api.service';
import { decodeToken, jsonToQueryString } from 'src/app/utils/helper';

@Component({
  selector: 'app-questioner',
  templateUrl: './questioner.component.html',
  styleUrls: ['./questioner.component.scss'],
})
export class QuestionerComponent implements OnInit {
  token: string | null = null;
  decodedToken: any;
  questionaryForm = new UntypedFormGroup({
    os: new UntypedFormControl(null, Validators.required),

    code_repo_use: new UntypedFormControl(null, Validators.required),

    is_airgapped: new UntypedFormControl(null, Validators.required),

    is_corporate_proxy: new UntypedFormControl(null, Validators.required),

    total_dev: new UntypedFormControl(null, [
      Validators.required,
      Validators.min(1),
      Validators.pattern('^(0|[1-9][0-9]*)$'),
    ]),

    additional_info: new UntypedFormControl(null),
  });
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private api: ApiService,
    private toaster: ToastrService
  ) {
    // this.token = this.router.getCurrentNavigation()?.extras?.state['token'];
  }

  ngOnInit(): void {
    this.activeRoute.paramMap
      .pipe(map(() => window.history.state))
      .subscribe((state) => {
        //  this.highlight = state && state.highlight;
        // //console.log('state', state);
        this.token = state['token'];
        if (!this.token) {
          this.router.navigate(['/auth/login'], {
            state: { error: true, message: 'Invalid access' },
          });
        } else {
          this.decodedToken = decodeToken(this.token);
        }
      });
    // this.activeRoute.queryParams.subscribe((params: any) => {
    //   this.token = params['token'];
    // if (!this.token) {
    //   // this.router.navigate(['/auth/login'], {
    //   //   state: { error: true, message: 'Invalid access' },
    //   // });
    // } else {
    //   this.decodedToken = decodeToken(this.token);
    //   //console.log('decodedToken>', this.decodedToken);
    // }
    // });
  }
  onSubmit(): void {
    // //console.log('this>>', this.questionaryForm);
    if (this.questionaryForm.valid) {
      if (this.decodedToken.redirect_login) {
        let data = {
          ...this.questionaryForm.getRawValue(),
          org_id: this.decodedToken.org_id,
        };
        // //console.log('data>>', data);
        this.api.updateOrg(data).subscribe(
          (resp: any) => {
            // //console.log('resp>>', resp);
            if (resp.code == 0) {
              this.toaster.success(resp?.result);
              this.router.navigate(['/auth/login']);
            } else {
              this.toaster.error(resp?.message);
            }
          },
          (error: HttpErrorResponse) => {
            this.toaster.error('Something error! Please try after sometime');
          }
        );
      } else {
        this.router.navigate(['/auth/signup'], {
          queryParams: {
            ...this.questionaryForm.getRawValue(),
            token: this.token,
          },
        });
      }
    } else {
      this.questionaryForm.markAllAsTouched();
    }
  }
}
