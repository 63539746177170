<!-- <p>analytics-component works!</p> -->
<div class="block">
    <div class="block__nav">
      <ul class="nav">
        <!-- <li>
          <a href="javascript:void(0)" routerLinkActive="active">Dashboard</a>
        </li> -->
        <li>
            Analytics
        </li>
      </ul>
    </div>
</div>


<div class="block__content">
    <div class="monitor">
      <div class="row">
        <div class="col-md-2" *ngIf="this.filters">
          <h6>
            <img
            class="icon me-3"
            src="/assets/images/icon__account-preferences--blue.svg"
            alt=""
            />
            Filters
            <a
              (click)="resetFilter()"
              href="javascript:void(0)"
              class="reset ms-auto"
            >
              <i class="fa fa-refresh" aria-hidden="true"></i>
            </a>
          </h6>
          <ul class="nav flex-column">
            <li>
              <img
                class="icon me-3"
                src="/assets/images/icon__monitor-date.svg"
                alt=""
              />
              <input
                type="text"
                placeholder="Date Range"
                bsDaterangepicker
                [bsConfig]="{
                  displayMonths: 1,
                  rangeInputFormat: 'MM/DD/YYYY'
                }"
                [(ngModel)]="date"
                (ngModelChange)="onDateChange()"
                placement="bottom"
                [maxDate]="maxDate"
                [minDate]="orgStartDate"
              />
              <a
                (click)="clearCalendar()"
                href="javascript:void(0)"
                class="reset ms-2"
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </a>
            </li>
            <li class="dropdown" dropdown [insideClick]="true">
              <a
                href="javascript:void(0)"
                dropdownToggle
                (click)="(false)"
                aria-controls="dropdown__monitor--groups"
              >
                <img
                  class="icon me-3"
                  src="/assets/images/icon__monitor-group.svg"
                  alt=""
                />
                Teams
              </a>
              <div
                id="dropdown__monitor--groups"
                *dropdownMenu
                class="dropdown-menu"
                role="menu"
                aria-labelledby="basic-link"
              >
                <ul *ngIf="groups.length === '0'">
                  <span>No records found</span>
                </ul>
                <ul *ngIf="groups.length != 0">
                  <li *ngFor="let g of groups" (click)="selectGroup(g._id)">
                    <div class="form-checkbox has-label">
                      <input
                        type="checkbox"
                        [checked]="selectedGroup.includes(g._id)"
                      />
                      <span class="icon"></span>
                      <label for="" class="form-label">{{ g.name }}</label>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li class="dropdown" dropdown [insideClick]="true">
              <a
                href="javascript:void(0)"
                dropdownToggle
                (click)="(false)"
                aria-controls="dropdown__monitor--repository"
              >
                <img
                  class="icon me-3"
                  src="/assets/images/icon__monitor-repository.svg"
                  alt=""
                />
                Repository
              </a>
              <div
                id="dropdown__monitor--repository"
                *dropdownMenu
                class="dropdown-menu"
                role="menu"
                aria-labelledby="basic-link"
              >
                <ul *ngIf="repos.length != 0">
                  <li *ngFor="let r of repos" (click)="selectRepos(r._id)">
                    <div class="form-checkbox has-label">
                      <input
                        type="checkbox"
                        [checked]="selectedRepo.includes(r._id)"
                      />
                      <span class="icon"></span>
                      <label for="" class="form-label">{{ r.name }}</label>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-md-10">
            <!-- <div class="col-md-12"> -->
            <app-analytics-page></app-analytics-page>
        </div>
      </div>
    </div>
  </div>

