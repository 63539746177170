<div class="modal fade" id="delete_confirmation" tabindex="-1" aria-labelledby="delete_confirmation"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeModal (click)="onCancelOrClosed()">

      </button>
      <a href="javascript:void(0)" class="close" data-dismiss="modal" aria-label="Close">
        <img src="../../../../assets/images/icon__close.svg" alt="">
      </a>
      <div class="modal-body ">
                        <span class="icon">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </span>
        <h4>Are you sure?</h4>
        <p>{{deleteModalText}} <br>This process can't be undone.</p>
        <div class="button-group justify-content-center pt-2">
          <a href="javascript:void(0)" class="button button--sml button--primary button--reverse" data-dismiss="modal" (click)="onCancelOrClosed()">
            Cancel
          </a>
          <a href="javascript:void(0)" class="button button--sml button--primary" (click)="onDelete()">
            Delete
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
