<form [formGroup]="projectForm">
  <div class="mb-4 d-flex align-items-start">
    <a
      class="back me-3"
      href="javascript:void(0)"
      (click)="onBack()"
    >
      <img src="/assets/images/icon__arrow--left.svg" alt="" />
    </a>
    <div>
      <h4 class="mb-1">{{ title }} Project</h4>
    </div>
  </div>
  <div class="row mb-5">
    <div class="col-md-12">
      <div class="mb-4">
        <label for="" class="form-label">Project Name</label>
        <input
          type="text"
          class="form-control"
          placeholder="Please enter your project name"
          formControlName="name"
        />
        <span
          class="form-error"
          *ngIf="
            projectForm.get('name')?.hasError('required') &&
            projectForm.get('name')?.touched
          "
          >The field is required.</span
        >
      </div>
    </div>

    <div class="col-md-6">
      <div class="mb-4">
        <label for="" class="form-label">Provider</label>
        <ng-select
          placeholder="Please choose your provider"
          [searchable]="false"
          formControlName="provider"
          (change)="changeProvider()"
        >
          <ng-option value="GitHub">
            GitHub or GitHub Enterprise Cloud
          </ng-option>
          <ng-option value="Bitbucket">Bitbucket</ng-option>
          <ng-option value="Gitlab">Gitlab</ng-option>
          <ng-option value="Azure">Azure</ng-option>
        </ng-select>
        <span
          class="form-error"
          *ngIf="
            projectForm.get('provider')?.hasError('required') &&
            projectForm.get('provider')?.touched
          "
          >The field is required.</span
        >
      </div>
    </div>
    <div class="col-md-6">
      <div class="mb-4">
        <label for="" class="form-label">Connection</label>
        <div class="position-relative">
          <ng-select
            placeholder="Please choose your connection"
            [items]="connections"
            bindValue="_id"
            bindLabel="connection_name"
            (change)="selectConnection($event)"
            formControlName="connection"
          >
          </ng-select>
          <span class="form-loading" *ngIf="appLoader"
            ><i class="fa fa-spinner fa-pulse"></i
          ></span>
        </div>
      </div>
    </div>
    <div class="col-md-12 text-center pt-2 pb-1">
      <strong class="weight-600">Or</strong>
    </div>
    <div class="button-group justify-content-center mt-4">
      <button
        type="submit"
        class="button button--primary"
        (click)="onConnect()"
      >
        Connect to {{ projectForm.get("provider")?.value }}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="mb-4">
        <div class="d-flex align-items-center mb-2">
          <label for="" class="form-label mb-0"> Repository Name </label>
          <span class="form-text d-block m-0 ms-auto color-primary"
            >E.g. &lt;account&gt; /
            &lt;repository-name&gt;&lt;/repository-name&gt;</span
          >
        </div>
        <div class="position-relative">
          <ng-select
            placeholder="Please choose your repository"
            [items]="repos"
            bindValue="full_name"
            bindLabel="full_name"
            (change)="selectRepo($event.full_name)"
            formControlName="repository_full_name"
          >
          </ng-select>
          <span class="form-loading" *ngIf="repoLoader"
            ><i class="fa fa-spinner fa-pulse"></i
          ></span>
        </div>
        <span
          class="form-error"
          *ngIf="
            projectForm.get('repository_full_name')?.hasError('required') &&
            projectForm.get('repository_full_name')?.touched
          "
          >The field is required.</span
        >
      </div>
    </div>
    <!-- <div class="col-md-6">
      <div class="mb-4">
        <label for="" class="form-label">Branch Name</label>
        <ng-select
          placeholder="Please choose your branch"
          [items]="branches"
          bindValue="name"
          bindLabel="name"
          formControlName="listener_branch"
          (change)="onSelectBranch($event)"
        > -->
          <!-- <ng-option>Branch A</ng-option>
          <ng-option>Branch B</ng-option>
          <ng-option>Branch C</ng-option> -->
        <!-- </ng-select>
        <span
          class="form-error"
          *ngIf="
            projectForm.get('listener_branch')?.hasError('required') &&
            projectForm.get('listener_branch')?.touched
          "
          >The field is required.</span
        >
      </div>
    </div> -->
    <!-- <div
      class="col-md-6"
      *ngIf="projectForm.get('provider')?.value === 'GitHub'"
    >
      <div class="mb-4">
        <label for="" class="form-label">Whitelist Contributors</label>
        <ng-select
          placeholder="Please Select Contributors"
          [multiple]="true"
          [items]="contributorsList"
          bindValue="login"
          bindLabel="login"
          formControlName="contributors" -->
        <!-- > -->
          <!-- <ng-option>Branch A</ng-option>
          <ng-option>Branch B</ng-option>
          <ng-option>Branch C</ng-option> -->
        <!-- </ng-select>
      </div>
    </div> -->

    <div class="col-md-6">
      <div class="mb-4">
        <label for="" class="form-label">Hash Function Type</label>
        <ng-select
          placeholder="Please choose your hash function type"
          formControlName="hash_function_type"
          (change)="onSelectHashFUnctionType($event)"
        >
          <!-- <ng-option [value]="null">Select hash function type</ng-option> -->
          <ng-option value="Independent">Auto Assign</ng-option>
          <ng-option value="Linked">Manually Assign</ng-option>
        </ng-select>
        <span
          class="form-error"
          *ngIf="
            projectForm.get('hash_function_type')?.hasError('required') &&
            projectForm.get('hash_function_type')?.touched
          "
          >The field is required.</span
        >
      </div>
    </div>
    <div
      class="col-md-6"
      *ngIf="projectForm.get('hash_function_type')?.value === 'Linked'"
    >
      <div class="mb-4">
        <label for="" class="form-label">Hash Function </label>
        <ng-select
          placeholder="Please choose your hash function "
          formControlName="hash_function"
        >
          <!-- <ng-option value="md5">MD5</ng-option> -->
          <ng-option value="sha256">SHA256</ng-option>
          <ng-option value="sha384">SHA384</ng-option>
          <ng-option value="sha512">SHA512</ng-option>
        </ng-select>
        <span
          class="form-error"
          *ngIf="
            projectForm.get('hash_function')?.hasError('required') &&
            projectForm.get('hash_function')?.touched
          "
          >The field is required.</span
        >
      </div>
    </div>
    <div class="col-md-6">
      <div class="mb-4">
        <label for="" class="form-label">Number of Lines</label>
        <input
          type="number"
          class="form-control"
          min="1"
          placeholder="Enter the number of lines after which you want insert the hash"
          formControlName="lines"
        />
        <span
          class="form-error"
          *ngIf="
            projectForm.get('lines')?.hasError('required') &&
            projectForm.get('lines')?.touched
          "
          >The field is required.</span
        >
      </div>
    </div>
    <div
      class="button-group justify-content-center mt-4"
      *ngIf="projectForm.get('provider')?.value === 'Gitlab'"
    >
      <button
        type="submit"
        class="button button--primary"
        (click)="modalWebhookUrl.show()"
      >
      {{ title }} Project
      </button>
    </div>
    <div
      class="button-group justify-content-center mt-4"
      *ngIf="projectForm.get('provider')?.value === 'Azure'"
    >
      <button
        type="submit"
        class="button button--primary"
        (click)="modalWebhookUrlAzure.show()"
      >
        {{ title }} Project
      </button>
    </div>
    <div
      class="button-group justify-content-center mt-4"
      *ngIf="
        projectForm.get('provider')?.value !== 'Gitlab' &&
        projectForm.get('provider')?.value !== 'Azure'
      "
    >
      <button type="submit" class="button button--primary" (click)="onSave()">
        {{ title }} Project
      </button>
    </div>
  </div>
</form>

<div
  bsModal
  #modalWebhookUrl="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title color-white">
          <img src="/assets/images/icon__monitor-repository.svg" alt="" />
          Repository Webhook Url
        </h4>
        <a
          class="modal-close"
          href="javascript:void(0)"
          (click)="modalWebhookUrl.hide()"
          aria-label="Close"
        >
          <img src="/assets/images/icon__close.svg" alt="" />
        </a>
      </div>
      <div class="modal-body">
        <div class="mb-4">
          <label class="form-label"
            >Add this url to your repository webhook section</label
          >

          <div class="form-action">
            <input
              #webhookInput
              type="text"
              class="form-control"
              readonly
              [value]="
                serverUrl +
                'webhook/gitlab/' +
                (beforeProjectId ? beforeProjectId : updateData?._id)
              "
            />
            <a href="javascript:void(0)" tooltip="Copy URL" (click)="onCopy()">
              <img src="/assets/images/icon__copy.svg" alt="" />
            </a>
          </div>

          <div class="form-action mt-4" *ngIf="webhookSecret">
            <input
              #webhookSecretInput
              [(ngModel)]="webhookSecret"
              type="text"
              class="form-control"
              readonly
              [value]="
                serverUrl +
                'webhook/gitlab/' +
                (beforeProjectId ? beforeProjectId : updateData._id)
              "
            />
            <a
              href="javascript:void(0)"
              tooltip="Copy Secret"
              (click)="onCopyWebHookSecret()"
            >
              <img src="/assets/images/icon__copy.svg" alt="" />
            </a>
          </div>
        </div>

        <div class="button-group justify-content-end">
          <a
            href="https://docs.gitlab.com/ee/user/project/integrations/webhooks.html"
            target="_blank"
            class="button button--primary button--sml"
          >
            View Documentation
          </a>
          <button
            type="button"
            class="button button--primary button--sml"
            (click)="onSave()"
          >
            Save
          </button>
          <button
            type="button"
            class="button button--primary button--sml"
            (click)="onGenerateSecret()"
          >
            Generate Secret
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  bsModal
  #modalWebhookUrlAzure="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title color-white">
          <img src="/assets/images/icon__monitor-repository.svg" alt="" />
          Repository Webhook Url
        </h4>
        <a
          class="modal-close"
          href="javascript:void(0)"
          (click)="modalWebhookUrlAzure.hide()"
          aria-label="Close"
        >
          <img src="/assets/images/icon__close.svg" alt="" />
        </a>
      </div>
      <div class="modal-body">
        <div class="mb-4">
          <label class="form-label"
            >Add this url to your repository webhook section</label
          >

          <div class="form-action">
            <input
              #webhookAzureInput
              type="text"
              class="form-control"
              readonly
              [value]="
                serverUrl +
                'webhook/azure/' +
                (beforeProjectId ? beforeProjectId : updateData?._id)
              "
            />
            <a href="javascript:void(0)" tooltip="Copy URL" (click)="onCopyAzure()">
              <img src="/assets/images/icon__copy.svg" alt="" />
            </a>
          </div>
        </div>

        <div class="button-group justify-content-end">
          <!-- <a
            href="https://docs.gitlab.com/ee/user/project/integrations/webhooks.html"
            target="_blank"
            class="button button--primary button--sml"
          >
            View Documentation
          </a> -->
          <button
            type="button"
            class="button button--primary button--sml"
            (click)="onSave()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
