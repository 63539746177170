import { DecimalPipe, Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { Util } from 'src/app/service/utils';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  status = 'open';
  addtionalProjectCost: number = 750000;
  addtionalDeveloperCost: number = 95000;
  // maxDeveloper: number = 40;
  minDeveloper: number = 0;
  maxProject: number = 4;
  minProject: number = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private toaster: ToastrService,
    private util: Util,
    private location: Location,
    private decimalPipe: DecimalPipe
  ) {}
  developer = 10;
  projects = 1;
  additionalDeveloper = 0;
  additionalProject = 0;
  totalAmount = 0;
  plan = '';
  selectedPlan: any;
  plans = [];
  coupon = '';
  appliedCoupon = '';
  discountApplied = 0;
  totalDiscount = 0;
  totalAmountShow : string ='';
  totalDiscountShow : string = '';
  ngOnInit(): void {
    // //console.log('onInit');
    if (this.util.isPermission('isUpgradePlan')) {
      this.route.queryParams.subscribe((params: any) => {
        // //console.log('query>>', params);
        if (Object.prototype.hasOwnProperty.call(params, 'q')) {
          this.plan = decodeURIComponent(params.q);
          // //console.log('plan>>>', this.plan);
        } else {
          this.toaster.error('Please select a plan');
          this.router.navigate(['/dashboard/billing']);
        }
      });
      this.getAllPlans();
    } else {
      this.location.back();
    }
  }
  ngOnChanges(): void {
    // //console.log('ngOnChanges');
  }
  getAllPlans(): void {
    this.api.getAllPlan().subscribe(
      (resp) => {
        // //console.log('all plans>>', resp);
        if (resp.code === 0) {
          this.plans = resp.result;
          this.selectedPlan = this.plans.filter(
            (p: any) => p.plan_id === this.plan
          )[0];
          this.developer = this.selectedPlan.developer;
          this.projects = this.selectedPlan.projects;
          this.totalAmount = this.selectedPlan.amount;
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  onSubscribe(): void {
    // if (this.developer < 10 || this.projects < 1) {
    //   this.toaster.error('Atleast 10 Developer and 1 Project Required');
    //   return;
    // }

    // if (this.developer > 60 || this.projects > 6) {
    //   this.toaster.error('Please Contact Sales Team for Better Deals');
    //   return;
    // }
    // //console.log(this.plan, this.developer + this.additionalDeveloper)
    this.api
      .getPaymentHostPage({
        developer: this.developer + this.additionalDeveloper,
        // projects: this.additionalProject,
        planId: this.plan,
        coupon: this.appliedCoupon || '',
      })
      .subscribe(
        (resp: any) => {
          if (resp.code === 0) {
            localStorage.setItem('checkout_id', resp.result.id);
            this.saveDevProjectInLocal();
            const link = document.createElement('a');
            link.href = resp.result.url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            this.toaster.error(resp.result);
          }
          // //console.log(resp)
        },
        (error: HttpErrorResponse) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
  }
  private saveDevProjectInLocal(): void {
    localStorage.setItem('developer', String(this.additionalDeveloper));
    localStorage.setItem('projects', String(this.additionalProject));
    localStorage.setItem('plan', JSON.stringify(this.selectedPlan));
  }
  private removeItemsFromLS(): void {
    localStorage.removeItem('developer');
    localStorage.removeItem('projects');
    localStorage.removeItem('checkout_id');
    localStorage.removeItem('plan');
  }
  // onPlanChange(plan: any): void {
  //   this.selectedPlan = this.plans.find((e: any) => e.planId === plan);
  //   if (this.selectedPlan.name === 'BASE++') {
  //     this.additionalDeveloper = 0;
  //     this.additionalProject = 0;
  //     this.totalAmount = 15000;
  //     this.changeValues();
  //   }
  // }
  changeValues(): void {
    // if (
    //   this.additionalDeveloper <= this.maxDeveloper &&
    //   this.additionalDeveloper >= this.minDeveloper &&
    //   this.additionalProject <= this.maxProject &&
    //   this.additionalProject >= this.minProject
    // ) {
      this.additionalDeveloper = parseInt(this.additionalDeveloper?.toString().replace(/[^a-zA-Z0-9 ]/g, ''));
      this.totalAmount = this.selectedPlan.amount * (this.additionalDeveloper + 1) || this.selectedPlan.amount
      // this.totalAmountShow = this.totalAmount.toFixed(2)
        // this.additionalDeveloper * this.addtionalDeveloperCost +
        // this.additionalProject * this.addtionalProjectCost +
        // this.selectedPlan.amount;

      this.totalDiscount = this.totalAmount * (this.discountApplied / 100);
      // this.totalDiscountShow = this.totalDiscount.toFixed(2)
    // } else {
    //   this.toaster.error(
    //     `You should be set additional developer between ${this.minDeveloper}-${this.maxDeveloper} or addtional project between ${this.minProject}-${this.maxProject}`
    //   );
    //   this.additionalProject = 0;
    //   this.additionalDeveloper = 0;
    //   this.totalAmount =
    //     this.additionalDeveloper * this.addtionalDeveloperCost +
    //     this.additionalProject * this.addtionalProjectCost +
    //     this.selectedPlan.amount;

    //   this.totalDiscount = this.totalAmount * (this.discountApplied / 100);
    // }
  }
  applyPromo(): void {
    this.api.verifyCouponCode(this.coupon).subscribe(
      (resp: any) => {
        if (resp.code === 0) {
          this.appliedCoupon = this.coupon;
          this.coupon = '';
          this.discountApplied = resp.result.discount_percentage;
          this.totalDiscount = this.totalAmount * (this.discountApplied / 100);
        } else {
          this.toaster.error('Invalid Coupon');
        }
      },
      (error: HttpErrorResponse) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  removePromo(): void {
    this.coupon = '';
    this.appliedCoupon = '';
    this.discountApplied = 0;
    this.totalDiscount = 0;
  }
  format(num: number) {
    return this.decimalPipe.transform(num, '2.0-2');
  }

  onlyNumber(event : any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  
  }

  numberWithCommas(x: string) {
    var parts = x.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

}
