import {Action} from '@ngrx/store';

export const UPDATE_DATE_RANGE = 'UPDATE_DATE_RANGE';
export const UPDATE_GROUPS = 'UPDATE_GROUPS';
export const UPDATE_REPOS = 'UPDATE_REPOS';
export const RESET_ALL_FILTERS = 'RESET_ALL_FILTERS';
export const RESET_CALENDAR_FILTERS = 'RESET_CALENDAR_FILTERS'
export const UPDATE_TREE_DATA = 'UPDATE_TREE_DATA';
export const UPDATE_CLI_DATA = 'UPDATE_CLI_DATA';
export const UPDATE_REPO_DATA = 'UPDATE_REPO_DATA';
export const UPDATE_ORG_DATE = 'UPDATE_ORG_DATE';
export const UPDATE_COMMIT_USER_NAME = 'UPDATE_COMMIT_USER_NAME';





export class UpdateDateRange implements Action{
  readonly type = UPDATE_DATE_RANGE;
  constructor(public payload: {
    start_date: Date | string, end_date: Date | string
  }) {
  }
}

export class UpdateGroups implements Action{
  readonly type = UPDATE_GROUPS;
  constructor(public payload: {
   groups: string[]
  }) {
  }
}

export class UpdateRepos implements Action{
  readonly type = UPDATE_REPOS;
  constructor(public payload: {
    repos: string[]
  }) {
  }
}

export class ResetAllFilters implements Action {
  readonly type = RESET_ALL_FILTERS;
  constructor(public payload: {
    groups: string[],
    repos: string[],
    start_date: string | Date,
    end_date: string | Date
  }) {
  }
}

export class ResetCalendarFilter implements Action {
  readonly type = RESET_CALENDAR_FILTERS;
  constructor(public payload: {
    start_date: string | Date,
    end_date: string | Date
  }) {
  }
}

export class updateTreeData implements Action {
  readonly type = UPDATE_TREE_DATA;
  constructor(public payload: {
    tree: any,
  }) {
  }
}

export class updateCommitUserName implements Action {
  readonly type = UPDATE_COMMIT_USER_NAME;
  constructor(public payload: {
    commitUserName: any,
  }) {
  }
}

export class updateCliData implements Action {
  readonly type = UPDATE_CLI_DATA;
  constructor(public payload: {
    cli: boolean,
  }) {
  }
}


export class updateRepoData implements Action {
  readonly type = UPDATE_REPO_DATA;
  constructor(public payload: {
    repo: any,
  }) {
  }
}
export class updateOrgDate implements Action {
  readonly type = UPDATE_ORG_DATE;
  constructor(public payload: {
    date: any,
  }) {
  }
}
export type SidebarFilterAction = UpdateDateRange | UpdateRepos | UpdateGroups | ResetAllFilters  | updateTreeData | ResetCalendarFilter | updateCliData | updateRepoData | updateOrgDate | updateCommitUserName;
