<div class="pagination" *ngIf="count>0">
  <ul class="nav">
    <li [class.disabled]="activePage===1">
      <a href="javascript:void(0)" (click)="backwardPagenator()">
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
      </a>
    </li>
    <li  *ngFor="let p of viewPages" [class.current]="p===activePage">
      <a href="javascript:void(0)" (click)="setActivePage(p)">{{p}}</a>
    </li>
    <li [class.disabled]="activePage === viewPages[viewPages.length - 1]">
      <a href="javascript:void(0)" (click)="forwardPaginator()">
        <i class="fa fa-chevron-right" aria-hidden="true"></i>
      </a>
    </li>
  </ul>
</div>
