<div class="site__maintenance">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-center mb-4">
                    <img class="logo" src="/assets/images/logo.svg" alt="">
                </div>
                <div class="row justify-content-center mb-5">
                    <div class="col-md-5">
                        <img class="img-fluid" src="/assets/images/maintenance.svg" alt="">
                    </div>
                </div>
                <p class="m-0 weight-600"><span class="color-primary p--l d-block mb-1">We're pushing some updates, be back soon!</span>Don't worry. Your code is still being monitored. If you have any questions or concerns, please contact us at <a href="mailto:support@codelock.it">support@codelock.it</a>.</p>
            </div>
        </div>
    </div>
</div>