<div class="site__authentication site__signin">
  <div class="container-fluid align-self-center">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <form
          [formGroup]="requestChangePasswordForm"
          autocomplete="off"
          class="mb-5"
        >
          <div class="logo">
            <img src="/assets/images/logo__icon.svg" alt="" />
          </div>
          <div class="mb-4">
            <div class="position-relative">
              <input
                type="email"
                class="form-control"
                placeholder="Email..."
                autocomplete="off"
                formControlName="email"
              />
              <span class="icon">
                <img src="/assets/images/icon__email.svg" alt="" />
              </span>
            </div>
            <span
              class="form-text form-error"
              *ngIf="
                requestChangePasswordForm.get('email')?.getError('required') &&
                requestChangePasswordForm.get('email')?.touched
              "
              >This is a required field.</span
            >
          </div>
          <div class="button-group">
            <button
              (click)="onForgotPassword()"
              type="submit"
              class="button button--primary w-100"
            >
              Forgot Password?
            </button>
          </div>
        </form>
        <p class="text-center m-0">
          Already have an account?
          <a href="javascript:void(0)" routerLink="/auth/login">Sign In</a>
        </p>
      </div>
    </div>
  </div>
</div>
