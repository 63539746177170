import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { API } from '../interface/api';
import { User } from '../interface/user';
import { ApiService } from './api.service';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionStatus } from '../interface/SubscriptionStatus';
import { Store } from '@ngrx/store';
import { UpdateAll, UpdateSubscriptionStatus } from '../store/user.action';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  apiUrl = environment.apiUrl;
  private userData: User | undefined;
  private userDataCopy: User | undefined;
  private subStatus: SubscriptionStatus | undefined;
  // private userPermission: UserPermission;
  constructor(
    private http: HttpClient,
    private router: Router,
    private api: ApiService,
    private toaster: ToastrService,
    private store: Store<{ userData: User }>
  ) {}

  login(data: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + 'login', data);
  }
  verifyFaceId(data: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + 'verify-face-id', data);
  }
  signUp(data: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + 'create-root-user', data);
  }
  setUserData(data: any): void {
    this.userData = data;
    // this.userDataCopy = data;
    // this.getUserData = () => this.userData;
    this.store.dispatch(new UpdateAll(this.userData));
  }
  getUserData = () => {
    return this.userData;
  };
  getTokenFormLS = () => {
    return {
      token: localStorage.getItem('token'),
      first_name: localStorage.getItem('first_name'),
      last_name: localStorage.getItem('last_name'),
      accountId: localStorage.getItem('accountId'),
      org_id: localStorage.getItem('org_id'),
      org_name: localStorage.getItem('org_name'),
      type: localStorage.getItem('type'),
      two_step_on: localStorage.getItem('two_step_on') !== 'false',
      permissions: JSON.parse(localStorage.getItem('permissions') || '{}'),
      time: localStorage.getItem('time'),
    };
  };
  clearsessionStorage(): void {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('first_name');
    sessionStorage.removeItem('last_name');
    sessionStorage.removeItem('accountId');
    sessionStorage.removeItem('org_id');
    sessionStorage.removeItem('org_name');
    sessionStorage.removeItem('type');
    sessionStorage.removeItem('two_step_on');
    sessionStorage.removeItem('permissions');
    sessionStorage.removeItem('time');
    localStorage.removeItem('token');
    localStorage.removeItem('first_name');
    localStorage.removeItem('last_name');
    localStorage.removeItem('accountId');
    localStorage.removeItem('org_id');
    localStorage.removeItem('org_name');
    localStorage.removeItem('type');
    localStorage.removeItem('two_step_on');
    localStorage.removeItem('permissions');
    localStorage.removeItem('time');
  }
  saveToken = (
    token: string,
    first_name: string,
    last_name: string,
    accountId: string,
    orgId: string,
    org_name: string,
    type: string,
    twoStepOn: boolean,
    permissions: string[],
    time: string,
    userId: string = ''
  ): void => {
    // //console.log('set token', token);

    sessionStorage.setItem('token', token);
    sessionStorage.setItem('first_name', first_name);
    sessionStorage.setItem('last_name', last_name);
    sessionStorage.setItem('accountId', accountId);
    sessionStorage.setItem('org_id', orgId);
    sessionStorage.setItem('org_name', org_name);
    sessionStorage.setItem('type', type);
    sessionStorage.setItem('two_step_on', String(twoStepOn));
    sessionStorage.setItem('permissions', JSON.stringify(permissions));
    sessionStorage.setItem('time', time);
    sessionStorage.setItem('userId', userId);
    this.setUserData({
      token,
      first_name,
      last_name,
      accountId,
      org_name,
      orgId,
      type,
      twoStepOn,
      permissions,
      time,
      userId
    });
    localStorage.setItem('token', token);
    localStorage.setItem('first_name', first_name);
    localStorage.setItem('last_name', last_name);
    localStorage.setItem('accountId', accountId);
    localStorage.setItem('org_id', orgId);
    localStorage.setItem('org_name', org_name);
    localStorage.setItem('type', type);
    localStorage.setItem('two_step_on', String(twoStepOn));
    localStorage.setItem('permissions', JSON.stringify(permissions));
    localStorage.setItem('time', time);
    localStorage.setItem('userId', userId);
    this.setUserData({
      token,
      first_name,
      last_name,
      accountId,
      org_name,
      orgId,
      type,
      twoStepOn,
      permissions,
      time,
      userId
    });
  };
  logout = () => {
    this.api.logout().subscribe((resp) => {
      if (resp.code === 0) {
        this.clearsessionStorage();
        this.userData = undefined;
        this.store.dispatch(
          new UpdateAll({
            token: null,
            first_name: null,
            last_name: null,
            type: null,
            accountId: null,
            two_step_on: false,
            org_id: null,
            permissions: null,
          })
        );
        this.router.navigate(['/auth/login']);
      } else {
        if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
          this.toaster.error(resp.result);
        } else {
          this.toaster.error('Internal Server Error');
        }
      }
    });
  };
  resendVerificationMail(): void {
    if (!this.userData?.token) return;
    this.api.resendVerificationMail(this.userData?.token).subscribe(
      (resp) => {
        if (resp.code === 0) {
          this.toaster.success(
            'Verification mail has been sent it can take upto 30 min'
          );
        } else {
          if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
            this.toaster.error(resp.result);
          } else {
            this.toaster.error('Internal Server Error');
          }
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  async autoLogin(url: string): Promise<void> {
    const data = this.getTokenFormLS();

    if (
      data &&
      data.token &&
      // data.first_name &&
      // data.last_name &&
      data.org_id &&
      data.accountId
      // data.type &&
      // data.two_step_on !== null &&
      // data.two_step_on !== undefined
    ) {
      this.userData = data;

      this.store.dispatch(new UpdateAll(this.userData));
      this.subStatus = await this.checkSubStatus();
      const queryParamsObj = this.processesUrl(url);
      if (url === '' || url === '/dashboard' || url === '/auth/login') {
        this.router.navigate(['/home']);
      } else {
        const originalUrl = url.split('?')[0];
        if (Object.keys(queryParamsObj).length > 0) {
          this.router.navigate([originalUrl], { queryParams: queryParamsObj });
        } else {
          this.router.navigate([originalUrl]);
        }
      }
    } else {
      this.clearsessionStorage();
      const queryParamsObj = this.processesUrl(url);
      const routeUrl = url.split('?');
      // //console.log('url>>>', url, queryParamsObj);
      if ('developer' in queryParamsObj && 'project' in queryParamsObj) {
        this.saveDevProjectInLocal(
          queryParamsObj.developer,
          queryParamsObj.project
        );
      }
      // change from / to url
      this.router.navigate([routeUrl[0]], { queryParams: queryParamsObj });
    }
  }
  verifyRootUserOtp(data: any): Observable<API> {
    return this.http.post<API>(this.apiUrl + 'verify-login-otp', data);
  }
  private saveDevProjectInLocal(developer: number, projects: number): void {
    localStorage.setItem('developer', String(developer));
    localStorage.setItem('projects', String(projects));
  }
  private processesUrl(url: string): any {
    const queryParams = url.split('?')[1];
    if (queryParams) {
      const queryParamsObj: any = {};
      queryParams.split('&').forEach((ele) => {
        queryParamsObj[ele.split('=')[0]] = ele.split('=')[1];
      });
      return queryParamsObj;
    }
    return {};
  }
  checkSubStatus(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.api.checkForSubscription().subscribe(
        (resp) => {
          if (resp.code === 0) {
            this.store.dispatch(new UpdateSubscriptionStatus(resp.result));
            // console.log('sub status', resp.result);
            resolve(resp.result);
          }
        },
        (error) => {}
      );
    });
  }
  getSubStatus(): SubscriptionStatus | undefined {
    return this.subStatus;
  }
}
