
<div class="d-flex align-items-center mb-4" 
*ngIf="this.tree === undefined">

  <div class="d-flex align-items-center">
    <a class="back me-3" href="javascript:void(0)" (click)="onBack()">
      <img src="/assets/images/icon__arrow--left.svg" alt="" />
    </a>
    <h4 class="m-0">Dispute Log   Commited By: {{ commitUserName }}</h4>
  </div>
  <div class="ms-auto d-flex align-items-center">
    <p class="mb-0 me-5 weight-600">
      Total dispute files
      <span class="badge ms-1">{{ diffs?.length }}</span>
    </p>
    <div class="button-group" *ngIf="this.showAction">
      <a
        href="javascript:void(0)"
        class="button button--icon"
        tooltip="Approve All"
        (click)="onApproveAll()"
      >
        <i class="fa fa-check" aria-hidden="true"></i>
      </a>
      <a
        href="javascript:void(0)"
        class="button button--icon"
        tooltip="Disapprove All"
        (click)="onDisApproveAll()"
      >
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
  </div>
</div>
<accordion
  [isAnimated]="true"
  [closeOthers]="oneAtATime"
  class="accordion accordion__dispute-log"
  *ngIf="provider !== 'Azure' && this.tree === undefined"
>
  <accordion-group [isOpen]="true" *ngFor="let dispute of diffs">
    <div class="accordion__title" accordion-heading>
      <span class="path">
        <i class="fa fa-caret-right" aria-hidden="true"></i>
        {{ dispute.relative_path + dispute.file_name }} 
        <!-- /{{ dispute.file_path }} -->
        <a
          href="javascript:void(0)"
          class="ms-2 color-primary"
          *ngIf="dispute.multiple"
          tooltip="You have not approved dispute of this file from previous scan by approving this the latest changes of the fill will be picked"
        >
          <i class="fa fa-info-circle d-block" aria-hidden="true"></i>
        </a>
      </span>

    </div>
    <div class="accordion__content">
      <div class="approve-msg" *ngIf="dispute.approved">
        <span class="d-block">Approved By: {{ dispute.actionUser }}</span> 
        <span class="d-block">Approved Time: {{ approvedTime }}</span>
      </div> 
      <div class="approve-msg" *ngIf="dispute.disapproved">
        <span class="d-block">Disapproved By: {{ dispute.actionUser }}</span> 
        <span class="d-block">Disapproved Time: {{ approvedTime }}</span>
      </div> 
      <div class="table-responsive">
        <table class="code" *ngFor="let patch of dispute.patchs">
          <tr>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr
            *ngFor="let lines of patch.patchData; index as i"
            [class]="
              lines.line.startsWith('+')
                ? 'code_new'
                : lines.line.startsWith('-')
                ? 'code_old'
                : null
            "
          >
            <td>
              {{ !lines.line.startsWith("+") ? lines.lineNumber : "" }}
            </td>
            <td>
              {{ !lines.line.startsWith("-") ? lines.lineNumber : "" }}
            </td>
            <td *ngIf="lines.line.startsWith('+')">
              <span>+</span>{{ lines.line.substr(1) }}
            </td>
            <td *ngIf="lines.line.startsWith('-')">
              <span>-</span>{{ lines.line.substr(1) }}
            </td>
            <td
              *ngIf="!lines.line.startsWith('+') && !lines.line.startsWith('-')"
            >
              {{ lines.line }}
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>
    </div>
  </accordion-group>
  <!-- <div *ngIf="isLoading">Loading diff...</div> -->
  <div *ngIf="!isLoading">

    <app-diff-viewer [diffObject]="diffObject"></app-diff-viewer>
    </div>


  <div class="table-main">
    <div class="table-responsive">
      <table class="table table-vmiddle m-0">
        <thead>
          <tr>
            <th scope="col">Scan Name</th>
            <th scope="col">Committed By</th>
            <th scope="col">Branch</th>
            <th scope="col">Scan Status</th>
            <th scope="col">Trigger Point</th>
            <th scope="col">Trigger Time</th>
            <th scope="col">MFA</th>
            <th scope="col">CommitID</th>
            <th scope="col">Time Taken to Scan</th>
          </tr>
        </thead>
        <tbody>
          <tr class="disputed">
            <td>
              <div class="d-flex align-items-center">
                <a
                  href="javascript:void(0)"
                  class="weight-600 d-flex align-items-center"
                  >{{ this.scanInfo.name }}
                  <span
                    *ngIf="this.scanInfo.pendingcommits"
                    class="badge bg-warning status__cli text-dark px-1 mx-3"
                    >CLI</span
                  ></a
                >
                <span
                  *ngIf="this.scanInfo.pending_dispute > 0  || (this.scanInfo.pendingcommits && this.scanInfo.pendingcommits.isCommitPushed === false)"
                  tooltip="Dispute Is pending For Approval"
                  class="text-danger icon__dispute ms-auto"
                >
                  <svg
                    height="1792"
                    viewBox="0 0 1792 1792"
                    width="1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1440 893q0-161-87-295l-754 753q137 89 297 89 111 0 211.5-43.5t173.5-116.5 116-174.5 43-212.5zm-999 299l755-754q-135-91-300-91-148 0-273 73t-198 199-73 274q0 162 89 299zm1223-299q0 157-61 300t-163.5 246-245 164-298.5 61-298.5-61-245-164-163.5-246-61-300 61-299.5 163.5-245.5 245-164 298.5-61 298.5 61 245 164 163.5 245.5 61 299.5z"
                    ></path>
                  </svg>
                </span>
              </div>
            </td>
            <td>{{ this.scanInfo.commitUserName }} ({{ this.scanInfo.commitUserEmail }})</td>
            <td>{{ this.scanInfo.branch }}</td>
            <td class="text-center">
              <span
                class="badge px-2"
                [class]="
                  this.scanInfo.status === 'Success'
                    ? 'status__success bg-success'
                    : this.scanInfo.status === 'Failed'
                    ? 'status__failed bg-danger'
                    : 'status__processing bg-info'
                "
              >
                {{ this.scanInfo.status }}
              </span>
            <td>{{ this.scanInfo.trigger_point }}</td>
            <td>{{ this.scanInfo.createdAt | date: "medium" }}</td>
            <td>{{ this.scanInfo.mfa || "N/A"}}</td>
            <td>{{ this.scanInfo.commitId }}</td>
            <td>{{ this.scanInfo.duration ? (this.scanInfo.duration / 1000).toFixed(2) : 0 }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</accordion>

<div class="d-flex align-items-center mb-4" 
*ngIf="this.tree === 'CLI'"
>
  <div class="d-flex align-items-center">
    <a class="back me-3" href="javascript:void(0)" (click)="onBack()">
      <img src="/assets/images/icon__arrow--left.svg" alt="" />
    </a>
    <h4 class="m-0">Dispute Log</h4>
  </div>
  <div class="ms-auto d-flex align-items-center">
    <p class="mb-0 me-5 weight-600">
      Total dispute files
      <span class="badge ms-1">{{ diffs?.length }}</span>
    </p>
    <div class="button-group" *ngIf="this.showCliAction === false">
      <a
        href="javascript:void(0)"
        class="button button--icon"
        tooltip="Approve All"
        (click)="onApproveAllCli()"
      >
        <i class="fa fa-check" aria-hidden="true"></i>
      </a>
    </div>
  </div>
</div>

<accordion
  [isAnimated]="true"
  [closeOthers]="oneAtATime"
  class="accordion accordion__dispute-log accordion__cli"
  *ngIf="provider !== 'Azure' && this.tree === 'CLI'"
>
  <accordion-group [isOpen]="true" *ngFor="let dispute of diffs; index as i">
    <div class="accordion__title" accordion-heading >
        <span class="path" >
          <i class="fa fa-caret-right" aria-hidden="true"></i>
          /{{ dispute.file_path }} Changed on Line: {{dispute.line[0].line_number}}
        </span>
    </div>
    <div class="accordion__content" *ngFor="let list of dispute.content; index as j">
      {{list}}<br />
    </div>
  </accordion-group>
</accordion>

<div class="table-main" *ngIf="provider === 'Azure'">
  <div class="table-responsive">
    <table class="table table-nowrap table-vmiddle m-0">
      <thead>
        <tr>
          <th scope="col">File Name</th>
          <th scope="col">Relative Path</th>
          <th class="text-center">Starting & Ending Line No.</th>
          <th class="text-center">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let dispute of diffs">
          <td>{{ dispute.file_name }}</td>
          <td>{{ dispute.relative_path + dispute.file_name }}</td>
          <td class="text-center">
            <a
              href="javascript:void(0)"
              class="button button--primary button--xsml"
              (click)="viewMore(dispute)"
              >View More</a
            >
          </td>
          <td>
            <div class="button-group justify-content-center">
              <a
                href="javascript:void(0)"
                tooltip="Approve"
                class="button button--icon"
                (click)="
                  onApproveSingle(dispute._id);
                  $event.stopImmediatePropagation()
                "
              >
                <i class="fa fa-check" aria-hidden="true"></i>
              </a>
              <a
                href="javascript:void(0)"
                class="button button--icon"
                (click)="
                  onDisApproveSingle(dispute._id);
                  $event.stopImmediatePropagation()
                "
                tooltip="Disapprove"
              >
                <i class="fa fa-times" aria-hidden="true"></i>
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


<div
  bsModal
  #modalAzureDisputeDetails="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title color-white">
          <i class="fa fa-gavel me-3" aria-hidden="true"></i>
          Dispute Details
        </h4>
        <a
          class="modal-close"
          href="javascript:void(0)"
          (click)="modalAzureDisputeDetails.hide()"
          aria-label="Close"
        >
          <img src="/assets/images/icon__close.svg" alt="" />
        </a>
      </div>
      
      <div class="modal-body">
        <div class="table-main">
          <div class="table-responsive">
            <table class="table table-nowrap table-vmiddle m-0">
              <thead>
                <tr>
                  <th scope="col">File Name</th>
                  <th scope="col">Relative Path</th>
                  <th class="text-center">Starting Line No.</th>
                  <th class="text-center">Ending Line No.</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let dispute of selectedDispute?.items; index as i">
                  <td>{{ selectedDispute.file_name }}</td>
                  <td>
                    {{
                      selectedDispute.relative_path + selectedDispute.file_name
                    }}
                  </td>
                  <td class="text-center">{{ dispute.start_line_number }}</td>
                  <td class="text-center">{{ dispute.end_line_number }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<app-otp-approval
(otpEvent)="type === approveAll($event)"
(modalEvent)="modalStatusChange($event)"
[isOpen]="isOTPModalOpen"
></app-otp-approval>