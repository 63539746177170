import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../../service/authentication.service';
import { ApiService } from '../../../service/api.service';

@Component({
  selector: 'app-report-issue',
  templateUrl: './report-issue.component.html',
  styleUrls: ['./report-issue.component.scss'],
})
export class ReportIssueComponent implements OnInit {
  @Output() closeModal = new EventEmitter<void>();
  fileList: string[] | undefined = [];
  contactUsForm = new UntypedFormGroup({
    user: new UntypedFormControl(null),
    details: new UntypedFormControl(null, Validators.required),
    // topic: new FormControl(null, Validators.required),
    question: new UntypedFormControl(null, Validators.required),
  });
  constructor(
    private auth: AuthenticationService,
    private api: ApiService,
    private toaster: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  onFileSelect(event: any[]): void {
    const formData: FormData = new FormData();
    const fileToUpload = event[0];
    const files = fileToUpload.name.split('.');
    const fileExt = files[files.length - 1];
    // //console.log(fileExt);
    if (
      fileExt === 'pdf' ||
      fileExt === 'png' ||
      fileExt === 'jpg' ||
      fileExt === 'jpeg'
    ) {
      formData.append('image', fileToUpload, fileToUpload.name);
      this.toaster.info('File is uploading');
      this.api.uploadSingleFile(formData).subscribe(
        (resp) => {
          this.toaster.success('File has been uploaded successfully');
          if (this.fileList?.length) {
            this.fileList[0] = resp.result;
          } else {
            this.fileList?.push(resp.result);
          }
        },
        (error) => {},
        () => {}
      );
    } else {
      this.toaster.error('Please upload a Valid File.');
    }
  }

  saveContactUs(): void {
    if (this.contactUsForm.valid) {
      let data = this.contactUsForm.getRawValue();
      if (this.fileList?.length) data.file = this.fileList[0];
      this.api.reportIssue(data).subscribe((resp) => {
        if (resp.code === 0) {
          this.toaster.success('Issue Submitted Successfully.');
          this.onCloseModal();
          this.contactUsForm.reset();
          this.fileList = [];
        } else {
          this.toaster.error('Something Went Wrong');
        }
      });
    } else {
      this.markTouchContactUs();
    }
  }

  markTouchContactUs(): void {
    Object.keys(this.contactUsForm.controls).forEach((field) => {
      const control = this.contactUsForm.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
  }
  onRemoveFile(index: number): void {
    this.fileList?.splice(index, 1);
  }
  onCloseModal(): void {
    this.closeModal.emit();
  }
}
