<div class="block mb-5">
  <div class="block__title">
    <img class="icon me-3" src="/assets/images/icon__account.svg" alt="" />
    <h6>Edit User</h6>
  </div>
  <form [formGroup]="editOrgUserForm" (submit)="updateUser()">
    <div class="block__content">
      <div class="row align-items-center mb-4">
        <div class="col-md-4">
          <label for="" class="form-label m-0">Username</label>
        </div>
        <div class="col-md-8">
          <input
            type="text"
            class="form-control"
            formControlName="username"
            placeholder="Username"
          />
        </div>
        <div
          class="col-md-12"
          *ngIf="
            editOrgUserForm.get('username')?.hasError('required') &&
            editOrgUserForm.get('username')?.touched
          "
        >
          <div class="row justify-content-end">
            <div class="col-md-8">
              <span class="form-text form-error">
                This is a required field.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center mb-4">
        <div class="col-md-4">
          <label for="" class="form-label m-0">First Name</label>
        </div>
        <div class="col-md-8">
          <input
            type="text"
            class="form-control"
            placeholder="First name"
            formControlName="first_name"
          />
        </div>
        <div
          class="col-md-12"
          *ngIf="
            editOrgUserForm.get('first_name')?.hasError('required') &&
            editOrgUserForm.get('first_name')?.touched
          "
        >
          <div class="row justify-content-end">
            <div class="col-md-8">
              <span class="form-text form-error">
                This is a required field.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center mb-4">
        <div class="col-md-4">
          <label for="" class="form-label m-0">Last Name</label>
        </div>
        <div class="col-md-8">
          <input
            type="text"
            class="form-control"
            placeholder="Last name"
            formControlName="last_name"
          />
        </div>
        <div
          class="col-md-12"
          *ngIf="
            editOrgUserForm.get('last_name')?.hasError('required') &&
            editOrgUserForm.get('last_name')?.touched
          "
        >
          <div class="row justify-content-end">
            <div class="col-md-8">
              <span class="form-text form-error">
                This is a required field.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center mb-4">
        <div class="col-md-4">
          <label for="" class="form-label m-0">Email</label>
        </div>
        <div class="col-md-8">
          <input
            type="text"
            class="form-control"
            placeholder="Email"
            readonly
            formControlName="email"
          />
        </div>
        <div
          class="col-md-12"
          *ngIf="
            editOrgUserForm.get('email')?.hasError('required') &&
            editOrgUserForm.get('email')?.touched
          "
        >
          <div class="row justify-content-end">
            <div class="col-md-8">
              <span class="form-text form-error">
                This is a required field.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center mb-4">
        <div class="col-md-4">
          <label for="" class="form-label m-0">Role</label>
        </div>
        <div class="col-md-8">
          <ng-select
            placeholder="Select Level"
            [items]="roles"
            bindLabel="name"
            bindValue="_id"
            formControlName="role"
          >
          </ng-select>
        </div>
        <div
          class="col-md-12"
          *ngIf="
            editOrgUserForm.get('role')?.hasError('required') &&
            editOrgUserForm.get('role')?.touched
          "
        >
          <div class="row justify-content-end">
            <div class="col-md-8">
              <span class="form-text form-error">
                This is a required field.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center mb-4">
        <div class="col-md-4">
          <label for="" class="form-label m-0">Notification</label>
        </div>
        <div class="col-md-8">
          <ng-select
            placeholder="Select Level"
            formControlName="notify"
            [items]="notifiedOptions"
            bindLabel="label"
            bindValue="key"
            (change)="onNotificationTypeChange($event)"
          >
          </ng-select>
        </div>
        <div
          class="col-md-12"
          *ngIf="
            editOrgUserForm.get('role')?.hasError('required') &&
            editOrgUserForm.get('role')?.touched
          "
        >
          <div class="row justify-content-end">
            <div class="col-md-8">
              <span class="form-text form-error">
                This is a required field.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row align-items-center mb-4"
        *ngIf="
          editOrgUserForm.get('notify')?.value == 'phone' ||
          editOrgUserForm.get('notify')?.value == 'both'
        "
      >
        <div class="col-md-4">
          <label for="" class="form-label m-0">Phone</label>
        </div>
        <div class="col-md-3">
          <ng-select
            placeholder="Select country code"
            [items]="countryCodes"
            bindLabel="label"
            bindValue="code"
            placeholder="Select country code"
            formControlName="country_code"
          >
          </ng-select>
        </div>
        <div class="col-md-5">
          <input
            type="text"
            class="form-control"
            placeholder="Phone"
            formControlName="phone_no"
            (keypress)="numberOnly($event)"
            (input)="onPastePhoneNumber()"
          />
        </div>
        <div
          class="col-md-12"
          *ngIf="
            (editOrgUserForm.get('phone_no')?.hasError('required') &&
              editOrgUserForm.get('phone_no')?.touched) ||
            (editOrgUserForm.get('country_code')?.hasError('required') &&
              editOrgUserForm.get('country_code')?.touched)
          "
        >
          <div class="row justify-content-end">
            <div class="col-md-8">
              <span class="form-text form-error">
                This is a required field.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center mb-5">
        <div class="col-md-4">
          <label for="" class="form-label m-0">Enabled</label>
        </div>
        <div class="col-md-8">
          <div class="form-checkbox">
            <input
              type="checkbox"
              name=""
              id=""
              formControlName="isEnabled"
              (change)="check()"
            />
            <span class="icon"></span>
          </div>
        </div>
      </div>
      <div
        class="row align-items-center mb-5"
        *ngIf="editOrgUserForm.get('isEnabled')?.value"
      >
        <div class="col-md-4">
          <label for="" class="form-label m-0">Active</label>
        </div>
        <div class="col-md-8">
          <div class="form-checkbox">
            <input type="checkbox" name="" id="" formControlName="isActive" />
            <span class="icon"></span>
          </div>
        </div>
      </div>

      <div class="button-group mb-4">
        <button type="button" class="button button--primary" (click)="back()">
          Back
        </button>
        <button type="submit" class="button button--primary">
          Update User
        </button>
        <button type="button" class="button button--primary" (click)="resetPasswordRequest()">
          Reset Password
        </button>
      </div>
      <!-- <div class="form-checkbox has-label justify-content-start me-3">
      <input type="checkbox" id="notify__user" />
      <span class="icon"></span>
      <label class="form-label" for="notify__user"
        >Notify user of this change</label
      >
    </div> -->
    </div>
  </form>
</div>
<!-- <div class="block">
    <div class="block__title">
        <img class="icon me-3" src="/assets/images/icon__account-preferences.svg" alt="">
        <h6>Account Preferences</h6>
    </div>
    <div class="block__content">
        <div class="row align-items-center mb-4">
            <div class="col-md-4">
                <label for="" class="form-label m-0">Project Assigned</label>
            </div>
            <div class="col-md-8">
                <input type="text" class="form-control" placeholder="What are you working on?">
            </div>
            <div class="col-md-12">
                <div class="row justify-content-end">
                    <div class="col-md-8">
                        <span class="form-text form-error">
                            This is a required field.
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row align-items-center mb-4">
            <div class="col-md-4">
                <label for="" class="form-label m-0">Create Webhooks</label>
            </div>
            <div class="col-md-8">
                <div class="form-checkbox">
                    <input type="checkbox" name="" id="">
                    <span class="icon"></span>
                </div>
            </div>
        </div>
        <div class="row align-items-center mb-4">
            <div class="col-md-4">
                <label for="" class="form-label m-0">Create Connection</label>
            </div>
            <div class="col-md-8">
                <div class="form-checkbox">
                    <input type="checkbox" name="" id="">
                    <span class="icon"></span>
                </div>
            </div>
        </div>
        <div class="row align-items-center mb-4">
            <div class="col-md-4">
                <label for="" class="form-label m-0">Approve Repo Changes/Alarms</label>
            </div>
            <div class="col-md-8">
                <div class="form-checkbox">
                    <input type="checkbox" name="" id="">
                    <span class="icon"></span>
                </div>
            </div>
        </div>
        <div class="row align-items-center mb-4">
            <div class="col-md-4">
                <label for="" class="form-label m-0">View Monitor</label>
            </div>
            <div class="col-md-8">
                <div class="form-checkbox">
                    <input type="checkbox" name="" id="">
                    <span class="icon"></span>
                </div>
            </div>
        </div>
        <div class="row align-items-center mb-4">
            <div class="col-md-4">
                <label for="" class="form-label m-0">Enable Facial Recognition</label>
            </div>
            <div class="col-md-8">
                <div class="form-checkbox">
                    <input type="checkbox" name="" id="">
                    <span class="icon"></span>
                </div>
            </div>
        </div>
        <div class="row align-items-center mb-4">
            <div class="col-md-4">
                <label for="" class="form-label m-0">View Server Alarms</label>
            </div>
            <div class="col-md-8">
                <div class="form-checkbox">
                    <input type="checkbox" name="" id="">
                    <span class="icon"></span>
                </div>
            </div>
        </div>
        <div class="row align-items-center mb-4">
            <div class="col-md-4">
                <label for="" class="form-label m-0">Approve Server Changes/Alarms</label>
            </div>
            <div class="col-md-8">
                <div class="form-checkbox">
                    <input type="checkbox" name="" id="">
                    <span class="icon"></span>
                </div>
            </div>
        </div>
        <div class="row align-items-center mb-4">
            <div class="col-md-4">
                <label for="" class="form-label m-0">Create Project</label>
            </div>
            <div class="col-md-8">
                <div class="form-checkbox">
                    <input type="checkbox" name="" id="">
                    <span class="icon"></span>
                </div>
            </div>
        </div>
        <div class="row align-items-center mb-4">
            <div class="col-md-4">
                <label for="" class="form-label m-0">Create Groups</label>
            </div>
            <div class="col-md-8">
                <div class="form-checkbox">
                    <input type="checkbox" name="" id="">
                    <span class="icon"></span>
                </div>
            </div>
        </div>
        <div class="row align-items-center mb-4">
            <div class="col-md-4">
                <label for="" class="form-label m-0">Change Hash Frequency</label>
            </div>
            <div class="col-md-8">
                <div class="form-checkbox">
                    <input type="checkbox" name="" id="">
                    <span class="icon"></span>
                </div>
            </div>
        </div>
        <div class="row align-items-center mb-5">
            <div class="col-md-4">
                <label for="" class="form-label m-0">Change Hash Type</label>
            </div>
            <div class="col-md-8">
                <div class="form-checkbox">
                    <input type="checkbox" name="" id="">
                    <span class="icon"></span>
                </div>
            </div>
        </div>
        <div class="button-group">
            <button type="submit" class="button button--primary">Save &amp; Continue</button>
        </div>
    </div>
</div>  -->
<div
  bsModal
  #modalRemoveProject="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title color-white">
          <img src="/assets/images/icon__delete.svg" alt="" />
          Remove Project
        </h4>
        <a
          class="modal-close"
          href="javascript:void(0)"
          (click)="onNotAgree()"
          aria-label="Close"
        >
          <img src="/assets/images/icon__close.svg" alt="" />
        </a>
      </div>
      <div class="modal-body text-center">
        <div class="mb-4">
          <h4>Are you sure?</h4>
          <p>
            Do you really want to deactivated this user? <br />This process
            can't be undone.
          </p>
        </div>
        <div class="button-group justify-content-center">
          <button
            type="submit"
            class="button button--primary button--sml"
            (click)="onNotAgree()"
            aria-label="Close"
          >
            Cancel
          </button>
          <button
            type="button"
            class="button button--primary button--sml"
            (click)="modalRemoveProject.hide()"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
