import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserState } from '../../../interface/user';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from '../../../service/event.service';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../service/api.service';
import { Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { Util } from 'src/app/service/utils';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SideFilter } from 'src/app/interface/SideFilter';

@Component({
  selector: 'app-dispute-log',
  templateUrl: './dispute-log.component.html',
  styleUrls: ['./dispute-log.component.scss'],
})
export class DisputeLogComponent implements OnInit {
  @ViewChild('otpModal') otpModal: ElementRef<HTMLAnchorElement> | undefined;
  @ViewChild('modalAzureDisputeDetails')
  modalAzureDisputeDetails?: ModalDirective;
  oneAtATime = true;
  id: string | undefined | null;

  diffObject: any;
  commitMessage: string = '';
  commitId: string = '';

  isLoading: boolean = true;
  diffs: any;
  approvedTime: any;
  disputeId: any;
  showAction: boolean = false;
  type: undefined | string;
  scanInfo: any;
  allType = 'Approved';
  singleType = 'Approved';
  userData: UserState | undefined;
  isResend = false;
  provider: string = '';
  isOTPModalOpen: boolean = false;
  selectedDispute: any;
  // content:any[]=[];
  contentList:any=[];
  tree: any;
  showCliAction: boolean = true;
  repoScanId: any;
  commitUserName: any;
  constructor(
    private route: ActivatedRoute,
    private store: Store<{ userData: UserState, sideBarFilter: SideFilter }>,
    private api: ApiService,
    private toaster: ToastrService,
    private router: Router,
    private location: Location,
    private eventService: EventService,
    private util: Util,
  ) {}

  ngOnInit(): void {
    // this.url = this.router.url;
    // this.url = this.url.substring(
    //   this.url.lastIndexOf('/') + 1,
    //   this.url.lastIndexOf('?')
    // );
    // //console.log(this.url);
  //   this.api.getDiffFromServerTesting().subscribe((resp) => {
  //     console.log(resp);
  //     //@ts-ignore
  //     this.diffObject = resp;
  //     this.isLoading = false;
  //   }
  // );


    this.resendOtpListener();
    this.route.paramMap.subscribe((param) => {
      this.id = param.get('id');
      if (this.id && this.id !== '') {
        this.getScanDetails();
      }
    });
    this.store.select('sideBarFilter').subscribe((filterData) => {
      this.tree = filterData.tree;
      this.showCliAction = filterData.cli;
      this.repoScanId = filterData.repo;
      this.commitUserName = filterData.commitUserName;
      // //console.log(this.commitUserName);
      // //console.log(this.showCliAction);
    });
    this.route.queryParamMap.subscribe((param) => {
      this.provider = param.get('provider') || '';
      this.commitMessage = param.get('commitMessage') || '';
      this.commitId = param.get('commitId') || '';

      this.getAllDiff();

      // if (this.tree === undefined){
      //   this.getAllDiff();
      // }else {
      //   this.diffs = this.tree;
      // }
    });
    this.store.select('userData').subscribe((userData) => {
      this.userData = userData;
    });

  }
  getScanDetails(): void {
    if (!this.id) return;
    this.api.getSingleScanDetails(this.id).subscribe(
      (resp) => {
        this.scanInfo = resp.result;

        this.showAction = !this.scanInfo?.approved

        let data = {
          "commitId": this.commitId,
          "repoId": this.scanInfo.repository
       }
       const commitMessage = this.commitMessage || null;
        this.api.getDiffFromCommitId(data).subscribe((resp) => {

          if(resp.code === 0){
          this.diffObject = [resp.result, commitMessage];
          this.isLoading = false;
          }else{
            this.toaster.error(resp.result);
          }
        });    

      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  onBack(): void {
    this.location.back();
  }
  getAllDiff(): void {
    // //console.log("hello");
    
    if (!this.id) return;
    this.api.getAllDiff(this.id, this.provider).subscribe(
      (resp) => {
        // let counter = 0;
        if (resp.code === 0) {
          // //console.log('differ>>', resp);
          this.diffs = resp.result;
          // this.datePipe.transform(resp.result.actionTime 'yyyy-MM-dd');
          this.approvedTime = new Date(resp.result[0].actionTime);

          for(let i of resp.result){
            // this.showAction ||= i.takeAction;
            // //console.log(i);
            // //console.log(counter);
            // //console.log(i.content.split('\n'));
            
            // //console.log(JSON.stringify(i.content));
            // this.content[i] = i.content.split('\n');
            // //console.log(this.content);
            // this.diffs.content = i.content.split('\n');
            // //console.log(i);
            
            // this.contentList.push({i:i,content:i.content.split('\n')});
            this.contentList.push(i.content.split('\n'));

            // //console.log(this.contentList);
            // counter++;
            
            
            // this.content = i.content.substring(
            //     i.content.lastIndexOf('\n') + 1,
            //     i.content.lastIndexOf('')
            //   );
            
            // if(i.takeAction === false){
              //   this.showAction = false;
              // }else {
                //   this.showAction = true;
                // };
              }
              for(let k=0; k<this.contentList.length; k++){
                this.diffs[k].content = this.contentList[k];
              }
              // //console.log(this.diffs);
              // //console.log(this.contentList);
              
              
          if (this.diffs.length === 0) {
            this.router.navigate([
              `/dashboard/project-details/${this.scanInfo.repository}`,
            ]);
            this.toaster.error('No file to supervise');
          }
        } else {
          if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
            this.toaster.error(resp.result);
          } else {
            this.toaster.error('Internal Server Error');
          }
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  resendOtpListener(): void {
    this.eventService.otpEvent.subscribe((v) => {
      this.isResend = true;
      if (this.type == 'All') {
        if (this.allType == 'Approved') {
          this.onApproveAll();
        } else {
          this.onDisApproveAll();
        }
      } else {
        if (this.singleType == 'Approved') {
          this.onApproveSingle(this.disputeId);
        } else {
          this.onDisApproveSingle(this.disputeId);
        }
      }
    });
  }
  viewMore(val: any): void {
    this.modalAzureDisputeDetails?.show();
    this.selectedDispute = this.scanInfo?.disputes?.filter((s: any) => {
      return (
        s.file_name == val.file_name && s.relative_path == val.relative_path
      );
    })[0];
  }

  sendAllOtp(): void {
    if (!this.id) return;
    this.type = 'All';
    this.api
      .getOtpForApproveAll(this.id, {
        resource: 'Project',
        id: this.scanInfo.repository,
      })
      .subscribe(
        (resp) => {
          if (resp.code === 0) {
            if (!this.isResend) {
              // this.otpModal?.nativeElement.click();
              this.isOTPModalOpen = true;
            }
            this.toaster.success(resp.result);
            this.isResend = false;
          } else if (resp.code === 3) {
            this.toaster.error(resp.result);
          } else if (resp.code === 97) {
            this.toaster.error(
              `You are not allowed to ${this.allType.toLowerCase()} the dispute(s)`
            );
          } else {
            if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
              this.toaster.error(resp.result);
            } else {
              this.toaster.error('Internal Server Error');
            }
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
  }
  sendSingleOtp(disputeId: string): void {
    if (!this.id) return;
    this.type = 'Single';
    this.disputeId = disputeId;
    this.api
      .getOtpForSingle(this.id, disputeId, {
        resource: 'Project',
        id: this.scanInfo.repository,
      })
      .subscribe(
        (resp) => {
          if (resp.code === 0) {
            if (!this.isResend) this.isOTPModalOpen = true;
            this.toaster.success(resp.result);
            this.isResend = false;
          } else if (resp.code === 3) {
            this.toaster.error(resp.result);
          } else if (resp.code === 97) {
            this.toaster.error(
              `You are not allowed to ${this.singleType.toLowerCase()} the dispute(s)`
            );
          } else {
            if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
              this.toaster.error(resp.result);
            } else {
              this.toaster.error('Internal Server Error');
            }
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
  }
  approveSingle(otp: number): void {
    if (!this.id) return;
    this.api
      .approveSingleDispute(this.id, this.disputeId, this.singleType, {
        otp,
        resource: 'Project',
        id: this.scanInfo.repository,
      })
      .subscribe(
        (resp) => {
          if (resp.code === 0) {
            this.getAllDiff();
            this.toaster.success(resp.result);
            this.singleType = 'Approved';
          } else if (resp.code === 3) {
            this.toaster.error(resp.result);
          } else if (resp.code === 97) {
            this.toaster.error(
              `You are not allowed to ${this.singleType.toLowerCase()} the dispute(s)`
            );
          } else {
            if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
              this.toaster.error(resp.result);
            } else {
              this.toaster.error('Internal Server Error');
            }
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
  }
  approveAll(otp: number): void {
    if (!this.id) return;
    this.api
      .approveAllDispute(this.id, this.allType, {
        otp,
        resource: 'Project',
        id: this.scanInfo.repository,
      })
      .subscribe(
        (resp) => {
          if (resp.code === 0) {
            this.toaster.success(resp.result);
            this.allType = 'Approved';
            this.router.navigate(
              [`/dashboard/project-details/${this.scanInfo.repository}`],
              { queryParams: { provider: this.provider } }
            );
          } else if (resp.code === 3) {
            this.toaster.error(resp.result);
          } else if (resp.code === 97) {
            this.toaster.error(
              `You are not allowed to ${this.allType.toLowerCase()} the dispute(s)`
            );
          } else {
            if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
              this.toaster.error(resp.result);
            } else {
              this.toaster.error('Internal Server Error');
            }
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
  }
  checkForTwoStep(): boolean {
    if (!this.userData?.two_step_on) {
      this.toaster.error('Please Enable 2 Step Authentication', 'Try Again');
    }
    return !!this.userData?.two_step_on;
  }

  onApproveAll(): void {
    if (!this.util.isPermission('isApproveDispute')) return;
    if (!this.checkForTwoStep()) {
      return;
    }
    this.allType = 'Approved';
    this.sendAllOtp();
  }

  onApproveAllCli(): void {
    this.showCliAction = true;
    const data = {
      repoScanId: this.repoScanId
    }
    this.api.approvedCli(data).subscribe((resp) => {
      // //console.log(resp);
      
      this.toaster.success('Approved Successful');
      // this.allType = 'Approved';
      this.router.navigate(
        [`/dashboard/project-details/${this.scanInfo.repository}`],
        { queryParams: { provider: this.provider } }
      );
      // if (resp.code === 0) {
      // } else if (resp.code === 3) {
      //   this.toaster.error(resp.result);
      // } else if (resp.code === 97) {
      //   this.toaster.error(
      //     `You are not allowed to ${this.allType.toLowerCase()} the dispute(s)`
      //   );
      // } else {
      //   if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
      //     this.toaster.error(resp.result);
      //   } else {
      //     this.toaster.error('Internal Server Error');
      //   }
      // }
    },
    (error) => {
      // //console.log(error);
      
      if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
        this.toaster.error(error.error.result.error);
      } 
      else {
        
        this.toaster.error('Something went wrong');
      }
    }
    )
  }

  onApproveSingle(id: string): void {
    if (!this.util.isPermission('isApproveDispute')) return;
    if (!this.checkForTwoStep()) {
      return;
    }
    this.singleType = 'Approved';
    this.sendSingleOtp(id);
  }

  onDisApproveAll(): void {
    if (!this.util.isPermission('isDisapproveDispute')) return;
    if (!this.checkForTwoStep()) {
      return;
    }
    this.allType = 'Disapproved';
    this.sendAllOtp();
  }

  onDisApproveSingle(id: string): void {
    if (!this.util.isPermission('isDisapproveDispute')) return;
    if (!this.checkForTwoStep()) {
      return;
    }
    this.singleType = 'Disapproved';
    this.sendSingleOtp(id);
  }
  modalStatusChange(status: string) {
    if (status == 'close') {
      this.isOTPModalOpen = false;
    } else if (status === 'resendOTP') {
      this.resendOTP();
    }
  }
  resendOTP(): void {}
}
