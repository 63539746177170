<div class="modal-dialog modal-lg modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title color-white">
        <img src="/assets/images/icon__report.svg" alt="" />
        Your Information
      </h4>
      <a
        class="modal-close"
        href="javascript:void(0)"
        aria-label="Close"
        (click)="onCloseModal()"
      >
        <img src="/assets/images/icon__close.svg" alt="" />
      </a>
    </div>
    <div class="modal-body">
      <label
        >Please enter your info here and a member of our team will get back to
        you as quickly as possible.</label
      >
      <br />
      <br />
      <form [formGroup]="contactUsForm" (ngSubmit)="saveContactSales()">
        <div class="mb-4">
          <label for="" class="form-label">First Name *</label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter first name"
            formControlName="first_name"
          />
          <span
            class="form-text form-error"
            *ngIf="
              contactUsForm.get('first_name')?.getError('required') &&
              contactUsForm.get('first_name')?.touched
            "
            >First Name is Required</span
          >
        </div>
        <div class="mb-4">
          <label for="" class="form-label">Last Name</label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter last name"
            formControlName="last_name"
          />
          <span
            class="form-text form-error"
            *ngIf="
              contactUsForm.get('last_name')?.getError('required') &&
              contactUsForm.get('last_name')?.touched
            "
            >Last Name is Required</span
          >
        </div>
        <div class="mb-4">
          <label for="" class="form-label">Organization name</label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter organization name "
            formControlName="company"
          />
          <span
            class="form-text form-error"
            *ngIf="
              contactUsForm.get('company')?.getError('required') &&
              contactUsForm.get('company')?.touched
            "
            >Organization name is Required</span
          >
        </div>
        <div class="mb-4">
          <label for="" class="form-label">Job Title *</label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter job title"
            formControlName="job_title"
          />
          <span
            class="form-text form-error"
            *ngIf="
              contactUsForm.get('job_title')?.getError('required') &&
              contactUsForm.get('job_title')?.touched
            "
            >Job Title is Required</span
          >
        </div>
        <div class="mb-4">
          <label for="" class="form-label">Email *</label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter email"
            formControlName="email"
          />
          <span
            class="form-text form-error"
            *ngIf="
              contactUsForm.get('email')?.getError('required') &&
              contactUsForm.get('email')?.touched
            "
            >Email is Required</span
          >
        </div>
        <div class="mb-4">
          <label for="" class="form-label">Phone</label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter phone number"
            formControlName="phone"
            (keypress)="numberOnly($event)"
            (input)="onPastePhoneNumber()"
          />
          <span
            class="form-text form-error"
            *ngIf="
              contactUsForm.get('phone')?.getError('required') &&
              contactUsForm.get('phone')?.touched
            "
            >Phone No is Required</span
          >
        </div>
        <div class="mb-4">
          <label for="" class="form-label">Preferred method of contact:</label>
          <div class="col-md-12 mb-4 d-flex align-items-center">
            <div class="form-switcher me-3">
              <input
                type="radio"
                name="preferred_contact_method"
                formControlName="preferred_contact_method"
                value="Phone"
                checked
              />
              <label for="two_step_varification"></label>
            </div>
            <label for="" class="form-label m-0">Phone</label>
          </div>
          <div class="col-md-12 mb-4 d-flex align-items-center">
            <div class="form-switcher me-3">
              <input
                type="radio"
                name="preferred_contact_method"
                formControlName="preferred_contact_method"
                value="Email"
              />
              <label for="two_step_varification"></label>
            </div>
            <label for="" class="form-label m-0">Email</label>
          </div>
        </div>
        <div class="mb-4">
          <label for="" class="form-label">How can we help you?</label>
          <textarea
            class="form-control form-textarea"
            formControlName="message"
          ></textarea>
          <span
            class="form-text form-error"
            *ngIf="
              contactUsForm.get('message')?.getError('required') &&
              contactUsForm.get('message')?.touched
            "
            >Description is Required</span
          >
        </div>
        <div class="button-group">
          <button type="submit" class="button button--primary">Send</button>
        </div>
      </form>
    </div>
  </div>
</div>
