import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { ConnectionService } from 'src/app/service/connection.service';
import { Util } from 'src/app/service/utils';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-create-connection',
  templateUrl: './create-connection.component.html',
  styleUrls: ['./create-connection.component.scss'],
})
export class CreateConnectionComponent implements OnInit {
  code = '';
  provider = '';
  urlRegex = /(https)/;
  connectionForm = new UntypedFormGroup({
    provider: new UntypedFormControl('GitHub', Validators.required),
    connection_name: new UntypedFormControl(null, Validators.required),
    gitlabUrl: new UntypedFormControl(null),
    personalAccessToken: new UntypedFormControl(null),
    azureOrgName: new UntypedFormControl(null),
  });
  constructor(
    private connection: ConnectionService,
    private api: ApiService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private toaster: ToastrService,
    private util: Util
  ) {}

  ngOnInit(): void {
    if (
      this.util.isPermission('isAddConnection') ||
      this.util.isPermission('isEditConnection')
    ) {
      this.route.queryParams.subscribe((params: any) => {
        this.code = params.code ? params.code : '';
        this.provider = params.provider;
        if (this.provider && this.provider != '') {
          this.connectionForm.patchValue({ provider: this.provider });
        }
        // //console.log('params>>', params);
        if (this.code && this.code !== '') {
          // //console.log(this.provider);
          if (this.provider === 'github') {
            this.api.connectGithub({ code: this.code }).subscribe(
              (resp) => {
                if (resp.code === 0) {
                  // this.router.navigate(['/dashboard/create-github-connection']);
                  this.router.navigate([
                    '/dashboard/manage/connection-settings',
                  ]);
                } else {
                  this.connectionForm.reset();
                  this.toaster.error('Failed to connect with Github');
                }
              },
              (error) => {
                this.connectionForm.reset();
                this.toaster.error('Failed to connect with Github');
              }
            );
          } else if (this.provider === 'bitbucket') {
            this.api.connectBitbucket({ code: this.code }).subscribe(
              (resp) => {
                if (resp.code === 0) {
                  this.router.navigate([
                    '/dashboard/manage/connection-settings',
                  ]);
                } else {
                  this.connectionForm.reset();
                  this.toaster.error(`Failed to connect with ${this.provider}`);
                }
              },
              (error) => {
                this.connectionForm.reset();
                this.toaster.error(`Failed to connect with ${this.provider}`);
              }
            );
          }
        }
      });
    } else {
      this.location.back();
    }
  }
  onConnect(): void {
    // if (this.connectionForm.get('provider').value !== 'GitHub') {
    //   this.toaster.error(this.connectionForm.get('provider').value + ' is not available');
    //   return;
    // }

    if (
      this.connectionForm.get('provider')?.value === 'Gitlab' ||
      this.connectionForm.get('provider')?.value === 'Azure'
    ) {
      this.connectGitlab();
      return;
    }

    const link = document.createElement('a');
    if (this.connectionForm.get('provider')?.value === 'GitHub') {
      link.href =
        'https://github.com/login/oauth/authorize?client_id=' +
        environment.GUIHUB_CLIENT_ID;
    } else if (this.connectionForm.get('provider')?.value === 'Bitbucket') {
      link.href =
        'https://bitbucket.org/site/oauth2/authorize?client_id=' + 
        environment.BITBUCKET_CLIENT_ID+ '&response_type=code';
    }
    document.body.appendChild(link);
    this.api
      .getSetConnectionName(
        this.connectionForm.get('connection_name')?.value,
        this.connectionForm.get('provider')?.value,
        this.connectionForm.get('personalAccessToken')?.value
      )
      .subscribe(
        (resp) => {
          if (resp.code === 0) {
            link.click();
            document.body.removeChild(link);
          }
        },
        (error) => {
          this.toaster.error(
            `Failed to connect with ${
              this.connectionForm.get('provider')?.value
            }`
          );
        }
      );
  }
  connectGitlab(): void {
    // //console.log('this.connectionForm', this.connectionForm);
    if (this.connectionForm.valid) {
      // console.error(this.connectionForm.valid);

      const { azureOrgName, gitlabUrl, ...rest } =
        this.connectionForm.getRawValue();
      let data;
      if (this.provider == 'Azure') {
        data = { ...rest, azureOrgName };
      } else {
        data = { ...rest, gitlabUrl };
      }
      this.api
        .createConnection({
          ...data,
          connection_type: 'User',
        })
        .subscribe(
          (resp) => {
            if (resp.code === 0) {
              this.toaster.success(`${this.provider} Connection Create`);
              this.router.navigate(['/manage/repo-connection']);
              // this.router.navigate(['/dashboard/connection-list']);
            } else {
              if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
                this.toaster.error(resp.result);
              } else {
                this.toaster.error('Internal Server Error');
              }
            }
          },
          (error) => {
            if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
              this.toaster.error(error.error.result);
            } else {
              this.toaster.error('Something Went Wrong');
            }
          }
        );
    } else {
      this.connectionForm.markAllAsTouched();
    }
  }

  onBack(): void {
    this.location.back();
  }
  changeProvider(provider: string): void {
    if (provider === 'Gitlab') {
      this.connectionForm
        .get('personalAccessToken')
        ?.setValidators(Validators.required);
      this.connectionForm
        .get('gitlabUrl')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.urlRegex),
        ]);
    } else if (provider === 'Azure') {
      this.connectionForm
        .get('personalAccessToken')
        ?.setValidators(Validators.required);
      this.connectionForm
        .get('azureOrgName')
        ?.setValidators([Validators.required]);
    } else {
      this.connectionForm.get('personalAccessToken')?.clearValidators();
      this.connectionForm.get('gitlabUrl')?.clearValidators();
    }
  }
}
