import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  appName: string | undefined;
  constructor() {}
  setAppName(name: string): void {
    this.appName = name;
  }
  getAppName(): string {
    //@ts-ignore
    return this.appName;
  }
}
