<div class="d-flex align-items-center mb-4">
  <h4 class="m-0">{{provider}} Project List</h4>
  <div class="d-flex align-items-center ms-auto">
    <div class="form-search me-4">
      <input
        type="text"
        class="form-control"
        placeholder="Filter by Name..."
        [(ngModel)]="term"
        (change)="search()"
      />
      <button type="submit">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 27.18 27.9"
          style="enable-background: new 0 0 27.18 27.9"
          xml:space="preserve"
        >
          <path
            d="M17.76,20.63c-5.5,3.82-12.7,1.62-15.85-3.11C-1.21,12.83-0.48,6.56,3.94,2.76 c4.09-3.51,10.17-3.77,14.64-0.19c4.26,3.4,6.04,10.45,1.52,15.96c0.36,0.37,0.72,0.75,1.08,1.12c1.83,1.88,3.67,3.76,5.49,5.65 c0.87,0.91,0.57,2.22-0.58,2.54c-0.64,0.18-1.14-0.07-1.58-0.52c-1.58-1.64-3.16-3.28-4.76-4.9C19.13,21.8,18.44,21.24,17.76,20.63z M11.32,19.59c4.54,0.07,8.36-3.63,8.44-8.19c0.08-4.58-3.62-8.37-8.26-8.44c-4.67-0.07-8.48,3.61-8.54,8.26 C2.9,15.75,6.65,19.51,11.32,19.59z"
          />
        </svg>
      </button>
    </div>
    <div class="button-group">
      <a
        class="button button--open"
        href="javascript:void(0)"
        (click)="onEdit()"
      >
        <svg
          version="1.1"
          class="icon icon--sml me-2"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 15.75 15.75"
          style="enable-background: new 0 0 15.75 15.75"
          xml:space="preserve"
        >
          <path
            d="M15.75,3.17c-0.02,0.7-0.3,1.43-0.9,2.02c-3.13,3.13-6.27,6.27-9.41,9.4
                    c-0.11,0.11-0.26,0.2-0.41,0.23c-1.42,0.32-2.84,0.62-4.26,0.92c-0.51,0.11-0.87-0.28-0.76-0.79c0.3-1.39,0.59-2.78,0.9-4.17
                    c0.04-0.19,0.16-0.39,0.3-0.53c3.09-3.1,6.19-6.2,9.29-9.3c0.91-0.91,2.2-1.19,3.37-0.71C15.02,0.69,15.75,1.8,15.75,3.17z
                    M1.4,14.33c0.15-0.02,0.25-0.03,0.34-0.05c0.88-0.19,1.76-0.37,2.63-0.58c0.18-0.04,0.38-0.15,0.51-0.28
                    c2.24-2.23,4.47-4.46,6.71-6.69c0.08-0.08,0.2-0.14,0.26-0.18c-0.91-0.91-1.75-1.75-2.6-2.61C9.24,3.96,9.19,3.99,9.14,4.04
                    c-2.3,2.3-4.6,4.6-6.9,6.9C2.18,11,2.1,11.09,2.08,11.18C1.85,12.21,1.63,13.24,1.4,14.33z M10.23,3.01
                    c0.84,0.84,1.68,1.68,2.52,2.53c0.43-0.42,0.89-0.85,1.32-1.3c0.64-0.67,0.62-1.77-0.01-2.45c-0.64-0.68-1.74-0.79-2.44-0.18
                    C11.11,2.04,10.68,2.55,10.23,3.01z"
          />
        </svg>
        Edit Project
      </a>
      <a
        class="button button--open ms-4"
        href="javascript:void(0)"
        (click)="onDeleteRequest()"
      >
        <svg
          version="1.1"
          class="icon icon--sml me-2"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 15.38 19.23"
          style="enable-background: new 0 0 15.38 19.23"
          xml:space="preserve"
        >
          <g>
            <g>
              <path
                d="M13.87,5.28c-0.58-0.14-1.06,0.27-1.06,0.93c0,3.16,0,6.33,0,9.49c0,1.06-0.69,1.77-1.72,1.77
                                c-2.26,0-4.53,0-6.79,0c-1.05,0-1.74-0.71-1.74-1.79c0-1.56,0-3.12,0-4.68c0-1.6,0-3.21,0-4.81c0-0.49-0.25-0.82-0.66-0.91
                                c-0.6-0.13-1.05,0.27-1.05,0.98c0,3.18,0,6.35,0,9.53c0,0.24,0.02,0.48,0.07,0.71c0.34,1.61,1.64,2.71,3.28,2.72
                                c2.32,0.02,4.63,0.01,6.95,0c0.24,0,0.49-0.03,0.73-0.08c1.59-0.37,2.63-1.7,2.64-3.4c0.01-3.14,0-6.29,0-9.43
                                c0-0.13-0.01-0.26-0.04-0.39C14.42,5.57,14.21,5.36,13.87,5.28z M14.87,2.71c-0.16-0.06-0.35-0.08-0.53-0.08
                                c-0.97-0.01-1.94-0.01-2.91,0c-0.26,0-0.33-0.08-0.32-0.34c0.02-0.46,0.01-0.92,0-1.37c-0.01-0.55-0.32-0.9-0.85-0.91
                                C8.55,0,6.84,0,5.13,0.01c-0.53,0-0.84,0.36-0.85,0.9c-0.01,0.44-0.02,0.87,0,1.31C4.3,2.54,4.21,2.64,3.9,2.63
                                C2.93,2.61,1.95,2.63,0.98,2.62c-0.39,0-0.72,0.13-0.9,0.51c-0.28,0.6,0.15,1.24,0.85,1.24c2.24,0,4.48,0,6.72,0
                                c2.08,0,4.16,0,6.24,0c0.22,0,0.45,0.01,0.67,0c0.4-0.02,0.73-0.33,0.8-0.73C15.43,3.26,15.23,2.85,14.87,2.71z M8.71,2.62
                                c-0.79,0-1.58-0.01-2.36,0.01C6.06,2.64,5.94,2.55,5.98,2.25c0.02-0.15,0-0.3,0-0.47c1.14,0,2.26,0,3.38,0
                                C9.5,2.62,9.5,2.62,8.71,2.62z M10.25,13.89c0-0.98,0-1.96,0-2.95c0-0.99,0-1.99,0-2.98c0-0.56-0.34-0.95-0.83-0.97
                                C8.93,6.98,8.55,7.35,8.55,7.91c-0.01,2.01-0.01,4.02,0,6.02c0,0.56,0.38,0.93,0.87,0.92C9.91,14.84,10.25,14.45,10.25,13.89z
                                M6.82,7.78c-0.04-0.49-0.44-0.81-0.9-0.78c-0.44,0.03-0.79,0.4-0.79,0.87c-0.01,2.04-0.01,4.08,0,6.12c0,0.43,0.3,0.78,0.7,0.86
                                c0.37,0.07,0.78-0.14,0.91-0.5c0.07-0.18,0.09-0.38,0.09-0.57c0.01-0.95,0-1.9,0-2.85c0-0.64,0-1.29,0-1.93
                                C6.84,8.58,6.86,8.18,6.82,7.78z"
              />
            </g>
          </g>
        </svg>
        Delete Project
      </a>
      <a
        class="button button--open ms-4"
        href="javascript:void(0)"
        (click)="createProjectRequest()"
      >
        <i class="fa fa-plus me-2" aria-hidden="true"></i>
        Create Project
      </a>
    </div>
  </div>
</div>
<div class="table-main mb-4">
  <div class="table-responsive">
    <table class="table table-borderless table-vmiddle text-center m-0">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col" class="text-start">Your Projects</th>
          <th scope="col">Hash Function</th>
          <th scope="col">Provider</th>
          <th scope="col">Repository Name</th>
          <th scope="col">Repository Type</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let project of projects">
          <td>
            <div class="form-radio">
              <input
                type="radio"
                name="radio_projects"
                id=""
                (change)="onSelectProject(project._id)"
                [checked]="selectedProject === project._id"
              />
              <span class="icon"></span>
            </div>
          </td>
          <td class="text-start">
            <div class="d-flex align-items-center">
              <a
                href="javascript:void(0)"
                class="weight-600"
                routerLink="/dashboard/project-details/{{ project._id }}"
                >{{ project?.name }}</a
              >
              <a
                href="javascript:void(0)"
                class="button button--primary button--xsml ms-3"
                routerLink="/dashboard/members"
                [queryParams]="{ id: project._id, resource: 'Project' }"
                >{{ project.permission.length }} Members</a
              >
              <a
              href="javascript:void(0)"
              class="button button--primary button--xsml ms-3"
              routerLink="/dashboard/alert-settings"
              [queryParams]="{ id: project._id, resource: 'Project' }"
              >Alert Settings</a
            >
            </div>
          </td>
          <td>{{ project.hash_function }}</td>
          <td>{{ project.provider }}</td>
          <td>{{ project.repository_name }}</td>
          <td>{{ project.repository_type }}</td>
        </tr>
        <tr *ngIf="projects.length === 0 && isProcessing">
          <td class="text-center" colspan="6">No Projects Found</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="d-flex justify-content-end">
  <app-pagination
    [count]="total"
    (changePage)="onChangePage($event)"
  ></app-pagination>
</div>
<div
  bsModal
  #modalRemoveProject="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title color-white">
          <img src="/assets/images/icon__delete.svg" alt="" />
          Remove Project
        </h4>
        <a
          class="modal-close"
          href="javascript:void(0)"
          (click)="modalRemoveProject.hide()"
          aria-label="Close"
        >
          <img src="/assets/images/icon__close.svg" alt="" />
        </a>
      </div>
      <div class="modal-body text-center">
        <div class="mb-4">
          <h4>Are you sure?</h4>
          <p>
            Do you really want to delete these records? <br />This process can't
            be undone.
          </p>
        </div>
        <div class="button-group justify-content-center">
          <button
            type="submit"
            class="button button--primary button--sml"
            (click)="modalRemoveProject.hide()"
            aria-label="Close"
          >
            Cancel
          </button>
          <button
            type="button"
            class="button button--primary button--sml"
            (click)="onDelete()"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
