<header class="site-header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 d-flex justify-content-between align-items-center">
                <a class="site-logo" href="javascript:void(0)" [routerLink]="'/dashboard/groups'">
                    <span class="icon">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                            style="enable-background:new 0 0 100 100;" xml:space="preserve">
                            <g>
                                <g id="XMLID_321_">
                                    <path id="product-icon__codelock--1"
                                        d="M72.791,48.195h-3.968c-0.855,0-1.55-0.699-1.55-1.558V26.856c0.042-5-1.864-9.881-5.229-13.403 c-3.198-3.369-7.43-5.223-11.918-5.223h-0.133c-9.577,0-17.369,8.345-17.369,18.602v19.805c0,0.859-0.696,1.558-1.55,1.558h-3.967 c-0.855,0-1.55-0.699-1.55-1.558l0.043-20.858C26.133,11.996,36.86,0.957,49.971,0.957c0.028,0,0.057,0,0.084,0 c6.339,0,12.532,2.693,17.022,7.398c4.662,4.861,7.31,11.607,7.265,18.507v19.774C74.342,47.496,73.647,48.195,72.791,48.195z M69.46,46.041h2.695V26.856c0.042-6.358-2.39-12.56-6.67-17.023c-4.139-4.336-9.608-6.721-15.408-6.721c-0.027,0-0.053,0-0.08,0 c-12.271,0-22.232,10.652-22.232,23.744l-0.022,19.185h2.695V26.832c0-11.444,8.773-20.756,19.555-20.756h0.133 c5.026,0,9.952,2.152,13.513,5.903c3.744,3.919,5.867,9.345,5.821,14.885V46.041z" />
                                </g>
                                <g id="product-icon__codelock--2">
                                    <g id="XMLID_320_">
                                        <path
                                            d="M49.232,79.931c-2.142,0-3.884-1.743-3.884-3.885c0-2.141,1.743-3.884,3.884-3.884 s3.884,1.743,3.884,3.884C53.116,78.188,51.374,79.931,49.232,79.931z M49.232,74.35c-0.936,0-1.697,0.761-1.697,1.697 c0,0.936,0.761,1.698,1.697,1.698c0.936,0,1.697-0.761,1.697-1.698C50.93,75.111,50.168,74.35,49.232,74.35z" />
                                    </g>
                                    <g id="XMLID_319_">
                                        <path
                                            d="M49.232,91.622c-1.702,0-3.085-1.384-3.085-3.085v-4.796c-3.099-1.237-5.199-4.282-5.199-7.674 c0-4.568,3.717-8.284,8.285-8.284c3.676,0,6.942,2.465,7.944,5.994c0.617,2.105,0.373,4.334-0.691,6.269 c-0.932,1.698-2.394,2.987-4.168,3.694v4.798C52.318,90.238,50.934,91.622,49.232,91.622z M49.232,69.969 c-3.363,0-6.098,2.735-6.098,6.097c0,2.703,1.811,5.104,4.404,5.841l0.795,0.225v6.404c0,0.495,0.403,0.898,0.899,0.898 c0.495,0,0.899-0.403,0.899-0.898v-6.4l0.791-0.227c1.566-0.451,2.862-1.486,3.648-2.917c0.781-1.42,0.96-3.057,0.505-4.609 C54.336,71.78,51.934,69.969,49.232,69.969z" />
                                    </g>
                                </g>
                                <g id="XMLID_314_">
                                    <g id="XMLID_315_">
                                        <g id="XMLID_318_">
                                            <path
                                                d="M54.818,39.8c-0.185,0-0.371-0.068-0.517-0.205c-0.302-0.286-0.316-0.762-0.031-1.064 l4.156-4.403l-4.156-4.402c-0.286-0.302-0.272-0.779,0.031-1.064c0.303-0.286,0.779-0.272,1.064,0.031l4.643,4.92 c0.274,0.29,0.274,0.743,0,1.034l-4.643,4.92C55.218,39.721,55.018,39.8,54.818,39.8L54.818,39.8z" />
                                        </g>
                                        <g id="XMLID_317_">
                                            <path
                                                d="M45.532,39.8c-0.2,0-0.4-0.079-0.548-0.236l-4.644-4.92c-0.274-0.29-0.274-0.744,0-1.034 l4.643-4.92c0.285-0.302,0.762-0.316,1.064-0.031c0.302,0.285,0.316,0.762,0.031,1.064l-4.155,4.403l4.155,4.403 c0.285,0.302,0.272,0.779-0.031,1.064C45.903,39.732,45.717,39.8,45.532,39.8L45.532,39.8z" />
                                        </g>
                                        <g id="XMLID_316_">
                                            <path
                                                d="M49.072,42.109c-0.038,0-0.076-0.003-0.115-0.009c-0.411-0.063-0.693-0.447-0.631-0.858 l2.209-14.457c0.063-0.411,0.447-0.693,0.858-0.631c0.411,0.063,0.693,0.447,0.631,0.858L49.815,41.47 C49.758,41.842,49.437,42.109,49.072,42.109L49.072,42.109z" />
                                        </g>
                                    </g>
                                </g>
                                <g id="XMLID_313_">
                                    <path
                                        d="M41.429,48.306l-0.756-0.586c0.24-0.27,0.491-0.529,0.751-0.776c0.104-0.1,0.169-0.163,0.195-0.19 c-0.083-0.013-0.322-0.068-0.716-0.165c-0.284-0.07-0.47-0.122-0.561-0.155l0.296-0.881c0.437,0.177,0.827,0.372,1.171,0.586 c-0.08-0.544-0.12-0.988-0.12-1.331h0.891c0,0.243-0.045,0.69-0.136,1.341c0.067-0.027,0.21-0.092,0.431-0.195 c0.3-0.137,0.577-0.254,0.831-0.35l0.265,0.906c-0.37,0.083-0.799,0.165-1.286,0.245l0.601,0.676 c0.12,0.137,0.215,0.248,0.286,0.335l-0.766,0.505l-0.675-1.116C41.926,47.515,41.692,47.899,41.429,48.306z" />
                                    <path
                                        d="M45.418,48.306l-0.756-0.586c0.24-0.27,0.491-0.529,0.751-0.776c0.104-0.1,0.169-0.163,0.195-0.19 c-0.083-0.013-0.322-0.068-0.716-0.165c-0.284-0.07-0.47-0.122-0.561-0.155l0.296-0.881c0.437,0.177,0.827,0.372,1.171,0.586 c-0.08-0.544-0.12-0.988-0.12-1.331h0.891c0,0.243-0.045,0.69-0.136,1.341c0.067-0.027,0.21-0.092,0.431-0.195 c0.3-0.137,0.577-0.254,0.831-0.35l0.265,0.906c-0.37,0.083-0.799,0.165-1.286,0.245l0.601,0.676 c0.12,0.137,0.215,0.248,0.286,0.335l-0.766,0.505l-0.675-1.116C45.915,47.515,45.681,47.899,45.418,48.306z" />
                                    <path
                                        d="M49.407,48.306l-0.756-0.586c0.24-0.27,0.491-0.529,0.751-0.776c0.104-0.1,0.169-0.163,0.195-0.19 c-0.083-0.013-0.322-0.068-0.716-0.165c-0.284-0.07-0.47-0.122-0.561-0.155l0.296-0.881c0.437,0.177,0.827,0.372,1.171,0.586 c-0.08-0.544-0.12-0.988-0.12-1.331h0.891c0,0.243-0.045,0.69-0.136,1.341c0.067-0.027,0.21-0.092,0.431-0.195 c0.3-0.137,0.577-0.254,0.831-0.35l0.265,0.906c-0.371,0.083-0.799,0.165-1.287,0.245l0.601,0.676 c0.12,0.137,0.215,0.248,0.286,0.335l-0.766,0.505l-0.675-1.116C49.904,47.515,49.67,47.899,49.407,48.306z" />
                                    <path
                                        d="M53.396,48.306L52.64,47.72c0.24-0.27,0.49-0.529,0.751-0.776c0.104-0.1,0.169-0.163,0.195-0.19 c-0.083-0.013-0.322-0.068-0.717-0.165c-0.283-0.07-0.47-0.122-0.561-0.155l0.296-0.881c0.437,0.177,0.828,0.372,1.171,0.586 c-0.08-0.544-0.121-0.988-0.121-1.331h0.892c0,0.243-0.046,0.69-0.136,1.341c0.066-0.027,0.21-0.092,0.43-0.195 c0.3-0.137,0.578-0.254,0.831-0.35l0.265,0.906c-0.369,0.083-0.799,0.165-1.286,0.245l0.6,0.676 c0.121,0.137,0.216,0.248,0.286,0.335l-0.767,0.505l-0.675-1.116C53.893,47.515,53.659,47.899,53.396,48.306z" />
                                    <path
                                        d="M57.385,48.306l-0.756-0.586c0.24-0.27,0.491-0.529,0.751-0.776c0.104-0.1,0.169-0.163,0.195-0.19 c-0.083-0.013-0.321-0.068-0.715-0.165c-0.284-0.07-0.471-0.122-0.561-0.155l0.296-0.881c0.437,0.177,0.826,0.372,1.17,0.586 c-0.08-0.544-0.12-0.988-0.12-1.331h0.891c0,0.243-0.045,0.69-0.136,1.341c0.067-0.027,0.21-0.092,0.431-0.195 c0.3-0.137,0.577-0.254,0.831-0.35l0.265,0.906c-0.371,0.083-0.799,0.165-1.287,0.245l0.601,0.676 c0.12,0.137,0.215,0.248,0.285,0.335l-0.766,0.505l-0.676-1.116C57.882,47.515,57.648,47.899,57.385,48.306z" />
                                </g>
                                <g id="XMLID_68_">
                                    <path
                                        d="M74.717,99.24H24.403c-4.015,0-6.713-2.898-6.713-7.211V59.682 c-0.125-2.162,0.444-4.014,1.649-5.289c1.036-1.097,2.51-1.742,4.297-1.888l0.001-0.038l0.807,0.006l-0.008,2.187 c-1.553,0-2.733,0.415-3.506,1.234c-0.793,0.839-1.147,2.093-1.054,3.726l0.002,32.409c0,2.429,1.189,5.024,4.526,5.024h50.314 c3.098,0,5.619-2.254,5.619-5.024V59.682c0-2.431-1.209-5.029-4.601-5.029v-2.187c4.06,0,6.788,2.901,6.788,7.217v32.347 C82.523,96.006,79.022,99.24,74.717,99.24z" />
                                </g>
                                <g>
                                    <rect x="34.094" y="52.493" width="31.71" height="2.187" />
                                </g>
                                <g id="product-icon__codelock--3">
                                    <g>
                                        <path
                                            d="M29.265,64.126c-0.089,0-0.178-0.002-0.267-0.006c-1.468-0.078-2.785-0.797-3.811-2.078l1.28-1.025 c0.725,0.906,1.629,1.413,2.617,1.465c1.147,0.064,2.391-0.497,3.414-1.53l1.165,1.155C32.367,63.416,30.815,64.126,29.265,64.126 z" />
                                    </g>
                                    <g>
                                        <path
                                            d="M71.003,64.126c-0.089,0-0.177-0.002-0.267-0.006c-1.467-0.078-2.785-0.797-3.811-2.078 l1.281-1.025c0.724,0.907,1.629,1.413,2.617,1.465c1.139,0.066,2.39-0.497,3.412-1.529l1.166,1.153 C74.104,63.416,72.553,64.126,71.003,64.126z" />
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <span></span>
                    </span>
                    CodeLock <sup>&reg;</sup>
                </a>
                <div class="site-account dropdown">
                    <a href="javascript:void(0)" id="site-account__dropdown" data-toggle="dropdown">
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 460.8 460.8" style="enable-background:new 0 0 460.8 460.8;" xml:space="preserve"> <g> <g> <path d="M230.432,0c-65.829,0-119.641,53.812-119.641,119.641s53.812,119.641,119.641,119.641s119.641-53.812,119.641-119.641 S296.261,0,230.432,0z"/> </g> </g> <g> <g> <path d="M435.755,334.89c-3.135-7.837-7.314-15.151-12.016-21.943c-24.033-35.527-61.126-59.037-102.922-64.784 c-5.224-0.522-10.971,0.522-15.151,3.657c-21.943,16.196-48.065,24.555-75.233,24.555s-53.29-8.359-75.233-24.555 c-4.18-3.135-9.927-4.702-15.151-3.657c-41.796,5.747-79.412,29.257-102.922,64.784c-4.702,6.792-8.882,14.629-12.016,21.943 c-1.567,3.135-1.045,6.792,0.522,9.927c4.18,7.314,9.404,14.629,14.106,20.898c7.314,9.927,15.151,18.808,24.033,27.167 c7.314,7.314,15.673,14.106,24.033,20.898c41.273,30.825,90.906,47.02,142.106,47.02s100.833-16.196,142.106-47.02 c8.359-6.269,16.718-13.584,24.033-20.898c8.359-8.359,16.718-17.241,24.033-27.167c5.224-6.792,9.927-13.584,14.106-20.898 C436.8,341.682,437.322,338.024,435.755,334.89z"/> </g> </g> </svg>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="site-account__dropdown">
                        <a class="dropdown-item" href="javascript:void(0)" routerLink="/code-monitor/monitor">Code
                            Monitor</a>
                        <a class="dropdown-item" href="javascript:void(0)" routerLink="/profile/basic-profile/edit-profile">Profile Dashboard</a>
                        <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal"
                            data-target="#reset_password">Reset
                            Password</a>
                        <a class="dropdown-item" href="javascript:void(0)" (click)="onLogout()">Logout</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
