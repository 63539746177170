import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { Util } from 'src/app/service/utils';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss'],
})
export class MembersComponent implements OnInit {
  @ViewChild('modalRemoveMember') modalRemoveMember?: ModalDirective;
  @ViewChild('modalEditMember') modalEditMember?: ModalDirective;
  constructor(
    private api: ApiService,
    private toaster: ToastrService,
    private route: ActivatedRoute,
    private location: Location,
    private util: Util,
    private router: Router,
    private fb: UntypedFormBuilder
  ) {}
  repositoryAccessSettingsForm = this.fb.group({
    isOTPRequired: [true],
    isFaceIDRequired: [false],
    otpTimeout: ['', [Validators.required, Validators.min(0)]],
  });
  
  id: any;
  resource: any;
  term = '';
  permission = 'All';
  currentUserModal: any;
  permissionList: any = [];
  selectedCar: number | null = null;
  regex = /^([a-zA-Z0-9\.\-_]+)@([a-zA-Z0-9-]+)\.([a-z]{2,4})(.[a-z]{2,4})?$/;
  users = [];
  currentUserPermission: any;
  minDate = new Date();
  inviteFrom = new UntypedFormGroup({
    user: new UntypedFormControl(null, Validators.required),
    role: new UntypedFormControl(null, Validators.required),
    expireDate: new UntypedFormControl(null),
    requireOtp: new UntypedFormControl(true),
    requireFaceId: new UntypedFormControl(false),
    otpTimeout: new UntypedFormControl(10, [Validators.required, Validators.min(0)]),
  });
  resourceData: any;
  removePermissionId: any;
  removePermissionUser: any;
  isWhiteList: boolean = false;
  // @ViewChild('deleteModal') deleteModal: ElementRef;

  ngOnInit(): void {
    
    if (this.util.isPermission('isViewMonitor')) {
      this.route.queryParams.subscribe((params: any) => {
        this.id = params['id'];
        this.resource = params['resource'];
        this.getCurrentUserPermission();
        this.getResourceData();
        this.getAllData();
        this.onUserSearch('');
        if (Object.prototype.hasOwnProperty.call(params, 'isWhitelist')) {
          this.isWhiteList = true;
          setTimeout(() => {
            // self.inviteFrom.patchValue({ user: params.u.trim() });
            this.inviteFrom.controls['user'].setValue([params.u]);
          }, 1000);
        } else {
          this.isWhiteList = false;
        }
      });
    } else {
      this.location.back();
    }
  }
  getResourceData(): void {
    this.api.getResourceData(this.id, this.resource).subscribe(
      (resp) => {
        if (resp.code === 0) {
          this.resourceData = resp.result;
        } else {
          if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
            this.toaster.error(resp.result);
          } else {
            this.toaster.error('Internal Server Error');
          }
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  getAllData(): void {
    if (this.resource === 'Project') {
      this.getAllProjectPermissionData();
    } else if (this.resource === 'Group') {
      this.getAllGroupPermissionData();
    }
  }
  getAllProjectPermissionData(): void {
    this.api
      .getProjectPermission(this.id, this.term, this.permission)
      .subscribe(
        (resp) => {
          if (resp.code === 0) {
            this.permissionList = resp.result.map((e: any) => {
              return {
                ...e,
                expiry_date: e.expiry_date ? new Date(e.expiry_date) : '',
              };
            });
          } else {
            if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
              this.toaster.error(resp.result);
            } else {
              this.toaster.error('Internal Server Error');
            }
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
  }
  getAllGroupPermissionData(): void {
    this.api.getGroupsPermission(this.id, this.term, this.permission).subscribe(
      (resp) => {
        if (resp.code === 0) {
          this.permissionList = resp.result.map((e: any) => {
            return {
              ...e,
              expiry_date: e.expiry_date ? new Date(e.expiry_date) : '',
            };
          });
        } else {
          if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
            this.toaster.error(resp.result);
          } else {
            this.toaster.error('Internal Server Error');
          }
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  getCurrentUserPermission(): void {
    this.api.getCurrentUserPermission(this.id, this.resource).subscribe(
      (resp) => {
        if (resp.code === 0) {
          this.currentUserPermission = resp.result;
        } else {
          if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
            this.toaster.error(resp.result);
          } else {
            this.toaster.error('Internal Server Error');
          }
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  onInvite(): void {
    const data = this.inviteFrom.getRawValue();

    const inviteData = data?.user?.map((e: any) => {
      return {
        user: !this.regex.test(e) ? e : null,
        email: this.regex.test(e) ? e : null,
        role: data.role,
        expiry_date: data.expireDate,
        requireOtp: data.requireOtp,
        requireFaceId: data.requireFaceId,
        otpTimeout: data.otpTimeout,
      };
    });
    if (this.inviteFrom.valid) {
      this.api
        .inviteOtherPerson({
          permission: inviteData,
          resource: this.resource,
          id: this.id,
        })
        .subscribe(
          (resp) => {
            if (resp.code === 0) {
              ////console.log(inviteData);
              this.toaster.success('Invitation Sent');
              this.inviteFrom.reset();
              this.getAllData();
            } else if (resp.code === 97) {
              this.toaster.error('You are not allowed to Grant Permission');
            } else {
              if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
                this.toaster.error(resp.result);
              } else {
                this.toaster.error('Internal Server Error');
              }
            }
          },
          (error) => {
            if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
              this.toaster.error(error.error.result);
            } else {
              this.toaster.error('Something Went Wrong');
            }
          }
        );
    } else {
      // this.markTouch();
      this.inviteFrom.markAllAsTouched();
    }
  }
  markTouch(): void {
    Object.keys(this.inviteFrom.controls).forEach((field) => {
      const control = this.inviteFrom.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
  }
  onChangeSearch(): void {
    if (this.term === '') {
      this.getAllData();
    }
  }
  onSearch(): void {
    this.getAllData();
  }

  deletePermission(): void {
    this.api
      .revokePermission({
        id: this.id,
        resource: this.resource,
        permission_id: this.removePermissionId,
        user: this.removePermissionUser,
      })
      .subscribe(
        (resp) => {
          if (resp.code === 0) {
            this.toaster.success(resp.result);
            this.removePermissionId = null;
            this.removePermissionUser = null;
            this.modalRemoveMember?.hide();
            this.getAllData();
          } else if (resp.code === 97) {
            this.toaster.error('You are not allowed to do Revoke Permission');
          } else {
            if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
              this.toaster.error(resp.result);
            } else {
              this.toaster.error('Internal Server Error');
            }
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
  }
  onUserSearch(term: string): void {
    this.api.searchUser(term.toLowerCase().trim()).subscribe(
      (resp) => {
        if (resp.code === 0) {
          this.users = resp.result;
        } else {
          if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
            this.toaster.error(resp.result);
          } else {
            this.toaster.error('Internal Server Error');
          }
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  onAdd(item: any): void {
    const items = this.permissionList.find(
      (ele: any) => ele.user === item._id || ele?.email === item._id
    );
    if (items) {
      const user = this.inviteFrom.get('user')?.value;
      user.pop();
      this.inviteFrom.patchValue({ user });
      this.toaster.error('User Already Exists in Permission List');
      return;
    }
    // //console.log('id:', this.id, 'resource:', this.resource, 'email : ', item);
    if (!Object.prototype.hasOwnProperty.call(item, '_id')) {
      this.router.navigate(['/dashboard/manage/users'], {
        queryParams: {
          gt_id: this.id,
          resource: this.resource,
          u: item.name,
          isWhitelistUser: true,
        },
      });
    }
  }
  onUpdate(i: any, date?: Date): void {
    this.api
      .updateUserPermission({
        permission: {
          _id: this.permissionList[i]._id,
          role: this.permissionList[i].role,
          expiry_date: date ? date : this.permissionList[i].expiry_date,
        },
        id: this.id,
        resource: this.resource,
        user: this.permissionList[i].user,
      })
      .subscribe(
        (resp) => {
          if (resp.code === 0) {
            this.toaster.success('Permission Updated');
          } else if (resp.code === 97) {
            this.toaster.error('You are allowed to update Permission');
          } else {
            if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
              this.toaster.error(resp.result);
            } else {
              this.toaster.error('Internal Server Error');
            }
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
  }
  onDeleteClicked(id: string, user: string): void {
    this.removePermissionId = id;
    this.removePermissionUser = user;
    // this.deleteModal.nativeElement.click();
    this.modalRemoveMember?.show();
  }
  onEditClicked(id: string, user: string): void {
    this.removePermissionId = id;
    this.removePermissionUser = user;
    // this.deleteModal.nativeElement.click();
    this.getEditValues(user);
    this.modalEditMember?.show();
  }
  getEditValues(user: any): void {
    let id = this.id;
    this.currentUserModal = user;
    this.api.getRepoPerms({ id, user }).subscribe(
      (resp) => {
        if (resp.code === 0) {
          ////console.log(resp.result);
          this.repositoryAccessSettingsForm.patchValue({
            isOTPRequired: resp.result.requireOTP,
            isFaceIDRequired: resp.result.requireFaceID,
            otpTimeout: resp.result.requireOTPtimer,
          });

        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  OnEditsubmit(): void {
    if (this.repositoryAccessSettingsForm.valid) {
      let repository_id = this.id;
      let settings = this.repositoryAccessSettingsForm.value;
      let user = this.currentUserModal;
      ////console.log(settings);
      ////console.log(user);
      ////console.log(repository_id);
      this.api.updateRepoPerms({ repository_id, user, settings }).subscribe(
        (resp) => {
          if (resp.code === 0) {
            this.toaster.success('Settings updated successfully');
            this.modalEditMember?.hide();
          } else {
            this.toaster.error('Failed to update settings');
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
    } else {
      this.toaster.error('Invalid form data');
    }
    this.currentUserModal = null;
  }


  onBack(): void {
    this.location.back();
  }
  updateSettings(): void {
  }
}
