import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RepoServices } from 'src/app/service/repo.service';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-fileviewer',
  templateUrl: './fileviewer.component.html',
  styleUrls: ['./fileviewer.component.scss'],
})
export class FileviewerComponent implements OnInit {
  components: any[] = [];
  filteredComponents: any[] = [];
  urlValid: Boolean = true;
  repositoryName: string = '';
  searchTerm: string = '';
  generatedAt: string = '';
  displayedColumns: string[] = ['author', 'name', 'version'];
  grypeUrl: string = '';
  grantUrl: string = '';
  dataSource = new MatTableDataSource<any>();
  data: any;
  constructor(
    private route: ActivatedRoute,
    private api: RepoServices,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: any) => {
        this.api
          .httpGetRepoWithId(params.params.id, params.params.format)
          .subscribe(
            (resp: any) => {
              if (resp?.result?.length > 0) {
                let result = resp?.result[0];
                let formatestatus = result[`${params.params.format}shareAble`];

                let s3Url = null;
                if (params.params.format === 'SBOM' && formatestatus) {
                  s3Url = result.SBOMjson;
                } else if (
                  params.params.format === 'CycloneDX' &&
                  formatestatus
                ) {
                  s3Url = result.cycloneDxFileUrl;
                } else if (params.params.format === 'SWID' && formatestatus) {
                  s3Url = result.SWIDjson;
                } else if (params.params.format === 'SPDX' && formatestatus) {
                  s3Url = result.SPDXjson;
                } else {
                  this.urlValid = false;
                }
                this.repositoryName = result.repoName;
                this.grypeUrl = result.grypeOutputUrl;
                this.grantUrl = result.grantOutputUrl

                if (s3Url) {
                  this.fetchJsonFromUrl(s3Url);
                }
              } else {
                this.urlValid = false;
              }
            },
            (error: any) => {
              this.urlValid = false;
            }
          );
      });
  }

  fetchJsonFromUrl(url: string): void {
    this.http.get(url).subscribe(
      (data: any) => {
        if (data && data.components) {
          this.components = data.components;
          this.data = data
          this.generatedAt = data.metadata.timestamp;
          this.dataSource.data = data.metadata.tools.components;
          this.filterComponents();
        } else {
          console.error('No components found in JSON data.');
        }
      },
      (error: any) => {
        this.urlValid = false;
        console.error('Error fetching JSON data:', error);
      }
    );
  }

  filterComponents(): void {
    this.filteredComponents = this.components.filter(component =>
      component.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      component.type.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }
  getBomUrl(bomRef: string): string {
    if (!bomRef) {
      return '';
    }
    const npmBaseUrl = 'https://www.npmjs.com/package/';
  
    // Extract the parts of the BOM reference after 'pkg:npm/'
    const bomRefParts = bomRef.split('pkg:npm/')[1].split('@');
    const packageName = bomRefParts[0].replace('%40', '@'); // Handling for scoped packages
    const version = bomRefParts[1].split('?')[0];  // Extract the version part
  
    return `${npmBaseUrl}${packageName}/v/${version}`;
  }
  
  
  
}
