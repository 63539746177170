<div class="w-100">
  <div class="at-selectbucket">
    <h3 *ngIf="useDetailLoading"></h3>
    <h3 *ngIf="!useDetailLoading">
      {{ userDetail ? userDetail : "Codelock" }}
    </h3>

    <p>Select a repository source for your new SBOM</p>
    <ul class="at-buckets">
      <li>
        <div class="at-bucket">
          <a href="javascript:void(0);" (click)="openModal(template, 'gitlab')">
            <figure class="at-bucketicon">
              <img src="/assets/images/gitlab.svg" alt="Gitlab Icon" />
            </figure>
            <div class="at-bucketname">
              <h4>Git lab</h4>
            </div>
          </a>
        </div>
      </li>
      <li>
        <div class="at-bucket">
          <a href="javascript:void(0);" (click)="openModal(template, 'github')">
            <figure class="at-bucketicon">
              <img src="/assets/images/github.svg" alt="Github Icon" />
            </figure>
            <div class="at-bucketname">
              <h4>Git Hub</h4>
            </div>
          </a>
        </div>
      </li>
      <li>
        <div class="at-bucket">
          <a
            href="javascript:void(0);"
            (click)="openModal(template, 'bitbucket')"
          >
            <figure class="at-bucketicon">
              <img src="/assets/images/bitbucket.svg" alt="Bitbucket Icon" />
            </figure>
            <div class="at-bucketname">
              <h4>Bit Bucket</h4>
            </div>
          </a>
        </div>
      </li>
      <li>
        <div class="at-bucket">
          <a href="javascript:void(0);" (click)="openModal(template, 'azure')">
            <figure class="at-bucketicon">
              <img src="/assets/images/azuredevops.png" alt="Azure Icon" />
            </figure>
            <div class="at-bucketname">
              <h4>Azure</h4>
            </div>
          </a>
        </div>
      </li>

    </ul>
  </div>
  <ng-template #template class="at-accesstokenmodal">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Get your Repositries</h4>
      <button
        type="button"
        class="btn-close close pull-right"
        aria-label="Close"
        (click)="modalRef?.hide()"
      >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="at-tokencontent">
        <div *ngIf="modalType === 'github'">
          <strong>How To Get Access Token?:</strong>
          <p>
            Login into GitHub → Click Profile Picture → Go to Settings → Click
            on developer Settings → Get Access Token
          </p>
        </div>
        <div *ngIf="modalType === 'gitlab'">
          <strong>How To Get Access Token?:</strong>
          <p>
            Login into GitLab → Click on Edit Profile → Click on Access Token →
            Click on Add New Token → Get Access Token
          </p>
        </div>

        <div *ngIf="modalType === 'bitbucket'">
          <strong>How to find your username:</strong>
          <p>Login into Bitbucket → Settings → Personal Bitbucket Settings → Username</p>
          <strong>How to find your App Password:</strong>
          <p>
            Login into Bitbucket → Click on Settings button → Click on Personal
            Bitbucket Settings → Click on App passwords → Generate password →
            Get App password
          </p>
          <strong>How to find your workspace name:</strong>
          <p>Go into your repository → in the URL bar, you should see 'bitbucket.org/Workspace Name/overview', copy the name after the first /</p>
        </div>
      </div>

      <div *ngIf="modalType === 'bitbucket'" class="at-accesstokenarea">
        <div class="at-inputholder">
          <input
            type="password"
            autocomplete="off"
            formControlName="workspace"
            class=""
            placeholder="Enter Workspace Name"
            value="{{ workspaceName }}"
            (keyup)="workspaceHandler($event)"
          />
        </div>
        <div class="at-inputholder">
          <input
            type="text"
            autocomplete="off"
            formControlName="userName"
            class=""
            placeholder="Enter Username"
            value="{{ userName }}"
            (keyup)="userNameHandler($event)"
          />
        </div>
        <div class="at-inputholderbucketbtn">
          <input
            type="password"
            autocomplete="off"
            formControlName="accessToken"
            class=""
            placeholder="Enter App Password"
            value="{{ accessToken }}"
            (keyup)="accessTokenHandler($event)"
          />
          <button
            [disabled]="!accessToken || !userName || !workspaceName || fetchProjectLoading"
            type="button"
            class="at-btngetproject"
            (click)="getProjectsHandler()"
          >
            {{ fetchProjectLoading ? "Loading " : "Get Projects" }}
          </button>
        </div>
      </div>
      <div
        *ngIf="modalType === 'github' || modalType === 'gitlab'"
        class="at-accesstokenarea"
      >
        <div class="at-inputholder">
          <input
            type="text"
            autocomplete="off"
            formControlName="userName"
            class=""
            placeholder="Enter Username"
            value="{{ userName }}"
            (keyup)="userNameHandler($event)"
          />
        </div>
        <div class="at-inputholderbucketbtn">
          <input
            type="password"
            autocomplete="off"
            formControlName="accessToken"
            class=""
            placeholder="Enter Access Token"
            value="{{ accessToken }}"
            (keyup)="accessTokenHandler($event)"
          />
          <button
            [disabled]="!accessToken || !userName || fetchProjectLoading"
            type="button"
            class="at-btngetproject"
            (click)="getProjectsHandler()"
          >
            {{ fetchProjectLoading ? "Loading " : "Get Projects" }}
          </button>
        </div>
      </div>
      <div *ngIf="modalType === 'azure'" class="at-accesstokenarea">
        <div class="at-inputholder">
          <input
            type="password"
            autocomplete="off"
            formControlName="userName"
            class=""
            placeholder="Enter Organization Name"
            value="{{ userName }}"
            (keyup)="userNameHandler($event)"
          />
        </div>
        <div class="at-inputholderbucketbtn">
          <input
            type="password"
            autocomplete="off"
            formControlName="accessToken"
            class=""
            placeholder="Enter Access Token"
            value="{{ accessToken }}"
            (keyup)="accessTokenHandler($event)"
          />
          <button
            [disabled]="!accessToken || !userName || fetchProjectLoading"
            type="button"
            class="at-btngetproject"
            (click)="getProjectsHandler()"
          >
            {{ fetchProjectLoading ? "Loading " : "Get Projects" }}
          </button>
        </div>
      </div>

      <div class="at-checkboxtokenholder">
        <div class="at-saveaccestoken">
          <input
            #saveToken
            id="saveToken"
            type="checkbox"
            (change)="processingAlertHandler(saveToken.checked)"
            X
          />

          <label for="saveToken" *ngIf="modalType === 'bitbucket'"
            >Save Username &amp; App password
          </label>
          <label for="saveToken" *ngIf="modalType !== 'bitbucket'"
            >Save Access Token</label
          >
        </div>
        <div *ngIf="previousTokens.length" class="at-myaccesstoken">
          <h4 *ngIf="modalType === 'bitbucket'">Username &amp; App password</h4>
          <h4 *ngIf="modalType !== 'bitbucket'">Access Token</h4>
          <h4 *ngIf="modalType == 'azure'">
            Organization Name &amp; Access Token
          </h4>
          <ul>
            <li *ngFor="let item of previousTokens; let i = index">
              <!-- <ng-container *ngFor="let key of item | keyvalue"> -->
              <div class="AtAccesTokenList" *ngIf="item._id === modalType">
                <div>
                  <div class="at-accesstokenholder" *ngFor="let token of item.data">
                    <span>
                      <p>Connection Name : {{ token.connection_name }}</p>
                      <button
                      (click)="modalType === 'bitbucket' ? useExistBitbucketTokenHandler(token.personalAccessToken, token.connection_name, token.workspace) : useExistTokenHandler(token.personalAccessToken, token.connection_name)"
                      class="at-btngetproject"
                    >
                      Use
                    </button>
                    </span>
                    <!-- <span>
                      <p>
                        App Password :
                        <input
                          type="password"
                          value="{{ token.personalAccessToken }}"
                          readonly
                        />
                      </p>
                      <button
                        (click)="
                          useExistTokenHandler(
                            token.personalAccessToken,
                            token.connection_name
                          )
                        "
                        class="at-btngetproject"
                      >
                        Use
                      </button>
                    </span> -->
                  </div>
                </div>
                <!-- <div *ngIf="item._id === 'github' || item._id === 'gitlab'">
                  <div *ngFor="let token of item.data">
                    <span>
                      <p>
                        Connection Name : {{ token.connection_name }}
                        <input
                          type="password"
                          value="{{ token.personalAccessToken }}"
                          readonly
                          class="AtTokenInputs"
                        />
                      </p>

                      <button
                        (click)="
                          useExistTokenHandler(
                            token.personalAccessToken,
                            token.connection_name
                          )
                        "
                        class="at-btngetproject"
                      >
                        Use
                      </button>
                    </span>
                  </div>
                </div> -->
              </div>
              <!-- </ng-container> -->
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ng-template>
</div>
