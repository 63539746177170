<div class="modal-dialog modal-lg modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title color-white">
        <img src="/assets/images/icon__report.svg" alt="" />
        Report Issue
      </h4>
      <a
        class="modal-close"
        href="javascript:void(0)"
        aria-label="Close"
        (click)="onCloseModal()"
      >
        <img src="/assets/images/icon__close.svg" alt="" />
      </a>
    </div>
    <div class="modal-body">
      <form [formGroup]="contactUsForm" (ngSubmit)="saveContactUs()">
        <!-- <div class="mb-4">
          <label for="" class="form-label">What is your topic about?</label>
          <input
            type="text"
            class="form-control"
            placeholder="i.e. Dashboard, Billing etc."
            formControlName="topic"
          />
          <span
            class="form-text form-error"
            *ngIf="
              contactUsForm.get('topic')?.getError('required') &&
              contactUsForm.get('topic')?.touched
            "
            >Topic is Required</span
          >
        </div> -->
        <div class="mb-4">
          <label for="" class="form-label">What is your question about?</label>
          <ng-select
            placeholder="Select Question"
            [multiple]="false"
            formControlName="question"
          >
            <ng-option value="I have questions about getting started">
              I have questions about getting started
            </ng-option>
            <ng-option
              value="I have questions about setting up my teams workflow"
            >
              I have questions about setting up my teams workflow
            </ng-option>
            <ng-option value="Billing questions">Billing questions</ng-option>
            <ng-option
              value="I have questions about Automations/Integrations/Apps/API Marketplace questions"
            >
              I have questions about Automations/Integrations/Apps/API
              Marketplace questions
            </ng-option>
            <ng-option value="Something is not working">
              Something is not working
            </ng-option>
            <ng-option value="Legal and Security">Legal and Security</ng-option>
            <ng-option value="I am having trouble logging in">
              I am having trouble logging in
            </ng-option>
            <ng-option value="I have a feature request">
              I have a feature request
            </ng-option>
            <ng-option value="Mobile">Mobile</ng-option>
            <ng-option value="Other">Other</ng-option>
          </ng-select>
          <span
            class="form-text form-error"
            *ngIf="
              contactUsForm.get('question')?.getError('required') &&
              contactUsForm.get('question')?.touched
            "
            >Question is Required</span
          >
        </div>
        <div class="mb-4">
          <label for="" class="form-label">Details</label>
          <textarea
            class="form-control form-textarea"
            formControlName="details"
          ></textarea>
          <span
            class="form-text form-error"
            *ngIf="
              contactUsForm.get('details')?.getError('required') &&
              contactUsForm.get('details')?.touched
            "
            >Details is Required</span
          >
        </div>
        <div class="mb-4">
          <div class="form-files">
            <div class="names">
              <span class="label" *ngIf="fileList?.length === 0"
                >Upload your files</span
              >
              <!-- Uploaded file name & remove uploaded file ui -->
              <span *ngFor="let file of fileList; index as i">
                <a href="javascript:void(0)" (click)="onRemoveFile(i)"
                  >&times;</a
                >
                {{ file.split("/").pop() }}
              </span>
              <!-- Uploaded file name & remove uploaded file ui -->
            </div>
            <div class="action">
              <input
                type="file"
                (change)="onFileSelect($any($event).target.files)"
                accept="image/png,  image/jpeg"
              />
              <a href="javascript:void(0)" class="button button--secondary"
                >Browse</a
              >
            </div>
          </div>
        </div>
        <div class="button-group">
          <button type="submit" class="button button--primary">Submit</button>
        </div>
      </form>
    </div>
  </div>
</div>
