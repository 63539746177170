import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { Logs } from 'src/app/interface/Logs';
import { Router } from '@angular/router';
import { Util } from 'src/app/service/utils';
import { Location } from '@angular/common';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import fileDownload from 'js-file-download';

import { saveAs } from 'file-saver';
import * as fileSaver from 'file-saver';
// import * as fileSaver from 'file-saver';
// import * as XLSX from 'ts-xlsx';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent implements OnInit {
  term = '';
  page = 1;
  total = 0;
  totalLogs = 0;
  permissionLable = 'isActivityDownload';
  permissionGranted = false;
  loginTime: any;
  logoutTime: any;
  list: any;
  logs: any[] = [];
  logId: any;
  indivisualLogData: any | undefined;
  logType: any;
  isProcessing = false;

  constructor(
    private api: ApiService,
    private toaster: ToastrService,
    private router: Router,
    private util: Util,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (this.util.isPermission('isViewLog')) {
      // this.getPermissionList();
      this.getLogs();
      this.getData();
    } else {
      this.location.back();
    }
  }
  getLogs(): void {
    this.isProcessing = false;
    this.api.getActivityLogs(this.term, this.page).subscribe(
      (resp) => {
        // //console.log('resp', resp);
        this.isProcessing = true;
        if (resp.code === 0) {
          this.logs = resp.result.logs;
          this.total = resp.result.total;

          // //console.log(this.logs);
        } else {
          if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
            this.toaster.error(resp.result);
          } else {
            this.toaster.error('Internal Server Error');
          }
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  downloadUserLogs(): void {
    this.api.downloadUserLogs(this.term).subscribe((resp) => {
      // //console.log(resp.result.data);
      for(let i = 0; i< resp.result.data.length; i++){
        // //console.log(resp.result.data[i].Login_Time);
        // //console.log(resp.result.data[i].Logout_Time);
        if(resp.result.data[i].Login_Time != ''){
          this.loginTime = new Date(resp.result.data[i].Login_Time);
          resp.result.data[i].Login_Time = this.loginTime;
        }
        if(resp.result.data[i].Logout_Time != ''){
          this.logoutTime = new Date(resp.result.data[i].Logout_Time);
          resp.result.data[i].Logout_Time = this.logoutTime;
        }
        // //console.log(this.loginTime);
        // //console.log(this.logoutTime);
      }
      var options = {
        // fieldSeparator: ',',
        // quoteStrings: '"',
        // decimalseparator: '.',
        // showLabels: true,
        // showTitle: true,
        // title: 'Your title',
        // useBom: true,
        // noDownload: true,
        headers: Object.keys(resp.result.data[0]).map((data) =>
          data.replace('_', ' ')
        ),
        // useHeader: false,
        // nullToEmptyString: true,
      };
      new AngularCsv(resp.result.data, resp.result.fileName, options);
    });
  }
  getData() {
    this.list = JSON.parse(sessionStorage.getItem('permissions') || '{}');
    // //console.log(list?.length);
    // //console.log(this.list);

    for (let i = 0; i < this.list.length; i++) {
      if (this.permissionLable === this.list[i]) {
        this.permissionGranted = true;
      }
    }
  }
  // getPermissionList(): void {
  //   this.api.getPermissions().subscribe(
  //     (resp: any) => {
  //       if (resp.code === 0) {
  //         // this.permissions = resp.data;
  //         // //console.log(resp.data);

  //         for (let i = 0; i < resp.data.length; i++) {
  //           // //console.log(resp.data[i].label);
  //           //console.log(resp.data[i].key);

  //           if (this.permissionLable === resp.data[i].key) {
  //             this.permissionGranted = true;
  //           }
  //         }
  //       }
  //       // else {
  //       //   this.toaster.error(resp.message);
  //       // }
  //     },
  //     (error) => {
  //       this.toaster.error('Something Went Wrong');
  //     }
  //   );
  // }
  onClearSearch(): void {
    if (this.term === '') {
      this.getLogs();
    }
  }

  onclick(id?: any, resourceType?: string): void {
    // //console.log(id);
    this.logId = id;
    this.logType = resourceType;

    for (let i = 0; i < this.logs.length; i++) {
      if (this.logs[i]._id === id) {
        this.indivisualLogData = this.logs[i].events;
        this.totalLogs = this.logs[i].events.length;
      }
    }
    // //console.log(this.indivisualLogData);
    // //console.log(this.totalLogs);

    // this.http.get('https://api.punkapi.com/v2/beers/1').subscribe(Response => {
    //   //console.log(Response);
    //   this.li=Response;
    //   this.lis=this.li;
    // this.router.navigateByUrl(`/app-beer1/${id}`);
    // }
  }

  onChangePage(page: number) {
    if (this.page === page) return;
    this.page = page;
    this.getLogs();
  }
}
