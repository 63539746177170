import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../service/api.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  @ViewChild('closeButton') closeButton: ElementRef | undefined;
  passwordForm = new UntypedFormGroup({
    // current_password: new FormControl(null, Validators.required),
    password: new UntypedFormControl(null, Validators.required),
    confirm_password: new UntypedFormControl(null, Validators.required),
  });
  token: string = '';
  id: string = '';
  constructor(
    private api: ApiService,
    private toaster: ToastrService,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    // //console.log('hello');
    this.activeRoute.queryParamMap.subscribe((qp) => {
      this.token = qp.get('token') as string;
      this.id = qp.get('id') as string;
    });
  }
  changePassword(): void {
    if (this.passwordForm.valid) {
      this.api
        .resetPassword({
          password: this.passwordForm.get('password')?.value,
          id: this.id,
          token: this.token,
        })
        .subscribe(
          (resp) => {
            if (resp.code === 0) {
              this.toaster.success(resp.result);
            } else if (resp.code === 3) {
              this.toaster.error(resp.result);
            } else {
              if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
                this.toaster.error(resp.result);
              } else {
                this.toaster.error('Internal Server Error');
              }
            }
          },
          (error) => {
            if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
              this.toaster.error(error.error.result);
            } else {
              this.toaster.error('Something Went Wrong');
            }
          }
        );
    }
  }
  onReset(): void {
    this.passwordForm.reset();
  }
}
