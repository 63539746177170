<div class="block">
    <div class="block__nav">
      <ul class="nav">
        <!-- <li>
          <a href="javascript:void(0)" routerLinkActive="active">Dashboard</a>
        </li> -->
        <li>
            Map
        </li>
      </ul>
    </div>
</div>

<div class="block__content">
    <div class="monitor">
      <div class="row">
        <!-- <div class="col-md-10"> -->
        <div class="col-md-12">
            <app-map-page></app-map-page>
        </div>
      </div>
    </div>
  </div>
