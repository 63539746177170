<div class="licenses-container">
    <h2 class="centered-title">
        Packages with Licenses
        <button mat-icon-button (click)="toggleTable()">
            <mat-icon>{{ isTableCollapsed ? 'expand_more' : 'expand_less' }}</mat-icon>
        </button>
    </h2>
    <h4 class="centered-subtitle">Total Packages with Licenses: {{ packagesWithLicenses.length }}</h4>

    <!-- Apply animation trigger to the collapsible content -->
    <div [@toggleTable]="isTableCollapsed ? 'collapsed' : 'expanded'">
        <mat-table [dataSource]="packagesWithLicenses" class="mat-elevation-z8">
            <!-- Package Name Column -->
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Package Name </mat-header-cell>
                <mat-cell *matCellDef="let pkg"> {{pkg.package.name}} </mat-cell>
            </ng-container>

            <!-- Version Column -->
            <ng-container matColumnDef="version">
                <mat-header-cell *matHeaderCellDef> Version </mat-header-cell>
                <mat-cell *matCellDef="let pkg"> {{pkg.package.version}} </mat-cell>
            </ng-container>

            <!-- License Name Column -->
            <ng-container matColumnDef="licenseName">
                <mat-header-cell *matHeaderCellDef> License Name </mat-header-cell>
                <mat-cell *matCellDef="let pkg"> {{pkg.license.name}} </mat-cell>
            </ng-container>

            <!-- SPDX Expression Column -->
            <ng-container matColumnDef="spdx">
                <mat-header-cell *matHeaderCellDef> SPDX Expression </mat-header-cell>
                <mat-cell *matCellDef="let pkg"> {{pkg.license.spdx_expression}} </mat-cell>
            </ng-container>

            <!-- OSI Approved Column -->
            <ng-container matColumnDef="osiApproved">
                <mat-header-cell *matHeaderCellDef> OSI Approved </mat-header-cell>
                <mat-cell *matCellDef="let pkg"> {{ pkg.license.is_osi_approved ? 'Yes' : 'No' }} </mat-cell>
            </ng-container>

            <!-- License Reference Column -->
            <ng-container matColumnDef="reference">
                <mat-header-cell *matHeaderCellDef> License Reference </mat-header-cell>
                <mat-cell *matCellDef="let pkg">
                    <a [href]="pkg.license.reference" target="_blank">License Info</a>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
</div>
