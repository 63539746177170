import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SideFilter } from 'src/app/interface/SideFilter';
import { MonitorApiService } from 'src/app/service/monitor-api.service';
import { Util } from 'src/app/service/utils';

@Component({
  selector: 'app-monitor-analytics-page2',
  templateUrl: './monitor-analytics-page2.component.html',
  styleUrls: ['./monitor-analytics-page2.component.scss'],
})
export class MonitorAnalyticsPage2Component implements OnInit {
  allFilter: any;
  pieChartData: any;
  barGraphData: any;
  lineChartData: any;
  bubbleChartData: any;
  bubbleFullData: any;
  lineChartDataRepoWiseUser: any;
  isShowPieChartData: boolean = false;
  isShowBubbleChartData: boolean = false;
  isShowLineChartData: boolean = false;
  isShowBarGraphData: boolean = false;
  isShowLineChartDataRepoWiseUser: boolean = false;
  type = 'Group';
  config = {
    displaylogo: false,
  };
  layout = {
    yaxis: { tickformat: ',d' },
  };

  constructor(
    private store: Store<{ sideBarFilter: SideFilter }>,
    private api: MonitorApiService,
    private location: Location,
    private router: Router,
    private util: Util
  ) {}

  ngOnInit(): void {
    if (this.util.isPermission('isViewMonitor')) {
      this.store.select('sideBarFilter').subscribe((resp: any) => {
        this.allFilter = resp;

        this.getDataForPieChart();
        this.getDataForBarChart();
        this.getDataForLineChart();
        this.getDataForBubble();
        this.getDataForLineChartRepoWiseUser();
      });
    } else {
      this.location.back();
    }
  }
  ngAfterViewInit(): void {}

  getDataForPieChart(): void {
    // this.pieChartData = undefined;

    this.api.getAllDataForPieChart(this.allFilter).subscribe((resp: any) => {
      // //console.log('getAllDataForPieChart>>>', resp);
      if (resp.code === 0) {
        if (resp.result.approved == 0 && resp.result.notApproved == 0) {
          this.isShowPieChartData = false;
        } else {
          this.isShowPieChartData = true;
        }
        this.pieChartData = {
          data: [
            {
              values: [resp.result?.approved, resp.result?.notApproved],
              labels: ['Approved', 'Not Approved'],
              type: 'pie',
              marker: {
                colors: ['#5942eb', '#09bbfd'],
              },
            },
          ],
        };
        // //console.log('pieChartData', this.pieChartData, this.isShowPieChartData);
      }
    });
  }
  getDataForBarChart(): void {
    this.barGraphData = undefined;

    this.api.getAllDataForBarChart(this.allFilter).subscribe((resp: any) => {
      if (resp.code === 0) {
        // //console.log('getAllDataForBarChart', resp);
        if (
          resp.result.commits == 0 &&
          resp.result.disputes == 0 &&
          resp.result.users == 0
        ) {
          this.isShowBarGraphData = false;
        } else {
          this.isShowBarGraphData = true;

          this.barGraphData = {
            data: [
              {
                x: resp.result.map((e: any) => e._id),
                y: resp.result.map((e: any) => e.count),
                type: 'bar',
                marker: {
                  color: '#09bbfd',
                },
              },
            ],
          };
        }
      }
    });
  }
  getDataForLineChart(): void {
    this.lineChartData = undefined;
    this.api.getAllDataForLineChart(this.allFilter).subscribe((resp: any) => {
      // //console.log('lineChartData', resp);
      if (resp.code === 0) {
        if (
          resp.result.commits == 0 &&
          resp.result.disputes == 0 &&
          resp.result.users == 0
        ) {
          this.isShowLineChartData = false;
        } else {
          this.isShowLineChartData = true;

          this.lineChartData = {
            data: [
              {
                x: resp.result.commits.map((e: any) => e.repo),
                y: resp.result.commits.map((e: any) => e.count),
                type: 'scatter',
                name: 'Commit',
                line: {
                  color: '#09bbfd',
                  width: 3,
                },
              },
              {
                x: resp.result.disputes.map((e: any) => e.repo),
                y: resp.result.disputes.map((e: any) => e.disputes),
                type: 'scatter',
                name: 'Dispute',
                line: {
                  color: '#5942eb',
                  width: 3,
                },
              },
            ],
          };
        }
      }
    });
  }
  getDataForLineChartRepoWiseUser(): void {
    this.lineChartDataRepoWiseUser = undefined;
    this.api
      .getAllDataForLineChartRepoWise(this.allFilter)
      .subscribe((resp: any) => {
        if (resp.code === 0) {
          // //console.log('lineChartDataRepoWiseUser', resp);
          if (resp.result.length == 0) {
            this.isShowLineChartDataRepoWiseUser = false;
          } else {
            this.isShowLineChartDataRepoWiseUser = true;

            this.lineChartDataRepoWiseUser = {
              data: [
                {
                  x: resp.result.map((e: any) => e.repo_name),
                  y: resp.result.map((e: any) => e.distinct_user.length),
                  type: 'bar',
                  text: resp.result.map((e: any) => e.distinct_user.join(',')),
                  name: 'Repo Wise Users',
                  line: {
                    color: '#09bbfd',
                    width: 3,
                  },
                },
              ],
            };
          }
        }
      });
  }

  getDataForBubble(): void {
    this.bubbleChartData = undefined;
    this.api.getAllDataForBubbleChart({ id: null }).subscribe((resp: any) => {
      if (resp.code === 0) {
        // //console.log(
        //   'bubbleChartData 0',
        //   resp,
        //   this.bubbleChartData,
        //   this.isShowBubbleChartData
        // );
        if (resp.result.length == 0) {
          this.isShowBubbleChartData = false;
        } else {
          this.isShowBubbleChartData = true;
          this.bubbleChartData = {
            data: [
              {
                x: resp.result.map((e: any) => e?.group?.name),
                y: resp.result.map((e: any) => e.count),
                mode: 'markers',
                marker: {
                  size: resp.result.map((e: any) => e.count * 100),
                  sizemode: 'area',
                  color: '#5942eb',
                },
              },
            ],
          };
          this.bubbleFullData = resp.result;
        }
      }
      // //console.log(
      //   'bubbleChartData',
      //   resp,
      //   this.bubbleChartData,
      //   this.isShowBubbleChartData
      // );
    });
  }
  onPointClick(event: any): void {
    if (this.type === 'Group') {
      const groupData = this.bubbleFullData.find(
        (e: any) => e?.group?.name === event.points[0].x
      );
      // //console.log(groupData);
      this.type = 'Repository';
      this.bubbleChartData = undefined;
      this.api
        .getAllDataForLineChart({
          ...this.allFilter,
          groups: [groupData._id],
          repos: [],
        })
        .subscribe((resp) => {
          if (resp.code === 0) {
            this.bubbleChartData = {
              data: [
                {
                  x: resp.result.disputes.map((e: any) => e?.repo),
                  y: resp.result.disputes.map((e: any) => e?.disputes),
                  mode: 'markers',
                  marker: {
                    size: resp.result.disputes.map(
                      (e: any) => e.disputes * 100
                    ),
                    sizemode: 'area',
                    color: '#5942eb',
                  },
                },
              ],
            };
            this.bubbleFullData = resp.result;
          }
        });
    } else {
      this.type = 'Group';
      this.getDataForBubble();
    }
  }
  onBack(): void {
    this.location.back();
  }
}
