import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { EventService } from 'src/app/service/event.service';
declare var $: any;

@Component({
  selector: 'app-otp-approval',
  templateUrl: './otp-approval.component.html',
  styleUrls: ['./otp-approval.component.scss'],
})
export class OtpApprovalComponent implements OnInit, OnChanges {
  @Input('type') type: any;
  @Input('isOpen') isOpen: boolean = false;
  @Output() otpEvent = new EventEmitter<number>();
  @Output() modalEvent = new EventEmitter<string>();
  @ViewChild('ngOtpInput') ngOtpInput?: ElementRef;
  @ViewChild('modalOTP') modalOTP?: ModalDirective;
  otp: number | undefined;
  timer = 60;
  intervalOp: any;
  startTimer(): void {
    this.intervalOp = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      }
    }, 1000);
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    clearInterval(this.intervalOp);
    this.timer = 60;
    // //console.log('destroy');
  }
  pauseTimer(): void {
    clearInterval(this.intervalOp);
  }
  constructor(private eventService: EventService) {}

  ngOnInit(): void {
    this.otp = 0; 

    if (this.isOpen) {
      this.modalOTP?.show();
    }
    else {
      this.modalOTP?.hide();
    }
  }
  ngAfterViewInit(): void {
    if (this.isOpen){
    this.modalOTP?.show();
  }
  else {
    this.modalOTP?.hide();
  }
}

  ngOnChanges(): void {
    // //console.log('onchange');

    if (this.isOpen) {
      this.modalOTP?.show();
      this.modalEvent.emit('open');
      this.timer = 60;
      this.startTimer();
      // @ts-ignore
      this.ngOtpInput?.setValue('');
    } else {
      this.closeModal();
    }
  }
  closeModal(): void {
    this.modalOTP?.hide();
    this.isOpen = false;
    this.modalEvent.emit('close');
    clearInterval(this.intervalOp);
  }
  onSubmit(): void {
    if (this.otp) {
      this.otpEvent.emit(this.otp);
      this.closeModal();
      this.otp = 0;
      // @ts-ignore
      this.ngOtpInput?.setValue('');
    }
  }
  onOtpChange(otp: any): void {
    this.otp = otp;
  }
  resendOTP(): void {
    this.eventService.resendOTP();
    this.modalEvent.emit('resendOTP');
  }
}
