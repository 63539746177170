import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Ip } from 'src/app/interface/ip';
import { SideFilter } from 'src/app/interface/SideFilter';
import { MonitorApiService } from 'src/app/service/monitor-api.service';
import {
  ResetCalendarFilter,
  UpdateDateRange,
  updateOrgDate,
} from 'src/app/store/sidebar-filter.action';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { UserState } from 'src/app/interface/user';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';


@Component({
  selector: 'app-map-page',
  templateUrl: './map-page.component.html',
  styleUrls: ['./map-page.component.scss']
})
export class MapPageComponent implements OnInit {
  lat = 39.045022;
  lng = -92.911885;
  zoom = 4.8;
  minZoom = 1.9;
  controlSize = 40;
  term = '';
  markers: Ip[] = [];
  ip: any;
  defaultStartDate: any;
  startDate: any;
  orgStartDate: any;
  endDate: any;
  date: any;
  today: Date = new Date();
  public currentYear: number = this.today.getFullYear();
  public currentMonth: number = this.today.getMonth();
  public currentDay: number = this.today.getDate();
  public maxDate: any = new Date(
    this.currentYear,
    this.currentMonth,
    this.currentDay
  );
  currentDate = this.maxDate;

  constructor(
    private api: MonitorApiService,
    // private datePipe: DatePipe,
    private store: Store<{ userData: UserState; filter: SideFilter }>
  ) {}

  ngOnInit(): void {
    // this.getAllIpLocations();
    this.getCurrentUser();
  }

  getCurrentUser(): void {
    this.api.getUser().subscribe(
      (resp: any) => {
        if (resp.code === 0) {
          this.orgStartDate = new Date(resp.result.orgStartDate);
          const date = [
            new Date(this.orgStartDate),
            new Date(),
          ];
          this.date = date;
          this.getAllIpLocations();
        }
      },
      (error: HttpErrorResponse) => {
        // //console.log('error>>', error);
      }
    );
  }

  onDateChange(): void {
    this.getAllIpLocations();
  }

  search(): void {
    this.getAllIpLocations();
  }

  clearCalendar(): void {
    const date = [new Date(this.orgStartDate), new Date()];
    this.date = date;
    this.getAllIpLocations();
  }

  getAllIpLocations(): void {
    let data = {
      start_date: this.date && this.date[0],
      end_date: this.date && !moment(this.date[1]).set({second: 0, millisecond: 0, minute: 0, hour: 0}).isSame(moment(this.date[0]).set({second: 0, millisecond: 0, minute: 0, hour: 0})) ? this.date[1] : new Date(moment(this.date[1]).add(1,'days').format('MM/DD/YYYY hh:mm')),
    };
    this.api
      .getAllIpLocation(moment(data.start_date).format('MM/DD/YYYY'), moment(data.end_date).format('MM/DD/YYYY'), this.term)
      .subscribe((resp) => {
        this.ip = resp.result.locs;
        this.markers = [];
        for (let i = 0; i < this.ip.length; i++) {
          if (this.ip[i].loc != undefined) {
            this.markers.push({
              lat: this.ip[i].loc.split(',')[0],
              lng: this.ip[i].loc.split(',')[1],
              city: this.ip[i].city ? this.ip[i].city : '',
              ip: this.ip[i].ip ? this.ip[i].ip : '',
              org: this.ip[i].org ? this.ip[i].org : '',
              region: this.ip[i].region ? this.ip[i].region : '',
              country: this.ip[i].country ? this.ip[i].country : '',
              timezone: this.ip[i].timezone ? this.ip[i].timezone : '',
            });
          }
        }
        
      });
  
  }

  mouseOver(infoWindow: any, e: any): void {
    infoWindow.open();
  }

  onMouseOut(infoWindow: any, e: any): void {
    infoWindow.close();
  }

}
