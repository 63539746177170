<div class="d-flex align-items-center mb-4">
  <h4 class="m-0">User Logs</h4>
  <div
    class="d-flex align-items-center justify-content-end ms-auto flex-grow-1"
  >
    <div class="form-search w-50">
      <input
        type="text"
        class="form-control"
        placeholder="Search (User, IP, Action, Resource & Resource Type)"
        [(ngModel)]="term"
        (keyup.enter)="getLogs()"
        (ngModelChange)="onClearSearch()"
      />
      <button type="submit">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 27.18 27.9"
          style="enable-background: new 0 0 27.18 27.9"
          xml:space="preserve"
        >
          <path
            d="M17.76,20.63c-5.5,3.82-12.7,1.62-15.85-3.11C-1.21,12.83-0.48,6.56,3.94,2.76 c4.09-3.51,10.17-3.77,14.64-0.19c4.26,3.4,6.04,10.45,1.52,15.96c0.36,0.37,0.72,0.75,1.08,1.12c1.83,1.88,3.67,3.76,5.49,5.65 c0.87,0.91,0.57,2.22-0.58,2.54c-0.64,0.18-1.14-0.07-1.58-0.52c-1.58-1.64-3.16-3.28-4.76-4.9C19.13,21.8,18.44,21.24,17.76,20.63z M11.32,19.59c4.54,0.07,8.36-3.63,8.44-8.19c0.08-4.58-3.62-8.37-8.26-8.44c-4.67-0.07-8.48,3.61-8.54,8.26 C2.9,15.75,6.65,19.51,11.32,19.59z"
          />
        </svg>
      </button>
    </div>
    <button
      class="button button--secondary ms-3"
      type="button"
      (click)="downloadUserLogs()"
      *ngIf="permissionGranted"
    >
      <i class="fa fa-download me-2" aria-hidden="true"></i>
      Download
    </button>
    <!--    <button class="button button&#45;&#45;open" type="button">-->
    <!--      <i class="fa fda-external-link me-2" aria-hidden="true"></i>-->
    <!--      Export-->
    <!--    </button>-->
  </div>
</div>
<div class="table-main mb-4">
  <div class="table-responsive">
    <table class="table table-nowrap m-0">
      <thead>
        <tr>
          <th scope="col">User</th>
          <th scope="col">Login Date &amp; Time</th>
          <th scope="col">Logout Date &amp; Time</th>

          <th scope="col">IP</th>
          <!-- <th scope="col">Component</th> -->
          <th scope="col">Action</th>
          <th scope="col">Event Sequence</th>
          <!-- <th scope="col">Approved by Admin</th> -->
          <th scope="col" class="text-center">Browser</th>
          <!-- <th scope="col">Files</th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="logs.length === 0 && isProcessing">
          <td colspan="9" class="text-center">No records found</td>
        </tr>
        <tr *ngFor="let log of logs; index as i">
          <td>{{ log.first_name }} {{ log.last_name }}</td>
          <td>{{ log.createdAt | date: "full" }}</td>
          <td>{{ log.logoutTime | date: "full" }}</td>
          <td>{{ log?.ip }}</td>
          <!-- <td>{{ log.resource }}</td> -->
          <td>
            <a
              href="javascript:void(0)"
              (click)="modalViewLogs.show(); onclick(log._id, log.resourceType)"
              >View More</a
            >
          </td>

          <!-- <td>{{ log.action }}</td> -->
          <td>{{ log.description }}</td>
          <!-- <td>
            <div class="d-inline-flex align-items-center">
              <span class="status__approve me-2">
                <i class="fa fa-check" aria-hidden="true"></i>
              </span>
              Timestamp
            </div>
          </td> -->
          <td class="text-center">
            <img
              class="browser"
              src="/assets/images/browsers/chrome.svg"
              alt=""
              *ngIf="log.browser === 'Chrome'"
            />
            <img
              class="browser"
              src="/assets/images/browsers/edge.svg"
              alt=""
              *ngIf="log.browser === 'Edge'"
            />
            <img
              class="browser"
              src="/assets/images/browsers/firefox.svg"
              alt=""
              *ngIf="log.browser === 'Firefox'"
            />
            <img
              class="browser"
              src="/assets/images/browsers/ie.svg"
              alt=""
              *ngIf="log.browser === 'Internet Explorer'"
            />
            <img
              class="browser"
              src="/assets/images/browsers/opera.svg"
              alt=""
              *ngIf="log.browser === 'Opera'"
            />
            <img
              class="browser"
              src="/assets/images/browsers/saferi.svg"
              alt=""
              *ngIf="log.browser === 'Safari'"
            />
          </td>
          <!-- <td>---</td> -->
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="d-flex justify-content-end">
  <app-pagination
    [count]="total"
    (changePage)="onChangePage($event)"
    [perPage]="20"
  ></app-pagination>
</div>
<div
  bsModal
  #modalViewLogs="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <ng-container *ngIf="logType === 'GitHook'; else normalLog">
    <app-view-more-push-logs
      (closeModal)="modalViewLogs.hide()"
      [eventData]="indivisualLogData"
      [total]="totalLogs"
      [logId]="logId"
    ></app-view-more-push-logs>
  </ng-container>
  <ng-template #normalLog>
    <app-view-more-logs
      (closeModal)="modalViewLogs.hide()"
      [eventData]="indivisualLogData"
      [total]="totalLogs"
      [logId]="logId"
    ></app-view-more-logs>
  </ng-template>
</div>


