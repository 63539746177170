<div class="site__authentication site__signin">
  <div class="container-fluid align-self-center">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <form
          *ngIf="!showOtp"
          [formGroup]="loginForm"
          autocomplete="off"
          class="mb-5"
        >
          <div class="logo">
            <img src="/assets/images/logo__icon.svg" alt="" />
          </div>
          <!-- <div class="mb-4 d-flex align-items-center justify-content-center">
            <div class="form-radio has-label">
              <input
                type="radio"
                id="option__signin--root"
                formControlName="isRoot"
                value="true"
              />
              <span class="icon"></span>
              <label for="option__signin--root" class="form-label">Admin</label>
            </div>
            <div class="form-radio has-label ms-4">
              <input
                type="radio"
                id="option__signin--user"
                formControlName="isRoot"
                value="false"
              />
              <span class="icon"></span>
              <label for="option__signin--user" class="form-label">User</label>
            </div>
          </div> -->
          <div class="mb-4">
            <div class="position-relative">
              <input
                type="number"
                class="form-control"
                placeholder="Company Code..."
                autocomplete="off"
                formControlName="accountId"
              />
              <span class="icon">
                <img src="/assets/images/icon__company.svg" alt="" />
              </span>
            </div>
            <span
              class="form-text form-error"
              *ngIf="
                loginForm.get('accountId')?.getError('required') &&
                loginForm.get('accountId')?.touched
              "
              >This is a required field.</span
            >
            <span
              class="form-text form-error"
              *ngIf="
                (loginForm.get('accountId')?.getError('pattern') ||
                  loginForm.get('accountId')?.getError('min')) &&
                loginForm.get('accountId')?.touched
              "
              >Invalid Id</span
            >
          </div>
          <div class="mb-4">
            <div class="position-relative">
              <input
                type="email"
                class="form-control"
                placeholder="Email..."
                autocomplete="off"
                formControlName="email"
              />
              <span class="icon">
                <img src="/assets/images/icon__email.svg" alt="" />
              </span>
            </div>
            <span
              class="form-text form-error"
              *ngIf="
                loginForm.get('email')?.getError('required') &&
                loginForm.get('email')?.touched
              "
              >This is a required field.</span
            >
          </div>
          <div class="mb-4">
            <div class="position-relative">
              <input
                type="password"
                class="form-control"
                placeholder="Password..."
                autocomplete="off"
                formControlName="password"
              />
              <span class="icon icon--lg">
                <img src="/assets/images/icon__password.svg" alt="" />
              </span>
            </div>
            <div
              id="simple-keyboard"
              class="simple-keyboard"
              [style]="showKeyboard ? 'display: block' : 'display: none'"
            ></div>
            <span
              class="form-text form-error"
              *ngIf="
                loginForm.get('password')?.getError('required') &&
                loginForm.get('password')?.touched
              "
              >This is a required field.</span
            >
          </div>

          <div class="button-group">
            <button
              href="javascript:void(0)"
              class="button button--primary w-100"
              (click)="onLogin()"
            >
              Sign In
            </button>
          </div>
        </form>
        <form *ngIf="showOtp" autocomplete="off" class="mb-5">
          <div class="logo">
            <img src="/assets/images/logo__icon.svg" alt="" />
          </div>
          <div class="mb-4">
            <label for="" class="form-label d-block text-center mb-3"
              >Please enter your 6 digit OTP</label
            >
            <div class="form-otp">
              <ng-otp-input
                [config]="{
                  length: 6,
                  allowNumbersOnly: true
                }"
                containerClass="true"
                inputClass="true"
                (onInputChange)="onOtpChange($event)"
                #ngOtpInput
              ></ng-otp-input>
            </div>
            <span
              class="form-text d-block text-center mt-3 weight-600 text-warning"
              *ngIf="timer_min > 0"
            >
              <i
                class="fa fa-hourglass-half me-2 fa-spin"
                aria-hidden="true"
              ></i>
              OTP valid until 0{{ timer_min }} :
              <span *ngIf="timer < 10">0</span>{{ timer }}
            </span>
            <span
              class="form-text d-block text-center mt-3 weight-600 text-warning"
              *ngIf="timer_min <= 0"
              >Haven't received the OTP?
              <a
                href="javascript:void(0)"
                *ngIf="timer_min <= 0"
                (click)="resendOtp()"
              >
                Resend OTP</a
              ></span
            >
          </div>
          <div class="button-group justify-content-center">
            <button
              href="javascript:void(0)"
              class="button button--primary w-50"
              (click)="onVerifyOtp()"
            >
              Verify OTP
            </button>
          </div>
        </form>
        <p class="text-center mb-1">
          <a href="javascript:void(0)" routerLink="/auth/forgot-password"
            >Forgot password?</a
          >
        </p>
        <p class="text-center m-0">
          Don't have an account?
          <a href="https://codelock.it/pricing" target="_blank" rel="noopener noreferrer">Sign Up</a>
        </p>
      </div>
      <!-- Face Recognition -->
      <!-- <div class="col-md-12 d-none">
        <a
          href="javascript:void(0)"
          id="add_face_button"
          class="d-none"
          data-toggle="modal"
          data-target="#authentication__face-detection"
          #detectFace
        ></a>
        <app-face-recognition
          [full]="fullScreen"
          (onAdd)="afterDetectFace($event)"
          (onClose)="afterClose()"
        ></app-face-recognition>
      </div> -->
      <div
        bsModal
        #faceRecognizeModal="bs-modal"
        class="modal fade"
        id="modal__faceRecognize"
        tabindex="-1"
        role="dialog"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <a
              class="modal-close"
              href="javascript:void(0)"
              (click)="stopVideo()"
              aria-label="Close"
            >
              <img src="/assets/images/icon__close.svg" alt="" />
            </a>
            <div class="modal-body">
              <div class="inner">
                <video
                  [class.show]="!isCaptured"
                  [class.d-none]="isCaptured"
                  #video
                  id="video"
                  [width]="WIDTH"
                  [height]="HEIGHT"
                  autoplay
                ></video>
                <canvas
                  [class.show]="isCaptured"
                  [class.d-none]="!isCaptured"
                  #canvas
                  id="canvas"
                  [width]="WIDTH"
                  [height]="HEIGHT"
                ></canvas>
                <div class="scan">
                  <span class="corner corner__tl"></span>
                  <span class="corner corner__tr"></span>
                  <span class="corner corner__bl"></span>
                  <span class="corner corner__br"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Face Recognition -->
    </div>
  </div>
</div>

