import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorComponent } from './Component/paginator/paginator.component';
import { SharedHeaderComponent } from './Component/shared-header/shared-header.component';
import { ConfirmEqualValidatorDirective } from './confirm-validator.directive';
import { RouterModule } from '@angular/router';
import { ResetPasswordComponent } from './Component/reset-password/reset-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OtpApprovalComponent } from './Component/otp-approval/otp-approval.component';
import { DeleteModalComponent } from './Component/delete-modal/delete-modal.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { DateAgoPipe } from './Pipes/date-ago.pipe';
import { InDaysPipe } from './Pipes/in-days.pipe';
import { ModalModule } from 'ngx-bootstrap/modal';
import { EqualValidatorDirective } from './equal-validator.directive';

import { HeaderComponent } from './Component/header/header.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NavigationComponent } from './Component/navigation/navigation.component';
import { ReportIssueComponent } from './Component/report-issue/report-issue.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PaginationComponent } from './Component/pagination/pagination.component';
// import { HeaderComponent } from '../component/header/header.component';
export * from './Component/header/header.component';
export * from './Component/navigation/navigation.component';
export * from './Component/pagination/pagination.component';
@NgModule({
  declarations: [
    PaginatorComponent,
    PaginationComponent,
    SharedHeaderComponent,
    ConfirmEqualValidatorDirective,
    EqualValidatorDirective,
    ResetPasswordComponent,
    OtpApprovalComponent,
    DeleteModalComponent,
    DateAgoPipe,
    HeaderComponent,
    NavigationComponent,
    ReportIssueComponent,
    InDaysPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    // HeaderComponent,
    NgOtpInputModule,
    NgSelectModule,

    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
  ],
  // providers: [HeaderComponent],
  exports: [
    PaginatorComponent,
    PaginationComponent,
    SharedHeaderComponent,
    ConfirmEqualValidatorDirective,
    EqualValidatorDirective,
    ResetPasswordComponent,
    OtpApprovalComponent,
    HeaderComponent,
    NavigationComponent,
    ReportIssueComponent,
    DeleteModalComponent,
    DateAgoPipe,
    InDaysPipe,
    ReactiveFormsModule,
  ],
})
export class SharedModule {}
