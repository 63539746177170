import { Component, Input, OnInit } from '@angular/core';

interface DiffLine {
  line: string;
  type: 'addition' | 'deletion' | 'context';
  originalLineNumber: number;
  modifiedLineNumber: number;
}

interface FileDiff {
  filename: string;
  diffLines: DiffLine[];
  isCollapsed: boolean;
}

@Component({
  selector: 'app-diff-viewer',
  templateUrl: './diff-viewer.component.html',
  styleUrls: ['./diff-viewer.component.css']
})
export class DiffViewerComponent implements OnInit {
  @Input() diffObject: [ { [filename: string]: string }, string ] = [{}, ''];
  // @Input() commitMessage: string = '';
  diffs: FileDiff[] = [];
  commitMessage: string = '';
  commitId: string = '';
  ngOnInit(): void {
    if (this.diffObject) {
      const [diffs, commitMessage] = this.diffObject;
      
      this.commitMessage = this.decodeMultipleTimes(commitMessage);
      this.parseDiffsObject(diffs);
    }
  }
  decodeMultipleTimes(encoded: string): string {
    let decoded = decodeURIComponent(encoded);
    while (decoded !== encoded) {
      encoded = decoded;
      decoded = decodeURIComponent(encoded);
    }
    return decoded;
  }

  parseDiffsObject(diffs: { [filename: string]: string }): void {
    const entries = Object.entries(diffs);
    this.diffs = entries.map(([filename, diffString], index) => ({
      filename,
      diffLines: this.parseDiff(diffString),
      isCollapsed: index !== 0, // Collapse all except the first one
    }));
  }

  parseDiff(diffString: string): DiffLine[] {
    const lines = diffString.split('\n');
    let originalLineNumber = 0;
    let modifiedLineNumber = 0;
    let insideHunk = false;
  
    const parsedDiff = lines.reduce((acc, line) => {
      let type = 'context'; // Default type for unchanged/context lines
  
      if (line.startsWith('@@ ')) {
        // Hunk header
        insideHunk = true;
        const match = /@@ -(\d+),\d+ \+(\d+),\d+ @@/.exec(line);
        if (match) {
          originalLineNumber = parseInt(match[1], 10);
          modifiedLineNumber = parseInt(match[2], 10);
        }
        // Skip adding hunk headers to the result
        return acc;
      }
  
      // Exclude metadata lines like "index ...", "--- a/tes.py", "+++ b/tes.py"
      if (line.startsWith('index ') || line.startsWith('--- ') || line.startsWith('+++ ')) {
        return acc;
      }
  
      if (insideHunk) {
        if (line.startsWith('-') && !line.startsWith('---')) {
          type = 'deletion';
          //@ts-ignore
          acc.push({ line, type, originalLineNumber });
          originalLineNumber++;
        } else if (line.startsWith('+') && !line.startsWith('+++')) {
          type = 'addition';
          //@ts-ignore
          acc.push({ line, type, modifiedLineNumber });
          modifiedLineNumber++;
        } else if (!line.startsWith('--- ') && !line.startsWith('+++ ')) {
          // Context line
          //@ts-ignore
          acc.push({ line, type, originalLineNumber, modifiedLineNumber });
          originalLineNumber++;
          modifiedLineNumber++;
        }
      }
  
      return acc;
    }, [] as DiffLine[]);
  
    return parsedDiff;
  }
  

  toggleCollapse(fileDiff: FileDiff): void {
    fileDiff.isCollapsed = !fileDiff.isCollapsed;
  }
  
  
}
