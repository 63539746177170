<div class="d-flex justify-content-end align-items-center mb-3">
  
    <div class="form-date with-reset">
      <input
        type="text"
        class="form-control"
        placeholder="Choose Date Range"
        bsDaterangepicker
        [bsConfig]="{
          displayMonths: 1,
          rangeInputFormat: 'MM/DD/YYYY'
        }"
        [(ngModel)]="date"
        (ngModelChange)="onDateChange()"
        placement="bottom"
        [maxDate]="maxDate"
        [minDate]="orgStartDate"
      />
      <a (click)="clearCalendar()" href="javascript:void(0)" class="reset">
        <i class="fa fa-refresh" aria-hidden="true"></i>
      </a>
    </div>
    <div class="form-search w-50 ms-3">
      <input
        type="text"
        class="form-control"
        placeholder="Search"
        [(ngModel)]="term"
        (change)="search()"
      />
      <button type="submit">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 27.18 27.9"
          style="enable-background: new 0 0 27.18 27.9"
          xml:space="preserve"
        >
          <path
            d="M17.76,20.63c-5.5,3.82-12.7,1.62-15.85-3.11C-1.21,12.83-0.48,6.56,3.94,2.76 c4.09-3.51,10.17-3.77,14.64-0.19c4.26,3.4,6.04,10.45,1.52,15.96c0.36,0.37,0.72,0.75,1.08,1.12c1.83,1.88,3.67,3.76,5.49,5.65 c0.87,0.91,0.57,2.22-0.58,2.54c-0.64,0.18-1.14-0.07-1.58-0.52c-1.58-1.64-3.16-3.28-4.76-4.9C19.13,21.8,18.44,21.24,17.76,20.63z M11.32,19.59c4.54,0.07,8.36-3.63,8.44-8.19c0.08-4.58-3.62-8.37-8.26-8.44c-4.67-0.07-8.48,3.61-8.54,8.26 C2.9,15.75,6.65,19.51,11.32,19.59z"
          />
        </svg>
      </button>
    </div>
  </div>
  <agm-map
    [latitude]="lat"
    [longitude]="lng"
    [zoom]="zoom"
    [minZoom]="minZoom"
    [controlSize]="controlSize"
    [fullscreenControl]="true"
    [mapTypeControl]="true"
    [disableDefaultUI]="true"
  >
    <ng-container *ngFor="let m of markers; let i = index">
      <agm-marker
        [latitude]="m.lat"
        [longitude]="m.lng"
        (mouseOver)="mouseOver(infoWindow, $event)"
        (mouseOut)="onMouseOut(infoWindow, $event)"
      >
        <agm-info-window [disableAutoPan]="false" #infoWindow>
          <strong class="color-black d-block">IP: {{ m.ip }}</strong>
          <strong class="color-black d-block">Company: {{ m.org }}</strong>
          <strong class="color-black d-block">City: {{ m.city }}</strong>
          <strong class="color-black d-block">Country: {{ m.country }}</strong>
          <strong class="color-black d-block">Region: {{ m.region }}</strong>
          <strong class="color-black d-block">Timezone: {{ m.timezone }}</strong>
        </agm-info-window>
      </agm-marker>
    </ng-container>
  </agm-map>
  