<div class="mt-4">
  <div class="mb-4 pb-1 d-flex align-items-start">
    <a
      class="back me-3"
      href="javascript:void(0)"
      routerLink="/dashboard/manage/roles"
    >
      <img src="/assets/images/icon__arrow--left.svg" alt="" />
    </a>
    <div>
      <h4 class="mb-0">Create ddddUser Role</h4>
    </div>
  </div>
  <form [formGroup]="roleForm" (submit)="onSubmit()">
    <div class="row">
      <div class="col-md-12">
        <div class="mb-4">
          <label for="" class="form-label">Name</label>
          <input type="text" class="form-control" formControlName="name" />
          <span
            class="form-text form-error"
            *ngIf="
              roleForm.get('name')?.hasError('required') &&
              roleForm.get('name')?.touched
            "
            >This is a required field.</span
          >
        </div>
        <div class="mb-4">
          <label for="" class="form-label">Role</label>
          <ng-select
            placeholder="Choose Role"
            [multiple]="true"
            [items]="permissions"
            bindLabel="label"
            bindValue="label"
            formControlName="permissionList"
          >
            <!-- <ng-option>Role A</ng-option>
            <ng-option>Role B</ng-option>
            <ng-option>Role C</ng-option> -->
          </ng-select>
          <span
            class="form-text form-error"
            *ngIf="
              roleForm.get('permissionList')?.hasError('required') &&
              roleForm.get('permissionList')?.touched
            "
            >This is a required field.</span
          >
        </div>
        <div class="button-group">
          <button type="submit" class="button button--primary">
            {{ isEdit ? "Update" : "Create" }} Role
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
