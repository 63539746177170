import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { SideFilter } from 'src/app/interface/SideFilter';
import { MonitorApiService } from 'src/app/service/monitor-api.service';
import { Util } from 'src/app/service/utils';

@Component({
  selector: 'app-monitor-analytics-page1',
  templateUrl: './monitor-analytics-page1.component.html',
  styleUrls: ['./monitor-analytics-page1.component.scss'],
})
export class MonitorAnalyticsPage1Component implements OnInit {
  allFilter: any;
  disputes = 0;
  serverDisputes = 0;
  users = 0;
  commits = 0;
  showStatus = false;
  orgConfig: any;
  totalDisputes = 0;
  monthlyActiveUser = 0;
  constructor(
    private store: Store<{ sideBarFilter: SideFilter }>,
    private api: MonitorApiService,
    private location: Location,
    private util: Util,
    private router: Router
  ) {}
  options: AnimationOptions = {
    path: '/assets/lottie/success.json',
    renderer: 'svg',
    loop: true,
    autoplay: true,
  };

  ngOnInit(): void {
    if (this.util.isPermission('isViewMonitor')) {
      this.getDevEnvStatus();
      this.store.select('sideBarFilter').subscribe((resp) => {
        // //console.log('allFilter>>>', resp);
        this.allFilter = resp;
        this.getAllDataForMonitor();
      });
    } else {
      this.location.back();
    }
  }
  getDevEnvStatus(): void {
    this.api.getAllMonitoringStats().subscribe((resp: any) => {
      if (resp.code === 0) {
        this.orgConfig = resp.result;
        this.getAllDisputeCount();
      }
    });
  }
  getAllDisputeCount(): void {
    this.api.getAllDisputeCount().subscribe(
      (resp: any) => {
        this.totalDisputes = resp.result.disputes;
        this.showStatus = true;
        if (this.orgConfig) {
          if (this.totalDisputes < this.orgConfig?.stable) {
            this.options = {
              ...this.options,
              path: '/assets/lottie/success.json',
            };
          } else if (this.totalDisputes < this.orgConfig?.warning) {
            this.options = {
              ...this.options,
              path: '/assets/lottie/warning.json',
            };
          } else {
            this.options = {
              ...this.options,
              path: '/assets/lottie/error.json',
            };
          }
        }
      },
      (error: HttpErrorResponse) => {}
    );
  }
  getAllDataForMonitor(): void {
    this.api.getAllDataForMonitor(this.allFilter).subscribe(
      (resp) => {
        if (resp.code === 0) {
          this.commits = resp.result.commits;
          this.disputes = resp.result.disputes;
          this.users = resp.result.users;
          this.serverDisputes = resp.result.serverScan;
          this.monthlyActiveUser = resp.result.monthlyActiveUser;
        }
      },
      (error) => {}
    );
  }
  onBack(): void {
    this.location.back();
  }
}
