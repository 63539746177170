import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input('count') count = 0;
  @Input('perPage') perPage = 10;
  @Input('activePage') activePage = 1;
  @Output() changePage = new EventEmitter<number>();
  pages: number = 0;
  viewPages: number[] = [];
  constructor() {}
  ngOnInit(): void {}
  ngOnChanges(): void {
    this.viewPages = [];
    this.pages = Math.ceil(this.count / this.perPage);
    if (this.pages > 7) {
      for (let i = 1; i <= 7; i++) {
        this.viewPages.push(i);
      }
    } else {
      for (let i = 1; i <= this.pages; i++) {
        this.viewPages.push(i);
      }
    }
  }

  setActivePage(page: number): void {
    this.activePage = page;
    if (this.activePage === this.viewPages[this.viewPages.length - 1]) {
      this.forwardPaginator();
    } else if (this.activePage === this.viewPages[0]) {
      this.backwardPagenator();
    }
    this.changePage.emit(page);
  }
  forwardPaginator(): void {
    if (this.viewPages[this.viewPages.length - 1] !== this.activePage) {
      this.activePage = this.activePage + 1;
      this.changePage.emit(this.activePage);
    }
    if (this.viewPages[this.viewPages.length - 1] === this.activePage) {
      for (let i = 1; i <= 1; i++) {
        if (this.viewPages[6] + 1 <= this.pages) {
          this.viewPages.push(this.viewPages[6] + 1);
          this.viewPages.splice(0, 1);
          this.viewPages = [...this.viewPages]
          if (!this.viewPages.includes(this.activePage)) {
            this.activePage = this.viewPages[0];
            this.changePage.emit(this.activePage);
          }
        } else {
          return;
        }
      }
    }
  }
  backwardPagenator(): void {
    if (this.viewPages[0] !== this.activePage) {
      this.activePage = this.activePage - 1;
      this.changePage.emit(this.activePage);
      return;
    }
    if (this.activePage === this.viewPages[0]) {
      for (let i = 1; i <= 1; i++) {
        if (this.viewPages[0] - 1 > 0) {
          this.viewPages = [this.viewPages[0] - 1, ...this.viewPages];
          this.viewPages.splice(7, 1);
          if (!this.viewPages.includes(this.activePage)) {
            this.activePage = this.viewPages[this.viewPages.length - 1];
            this.changePage.emit(this.activePage);
          }
        } else {
          return;
        }
      }
    }
  }
}
