import * as RepoAction from './repo.action';

const initialState: any = {
  repoDetail: null,
};

export function repoReducer(
  state = initialState,
  action: RepoAction.RepoAction
) {
  switch (action.type) {
    case RepoAction.SELECTED_REPO:
      return { ...state, repoDetail: action.payload };
    case RepoAction.RESET_SELECTED_REPO:
      return { ...state, repoDetail: null };
    default:
      return state;
  }
}
