import * as SidebarFilterAction from './sidebar-filter.action';
import {SideFilter} from '../interface/SideFilter';
const initialState: SideFilter = {
  start_date: '',
  end_date: '',
  groups: [],
  repos: [],
  tree: undefined,
  cli: false,
  repo: undefined,
  date: undefined,
  commitUserName: undefined
};
export function SideBarFilterReducer(state= initialState, action: SidebarFilterAction.SidebarFilterAction): any {
  switch (action.type){
    case SidebarFilterAction.UPDATE_DATE_RANGE:
      return {
        ...state,
        start_date: action.payload.start_date,
        end_date: action.payload.end_date
      };
    case SidebarFilterAction.UPDATE_REPOS:
      return {
        ...state,
        repos: action.payload.repos
      };
    case SidebarFilterAction.UPDATE_GROUPS:
      return {
        ...state,
        groups: action.payload.groups
      };
    case SidebarFilterAction.RESET_ALL_FILTERS:
      return {
        ...state,
        start_date: action.payload.start_date,
        end_date: action.payload.end_date,
        repos: action.payload.repos,
        groups: action.payload.groups,
      };
      case SidebarFilterAction.RESET_CALENDAR_FILTERS:
      return {
        ...state,
        start_date: action.payload.start_date,
        end_date: action.payload.end_date,
      };
    case SidebarFilterAction.UPDATE_TREE_DATA:
      return {
        ...state,
        tree: action.payload.tree
      }
      case SidebarFilterAction.UPDATE_CLI_DATA:
      return {
        ...state,
        cli: action.payload.cli
      }
      case SidebarFilterAction.UPDATE_ORG_DATE:
      return {
        ...state,
        date: action.payload.date
      }
      case SidebarFilterAction.UPDATE_COMMIT_USER_NAME:
      return {
        ...state,
        commitUserName: action.payload.commitUserName
      }

    default:
      return state;
  }
}
