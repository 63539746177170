import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Util } from 'src/app/service/utils';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {
  page = 1;
  total = 0;
  term: string = '';
  projects: any = [];
  selectedProject: any;
  deleteModalShow = false;
  isProcessing = false;
  groupId: any = '';
  provider: string = '';
  @ViewChild('modalRemoveProject', { static: false })
  modalRemoveProject?: ModalDirective;
  constructor(
    private api: ApiService,
    private toaster: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private util: Util
  ) {}

  ngOnInit(): void {
    if (this.util.isPermission('isViewProject')) {
      this.route.queryParams.subscribe((param) => {
        if (Object.prototype.hasOwnProperty.call(param, 'id')) {
          this.groupId = param['id'];
        } else {
          this.groupId = '';
        }
        if (Object.prototype.hasOwnProperty.call(param, 'provider')) {
          this.provider = param['provider'];
        } else {
          this.provider = '';
        }
        this.getAllRepository();
      });
    } else {
      this.location.back();
    }
  }

  getAllRepository(): void {
    this.api
      .getAllRepository(this.page, this.term, this.provider, this.groupId)
      .subscribe(
        (resp) => {
          this.isProcessing = true;
          if (resp.code === 0) {
            this.projects = resp.result.repositorys;
            this.total = resp.result.total;
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
  }
  search(): void {
    this.page = 1;
    this.getAllRepository();
  }
  onSelectProject(id: string): void {
    this.selectedProject = id;
  }
  onDeleteRequest(): void {
    if (this.selectedProject && this.selectedProject !== '') {
      this.modalRemoveProject?.show();
    } else {
      this.toaster.info('Please select a project');
    }
  }
  // project edit
  onEdit(): void {
    if (!this.util.isPermission('isEditProject')) return;
    if (this.projects.length == 0) {
      this.toaster.error('No Projects Available to select');
      return;
    }
    if (!this.selectedProject) {
      this.toaster.error('Please select an appropriate project from the list');
      return;
    }
    this.router.navigate(['/dashboard/update-project/' + this.selectedProject]);
  }
  // for delete project
  onDelete(): void {
    if (!this.util.isPermission('isDeleteProject')) return;
    if (!this.selectedProject) {
      return;
    }
    this.api.deleteRepository(this.selectedProject).subscribe(
      (resp: any) => {
        if (resp.code === 0) {
          this.modalRemoveProject?.hide();
          this.toaster.success(resp.result);
          this.selectedProject = null;
          this.getAllRepository();
        }
      },
      (error: HttpErrorResponse) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  onChangePage(page: number): void {
    if (this.page === page) {
      return;
    }
    this.page = page;
    this.getAllRepository();
  }
  createProjectRequest(): void {
    this.router.navigate(['/dashboard/create-project'], {
      queryParams: { group_id: this.groupId, provider: this.provider },
    });
  }
}
