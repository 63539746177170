import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  otpEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}
  resendOTP() {
    this.otpEvent.emit('resend');
  }
}
