import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../service/authentication.service';

@Component({
  selector: 'app-shared-header',
  templateUrl: './shared-header.component.html',
  styleUrls: ['./shared-header.component.scss'],
})
export class SharedHeaderComponent implements OnInit {
  constructor(private auth: AuthenticationService) {}

  ngOnInit(): void {}
  onLogout(): void {
    // //console.log('logout');
    this.auth.logout();
  }
}
