<div class="block">
  <div class="block__nav">
    <ul class="nav">
      <!-- <li>
        <a href="javascript:void(0)" routerLinkActive="active">Dashboard</a>
      </li> -->
      <li>
        <a
          href="javascript:void(0)"
          routerLink="/dashboard/monitor/monitor"
          routerLinkActive="active"
          >Code Monitor</a
        >
      </li>
      <li>
        <a
          href="javascript:void(0)"
          routerLink="/dashboard/monitor/analytics"
          routerLinkActive="active"
          >Analytics</a
        >
      </li>
    </ul>
  </div>
  <div class="block__content">
    <div class="monitor">
      <div class="row">
        <div class="col-md-2">
          <h6 (click)="resetFilter()">
            <img
              class="icon me-3"
              src="/assets/images/icon__account-preferences--blue.svg"
              alt=""
            />
            Filters
          </h6>
          <ul class="nav flex-column">
            <li>
              <img
                class="icon me-3"
                src="/assets/images/icon__monitor-date.svg"
                alt=""
              />
              <input
                type="text"
                placeholder="Date Range"
                bsDaterangepicker
                [bsConfig]="{
                  displayMonths: 1,
                  rangeInputFormat: 'DD/MM/YYYY'
                }"
                [(ngModel)]="date"
                (ngModelChange)="onDateChange()"
                placement="bottom"
                [maxDate]="maxDate"
              />
            </li>
            <li class="dropdown" dropdown [insideClick]="true">
              <a
                href="javascript:void(0)"
                dropdownToggle
                (click)="(false)"
                aria-controls="dropdown__monitor--groups"
              >
                <img
                  class="icon me-3"
                  src="/assets/images/icon__monitor-group.svg"
                  alt=""
                />
                Teams
              </a>
              <div
                id="dropdown__monitor--groups"
                *dropdownMenu
                class="dropdown-menu"
                role="menu"
                aria-labelledby="basic-link"
              >
                <ul *ngIf="groups.length === '0'">
                  <span>No records found</span>
                </ul>
                <ul *ngIf="groups.length != '0'">
                  <li *ngFor="let g of groups" (click)="selectGroup(g._id)">
                    <div class="form-checkbox has-label">
                      <input
                        type="checkbox"
                        [checked]="selectedGroup.includes(g._id)"
                      />
                      <span class="icon"></span>
                      <label for="" class="form-label">{{ g.name }}</label>
                    </div>
                  </li>

                  <!-- <li>
                    <div class="form-checkbox has-label">
                      <input type="checkbox" />
                      <span class="icon"></span>
                      <label for="" class="form-label">Group Name 2</label>
                    </div>
                  </li>
                  <li>
                    <div class="form-checkbox has-label">
                      <input type="checkbox" />
                      <span class="icon"></span>
                      <label for="" class="form-label">Group Name 3</label>
                    </div>
                  </li>
                  <li>
                    <div class="form-checkbox has-label">
                      <input type="checkbox" />
                      <span class="icon"></span>
                      <label for="" class="form-label">Group Name 4</label>
                    </div>
                  </li>
                  <li>
                    <div class="form-checkbox has-label">
                      <input type="checkbox" />
                      <span class="icon"></span>
                      <label for="" class="form-label">Group Name 5</label>
                    </div>
                  </li>
                  <li>
                    <div class="form-checkbox has-label">
                      <input type="checkbox" />
                      <span class="icon"></span>
                      <label for="" class="form-label">Group Name 6</label>
                    </div>
                  </li>
                  <li>
                    <div class="form-checkbox has-label">
                      <input type="checkbox" />
                      <span class="icon"></span>
                      <label for="" class="form-label">Group Name 7</label>
                    </div>
                  </li>
                  <li>
                    <div class="form-checkbox has-label">
                      <input type="checkbox" />
                      <span class="icon"></span>
                      <label for="" class="form-label">Group Name 8</label>
                    </div>
                  </li>
                  <li>
                    <div class="form-checkbox has-label">
                      <input type="checkbox" />
                      <span class="icon"></span>
                      <label for="" class="form-label">Group Name 9</label>
                    </div>
                  </li>
                  <li>
                    <div class="form-checkbox has-label">
                      <input type="checkbox" />
                      <span class="icon"></span>
                      <label for="" class="form-label">Group Name 10</label>
                    </div>
                  </li> -->
                </ul>
              </div>
            </li>
            <li class="dropdown" dropdown [insideClick]="true">
              <a
                href="javascript:void(0)"
                dropdownToggle
                (click)="(false)"
                aria-controls="dropdown__monitor--repository"
              >
                <img
                  class="icon me-3"
                  src="/assets/images/icon__monitor-repository.svg"
                  alt=""
                />
                Repository
              </a>
              <div
                id="dropdown__monitor--repository"
                *dropdownMenu
                class="dropdown-menu"
                role="menu"
                aria-labelledby="basic-link"
              >
                <ul>
                  <li *ngFor="let r of repos" (click)="selectRepos(r._id)">
                    <div class="form-checkbox has-label">
                      <input
                        type="checkbox"
                        [checked]="selectedRepo.includes(r._id)"
                      />
                      <span class="icon"></span>
                      <label for="" class="form-label">{{ r.name }}</label>
                    </div>
                  </li>
                  <!-- <li>
                    <div class="form-checkbox has-label">
                      <input type="checkbox" />
                      <span class="icon"></span>
                      <label for="" class="form-label">Repository Name 2</label>
                    </div>
                  </li>
                  <li>
                    <div class="form-checkbox has-label">
                      <input type="checkbox" />
                      <span class="icon"></span>
                      <label for="" class="form-label">Repository Name 2</label>
                    </div>
                  </li> -->
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-md-10">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
