<div class="d-flex align-items-center my-4">
  <h5 class="m-0">User Role</h5>
  <div class="d-flex align-items-center ms-auto">
    <div class="form-search me-4" style="width: 300px">
      <input
        type="text"
        class="form-control"
        placeholder="Search with role name"
        [(ngModel)]="term"
        (ngModelChange)="search()"
      />
      <button type="submit">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 27.18 27.9"
          style="enable-background: new 0 0 27.18 27.9"
          xml:space="preserve"
        >
          <path
            d="M17.76,20.63c-5.5,3.82-12.7,1.62-15.85-3.11C-1.21,12.83-0.48,6.56,3.94,2.76 c4.09-3.51,10.17-3.77,14.64-0.19c4.26,3.4,6.04,10.45,1.52,15.96c0.36,0.37,0.72,0.75,1.08,1.12c1.83,1.88,3.67,3.76,5.49,5.65 c0.87,0.91,0.57,2.22-0.58,2.54c-0.64,0.18-1.14-0.07-1.58-0.52c-1.58-1.64-3.16-3.28-4.76-4.9C19.13,21.8,18.44,21.24,17.76,20.63z M11.32,19.59c4.54,0.07,8.36-3.63,8.44-8.19c0.08-4.58-3.62-8.37-8.26-8.44c-4.67-0.07-8.48,3.61-8.54,8.26 C2.9,15.75,6.65,19.51,11.32,19.59z"
          />
        </svg>
      </button>
    </div>
    <button
      class="button button--open"
      type="button"
      routerLink="/dashboard/manage/roles/add"
    >
      <i class="fa fa-plus me-2" aria-hidden="true"></i>
      Create User Role
    </button>
  </div>
</div>
<div class="table-main mb-4">
  <div class="table-responsive">
    <table class="table table-borderless table-vmiddle m-0">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col" style="width: 60%">Permission</th>
          <th scope="col" class="text-center">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let r of roles">
          <td>{{ r.name }}</td>
          <td>
            <span
              class="button button--secondary button--xsml my-1 me-2"
              *ngFor="let per of r.permissionList"
              >{{ per }}</span
            >
            <!-- <span class="button button--secondary button--xsml my-1 me-2"
              >Permission 2</span
            >
            <span class="button button--secondary button--xsml my-1 me-2"
              >Permission 3</span
            > -->
          </td>
          <td class="text-center">
            <div class="d-flex justify-content-center">
              <a
                class="action action--sml"
                href="javascript:void(0)"
                tooltip="Edit"
                routerLink="/dashboard/manage/roles/{{ r._id }}"
              >
                <img src="/assets/images/icon__edit.svg" alt="" />
              </a>
              <a
                class="action action--sml ms-3"
                href="javascript:void(0)"
                tooltip="Delete"
                (click)="onDeleteRequest(r._id)"
              >
                <img src="/assets/images/icon__delete.svg" alt="" />
              </a>
            </div>
          </td>
        </tr>
        <!-- <tr>
                    <td>John Doe</td>
                    <td>
                        <span class="button button--secondary button--xsml my-1 me-2">Permission 1</span>
                        <span class="button button--secondary button--xsml my-1 me-2">Permission 2</span>
                        <span class="button button--secondary button--xsml my-1 me-2">Permission 3</span>
                    </td>
                    <td class="text-center">
                        <div class="d-flex justify-content-center">
                            <a class="action action--sml" href="javascript:void(0)" tooltip="Edit">
                                <img src="/assets/images/icon__edit.svg" alt="">
                            </a>
                            <a class="action action--sml ms-3" href="javascript:void(0)" tooltip="Delete">
                                <img src="/assets/images/icon__delete.svg" alt="">
                            </a>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>John Doe</td>
                    <td>
                        <span class="button button--secondary button--xsml my-1 me-2">Permission 1</span>
                        <span class="button button--secondary button--xsml my-1 me-2">Permission 2</span>
                        <span class="button button--secondary button--xsml my-1 me-2">Permission 3</span>
                    </td>
                    <td class="text-center">
                        <div class="d-flex justify-content-center">
                            <a class="action action--sml" href="javascript:void(0)" tooltip="Edit">
                                <img src="/assets/images/icon__edit.svg" alt="">
                            </a>
                            <a class="action action--sml ms-3" href="javascript:void(0)" tooltip="Delete">
                                <img src="/assets/images/icon__delete.svg" alt="">
                            </a>
                        </div>
                    </td>
                </tr> -->
      </tbody>
    </table>
  </div>
</div>
<div class="d-flex justify-content-end">
  <app-pagination [count]="total"></app-pagination>
</div>
<div
  bsModal
  #modalRemoveProject="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title color-white">
          <img src="/assets/images/icon__delete.svg" alt="" />
          Remove Project
        </h4>
        <a
          class="modal-close"
          href="javascript:void(0)"
          (click)="modalRemoveProject.hide()"
          aria-label="Close"
        >
          <img src="/assets/images/icon__close.svg" alt="" />
        </a>
      </div>
      <div class="modal-body text-center">
        <div class="mb-4">
          <h4>Are you sure?</h4>
          <p>
            Do you really want to delete these records? <br />This process can't
            be undone.
          </p>
        </div>
        <div class="button-group justify-content-center">
          <button
            type="submit"
            class="button button--primary button--sml"
            (click)="modalRemoveProject.hide()"
            aria-label="Close"
          >
            Cancel
          </button>
          <button
            type="button"
            class="button button--primary button--sml"
            (click)="onDelete()"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
