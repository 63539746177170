// Import the functions you need from the SDKs you need
import { getAuth } from "@firebase/auth";
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration


// export const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID
// };


export const firebaseConfig = {
apiKey: "AIzaSyBu9NTpSvWu49ln0m38VxYSpCfFxS3OkBU",
authDomain: "codelock-345708.firebaseapp.com",
projectId: "codelock-345708",
storageBucket: "codelock-345708.appspot.com",
messagingSenderId: "1045079167456",
appId: "1:1045079167456:web:713e256b650776fc41db85",
measurementId: "G-195B9HR5E5"
}


// Initialize Firebase
export const app = initializeApp(firebaseConfig ? firebaseConfig : {});

const auth = getAuth(app);

export { auth };