<div class="d-flex align-items-center my-4">
  <h5 class="m-0">API Keys</h5>
</div>
<div class="row mb-4">
  <form [formGroup]="apiSecretKeyForm">
    <div class="col-md-6">
      <label for="" class="form-label">API Key</label>
      <input
        type="text"
        class="form-control"
        formControlName="apiKey"
        readonly
      />
    </div>
    <div class="col-md-6">
      <label for="" class="form-label">Secret Access Key</label>
      <div class="form-action form-action--double">
        <input
          #secrectAccessKeyInput
          type="password"
          id="secretAccessKey"
          class="form-control"
          [value]="apiSecretKeyForm.get('secretAccessKey')?.value"
          readonly
        />
        <ul class="nav">
          <li>
            <!-- Show Key -->
            <a
              href="javascript:void(0)"
              tooltip="Show Key"
              (click)="getSecretKeyAccess()"
            >
              <i class="fa fa-eye" aria-hidden="true"></i>
            </a>
            <!-- Show Key -->

            <!-- Hide Key -->
            <!-- <a href="javascript:void(0)" tooltip="Hide Key" >
                        <i class="fa fa-eye-slash" aria-hidden="true"></i>
                    </a> -->
            <!-- Hide Key -->
          </li>
          <li>
            <div *ngIf="this.show === true">
              <a
                href="javascript:void(0)"
                tooltip="Copy Key"
                (click)="onCopy()"
              >
                <!-- <i class="fa fa-eye-slash" aria-hidden="true"></i> -->
                <img src="/assets/images/icon__copy.svg" alt="" />
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </form>
</div>
