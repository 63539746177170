import { Action } from '@ngrx/store';
import { User } from '../interface/user';

export const UPDATE_ALL = 'UPDATE_ALL';
export const UPDATE_TWO_STEP = 'UPDATE_TWO_STEP';
export const UPDATE_NAME = 'UPDATE_NAME';
export const UPDATE_SUBSCRIPTION_STATUS = 'UPDATE_SUBSCRIPTION_STATUS';

export class UpdateAll implements Action {
  readonly type = UPDATE_ALL;
  constructor(public payload: User | undefined) {}
}
// Compare this snippet from src/app/store/user.action.ts:

export class UpdateTwoStep implements Action {
  readonly type = UPDATE_TWO_STEP;
  constructor(
    public payload: {
      two_step_on: boolean;
    }
  ) {}
}

export class UpdateName implements Action {
  readonly type = UPDATE_NAME;
  constructor(
    public payload: {
      first_name: string;
      last_name: string;
    }
  ) {}
}

export class UpdateSubscriptionStatus implements Action {
  readonly type = UPDATE_SUBSCRIPTION_STATUS;
  constructor(
    public payload: {
      hasSubscription: boolean;
      expired: boolean;
      paymentFailure: boolean;
      subscriptionType: string;
    }
  ) {}
}

export type UserAction =
  | UpdateAll
  | UpdateTwoStep
  | UpdateName
  | UpdateSubscriptionStatus;
