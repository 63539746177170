<div class="site__authentication site__signin site__questionnaire">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-5">
        <form
          autocomplete="off"
          class="mb-5"
          [formGroup]="questionaryForm"
          (submit)="onSubmit()"
        >
          <div class="logo">
            <img src="/assets/images/logo__icon.svg" alt="" />
          </div>
          <div class="mb-4">
            <div class="row">
              <div class="col-md-12 mb-4">
                <div class="position-relative">
                  <ng-select
                    [items]="[
                      { label: 'Windows', code: 'Windows' },
                      { label: 'Linux', code: 'Linux' }
                    ]"
                    bindLabel="label"
                    bindValue="code"
                    placeholder="Select OS Used In Server"
                    formControlName="os"
                  ></ng-select>
                </div>
                <span
                  class="form-text form-error"
                  *ngIf="
                    questionaryForm.get('os')?.getError('required') &&
                    questionaryForm.get('os')?.touched
                  "
                  >This is a required field.</span
                >
              </div>
              <div class="col-md-12 mb-4">
                <div class="position-relative">
                  <ng-select
                    [items]="[
                      { label: 'Github', code: 'Github' },
                      { label: 'Bitbucket', code: 'Bitbucket' },
                      { label: 'Gitlab', code: 'Gitlab' }
                    ]"
                    bindLabel="label"
                    bindValue="code"
                    placeholder="Select Repository Used"
                    formControlName="code_repo_use"
                    [multiple]="true"
                  ></ng-select>
                </div>
                <span
                  class="form-text form-error"
                  *ngIf="
                    questionaryForm
                      .get('code_repo_use')
                      ?.getError('required') &&
                    questionaryForm.get('code_repo_use')?.touched
                  "
                  >This is a required field.</span
                >
              </div>
              <div class="col-md-12 mb-4">
                <div class="position-relative">
                  <ng-select
                    [items]="[
                      { label: 'Yes', code: true },
                      { label: 'No', code: false }
                    ]"
                    bindLabel="label"
                    bindValue="code"
                    placeholder="Is your environment connected to the internet, or will it be an airgapped on-premises deployment?"
                    formControlName="is_airgapped"
                  ></ng-select>
                </div>
                <span
                  class="form-text form-error"
                  *ngIf="
                    questionaryForm.get('is_airgapped')?.getError('required') &&
                    questionaryForm.get('is_airgapped')?.touched
                  "
                  >This is a required field.</span
                >
                <!-- <span class="form-text form-error"
                    >This Company is already registered</span
                  > -->
              </div>
              <div class="col-md-12 mb-4">
                <div class="position-relative">
                  <ng-select
                    [items]="[
                      { label: 'Yes', code: true },
                      { label: 'No', code: false }
                    ]"
                    bindLabel="label"
                    bindValue="code"
                    placeholder="Corporate proxy and/or VPN"
                    formControlName="is_corporate_proxy"
                  ></ng-select>
                </div>

                <span
                  class="form-text form-error"
                  *ngIf="
                    questionaryForm
                      .get('is_corporate_proxy')
                      ?.getError('required') &&
                    questionaryForm.get('is_corporate_proxy')?.touched
                  "
                  >This is a required field.</span
                >
              </div>
              <div class="col-md-12 mb-4">
                <div class="position-relative">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="No. of Developers"
                    formControlName="total_dev"
                  />
                  <span class="icon">
                    <img src="/assets/images/icon__country.svg" alt="" />
                  </span>
                </div>
                <span
                  class="form-text form-error"
                  *ngIf="
                    questionaryForm.get('total_dev')?.getError('required') &&
                    questionaryForm.get('total_dev')?.touched
                  "
                  >This is a required field.</span
                >
                <span
                  class="form-text form-error"
                  *ngIf="
                    questionaryForm.get('total_dev')?.getError('min') &&
                    questionaryForm.get('total_dev')?.touched
                  "
                  >Positive number only.</span
                >
                <span
                  class="form-text form-error"
                  *ngIf="
                    questionaryForm.get('total_dev')?.getError('pattern') &&
                    questionaryForm.get('total_dev')?.touched
                  "
                  >Numeric number only.</span
                >
              </div>
              <div class="col-md-12 mb-4">
                <div class="position-relative">
                  <textarea
                    class="form-textarea form-control"
                    placeholder="Any suggestions"
                    formControlName="additional_info"
                  ></textarea>
                  <!--                  <span class="icon">-->
                  <!--                      <img src="/assets/images/icon__phone.svg" alt="" />-->
                  <!--                    </span>-->
                </div>
              </div>
            </div>
          </div>

          <div class="button-group">
            <button type="submit" class="button button--primary w-100">
              Save
            </button>
          </div>
        </form>
        <!--        <p class="text-center m-0">-->
        <!--          Already have an account?-->
        <!--          <a href="javascript:void(0)" routerLink="/auth/login">Sign In</a>-->
        <!--        </p>-->
      </div>
    </div>
  </div>
</div>
