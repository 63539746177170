<div
  bsModal
  #faceRecognizeModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title color-white">
          <img src="/assets/images/icon__delete.svg" alt="" />
          Add Face
        </h4>
        <a class="modal-close" href="javascript:void(0)" aria-label="Close">
          <img src="/assets/images/icon__close.svg" alt="" />
        </a>
      </div>
      <div class="modal-body text-center">
        <video
          [class.show]="!isCaptured"
          [class.d-none]="isCaptured"
          #video
          id="video"
          [width]="WIDTH"
          [height]="HEIGHT"
          autoplay
        ></video>
        <canvas
          [class.show]="isCaptured"
          [class.d-none]="!isCaptured"
          #canvas
          id="canvas"
          [width]="WIDTH"
          [height]="HEIGHT"
        ></canvas>
        <div class="scan">
          <span class="corner corner__tl"></span>
          <span class="corner corner__tr"></span>
          <span class="corner corner__bl"></span>
          <span class="corner corner__br"></span>
        </div>
      </div>
    </div>
  </div>
</div>
