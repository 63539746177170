import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/interface/user';
import { ApiService } from 'src/app/service/api.service';
import { UpdateSubscriptionStatus } from 'src/app/store/user.action';
import { Location } from '@angular/common';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HttpErrorResponse } from '@angular/common/http';
import { Util } from 'src/app/service/utils';
@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
})
export class GroupsComponent implements OnInit {
  term = '';
  page = 1;
  total = 0;
  groups: any = [];
  id: any;
  editId: any;
  path: any;
  organization: any;
  isProcessing = false;
  isGroupComming = false;
  showBack = false;
  provider: string = 'GitHub';
  isEdit: boolean = false;

  @ViewChild('modalCreateGroup', { static: false })
  modalCreateGroup?: ModalDirective;
  groupAddUpdateForm = new UntypedFormGroup({
    name: new UntypedFormControl(null, Validators.required),
  });
  constructor(
    private api: ApiService,
    private location: Location,
    private store: Store<{ userData: User }>,
    private toaster: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private util: Util
  ) {}

  ngOnInit(): void {
    if (this.util.isPermission('isViewGroup')) {
      this.removeItemsFromLS();
      this.route.queryParams.subscribe((params: any) => {
        this.provider = params.provider;
        this.getGroupData();
      });
      this.route.paramMap.subscribe((param) => {
        // tslint:disable-next-line:max-line-length
        this.id = param.get('id');
        if (this.id) {
          this.showBack = true;
        } else {
          this.showBack = false;
        }
        this.getGroupData();
      });
      this.route.queryParams.subscribe((params: any) => {
        const id = params.id;
        const state = params.state;
        if (id && state && state === 'succeeded') {
          this.toaster.success('Payment Subscription Successful');
          setTimeout(() => {
            this.store.dispatch(
              new UpdateSubscriptionStatus({
                hasSubscription: true,
                paymentFailure: false,
                expired: false,
                subscriptionType: 'groupcomponent'
              })
            );
          }, 5000);
        }
      });
      this.getOrganization();
    }
  }
  onBack(): void {
    this.location?.back();
  }
  getOrganization(): void {
    this.isProcessing = false;
    this.api.getOrganization().subscribe(
      (resp) => {
        this.isProcessing = true;
        if (resp.code === 0) {
          this.organization = resp.result;
        } else {
          if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
            this.toaster.error(resp.result);
          } else {
            this.toaster.error('Internal Server Error');
          }
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  getAllGroups(): void {
    this.isProcessing = false;
    this.api.getAllProjects(this.provider, this.term, this.page).subscribe(
      (resp) => {
        this.isProcessing = true;
        if (resp.code === 0) {
          this.groups = resp.result.groups;
          this.total = resp.result.total;
          this.isGroupComming = true;
        } else {
          this.toaster.error(resp.result);
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  getAllSubGroups(): void {
    this.isGroupComming = false;
    // this.api.getAllSubProjects(this.term, this.page, this.id).subscribe(
    //   (resp) => {
    //     if (resp.code === 0) {
    //       this.groups = resp.result.groups;
    //       this.total = resp.result.total;
    //       this.isGroupComming = true;
    //     } else {
    //       this.toaster.error('Internal Server Error');
    //     }
    //   },
    //   (error) => {
    //     this.toaster.error('Something Went Wrong');
    //   }
    // );
  }
  addGroup(): void {
    if (this.util.isPermission('isAddGroup')) {
      this.isEdit = false;
      this.modalCreateGroup?.show();
    }
  }
  onEdit(data: any): void {
    if (this.util.isPermission('isEditGroup')) {
      this.editId = data._id;
      this.isEdit = true;
      this.modalCreateGroup?.show();
      this.groupAddUpdateForm.patchValue({ name: data.name });
    }
    // this.modalService.open(this.modalCreateGroup);
  }
  onChangePage(page: number): void {
    if (this.page === page) {
      return;
    }
    this.page = page;
    this.getGroupData();
  }
  searchTerm(): void {
    // //console.log('test');
    this.getGroupData();
  }
  searchTermChange(): void {
    if (this.term === '') {
      this.getGroupData();
    }
  }
  getGroupData(): void {
    if (this.id && this.id !== '') {
      this.getAllSubGroups();
    } else {
      this.getAllGroups();
    }
  }
  onProjectClick(index: number): void {
    const group: any = this.groups[index];
    if (group.repository.length > 0 || this.id) {
      this.router.navigate(['/dashboard/repository-list/' + group._id]);
    } else {
      this.router.navigate(['/dashboard/groups/' + group._id]);
    }
  }
  onCloseModal(updated: any): void {
    this.editId = undefined;
    if (updated) {
      this.getGroupData();
    }
  }
  onCreateRepository(): void {
    this.router.navigate(['/dashboard/create-repository/'], {
      queryParams: { group_id: this.id },
    });
  }
  goToPermission(id: any): void {
    this.router.navigate([`/dashboard/permission/${id}/Group`]);
  }
  private removeItemsFromLS(): void {
    localStorage.removeItem('developer');
    localStorage.removeItem('projects');
    localStorage.removeItem('checkout_id');
  }
  submitGroup(): void {
    if (this.groupAddUpdateForm.valid) {
      let data = this.groupAddUpdateForm.getRawValue();

      if (this.isEdit) {
        this.api.updateGroup(data, this.editId).subscribe(
          (resp: any) => {
            if (resp.code === 0) {
              this.toaster.success(resp.message);
              this.getAllGroups();
              this.modalCreateGroup?.hide();
              this.groupAddUpdateForm.reset();
            } else {
              this.toaster.error(resp.message);
            }
          },
          (error: HttpErrorResponse) => {}
        );
      } else {
        data.isSubGroup = false;
        data.provider = this.provider;
        this.api.createGroup(data).subscribe(
          (resp: any) => {
            if (resp.code === 0) {
              this.toaster.success(resp.message);
              this.modalCreateGroup?.hide();
              this.groupAddUpdateForm.reset();
              this.getAllGroups();
            } else {
              this.modalCreateGroup?.hide();
              this.toaster.error(resp.message);
            }
          },
          (error: HttpErrorResponse) => {}
        );
      }
    } else {
      this.groupAddUpdateForm.markAllAsTouched();
    }
  }
}
