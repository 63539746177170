import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanActivateChild,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserState } from '../interface/user';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { Util } from './utils';
import { subtier } from '../constants';
// i know this is confusing, but we switched to only two real plans (starter and premium) and before there were 3.
// Because I have been working on this all day, i do not feel like actually changing the code to reflect this. So, I will just leave it as is.
// if someone (or me) sees this in the future, and wants to actually make it make sense, change the routing page to reflect only two real plans. 
const subscriptions = {
  "free": subtier.FREE,
  "starter": subtier.STARTER,
  "starter-saas-plan": subtier.COMPLIANCE,
  "premium-saas-plan": subtier.COMPLIANCE_PREMIUM,
  "enterprise-saas-plan": subtier.ULTIMATE,
  "ultimate-saas-plan": subtier.ULTIMATE,
} as const;
@Injectable({
  providedIn: 'root',
})
export class SubscriptionGuard implements CanActivate, CanActivateChild {
  userData: UserState | any;
  constructor(
    private store: Store<{ userData: UserState }>,
    private router: Router,
    private location: Location,
    private toaster: ToastrService,
    //delete 
    private util: Util

  ) {
    this.store.select('userData').subscribe((userData) => {
      this.userData = userData;
    });
  }
  // checksubstatus in authentication.service is where subscription status is checked, sent from checkForSubscription in payment.ts (backend)
  // checksubstatus will return the subscription type IF the user has a subscription and it is not EXPIRED (sub_end_date).
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.userData.hasSubscription && !this.userData.expired) {
      const userTier = subscriptions[this.userData.subscriptionType as keyof typeof subscriptions];
      const requiredTier = route.data['requiredTier'] as number;
      // console.log(userTier, requiredTier)
      if (userTier >= requiredTier || !requiredTier) {
        return true; // User's subscription tier meets or exceeds the required tier
      } else {
        // this.toaster.info('Access Denied', 'Your subscription level does not grant access to this area.');
        return false;
      }
    } else {
      // Handle no subscription or expired subscription
      if (!this.userData.hasSubscription) {
        this.toaster.info("You haven't Subscribed to any Plan", 'Please Subscribe');
        this.router.navigate(['/dashboard/billing']);
      } else if (this.userData.expired) {
        this.toaster.info('Subscription Expired', 'Please Renew Your Plan');
        this.router.navigate(['/dashboard/billing']);
      }
      return false;
    }
  }
  
  
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.userData.hasSubscription && this.userData.expired) {
      this.toaster.info('Subscription Expired', 'Please Renew Your Plan');
      this.router.navigate(['/dashboard/billing']);
    } else if (!this.userData.hasSubscription) {
      this.toaster.info(
        "You haven't Subscribed to any Plan",
        'Please Subscribe'
      );
      this.router.navigate(['/dashboard/cart']);
    }
    return this.userData.hasSubscription && !this.userData.expired;
  }
}
