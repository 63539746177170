<div class="site-header">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 d-flex align-items-center">
        <a
          href="javascript:void(0)"
          class="site-header__logo"
          routerLink="/home"
        >
          <img src="/assets/images/logo.svg" alt="" />
        </a>
        <div class="site-header__aside ms-auto">
          <div class="button-group">
            <a
              href="javascript:void(0)"
              class="button button--secondary button--sml"
              (click)="modalReportIssue.show()"
            >
              <img
                src="/assets/images/icon__report.svg"
                alt=""
                class="icon icon--left"
              />
              Report Issue
            </a>
            <!-- <a
              href="javascript:void(0)"
              class="button button--secondary button--sml"
            >
              <img
                src="/assets/images/icon__invite.svg"
                alt=""
                class="icon icon--left"
              />
              Invite User
            </a> -->
            <!-- <a
              href="javascript:void(0)"
              class="button button--secondary button--sml"
            >
              All Projects
              <img
                src="/assets/images/icon__arrow--down.svg"
                alt=""
                class="icon icon--right icon--arrow"
              />
            </a> -->
            <div class="ms-4 position-relative" dropdown [insideClick]="true">
              <a
                href="javascript:void(0)"
                id="dropdown__account"
                dropdownToggle
                class="dropdown-toggle"
                aria-controls="dropdown__account"
              >
                <span class="pic" id="headerpfp">
                  <object data="/assets/images/icon__arrow-down.svg" type="image/svg">
                  <img 
                  [src]="this.currentUser?.user.profile_pic_url"
                      *ngIf="this.currentUser?.user.profile_pic_url"
                      class="circular-image" />
                  </object>
                </span>
                <span class="name">{{ data.first_name }}</span>
                <span class="icon">
                  <img src="/assets/images/icon__arrow--down.svg" alt="" />
                </span>
              </a>
              <ul
                id="dropdown__account"
                *dropdownMenu
                class="dropdown-menu"
                role="menu"
              >
                <li class="dropdown__code">
                  Company Code : {{ data.accountId }}
                </li>
                <li class="dropdown__name">
                  <span class="pic">
                    <img
                      [src]="this.currentUser?.user.profile_pic_url"
                      *ngIf="this.currentUser?.user.profile_pic_url"
                      class="circular-image"
                    /> </span
                  >{{ data.first_name }}
                </li>
                <li role="menuitem" class="mt-3">
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    routerLink="/profile"
                    >Profile Dashboard</a
                  >
                </li>
                <li role="menuitem" class="mt-2">
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    routerLink="/auth/change-password"
                    >Reset Password</a
                  >
                </li>
                <li role="menuitem" class="mt-2 mb-3">
                  <a
                    class="dropdown-item"
                    href="javascript:void(0)"
                    (click)="onLogout()"
                    >Sign Out</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  bsModal
  #modalReportIssue="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <app-report-issue (closeModal)="modalReportIssue.hide()"></app-report-issue>
</div>
