<div class="mb-5">
    <div class="mb-4 d-flex align-items-start">
      <a class="back me-3" href="javascript:void(0)" (click)="onBack()">
        <img src="/assets/images/icon__arrow--left.svg" alt="" />
      </a>
      <div>
        <h4 class="mb-1">{{ resource }} Alerts </h4>
        <p class="form-text m-0">
          Change alert settings for
          <span class="badge ms-1">{{ resourceData?.name }}</span>
        </p>
      </div>
    </div>
    <!-- (click)="onInvite()" -->

  <div class="mb-5">
    <div class="mb-4">
      <h4 class="mb-0 d-flex align-items-center">
        Members <span class="badge ms-2">{{ permissionList.length }}</span>
      </h4>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-search">
          <button type="submit">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 27.18 27.9"
              style="enable-background: new 0 0 27.18 27.9"
              xml:space="preserve"
            >
              <path
                d="M17.76,20.63c-5.5,3.82-12.7,1.62-15.85-3.11C-1.21,12.83-0.48,6.56,3.94,2.76 c4.09-3.51,10.17-3.77,14.64-0.19c4.26,3.4,6.04,10.45,1.52,15.96c0.36,0.37,0.72,0.75,1.08,1.12c1.83,1.88,3.67,3.76,5.49,5.65 c0.87,0.91,0.57,2.22-0.58,2.54c-0.64,0.18-1.14-0.07-1.58-0.52c-1.58-1.64-3.16-3.28-4.76-4.9C19.13,21.8,18.44,21.24,17.76,20.63z M11.32,19.59c4.54,0.07,8.36-3.63,8.44-8.19c0.08-4.58-3.62-8.37-8.26-8.44c-4.67-0.07-8.48,3.61-8.54,8.26 C2.9,15.75,6.65,19.51,11.32,19.59z"
              />
            </svg>
          </button>
          <input
            type="search"
            class="form-control"
            placeholder="Type to search members"
            [(ngModel)]="term"
            (ngModelChange)="onChangeSearch()"
            (keyup.enter)="onSearch()"
          />
        </div>
      </div>
      <div class="col-md-6">
        <ng-select
          placeholder="All"
          [searchable]="false"
          [(ngModel)]="permission"
          (ngModelChange)="onSearch()"
        >
          <ng-option value="All">All</ng-option>
          <ng-option value="Owner">Owner</ng-option>
          <ng-option value="Administrator">Administrator</ng-option>
          <ng-option value="Developer">Developer</ng-option>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="table-main">
    <div class="table-responsive">
      <table class="table table-vmiddle m-0">
        <thead>
          <tr>
            <th scope="col">User Name</th>
            <th scope="col">Source</th>
            <th scope="col">Access Granted</th>
            <th scope="col">Access Expires</th>
            <th scope="col" style="min-width: 175px">Max Role</th>
            <th scope="col">Expiration</th>
            <th scope="col">Receive Alerts</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let person of permissionList; index as i">
            <td>{{ person.name || person.email }}</td>
            <td>{{ person.type }}</td>
            <td>
              {{ person.invite_date | dateAgo }}
              <span class="d-block color-primary" *ngIf="person.invited_by"
                >by {{ person.invited_by }}</span
              >
            </td>
            <td *ngIf="!person.expiry_date">No expiration Set</td>
            <td *ngIf="person.expiry_date">
              {{ person.expiry_date | inDays }}
            </td>
            <td>
              <ng-select
                [(ngModel)]="person.role"
                [searchable]="false"
                (ngModelChange)="onUpdate(i)"
                [disabled]="
                  person.role === 'Owner' ||
                  person.role === 'root' ||
                  person.user === currentUserPermission?.user ||
                  currentUserPermission?.role === 'Developer'
                "
              >
                <ng-option value="Owner">Owner</ng-option>
                <ng-option value="root">Root</ng-option>
                <ng-option value="Administrator">Administrator</ng-option>
                <ng-option value="Developer">Developer</ng-option>
              </ng-select>
            </td>
  
            <td>
              <div class="form-date" style="width: 175px">
                <input
                  type="text"
                  [minDate]="minDate"
                  readonly
                  (ngModelChange)="onUpdate(i, $event)"
                  class="form-control"
                  placeholder="Expiration Date"
                  [(ngModel)]="person.expiry_date"
                  bsDatepicker
                  [disabled]="
                    (person.user === currentUserPermission?.user ||
                      currentUserPermission?.role === 'Developer') &&
                    person.role !== 'Owner'
                  "
                  [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }"
                />
              </div>
            </td>
            <td>
                <div class="col-md-8 text-right">
                    <div class="form-checkbox">
                    <input type="checkbox" [checked]="person.receiveAlerts" (change)="onCheckboxChange($event, person)" />
                    <span class="icon"></span>
                    </div>
                  </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>



  
  