export const subtier = {
    FREE: 0,
    STARTER: 1,
    COMPLIANCE: 2,
    COMPLIANCE_PREMIUM: 3,
    ULTIMATE: 4
  };
  
export const starter_tier_permissions = [
  "Add User", "Edit User", "View User Logs", "View ALL Logs", "Upgrade Plan", "View Billing", "View Compliance", "View Compliance Attachments", "View User",
]

 
  