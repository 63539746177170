import { Component, OnInit } from '@angular/core';
import { RepoServices } from 'src/app/service/repo.service';
import { Store } from '@ngrx/store';
import { selectedRepo } from '../../../store/repo.action';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sbom',
  templateUrl: './sbom.component.html',
  styleUrls: ['./sbom.component.scss'],
})
export class SbomComponent implements OnInit {
  project: any;
  constructor(
    private api: RepoServices,
    private store: Store<{ repoDetail: any }>,
    private router: Router
  ) {}

  sourceOptions = ['Github', 'Gitlab', 'Bitbucket'];
  selectedSource: string = 'Github';
  access_token: any = '';
  bitBucketUserName: string = '';
  private: string = 'private';
  public: string = 'public';
  resources: any = [];
  fetchProjects: any = [];
  selectedRepo: any = {};

  accessTokenHandler(event: any) {
    this.access_token = event.target.value;
  }
  bitBucketUserNameHandler(event: any) {
    this.bitBucketUserName = event.target.value;
  }
  bitBucketAppPasswordHandler(event: any) {
    this.access_token = event.target.value;
  }

  repoProjectsHandler() {
    localStorage.setItem('repo_access_token', this.access_token);
    this.fetchProjects = [];
    if (this.selectedSource === 'Github') {
      // this.api.fetchGithubProjects(this.access_token).subscribe(
      //   (resp: any) => {
      //     resp.forEach((element: any) => {
      //       let obj = {
      //         ...element,
      //         repoName: element.name,
      //       };
      //       this.fetchProjects = [...this.fetchProjects, obj];
      //       localStorage.setItem(
      //         'fetchProjects',
      //         JSON.stringify(this.fetchProjects)
      //       );
      //     });
      //   },
      //   (error) => {
      //     if (error.status === 401) {
      //       alert('This is not a valid token for Github');
      //     } else {
      //       alert(error.error.message);
      //     }
      //   }
      // );
    } else if (this.selectedSource === 'Gitlab') {
      // this.api.fetchGitlabProjects(this.access_token, 'private').subscribe(
      //   (resp: any) => {
      //     resp.forEach((element: any) => {
      //       let obj = {
      //         owner: {
      //           login: element.owner.username,
      //         },
      //         repoName: element.path,
      //         userName: element.owner.username,
      //         url: element.http_url_to_repo,
      //         createdAt: element.created_at,
      //         selectedSource: this.selectedSource,
      //         projectId: element.id,
      //         defalutBranch: element.default_branch,
      //       };
      //       this.fetchProjects = [...this.fetchProjects, obj];
      //       localStorage.setItem(
      //         'fetchProjects',
      //         JSON.stringify(this.fetchProjects)
      //       );
      //     });
      //   },
      //   (error) => {
      //     if (error.status === 401) {
      //       alert('This is not a valid token for Gitlab');
      //     } else {
      //       alert(error.error.message);
      //     }
      //   }
      // );
      // this.api.fetchGitlabProjects(this.access_token, 'public').subscribe(
      //   (resp: any) => {
      //     resp.forEach((element: any) => {
      //       let obj = {
      //         owner: {
      //           login: element.owner.username,
      //         },
      //         repoName: element.path,
      //         userName: element.owner.username,
      //         url: element.http_url_to_repo,
      //         createdAt: element.created_at,
      //         selectedSource: this.selectedSource,
      //         projectId: element.id,
      //         defalutBranch: element.default_branch,
      //       };
      //       this.fetchProjects = [...this.fetchProjects, obj];
      //     });
      //   },
      //   (error) => {
      //     //console.log(error);
      //   }
      // );
    } else if (this.selectedSource === 'Bitbucket') {
      // this.api
      //   .fetchBitBucketProjects(this.bitBucketUserName, this.access_token)
      //   .subscribe(
      //     (resp: any) => {
      //       resp.values.forEach((element: any) => {
      //         let obj = {
      //           ...element,
      //           repoName: element.name,
      //           owner: {
      //             login: element.workspace.slug,
      //           },
      //           userName: element.workspace.slug,
      //           url: element.links.clone[0].href,
      //           createdAt: element.created_on,
      //           selectedSource: this.selectedSource,
      //           defalutBranch: element.mainbranch.name,
      //         };
      //         this.fetchProjects = [...this.fetchProjects, obj];
      //         localStorage.setItem(
      //           'fetchProjects',
      //           JSON.stringify(this.fetchProjects)
      //         );
      //       });
      //     },
      //     (error) => {
      //       if (error.status === 401) {
      //         alert('Please provide valid Bitbucket credentials');
      //       } else {
      //         alert(error.error.message);
      //       }
      //     }
      //   );
    }
  }

  generateSBOMHandler(userName: string, repoName: string) {
    // this.api.generateSBOM(this.access_token, userName, repoName).subscribe(
    //   (resp) => {
    //     //console.log(resp);
    //   },
    //   (error) => {
    //     //console.log(error);
    //   }
    // );
  }
  navigateHandler() {
    this.router.navigate([`/dashboard/sbomdocument`]);
  }

  repoSaveHandler(repo: any) {
    this.store.dispatch(
      new selectedRepo({ ...repo, selectedSource: this.selectedSource })
    );
    this.router.navigate([`/dashboard/repo`]);
    // this.router.navigate([`/dashboard/repo`], {
    //   queryParams: { source: this.selectedSource },
    // });
  }

  onCheckChange(event: any) {
    if (this.resources.includes(event.target.value)) {
      this.resources = this.resources.filter(
        (value: any) => value !== event.target.value
      );
    } else {
      this.resources.push(event.target.value);
    }
  }

  ngOnInit(): void {
    let projects: any = localStorage.getItem('fetchProjects');

    if (projects) {
      this.fetchProjects = JSON.parse(projects);
    }
  }
}
