import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RepoServices } from 'src/app/service/repo.service';
import { selectedRepo } from '../../../store/repo.action';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-selectrepo',
  templateUrl: './selectrepo.component.html',
  styleUrls: ['./selectrepo.component.scss'],
})
export class SelectrepoComponent implements OnInit {
  fetchProjects: any = [];
  selectRepo: any = null;
  selectRepoLoading: Boolean = false;
  selectedFormat: any = '';
  branches: any = [];
  showBranchModal: boolean = false;
  selectedBranch: string = '';
  realBranch: string = '';
  constructor(
    private api: RepoServices,
    private router: Router,
    private toaster: ToastrService,
    private store: Store<{ repoDetail: any }>
  ) {}

  saveFilesHandler() {
    this.selectRepoLoading = true;
    this.toaster.success('System is reading repository files');
    if (this.selectRepo.source === 'github') {
      this.api
        .httpSaveGithubRepoDetail(
          this.selectRepo.access_token,
          this.selectRepo.userName,
          this.selectRepo.repoName,
          this.selectRepo.source,
          this.selectedBranch,
          this.selectRepo.defalutBranch,
        )
        .subscribe(
          (resp: any) => {
            this.selectRepoLoading = false;

            this.store.dispatch(new selectedRepo(this.selectRepo));
            this.router.navigate([`/dashboard/repo`]);
          },
          (error) => {
            this.selectRepoLoading = false;
            this.toaster.error(
              'Selected repository does not have package-lock.json file'
            );
          }
        );
    } else if (this.selectRepo.source === 'gitlab') {
      let obj = {
        access_token: this.selectRepo.access_token,
        projectID: this.selectRepo.projectId,
        branchName: this.selectRepo.defalutBranch,
        source: this.selectRepo.source,
        repoName: this.selectRepo.repoName,
        userName: this.selectRepo.userName,
        url: this.selectRepo.url,
        gitlab_name: this.selectRepo.gitlab_name,
      };
      //console.log(obj, 'obj')
      this.api.httpSaveGitlabRepoDetail(obj).subscribe(
        (resp: any) => {
          this.selectRepoLoading = false;
          this.store.dispatch(new selectedRepo({ ...obj, ...this.selectRepo }));
          this.router.navigate([`/dashboard/repo`]);
        },
        (error) => {
          this.selectRepoLoading = false;
          this.toaster.error(
            'Selected repository does not have package-lock.json file'
          );
        }
      );
    } else if (this.selectRepo.source === 'bitbucket') {
      let obj = {
        access_token: this.selectRepo.access_token,
        projectID: this.selectRepo.projectId,
        branchName: this.selectRepo.defalutBranch,
        source: this.selectRepo.source,
        repoName: this.selectRepo.repoName,
        workspaceName: this.selectRepo.workspaceName,
        userName: this.selectRepo.userName,
        url: this.selectRepo.url,
      };

      this.api.httpSaveBitbuckrtRepoDetail(obj).subscribe(
        (resp: any) => {
          this.selectRepoLoading = false;
          this.store.dispatch(new selectedRepo({ ...obj, ...this.selectRepo }));
          this.router.navigate([`/dashboard/repo`]);
        },
        (error) => {
          this.selectRepoLoading = false;
          this.toaster.error(
            'Selected repository does not have package-lock.json file'
          );
        }
      );
      
    }else if (this.selectRepo.source === 'azure') {
      this.api.httpSaveAzureRepoDetail(this.selectRepo).subscribe(
        (resp: any) => {
          this.selectRepoLoading = false;

          this.store.dispatch(new selectedRepo(this.selectRepo));
          this.router.navigate([`/dashboard/repo`]);
        },
        (error) => {
          this.selectRepoLoading = false;
          this.toaster.error(
            'Selected repository does not have package-lock.json file'
          );
        }
      );
    }
  }

  fetchPreviousProjects() {
    this.selectRepoLoading = true;
    this.api.httpAllRepos().subscribe(
      (resp: any) => {
        this.selectRepoLoading = false;
        if (resp.result.length) {
          const checkRepoExist = resp.result.find(
            (repo: any) => repo.repoName === this.selectRepo.repoName
          );
          if (checkRepoExist) {
            this.toaster.warning(
              'You have already generated an SBOM for selected repository'
            );
          } else {
            this.saveFilesHandler();
          }
        } else {
          this.saveFilesHandler();
        }
      },
      (error: any) => {
        this.selectRepoLoading = false;
        //console.log(error, 'error');
      }
    );
  }

  fetchBranches(): Promise<any> {
    //console.log(this.selectRepo, 'selectRepo')
    return new Promise((resolve, reject) => {
      this.api.fetchBranches(this.selectRepo).subscribe(
        (resp: any) => {
          // console.log(resp, 'resp')
          for (let i=0; i<resp.result.length; i++) {
            this.branches.push(resp.result[i]);
          }
          resolve(resp.result);
        },
        (error) => {
          //console.log(error, 'error');
          reject(error);
        }
      );
    });
  }
  lockBranch() {
    this.selectRepo.defalutBranch = this.selectedBranch;
    this.realBranch = this.selectedBranch;
    this.closeBranchModal();
  }
  
  async openBranchModal() {
    if (this.selectRepo) {
      //console.log("show branch modal");
      try {
        await this.fetchBranches();
        //console.log(this.branches, 'branches');
        this.showBranchModal = true;
      } catch (error) {
        console.error('Error fetching branches:', error);
      }
    }
  }
  
  closeBranchModal() {
    this.showBranchModal = false;
    this.selectedBranch = '';
    this.branches = [];
  }

  radioChanged(repo: any) {
    this.selectRepo = repo;
  }

  ngOnInit(): void {
    let projects: any = localStorage.getItem('fetchProjects');
    let modalType: any = localStorage.getItem('modalType');
    this.selectedFormat = modalType;

    if (projects) {
      this.fetchProjects = JSON.parse(projects);
    }
  }
}
