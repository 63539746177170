import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { data } from 'jquery';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { Util } from 'src/app/service/utils';

@Component({
  selector: 'app-members',
  templateUrl: './alertSettings.component.html',
  styleUrls: ['./alertSettings.component.scss'],
})
export class alertSettingsComponent implements OnInit {
  @ViewChild('modalRemoveMember') modalRemoveMember?: ModalDirective;
  @ViewChild('modalEditMember') modalEditMember?: ModalDirective;
  constructor(
    private api: ApiService,
    private toaster: ToastrService,
    private route: ActivatedRoute,
    private location: Location,
    private util: Util,
    private router: Router,
    private fb: UntypedFormBuilder
  ) {}
  repositoryAlertsForm = this.fb.group({
    receiveAlerts: [true],
  });


  id: any;
  resource: any;
  term = '';
  permission = 'All';
  currentUserModal: any;
  permissionList: any = [];
  selectedCar: number | null = null;
  regex = /^([a-zA-Z0-9\.\-_]+)@([a-zA-Z0-9-]+)\.([a-z]{2,4})(.[a-z]{2,4})?$/;
  users = [];
  currentUserPermission: any;
  minDate = new Date();
  inviteFrom = new UntypedFormGroup({
    user: new UntypedFormControl(null, Validators.required),
    role: new UntypedFormControl(null, Validators.required),
    expireDate: new UntypedFormControl(null),
    requireOtp: new UntypedFormControl(true),
    requireFaceId: new UntypedFormControl(false),
    otpTimeout: new UntypedFormControl(10, [Validators.required, Validators.min(0)]),
  });
  resourceData: any;
  removePermissionId: any;
  removePermissionUser: any;
  isWhiteList: boolean = false;
  // @ViewChild('deleteModal') deleteModal: ElementRef;

  ngOnInit(): void {

    if (this.util.isPermission('isViewMonitor')) {
      this.route.queryParams.subscribe((params: any) => {
        this.id = params['id'];
        this.resource = params['resource'];
        this.getCurrentUserPermission();
        this.getResourceData();
        this.getAllData();
        this.onUserSearch('');
        if (Object.prototype.hasOwnProperty.call(params, 'isWhitelist')) {
          this.isWhiteList = true;
          setTimeout(() => {
            // self.inviteFrom.patchValue({ user: params.u.trim() });
            this.inviteFrom.controls['user'].setValue([params.u]);
          }, 1000);
        } else {
          this.isWhiteList = false;
        }
      });
    } else {
      this.location.back();
    }
  }
  getResourceData(): void {
    this.api.getResourceData(this.id, this.resource).subscribe(
      (resp) => {
        if (resp.code === 0) {
          this.resourceData = resp.result;
        } else {
          if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
            this.toaster.error(resp.result);
          } else {
            this.toaster.error('Internal Server Error');
          }
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  getAllData(): void {
    if (this.resource === 'Project') {
      this.getAllProjectPermissionData();
    } else if (this.resource === 'Group') {
      this.getAllGroupPermissionData();
    }
  }
  getAllProjectPermissionData(): void {
    this.api
      .getProjectPermission(this.id, this.term, this.permission)
      .subscribe(
        (resp) => {
          if (resp.code === 0) {
            this.permissionList = resp.result.map((e: any) => {
              return {
                ...e,
                expiry_date: e.expiry_date ? new Date(e.expiry_date) : '',
              };
            });
            
          } else {
            if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
              this.toaster.error(resp.result);
            } else {
              this.toaster.error('Internal Server Error');
            }
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
  }
  getAllGroupPermissionData(): void {
    this.api.getGroupsPermission(this.id, this.term, this.permission).subscribe(
      (resp) => {
        if (resp.code === 0) {
          this.permissionList = resp.result.map((e: any) => {
            return {
              ...e,
              expiry_date: e.expiry_date ? new Date(e.expiry_date) : '',
            };
          });
        } else {
          if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
            this.toaster.error(resp.result);
          } else {
            this.toaster.error('Internal Server Error');
          }
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  getCurrentUserPermission(): void {
    this.api.getCurrentUserPermission(this.id, this.resource).subscribe(
      (resp) => {
        if (resp.code === 0) {
          this.currentUserPermission = resp.result;
        } else {
          if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
            this.toaster.error(resp.result);
          } else {
            this.toaster.error('Internal Server Error');
          }
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }

  markTouch(): void {
    Object.keys(this.inviteFrom.controls).forEach((field) => {
      const control = this.inviteFrom.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
  }
  onChangeSearch(): void {
    if (this.term === '') {
      this.getAllData();
    }
  }
  onSearch(): void {
    this.getAllData();
  }

  
  onUserSearch(term: string): void {
    this.api.searchUser(term.toLowerCase().trim()).subscribe(
      (resp) => {
        if (resp.code === 0) {
          this.users = resp.result;
        } else {
          if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
            this.toaster.error(resp.result);
          } else {
            this.toaster.error('Internal Server Error');
          }
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }

  onUpdate(i: any, date?: Date): void {
    this.api
      .updateUserPermission({
        permission: {
          _id: this.permissionList[i]._id,
          role: this.permissionList[i].role,
          expiry_date: date ? date : this.permissionList[i].expiry_date,
        },
        id: this.id,
        resource: this.resource,
        user: this.permissionList[i].user,
      })
      .subscribe(
        (resp) => {
          if (resp.code === 0) {
            this.toaster.success('Permission Updated');
          } else if (resp.code === 97) {
            this.toaster.error('You are not allowed to update Permission');
          } else {
            if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
              this.toaster.error(resp.result);
            } else {
              this.toaster.error('Internal Server Error');
            }
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
  }

  onBack(): void {
    this.location.back();
  }
  updateSettings(): void {
  }
  onCheckboxChange(event: Event, person: any) {
    const isChecked = (event.target as HTMLInputElement).checked;
    const data = {
      repository_id: this.id, // Replace with actual ID
      user: person.user, // Assuming 'id' is the field name
      receiveAlerts: isChecked // New checkbox state
    };
  
    // Assuming updateAlertSettings is a method in the same component or injected service
    this.api.updateAlertSettings(data).subscribe(
      response => {
        ////console.log('API call successful', response);
        this.toaster.success('Alert Settings Updated');
      },
      error => {
        ////console.log('API call failed', error);
        this.toaster.error('Update failed');
      }
    );
  }
  
  
}
