<div class="mt-4">
  <div class="mb-4 pb-1 d-flex align-items-start">
    <a
      class="back me-3"
      href="javascript:void(0)"
      routerLink="/manage/create-connection"
    >
      <img src="/assets/images/icon__arrow--left.svg" alt="" />
    </a>
    <div>
      <h4 class="mb-0">Connect to {{ provider }}</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <h4 class="mb-4">{{ provider }} Connection Setting</h4>
      <div class="mb-4">
        <label for="" class="form-label">Connection Name</label>
        <input type="text" class="form-control" [(ngModel)]="connectionName" />
      </div>
      <div class="mb-5">
        <label for="" class="form-label mb-1">{{ provider }} Apps</label>
        <span class="form-text d-block m-0 mb-2 pb-1"
          >{{ provider }} Apps create a link for your connection with
          {{ provider }}. To start, install a new app and save this
          connection.</span
        >
        <div class="d-flex align-items-center">
          <ng-select
            placeholder="Select App"
            [multiple]="false"
            class="flex-grow-1"
            [items]="apps"
            bindValue="_id"
            bindLabel="account"
            (change)="onSelectValueChange($event)"
          >
            <!-- <ng-option >App A</ng-option>
                        <ng-option >App B</ng-option>
                        <ng-option >App C</ng-option>  -->
          </ng-select>
          <strong class="weight-600 px-4">Or</strong>
          <button
            type="button"
            class="button button--primary flex-shrink-0"
            (click)="onInstallApp()"
          >
            Install a new app
          </button>
        </div>
      </div>
      <div class="button-group">
        <button
          type="submit"
          class="button button--primary"
          (click)="createConnection()"
        >
          Connect to {{ provider }}
        </button>
      </div>
    </div>
  </div>
</div>
