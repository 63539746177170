<div
  class="modal fade"
  id="reset_password"
  tabindex="-1"
  aria-labelledby="reset_password"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <a
        href="javascript:void(0)"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        #closeButton
      >
        <img src="../../../../assets/images/icon__close.svg" alt="" />
      </a>
      <div class="modal-body text-center">
        <span class="icon">
          <img src="../../../../assets/images/icon__password.svg" alt="" />
        </span>
        <h4 class="mb-4">Reset Password</h4>
        <form (Submit)="changePassword()">
          <!-- <div class="form-group">
            <input
              class="form-control"
              type="password"
              placeholder="Enter current password"
              formControlName="current_password"
            />
            <span
              class="form-error"
              *ngIf="
                passwordForm.get('current_password')?.getError('required') &&
                passwordForm.get('current_password')?.touched
              "
              >The field is required.</span
            >
          </div> -->
          <div class="form-group">
            <input
              class="form-control"
              type="password"
              placeholder="Enter new password"
              formControlName="password"
              (input)="
                passwordForm.get('confirm_password')?.updateValueAndValidity()
              "
            />
            <span
              class="form-error"
              *ngIf="
                passwordForm.get('password')?.getError('required') &&
                passwordForm.get('password')?.touched
              "
              >The field is required.</span
            >
          </div>
          <div class="form-group">
            <input
              class="form-control"
              type="password"
              appConfirmEqualValidator="password"
              placeholder="Re-enter new password"
              formControlName="confirm_password"
            />
            <span
              class="form-error"
              *ngIf="
                passwordForm.get('confirm_password')?.getError('required') &&
                passwordForm.get('confirm_password')?.touched
              "
              >The field is required.</span
            >
            <span
              class="form-error"
              *ngIf="
                passwordForm.get('confirm_password')?.getError('notEqual') &&
                !passwordForm.get('confirm_password')?.getError('required')
              "
              >Password & confirm password don't match</span
            >
          </div>
          <div class="button-group justify-content-center pt-2">
            <button
              type="reset"
              class="button button--sml button--primary button--reverse"
              (click)="onReset()"
            >
              Clear
            </button>
            <button type="submit" class="button button--sml button--primary">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
