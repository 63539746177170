import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CountryCode } from 'src/app/interface/CountryCode';
import { Nationality } from 'src/app/interface/Nationality';
import { ApiService } from 'src/app/service/api.service';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { decodeToken, onlycharector } from 'src/app/utils/helper';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  regex = /^([\w-.]+@(?!pqr.com)([\w-]+.)+[\w-]{2,4})?$/;
  ///^([\w-.]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!abc.com)(?!xyz.com)(?!pqr.com)(?!rediffmail.com)(?!live.com)(?!outlook.com)(?!me.com)(?!msn.com)(?!ymail.com)([\w-]+.)+[\w-]{2,4})?$/;
  regexPassword = /^(?=.{10,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/;
  showOtp = false;
  otp: number | undefined;
  countryCodes: CountryCode[] = [];
  nationalitys: Nationality[]=[];
  orgAvailability = true;
  params: any;
  userCompanyValue: any[] =[];
  decodedToken: any;
  switchCase: boolean = false;
  switchCase2: boolean = false;
  intervalOp: any;
  timer: number = 60;
  signupForm = new UntypedFormGroup({
    first_name: new UntypedFormControl(
      null,
      Validators.compose([Validators.required])
    ),
    last_name: new UntypedFormControl(null, Validators.compose([Validators.required])),
    company_name: new UntypedFormControl(null, Validators.required),
    user_company_name: new UntypedFormControl(null, Validators.required),
    email: new UntypedFormControl(
      null,
      Validators.compose([Validators.required, Validators.pattern(this.regex)])
    ),
    phoneNo: new UntypedFormControl(null, [
      Validators.required,
      //Validators.pattern('^\\d{10}$'),
    ]),
    countryCode: new UntypedFormControl(null, Validators.required),
    nationality: new UntypedFormControl(null, Validators.required),
    password: new UntypedFormControl(null, [
      Validators.required,
      Validators.pattern(this.regexPassword),
    ]),
    confirm_password: new UntypedFormControl(null, Validators.required),
    // acceptTerms: new FormControl(false, Validators.requiredTrue),
  });
  constructor(
    private toaster: ToastrService,
    private api: ApiService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private auth: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.getAllCountryCode();
    this.getParams();
    this.getNationality();
  }
  getAllCountryCode(): void {
    this.api.getAllCountryCode().subscribe((resp) => {
      if (resp.code === 0) {
        this.countryCodes = resp.result.map((c: any) => {
          return { ...c, label: c.code + ' (' + c.name + ')' };
        });
        this.signupForm.patchValue({ countryCode: '+1' });
      }
    });
  }
  getNationality(): void{
    this.api.getCountryCodes().subscribe((resp) => {
      if(resp.code === 0){
        this.nationalitys = resp.result.map((c: any) => {
          return { ...c, label: c.country + ' (' + c.name + ')' };
        });
        // this.signupForm.patchValue({ nationality: '+1' });
      }
    });
  }
  checkAvailability(): void {
    this.api
      .checkOrgAvailability({
        name: this.signupForm.get('company_name')?.value,
      })
      .subscribe((resp) => {

        this.orgAvailability = resp.code !== 5;
      });
  }

  // valueChange(value: any):void{
  //   // //console.log(value);
  //   if(value.data != null){
  //     this.userCompanyValue = this.userCompanyValue + value.data
  //   }else if(value.data === null){
  //     // //console.log('inside');
  //     this.userCompanyValue = this.userCompanyValue.slice(0,-1);
  //   }
  //   // //console.log(this.userCompanyValue);
  //   this.signupForm.patchValue({
  //     // user_company_name: this.signupForm.get('company_name')?.value
  //     user_company_name: this.userCompanyValue
  //   })
  // }

  onSignup(): void {
    // //console.log(this.signupForm.getRawValue());

    // //console.log(this.signupForm);

    if (this.signupForm.valid && this.orgAvailability) {
      const sendData = {
        phone_no:
          this.signupForm.get('countryCode')?.value +
          this.signupForm.get('phoneNo')?.value,
        email: this.signupForm.getRawValue().email.toLowerCase(),
      };
      this.api.sendOTP(sendData).subscribe(
        (resp) => {
          if (resp.code === 0) {
            this.startTimer();
            ////console.log('test');
            this.showOtp = true;

            this.toaster.success('Please Check Your Phone', 'OTP Sent');
          } else {
            if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
              this.toaster.error(resp.result);
            } else {
              this.toaster.error('Failed To Send OTP', 'Internal Server Error');
            }
          }
        },
        (error: HttpErrorResponse) => {
          // //console.log('error>>', error);
          if (error.error.code == 100) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Failed To Send OTP');
          }
        }
      );
    } else {
      this.signupForm.markAllAsTouched();
    }
  }
  resendOtp(): void {
    clearInterval(this.intervalOp);
    const sendData = {
      phone_no:
        this.signupForm.get('countryCode')?.value +
        this.signupForm.get('phoneNo')?.value,
      email: this.signupForm.getRawValue().email.toLowerCase(),
    };
    this.api.resendOTP(sendData).subscribe(
      (resp) => {
        if (resp.code === 0) {
          this.showOtp = true;
          this.startTimer();
          this.toaster.success('Please Check Your Phone', 'OTP Sent');
        } else {
          if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
            this.toaster.error(resp.result);
          } else {
            this.toaster.error('Failed To Send OTP', 'Internal Server Error');
          }
        }
      },
      (error: HttpErrorResponse) => {
        this.toaster.error('Failed To Send OTP');
      }
    );
  }
  onOtpChange(otp: string): void {
    this.otp = Number(otp);
  }
  toggleButton(): void {
    this.switchCase = !this.switchCase;
  }
  toggleButton2(): void {
    this.switchCase2 = !this.switchCase2;
  }
  onVerifyOtp(): void {
    if (!this.otp) return;
    this.api
      .verifyOTP({
        email: this.signupForm.get('email')?.value.toLowerCase(),
        otp: this.otp,
      })
      .subscribe(
        (resp) => {
          // //console.log('code>>', resp);
          if (resp.code === 0) {
            let data: any = {
              ...this.signupForm.getRawValue(),
              phone_no:
                this.signupForm.get('countryCode')?.value +
                this.signupForm.get('phoneNo')?.value,
              email: this.signupForm.getRawValue().email.toLowerCase(),
              country_code: this.signupForm.get('countryCode')?.value,
            };
            if (Object.keys(this.params).length) {
              let {
                os,
                code_repo_use,
                is_airgapped,
                is_corporate_proxy,
                total_dev,
                additional_info,
              } = this.params;
              let { org_name, org_id, ...tempRestToken } = this.decodedToken;
              data['os'] = os;
              data['code_repo_use'] = code_repo_use;
              data['is_airgapped'] = is_airgapped;
              data['is_corporate_proxy'] = is_corporate_proxy;
              data['total_dev'] = total_dev;
              data['additional_info'] = additional_info;
              data['org_id'] = org_id;
            }
            ////console.log(data);
            this.auth.signUp(data).subscribe(
              (resp) => {
                if (resp.code === 0) {
                  this.signupForm.reset();
                  this.router.navigate(['/auth/login']);
                  this.toaster.success(
                    'Verify your Email id & Login ID, it may take few minutes',
                    'Registration Successful',
                    { timeOut: 5000 }
                  );
                } else if (resp.code === 3) {
                  ////console.log('error');
                } else if (resp.code === 5 || resp.code === 4) {
                  ////console.log('error')
                } else {
                  if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
                    ////console.log('error')
                  } else {
                    this.toaster.error('Internal Server Error');
                  }
                }
              },
              (error) => {
                if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
                  ////console.log('error')
                } else {
                  this.toaster.error('Something Went Wrong');
                }
              }
            );
          } else if (resp.code === 3) {
            ////console.log('error');
          } else {
            if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
              ////console.log('error')
            } else {
              this.toaster.error('Internal Server Error');
            }
          }
        },
        (error: HttpErrorResponse) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            ////console.log('error')
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
  }
  getParams(): void {
    this.activeRoute.queryParams.subscribe((params: any) => {
      this.params = params;
      // //console.log('params>>', this.params);
      if (Object.prototype.hasOwnProperty.call(params, 'token')) {
        const decode: any = decodeToken(params['token']);

        if (decode) {
          this.decodedToken = decode;
          const { org_name, org_id, ...rest } = decode;
          this.signupForm.patchValue({ ...rest, company_name: org_name });
          this.signupForm.get('first_name')?.disable();
          this.signupForm.get('last_name')?.disable();
          this.signupForm.get('email')?.disable();
          this.signupForm.get('company_name')?.disable();
        }
      }
    });
  }
  charectorValid(e: any): boolean {
    // //console.log('test');
    return onlycharector(e);
  }
  startTimer(): void {
    this.intervalOp = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      }
    }, 1000);
  }

  numberOnly(event : any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  onPastePhoneNumber(){
    let pastedText = this.signupForm.get('phoneNo')?.value; //clipboardData?.getData('text');
    pastedText = pastedText?.replace(/[^0-9]/g, '');
    // this.signupForm.reset({phoneNo: ''});
    this.signupForm.get('phoneNo')?.patchValue(pastedText)
  }
}
