import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  requestChangePasswordForm = new UntypedFormGroup({
    email: new UntypedFormControl(null, Validators.required),
  });
  constructor(
    private api: ApiService,
    private toaster: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  onForgotPassword(): void {
    if (!this.requestChangePasswordForm.valid) {
      this.requestChangePasswordForm.markAllAsTouched();
      return;
    }
    this.api
      .requestResetPassword({
        email: this.requestChangePasswordForm.get('email')?.value,
      })
      .subscribe(
        (resp: any) => {
          if (resp.code === 0) {
            this.toaster.success('Password Reset Mail Sent');
            this.requestChangePasswordForm.reset();
            this.router.navigate(['/auth/login']);
          } else {
            if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
              this.toaster.error(resp.result);
            } else {
              this.toaster.error('Internal Server Error');
            }
          }
        },
        (error: HttpErrorResponse) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
  }
}
