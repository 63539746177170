import { Component, OnInit } from '@angular/core';
import { Providers } from 'src/app/utils/data';
import { Util } from 'src/app/service/utils';
declare function navigation(): any;

const routes = [
  "sbom",
  "projects",
  "map",
  "activity",
  "threat",
  "compliance",
  "monitor",
  "analytics",
  "repository",
];

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  providers: any[] = Providers;
  enabledRoutes: any[] = [];
  constructor(private util: Util) {}

  ngOnInit(): void {    
  }

  ngAfterViewInit() {
    navigation();
    this.getEnabledRoutes();
  }

  getEnabledRoutes() {
    routes.forEach((route) => {
      if (!this.util.canNavigate(route)) {
        document.querySelectorAll('.check_sub').forEach(element => {
          if (element.classList.contains(route)) { // Assuming 'route' can be a class to identify specific routes
            element.classList.add("disabled");
          }
        });
      }
    });
  }  
}

