<div class="at-repotablearea">
  <a
    href="javascript:void(0)"
    class="button button--primary AtBtnUpgrade at-btnaddnewproject"
    (click)="newProjectNavigateHandler()"
    >Add New Project</a
  >
  <table class="table table-borderless m-0 at-repotable">
    <thead>
      <tr>
        <th scope="col">Project</th>
        <th scope="col">Repository</th>
        <th scope="col">Branch</th>
        <th scope="col">Source</th>
        <th scope="col">Last Updated</th>
        <th scope="col">Status</th>
        <th scope="col">Vulnerability</th>
        <th scope="col">Report</th>
        <th scope="col">Download</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody *ngIf="getRepoLoading">
      <!-- LOADER -->
      <td colspan="12">
        <div class="at-tableloader">
          <div class="lds-dual-ring"></div>
        </div>
      </td>
    </tbody>
    <tbody *ngIf="repos.length">
      <tr *ngFor="let repo of repos; let i = index">
        <td>
          {{ repo.repoName }}
          <!-- <span style="color: rgb(242, 255, 0)">{{
            repo.webhookConfigure ? "" : "Webhook not configured"
          }}</span> -->
        </td>
        <td>
          {{ repo.repoName }}
        </td>
        <td>
          {{ repo.branchName }}
        </td>
        <td>
          <!-- {{ repo.source }} -->
          <figure class="at-sourceimg">
            <img
              *ngIf="repo.source === 'gitlab'"
              src="/assets/images/gitlab.png"
              alt="Gitlab"
            />
            <img
              *ngIf="repo.source === 'github'"
              src="/assets/images/github-w.png"
              alt="Github"
            />
            <img
              *ngIf="repo.source === 'bitbucket'"
              src="/assets/images/bitbucket.png"
              alt="Bitbucket"
            />
          </figure>
        </td>
        <td>
          <div *ngIf="repo.loading" class="at-procssingtag">
            <div id="html-spinner"></div>
            <span>Processing</span>
          </div>
          <div *ngIf="!repo.loading">
            <span>{{ repo.repoUpdateAt }}</span>
          </div>
        </td>
        <td *ngIf="repo.newChanges">
          <div
            class="at-procssingtag"
            style="background: #fca326; cursor: pointer"
            (click)="openVersionModal(versionHistory, repo)"
            [ngClass]="{ disabled: repo.loading }"
          >
            <span>New Change</span>
          </div>
        </td>
        <td *ngIf="!repo.newChanges">
          <div
            class="at-procssingtag"
            style="background: #028c41"
            [ngClass]="{ disabled: repo.loading }"
          >
            <span>No New Change</span>
          </div>
        </td>
        <td *ngIf="repo.grypeOutputUrl">
          <div
            class="at-procssingtag"
            style="background: #f80707; cursor: pointer"
            (click)="openPDFViewModal(pdfView, 'vuln', repo)"
            [ngClass]="{ disabled: repo.loading }"
          >
            <span>Alert</span>
          </div>
        </td>
        <td *ngIf="!repo.grypeOutputUrl">
          <div
            class="at-procssingtag"
            style="background: #028c41"
            [ngClass]="{ disabled: repo.loading }"
          >
            <span>No alert</span>
          </div>
        </td>
        <td>
          <div
          class="at-procssingtag"
          style="background: #028c41; cursor: pointer"
          (click)="openReportModal(repo)"
          [ngClass]="{ disabled: repo.loading }"
        >
          <span>View Report</span>
        </div>
        </td>
        
        <td>
          <div class="btn-group at-selectreposdropdown" dropdown>
            <button
              id="button-basic"
              dropdownToggle
              type="button"
              class="btn btn-primary dropdown-toggle"
              aria-controls="dropdown-basic"
              [ngClass]="{ disabled: repo.loading }"
            >
              <span>Select</span>
            </button>
            <ul
              id="dropdown-basic"
              *dropdownMenu
              class="dropdown-menu"
              role="menu"
              aria-labelledby="button-basic"
            >
              <!-- <li role="menuitem">
                <div
                  (click)="
                    openFormatModalTwo(
                      formatModalTwo,
                      repo.SBOMurl,
                      repo.sbomFileUrl,
                      'SBOM'
                    )
                  "
                >
                  <span> SBOM </span>
                </div>
              </li> -->
              <li role="menuitem">
                <div
                  (click)="
                    openFormatModalTwo(
                      formatModalTwo,
                      repo.cycloneDXurl,
                      repo.cycloneDxFileUrl,
                      'CycloneDX'
                    )
                  "
                >
                  <span> CycloneDX </span>
                </div>
              </li>
              <li role="menuitem">
                <div
                  (click)="
                    openFormatModalTwo(
                      formatModalTwo,
                      repo.SWIDurl,
                      repo.swidFileUrl,
                      'SWID'
                    )
                  "
                >
                  <span> SWID </span>
                </div>
              </li>
              <li role="menuitem">
                <div
                  (click)="
                    openFormatModalTwo(
                      formatModalTwo,
                      repo.SPDXurl,
                      repo.spdxFileUrl,
                      'SPDX'
                    )
                  "
                >
                  <span> SPDX </span>
                </div>
              </li>
            </ul>
          </div>
        </td>
        <td>
          <div class="at-actionbtns">
            <div class="btn-group at-btnmenudropdown" dropdown>
              <button
                id="button-basic"
                dropdownToggle
                type="button"
                class="btn btn-primary dropdown-toggle show"
                aria-controls="dropdown-basic"
                (click)="openFormatModal(formatModal, repo)"
                [ngClass]="{ disabled: repo.loading }"
                style="position: relative;"
              >
                <img src="/assets/images/eye-icon.png" alt="Menu Icon" style="position: relative;" />
                
              </button>
            </div>
            
            
            
            <div class="btn-group at-btnmenudropdown" dropdown>
              <button
                id="button-basic"
                dropdownToggle
                type="button"
                class="btn btn-primary dropdown-toggle show"
                aria-controls="dropdown-basic"
                [ngClass]="{ disabled: repo.loading }"
              >
                <img src="/assets/images/menu-dropdown.png" alt="Menu Icon" />
              </button>
              <ul
                id="dropdown-basic"
                *dropdownMenu
                class="dropdown-menu show"
                role="menu"
                aria-labelledby="button-basic"
              >
                <li role="menuitem">
                  <button
                    type="button"
                    class="at-btns"
                    (click)="openModalWithClass(template, repo)"
                  >
                    <img
                      src="/assets/images/dropdownicon/notofication.svg"
                      alt="Alert Icon"
                    />
                    <span>Alert Settings</span>
                  </button>
                </li>
                <li role="menuitem">
                  <button
                    type="button"
                    class="at-btns"
                    (click)="openShareModal(openModalShare, repo)"
                  >
                    <img
                      src="/assets/images/dropdownicon/share.svg"
                      alt="Share Icon"
                    />
                    <span>Share</span>
                  </button>
                </li>
                <li role="menuitem">
                  <button
                    type="button"
                    class="at-btns"
                    (click)="
                      openModalWebhookConfiguration(webhookConfiguration, repo)
                    "
                  >
                    <img
                      src="/assets/images/dropdownicon/pencil.svg"
                      alt="Edit Icon"
                    />
                    <span>Webhook Configuration</span>
                  </button>
                </li>
                <li role="menuitem">
                  <button
                    type="button"
                    class="at-btns"
                    (click)="startGrypeScan(repo.id)"
                  >
                    <img
                      src="/assets/images/dropdownicon/scan.svg"
                      alt="Edit Icon"
                    />
                    <span>Vulnerability Scan</span>
                  </button>
                </li>
                <li role="menuitem">
                  <button
                    type="button"
                    class="at-btns"
                    (click)="startGrantScan(repo.id)"
                  >
                    <img
                      src="/assets/images/dropdownicon/license.svg"
                      alt="Edit Icon"
                    />
                    <span>Scan Licenses</span>
                  </button>
                </li>
                <li role="menuitem">
                  <button
                    type="button"
                    class="at-btns"
                    (click)="deleteProjectHandler(repo.id)"
                  >
                    <img
                      src="/assets/images/dropdownicon/delete.svg"
                      alt="Edit Icon"
                    />
                    <span>Delete</span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="!repos.length && !getRepoLoading">
      <tr>
        <td colspan="12">
          <h3
            class="at-norecordfound"
            style="
              margin: 0;
              padding: 15px;
              text-align: center;
              font-size: 18px;
            "
          >
            No Record Found
          </h3>
        </td>
      </tr>
    </tbody>
    <!-- <ngx-loading [show]="getRepoLoading"></ngx-loading> -->
  </table>

  <ng-template #template>
    <div
      class="modal-body"
      style="
        background: #fff;
        border-radius: 0.5rem;
        padding: 20px 30px;
        position: relative;
      "
    >
      <button
        type="button"
        class="close btn-close pull-right at-btnclosemodal"
        aria-label="Close"
        (click)="modalRef?.hide()"
      >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
      <div class="at-alertcontent">
        <h3>Alert Settings</h3>
        <ul class="at-alertoptions">
          <li>
            <div class="at-alertoption">
              <h4>When new changes are detected.</h4>

              <div class="at-checkbox">
                <input
                  #documentChangesAlert
                  id="documentChangesAlert"
                  type="checkbox"
                  [checked]="shareAbleRepo.documentChangesAlert"
                  (change)="changesAlertHandler(documentChangesAlert.checked)"
                />
                <label for="documentChangesAlert"></label>
              </div>
            </div>
          </li>
          <li>
            <div class="at-alertoption">
              <h4>When the system runs a check.</h4>
              <div class="at-checkbox">
                <input
                  #documentProcessingAlert
                  id="documentProcessingAlert"
                  type="checkbox"
                  [checked]="shareAbleRepo.documentProcessingAlert"
                  (change)="
                    processingAlertHandler(documentProcessingAlert.checked)
                  "
                />
                <label for="documentProcessingAlert"></label>
              </div>
            </div>
          </li>
          <li>
            <div class="at-alertoption">
              <h4>When the system runs a check &amp; found an issue.</h4>
              <div class="at-checkbox">
                <input type="checkbox" name="select-option" id="found-issue" />
                <label for="found-issue"></label>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </ng-template>
  <ng-template #openModalShare>
    <div
      class="modal-body"
      style="
        background: #fff;
        border-radius: 0.5rem;
        padding: 20px 30px;
        position: relative;
      "
    >
      <button
        type="button"
        class="close btn-close pull-right at-btnclosemodal"
        aria-label="Close"
        (click)="modalRef?.hide()"
      >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
      <div class="at-alertcontent at-sharecontent">
        <h3>
          {{ shareAbleRepo.repoName }}
          <span>Select the format in which you want to share the document</span>
        </h3>
        <div class="at-repostries">
          <div
            *ngFor="let item of shareAbleFormate; let i = index"
            class="at-repostry"
          >
            <div>
              <input type="radio" name="shareableFormat" id="{{ item }}" />
              <label for="{{ item }}" (click)="shareAbleLinkHandler(item)">
                <h4>{{ item }}</h4>
              </label>
            </div>
            <div>
              <div
                *ngIf="selectedShareableFormat === item"
                class="at-copylinkaera"
              >
                <h5>Shareable link</h5>
                <div class="at-inputbtnholder">
                  <input
                    type="text"
                    disabled
                    class="form-control"
                    placeholder="{{ shareAbleUrl }}"
                  />
                  <button
                    (click)="shareAbleCopyHandler(item)"
                    class="at-btncopylink"
                    type="button"
                  >
                    <img src="/assets/images/copy-icon.svg" alt="Copy Icon" />
                    <span>Copy</span>
                  </button>
                </div>
                <ul class="at-stopgeneratebtns">
                  <li>
                    <button
                      (click)="linkActiveHandler(item)"
                      class="at-stopregenbtn"
                      type="button"
                    >
                      <img
                        *ngIf="
                          !urlStatusLoading &&
                          shareAbleRepo[selectedShareableFormat + 'shareAble']
                        "
                        src="assets/images/stopicon.svg"
                        alt="Stop Icon"
                      />
                      <!-- FOr RESUME -->
                      <img
                        width="14px"
                        *ngIf="
                          !urlStatusLoading &&
                          !shareAbleRepo[selectedShareableFormat + 'shareAble']
                        "
                        src="assets/images/resume.svg"
                        alt="Stop Icon"
                      />
                      <div *ngIf="urlStatusLoading" id="html-spinner"></div>
                      <span
                        *ngIf="
                          shareAbleRepo[selectedShareableFormat + 'shareAble']
                        "
                        >Stop</span
                      >
                      <span
                        *ngIf="
                          !shareAbleRepo[selectedShareableFormat + 'shareAble']
                        "
                        >Resume</span
                      >
                    </button>
                  </li>
                  <li>
                    <button
                      (click)="regenerateUrlHandler(item)"
                      class="at-stopregenbtn"
                      type="button"
                    >
                      <div *ngIf="urlRegenerateLoading" id="html-spinner"></div>
                      <img
                        *ngIf="!urlRegenerateLoading"
                        src="assets/images/re-generate.svg"
                        alt="Re-Generate Icon"
                      />
                      <span>Regenerate</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- After click on Eye Icon in Action table and click on any option open this modal  -->
  <ng-template #formatModal>
    <div
      class="modal-body"
      style="
        background: #fff;
        border-radius: 0.5rem;
        padding: 20px 30px;
        position: relative;
      "
    >
      <button
        type="button"
        class="close btn-close pull-right at-btnclosemodal"
        aria-label="Close"
        (click)="formatModalRef?.hide()"
      >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
      <div class="at-alertcontent at-sharecontent">
        <h3>
          Choose Format
          <span>Select the format in which you want to view.</span>
        </h3>
        <div class="at-formates">
          <!-- <button
            (click)="openPDFViewModal(pdfView, 'SBOM')"
            type="button"
            class="at-formate at-btnwithloader"
            [disabled]="JSONviewLoading"
          >
            <div
              *ngIf="JSONviewLoading && selectedShareableFormat == 'SBOM'"
              class="at-tableloader"
            >
              <div class="lds-dual-ring"></div>
            </div>
            <span> SBOM </span>
          </button> -->
          <button
            (click)="openPDFViewModal(pdfView, 'SPDX')"
            type="button"
            class="at-formate at-btnwithloader"
            [disabled]="JSONviewLoading"
          >
            <div
              *ngIf="JSONviewLoading && selectedShareableFormat == 'SPDX'"
              class="at-tableloader"
            >
              <div class="lds-dual-ring"></div>
            </div>
            <span> SPDX </span>
          </button>
          <button
            (click)="openPDFViewModal(pdfView, 'CycloneDX')"
            type="button"
            class="at-formate at-btnwithloader"
            [disabled]="JSONviewLoading"
          >
            <div
              *ngIf="JSONviewLoading && selectedShareableFormat == 'CycloneDX'"
              class="at-tableloader"
            >
              <div class="lds-dual-ring"></div>
            </div>
            <span> Cyclone DX </span>
          </button>
          <button
            (click)="openPDFViewModal(pdfView, 'SWID')"
            type="button"
            class="at-formate at-btnwithloader"
            [disabled]="JSONviewLoading"
          >
            <div
              *ngIf="JSONviewLoading && selectedShareableFormat == 'SWID'"
              class="at-tableloader"
            >
              <div class="lds-dual-ring"></div>
            </div>
            <span> SWID </span>
          </button>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- After click on Select and click on any option open this modal  -->
  <ng-template #formatModalTwo>
    <div
      class="modal-body"
      style="
        background: #fff;
        border-radius: 0.5rem;
        padding: 20px 30px;
        position: relative;
      "
    >
      <button
        type="button"
        class="close btn-close pull-right at-btnclosemodal"
        aria-label="Close"
        (click)="modalRef?.hide()"
      >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
      <div class="at-alertcontent at-sharecontent">
        <h3>
          Choose {{ fileFormate }} format
          <span>Select the format in which you want to share the document - Warning: PDFs for SPDX and CycloneDX may be incomplete, we are investigating this issue</span>
        </h3>
        <div class="at-formates">
          <!-- <button type="button" class="at-formate">
            PDF
          </button> -->
          <a
            class="at-formate"
            *ngIf="selectedurl"
            href="{{ selectedurl }}"
            download="{{ selectedurl }}"
          >
            PDF
          </a>
          <a
            class="at-formate"
            *ngIf="selectedFileUrl"
            href="{{ selectedFileUrl }}"
            download="{{ selectedFileUrl }}"
          >
            JSON
          </a>
          <!-- <button type="button" class="at-formate">SBOM</button> -->
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #versionHistory>
    <div class="modal-header at-modalheader">
      <figure class="at-mainlog">
        <img src="/assets/images/code-lock-logo.svg" alt="CodelockLogo" />
      </figure>
    </div>
    <div class="modal-body">
      <div class="at-versionhistorycontent">
        <!-- Version History Loader -->
        <div *ngIf="jsonFileViewerLoading" class="at-tableloader">
          <div class="lds-dual-ring"></div>
        </div>
        <div class="at-versionhistoryholder">
          <div *ngIf="!jsonFileViewerLoading" class="at-versionbox">
            <div class="at-versionboxhead">
              <h4>Previous History</h4>
            </div>
            <div class="at-versionboxcontent">
              <ngx-json-viewer
                [json]="orginalVersionFile"
                [expanded]="true"
              ></ngx-json-viewer>
            </div>
          </div>
          <div
            [class.zeroopacity]="jsonFileViewerLoading"
            [class.at-versionbox]="!jsonFileViewerLoading"
          >
            <div class="at-versionboxhead">
              <h4>Current History</h4>
            </div>
            <div class="at-versionboxcontent">
              <ul class="at-versionfilehistory" id="jsonList"></ul>
            </div>
          </div>
        </div>
      </div>
      <div class="at-historyarea">
        <div class="at-historyareahead">
          <h5>Version History</h5>
          <button
            type="button"
            class="btn-close close pull-right"
            aria-label="Close"
            (click)="modalRef?.hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="at-versionaccodionholder">
          <!-- <h6>Current Version</h6> -->
          <accordion
            class="at-versionaccordion"
            [isAnimated]="true"
            [closeOthers]="oneAtATime"
          >
            <accordion-group
              *ngFor="let version of versionHistoryData; let i = index"
              class="at-accordiongroup"
              heading="{{ version.historyDate }}"
            >
              <div class="at-accordioncontent">
                <h5 *ngFor="let time of version.data; let nestedIndex = index">
                  <span
                    (click)="versionFileShowHandler(version._id, nestedIndex)"
                    >{{ time.createdAt }}</span
                  >
                </h5>
                <!-- <span
                  class="at-changetag"
                  style="background: #028c41; color: #fff"
                  >20 Additions</span -->
                <!-- > -->
                <!-- <span
                  class="at-changetag"
                  style="background: #e24329; color: #fff"
                  >8 Deletions</span
                > -->
              </div>
            </accordion-group>
          </accordion>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #pdfView>
    <!-- <div class="modal-header at-modalheader">
      <figure class="at-mainlog">
        <img src="/assets/images/code-lock-logo.svg" alt="Logo" />
      </figure>
    </div> -->

    <div class="modal-body" *ngIf="!JSONviewLoading">
      <button
        type="button"
        class="close btn-close pull-right at-btnclosemodal"
        aria-label="Close"
        (click)="modalRef?.hide()"
      >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
      <div class="at-pdfheadingholder">
        <h3>{{ selectedShareableFormat }} Format:</h3>
        <div class="at-jsonviewerarea">
          <ngx-json-viewer
            [json]="JSONview"
            [expanded]="true"
          ></ngx-json-viewer>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #webhookConfiguration>
    <div
      class="modal-body"
      style="
        background: #fff;
        border-radius: 0.5rem;
        padding: 20px 30px;
        position: relative;
      "
    >
      <button
        type="button"
        class="close btn-close pull-right at-btnclosemodal"
        aria-label="Close"
        (click)="modalRef?.hide()"
      >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
      <!-- <div class="at-pdfheadingholder"> -->
      <div class="at-wbhookmodalcontent">
        <h3>Webhook Configuration:</h3>
        <p *ngIf="shareAbleRepo.source === 'github'">
          Select repo from github and go to settings → Select Webhooks → Click
          on Add Webhook → Add their provided url to payload input and select
          push event option → Click on Add Webhook
        </p>
        <p *ngIf="shareAbleRepo.source === 'gitlab'">
          Login into Gitlab → Go to Projects → Click on Project → Click on
          Settings → Click on Webhooks → Add Webhook → Select (push events) from
          trigger → Add Webhook
        </p>
        <p *ngIf="shareAbleRepo.source === 'bitbucket'">
          Login into Bitbucket → Select Repositries → Click on Projects → Select
          Repositries Settings → Click on Webhook → Add Webhook → Add Title and
          paste URL of Project → Mark Status Active → Select Push from trigger →
          Save
        </p>
        <h4>{{ webhookUrl }}</h4>
      </div>
    </div>
  </ng-template>
</div>
