import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { Store } from '@ngrx/store';
import { User } from 'src/app/interface/user';
import { UpdateName } from 'src/app/store/user.action';

@Component({
  selector: 'app-contact-sales',
  templateUrl: './contact-sales.component.html',
  styleUrls: ['./contact-sales.component.scss'],
})
export class ContactSalesComponent implements OnInit {
  @Output() closeModal = new EventEmitter<void>();
  data: any;
  originalPhoneNo: any;

  contactUsForm = new UntypedFormGroup({
    first_name: new UntypedFormControl(null, Validators.required),
    last_name: new UntypedFormControl(null, Validators.required),
    company: new UntypedFormControl(null, Validators.required),
    job_title: new UntypedFormControl(null, Validators.required),
    email: new UntypedFormControl(null, Validators.required),
    phone: new UntypedFormControl(null, Validators.required),
    preferred_contact_method: new UntypedFormControl('Phone'),
    // contactMethodPhone: new FormControl(true, Validators.required),
    // contactMethodEmail: new FormControl(false),
    message: new UntypedFormControl(null, Validators.required),
    current_customer: new UntypedFormControl(true),
  });
  constructor(
    private auth: AuthenticationService,
    private api: ApiService,
    private toaster: ToastrService,
    private router: Router,
    private store: Store<{ userData: User }>
  ) {}

  ngOnInit(): void {
    this.data = this.auth.getUserData();
    this.getUserInfo();
    // this.saveContactSales();
    // this.data = this.auth.getUserData();
    // //console.log('contact-sales', this.data);
  }
  getUserInfo(): void {
    this.api.getUser().subscribe(
      (resp) => {
        if (resp.code === 0) {
          // //console.log('user info', resp);

          this.contactUsForm.patchValue({
            first_name: resp.result.user?.first_name,
            last_name: resp.result.user?.last_name,
            email: resp.result.user.email,
            phone: resp.result.user.phone_no
              ? resp.result.user.phone_no.replace(resp.result.country_code, '')
              : null,
            company: this.data.org_name,
            // twoStepOn: resp.result.two_step_on,
            // url: resp.result.profile_pic_url,
            // recognition: resp.result.recognition,
            // country_code: resp.result.country_code
            //   ? resp.result.country_code
            //   : '+1',
          });
          // //console.log(this.contactUsForm.getRawValue());
          // company: 'scvascv';
          // current_customer: false;
          // email: 'puspendu.developer@gmail.com';
          // first_name: 'GAYATRI';
          // job_title: 'vcasdva';
          // last_name: 'MAITY';
          // message: 'sdfsdfsdf';
          // phone: '1231231231223';
          // preferred_contact_method: 'Email';
          // this.url = resp.result.profile_pic_url;
          // this.originalPhoneNo = resp.result.phone_no;
          // this.store.dispatch(
          //   new UpdateName({
          //     first_name: resp.result.first_name,
          //     last_name: resp.result.last_name,
          //   })
          // );
          // this.store.dispatch(
          //   new UpdateTwoStep({ two_step_on: resp.result.two_step_on })
          // );
        } else {
          if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
            this.toaster.error(resp.result);
          } else {
            this.toaster.error('Internal Server Error');
          }
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }

  saveContactSales(): void {
    // //console.log('contact sales', this.contactUsForm.getRawValue());

    if (this.contactUsForm.valid) {
      this.api
        .saveContactUs(this.contactUsForm.getRawValue())
        .subscribe((resp) => {
          // //console.log(resp);
          // //console.log(this.contactUsForm.getRawValue());

          if (resp.code === 0) {
            this.toaster.success('Submitted successfully.');
            // this.contactUsForm.reset();
            this.onCloseModal();
            this.contactUsForm.patchValue({ message: null, job_title: null });
          } else {
            this.toaster.error('Something Went Wrong');
          }
        });
    } else {
      this.markTouchContactUs();
    }
  }
  markTouchContactUs(): void {
    Object.keys(this.contactUsForm.controls).forEach((field) => {
      const control = this.contactUsForm.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
  }
  onCloseModal(): void {
    this.closeModal.emit();
  }

  numberOnly(event : any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 43) {
      return false;
    }
    // else if(charCode == 43){
    //   return true
    // }
    else{
      return true;
    }
    

  }
  onPastePhoneNumber(){
    let pastedText = this.contactUsForm.get('phone')?.value; //clipboardData?.getData('text');
    pastedText = pastedText?.replace(/[^0-9+]/g, '');
    pastedText = pastedText.substr(0,1) + pastedText.substr(1,pastedText.length).replace("+",'');
    // this.signupForm.reset({phone_no: ''});
    this.contactUsForm.get('phone')?.patchValue(pastedText)
  }
}
