import { Component, OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RepoServices } from 'src/app/service/repo.service';
import { ApiService } from 'src/app/service/api.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-sbomdocuments',
  templateUrl: './sbomdocuments.component.html',
  styleUrls: ['./sbomdocuments.component.scss'],
})
export class SbomdocumentsComponent implements OnInit {
  modalRef?: BsModalRef;
  modalType = '';
  accessToken = '';
  userName: string = '';
  workspaceName: string = '';
  saveToken: boolean = false;
  previousTokens: any = [];
  fetchProjects: any = [];
  fetchProjectLoading: Boolean = false;
  userDetail: any = null;
  useDetailLoading = true;

  constructor(
    private modalService: BsModalService,
    private api: RepoServices,
    private router: Router,
    private toaster: ToastrService,
    private profileApi: ApiService
  ) {}

  accessTokenHandler(event: any) {
    this.accessToken = event.target.value;
  }
  userNameHandler(event: any) {
    this.userName = event.target.value;
  }
  processingAlertHandler(value: boolean) {
    this.saveToken = value;
  }
  workspaceHandler(event: any) {
    this.workspaceName = event.target.value;
  }


  getProjectsHandler() {
    this.fetchProjectLoading = true;
    this.fetchProjects = [];
    let data = {
      personalAccessToken: this.accessToken,
      connection_name: this.userName,
      provider: this.modalType,
    };

    if (this.modalType === 'github') {
      this.api.fetchGithubProjects(this.accessToken).subscribe(
        (resp: any) => {
          this.fetchProjectLoading = false;
          if (this.saveToken) {
            this.api.saveAccessToken(data).subscribe();
          }

          if (resp.length) {
            // console.log(resp)
            resp.forEach((element: any) => {
              let obj = {
                access_token: this.accessToken,
                createdAt: moment(element.created_at).format('ll'),
                userName: element?.owner?.login,
                repoName: element.name,
                source: this.modalType,
                projectId: element?.projectId,
                defalutBranch: element?.default_branch,
                url: element?.url,
                branchName: element?.branchName,
              };
              this.fetchProjects = [...this.fetchProjects, obj];
              localStorage.setItem(
                'fetchProjects',
                JSON.stringify(this.fetchProjects)
              );
              this.router.navigate([`/dashboard/selectedrepo`]);
            });
          } else {
            this.toaster.warning('Does Not have any project in this account');
          }
        },
        (error) => {
          if (error.status === 401) {
            this.fetchProjectLoading = false;
            this.toaster.error('This is not a valid token for Github');
          } else {
            this.fetchProjectLoading = false;
            this.toaster.error(error.error.message);
          }
        }
      );
    } else if (this.modalType === 'gitlab') {
      const privateRepo = this.api.fetchGitlabProjects(
        this.accessToken,
        'private'
      );
      const publicRepo = this.api.fetchGitlabProjects(
        this.accessToken,
        'public'
      );

      combineLatest(privateRepo, publicRepo).subscribe(
        (resp: any) => {
          this.fetchProjectLoading = false;
          let respondArray = resp.flat(1);

          if (this.saveToken) {
            this.api.saveAccessToken(data).subscribe();
          }

          if (respondArray.length) {
            // console.log(respondArray, 'respondArrayrespondArray');
            respondArray.forEach((element: any) => {
              let obj = {
                owner: {
                  login: element.name,
                },
                access_token: this.accessToken,
                repoName: element.path,
                userName: element.name,
                url: element.http_url_to_repo,
                createdAt: moment(element.updated_at).format('ll'),
                source: this.modalType,
                projectId: element.id,
                defalutBranch: element.default_branch,
                gitlab_name: element.namespace.full_path
              };
              this.fetchProjects = [...this.fetchProjects, obj];
            });

            localStorage.setItem(
              'fetchProjects',
              JSON.stringify(this.fetchProjects)
            );
            this.router.navigate([`/dashboard/selectedrepo`]);
          } else {
            this.toaster.warning('Does Not have any project in this account');
          }
        },
        (error) => {
          if (error.status === 401) {
            this.fetchProjectLoading = false;
            this.toaster.error('This is not a valid token for Gitlab');
          } else {
            this.fetchProjectLoading = false;
            this.toaster.error(error.error.message);
          }
        }
      );
    } else if (this.modalType === 'bitbucket') {
      this.api
        .fetchBitBucketProjects(this.userName, this.accessToken, this.workspaceName)
        .subscribe(
          (resp: any) => {
            if (this.saveToken) {
              this.api.saveAccessToken(data).subscribe();
            }

            resp.values.forEach((element: any) => {
              let obj = {
                access_token: this.accessToken,
                createdAt: moment(element.created_on).format('ll'),
                userName: this.userName,
                repoName: element.name,
                workspaceName: this.workspaceName,
                source: this.modalType,
                projectId: element?.projectId,
                defalutBranch: element?.mainbranch?.name,
                url: element?.links?.self?.href,
              };
              this.fetchProjects = [...this.fetchProjects, obj];
              localStorage.setItem(
                'fetchProjects',
                JSON.stringify(this.fetchProjects)
              );
              this.router.navigate([`/dashboard/selectedrepo`]);
            });
          },

          (error) => {
            if (error.status === 401) {
              this.fetchProjectLoading = false;
              this.toaster.warning('Invalid Username & App password');
            } else if (error.status === 404) {
              this.fetchProjectLoading = false;
              this.toaster.warning(error.error.error.message);
            } else {
              this.fetchProjectLoading = false;
              this.toaster.error('Invalid Username & App password');
            }
          }
        );
    }
    else if (this.modalType === 'azure') {
      this.api.fetchAzureProjects(this.userName, this.accessToken).subscribe(
        (resp: any) => {
          this.fetchProjectLoading = false;

          // //console.log('resp--------------', resp);
          if (this.saveToken) {
            this.api.saveAccessToken(data).subscribe();
          }

          if (resp.value.length) {
            resp.value.forEach((element: any) => {
              let obj = {
                access_token: this.accessToken,
                createdAt: moment(element.created_at).format('ll'),
                userName: this.userName,
                repoName: element.name,
                source: this.modalType,
                projectId: element?.id,
                defalutBranch: element?.defaultBranch,
                url: element?.remoteUrl,
              };
              //console.log(obj, ' objobj');

              this.fetchProjects = [...this.fetchProjects, obj];
              localStorage.setItem(
                'fetchProjects',
                JSON.stringify(this.fetchProjects)
              );
              this.router.navigate([`/dashboard/selectedrepo`]);
            });
          } else {
            this.toaster.warning('Does Not have any project in this account');
          }
        },
        (error: any) => {}
      );
    }

  }

  navigateHandler() {
    this.router.navigate([`/dashboard/selectedrepo`]);
  }

  getUserAccessTokens() {
    this.api.getAccessToken().subscribe(
      (resp) => {
        // //console.log(resp.result, 'here is result');

        // resp.result.map((token: any) => {
        //   let obj: any = {};

        //   if (token.github !== '') {
        //     obj = { ...token, lastCharacter: token.github.slice(-4) };
        //   } else if (token.gitlab !== '') {
        //     obj = { ...token, lastCharacter: token.gitlab.slice(-4) };
        //   } else if (token.bitbucket !== '') {
        //     obj = { ...token, lastCharacter: token.bitbucket.slice(-4) };
        //   }

        //   this.previousTokens.push(obj);
        // });

        this.previousTokens = resp.result;
      },
      (error) => {
        //console.log(error);
      }
    );
  }
  getUserInfo(): void {
    this.profileApi.getUser().subscribe(
      (resp: any) => {
        this.useDetailLoading = false;
        if (resp?.result?.user?.user_company) {
          this.userDetail = resp?.result?.user?.user_company;
        } else {
          this.userDetail = 'Codelock';
        }
      },
      (error: any) => {
        this.useDetailLoading = false;
      }
    );
  }
  openModal(template: TemplateRef<any>, modalType: any) {
    this.accessToken = '';
    this.userName = '';
    this.modalType = modalType;
    localStorage.setItem('modalType', modalType);
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'at-accesstokenmodal' })
    );
  }
  useExistTokenHandler(token: any, username: any) {
    this.accessToken = token;
    this.userName = username;
  }
  useExistBitbucketTokenHandler(token: any, username: any, workspace: any) {
    // //console.log(token, username, workspace, 'here is token');
    this.accessToken = token;
    this.userName = username;
    this.workspaceName = workspace;
  }

  ngOnInit(): void {
    this.getUserAccessTokens();
    this.getUserInfo();
  }
  ngOnDestroy(): void {
    this.modalRef?.hide();
  }
}
