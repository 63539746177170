import { Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { Util } from 'src/app/service/utils';
import {
  updateCliData,
  updateCommitUserName,
  updateRepoData,
  updateTreeData,
} from 'src/app/store/sidebar-filter.action';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
})
export class ProjectDetailsComponent implements OnInit {
  @ViewChild('manuTabs', { static: false }) manuTabs?: TabsetComponent;
  @Output() afterApproveOrDisapprove = new EventEmitter<void>();
  id: any;
  projectInfo: any;
  page = 1;
  total = 0;
  repoScans: any = [];
  serverScans: any = [];
  selectedProjects: any = [];
  cliScans: any = [];
  tab = 'Repository';
  timer: any;
  isProcessing = false;
  approvalId: any;
  notApprovalId: any;
  type: string = '';
  isOTPMultiModalOpen: boolean = false;
  isOTPModalOpen: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private toaster: ToastrService,
    private location: Location,
    private util: Util,
    private store: Store
  ) {}

  ngOnInit(): void {
    // $('[data-toggle="tooltip"]').tooltip();
    if (
      this.util.isPermission('isViewRepoScan') ||
      this.util.isPermission('isViewServerScan')
    ) {
      this.route.paramMap.subscribe((param) => {
        this.id = param.get('id');
        if (this.id && this.id !== '') {
          this.getProjectDetails();
          this.getAllScaninfo();
        }
      });
      this.timer = setInterval(() => {
        if (this.tab === 'Repository') {
          this.getAllScaninfo();
        } else {
          this.getAllServerScan();
        }
      }, 60000);
    } else {
      this.location.back();
    }
  }
  onBack(): void {
    this.location.back();
  }
  getProjectDetails(): void {
    this.isProcessing = false;
    this.api.getProjectDetails(this.id).subscribe(
      (resp) => {
        this.isProcessing = true;
        if (resp.code === 0) {
          this.projectInfo = resp.result;
          ////console.log('project details', this.projectInfo);
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  getAllScaninfo(): void {
    this.isProcessing = false;
    ////console.log("scan info hoodfdfdsf");
    this.api.getAllScanInformation(this.id, this.page).subscribe(
      (resp) => {
        //console.log('scan info', resp);

        this.isProcessing = true;
        if (resp.code === 0) {
          this.repoScans = resp.result.scans;
          // //console.log(this.repoScans[1].pendingcommits.isCommitPushed
          // );
          this.total = resp.result.total;
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  getAllServerScan(): void {
    this.isProcessing = false;
    this.api.getAllServerScan(this.id, this.page).subscribe(
      (resp) => {
        // //console.log(resp);

        this.isProcessing = true;
        if (resp.code === 0) {
          this.serverScans = resp.result.serverScan;
          this.total = resp.result.total;
          // //console.log('server scan', this.serverScans);
          // //console.log('total', this.total);
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  // getAllCliScan(): void {
  //   this.isProcessing = false;
  //   this.api.getAllCliScan(this.id).subscribe(
  //     (resp) => {
  //       // //console.log(resp);

  //       this.isProcessing = true;
  //       if (resp.code === 0) {
  //         this.cliScans = resp.result.serverScan;
  //         this.total = resp.result.total;
  //         //console.log('CLI scan', this.cliScans);
  //         // //console.log('total', this.total);
  //       }
  //     },
  //     (error) => {
  //       if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
  //         this.toaster.error(error.error.result);
  //       } else {
  //         this.toaster.error('Something Went Wrong');
  //       }
  //     }
  //   );
  // }
  onSelectProject(project: any): void {
    const index = this.selectedProjects.indexOf(project);

    if (index === -1) {
      this.selectedProjects.push(project);
    } else {
      this.selectedProjects.splice(index, 1);
    }
  }
  isProjectSelected(projectId: any): boolean {
    return this.selectedProjects.includes(projectId);
  }
  onChangePage(page: number): void {
    if (page !== this.page) {
      this.page = page;
      if (this.tab === 'Repository') {
        this.getAllScaninfo();
      } else {
        this.getAllServerScan();
      }
    }
  }
  onRefresh(): void {
    this.getProjectDetails();
    this.getAllScaninfo();
  }
  triggerManualScan(): void {
    this.api.triggerManualCodeScan(this.id).subscribe(
      (resp) => {
        if (resp.code === 0) {
          this.toaster.success(resp.result);
          this.getAllScaninfo();
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  changeTab(tab: TabDirective): void {
    // //console.log(tab);
    this.tab = tab.heading as string;
    this.page = 1;
    if (this.tab === 'Repository') {
      if (!this.util.isPermission('isViewRepoScan')) {
        tab.active = false;
        return;
      }
      this.getAllScaninfo();
    } else {
      if (!this.util.isPermission('isViewServerScan')) {
        tab.active = false;
        return;
      }
      this.getAllServerScan();
      // this.getAllCliScan();
    }
  }
  ngOnDestroy(): void {
    clearInterval(this.timer);
  }
  // afterApproveOrDisapprove(): void {
  //   this.ngOnInit();
  // }
  goToScanDetails(index: number): void {
    this.store.dispatch(new updateTreeData({ tree: undefined }));
    // //console.log('disput>>', this.repoScans);
    if (
      this.repoScans[index].pending_dispute >= 0 || //change this to > 0
      // this.repoScans[index].scans.length
      this.repoScans[index].commitMessage
    ) {
      this.store.dispatch(
        new updateCommitUserName({
          commitUserName: this.repoScans[index].commitUserName,
        })
      );
      // //console.log(this.repoScans[index].commitUserName);

      if (this.repoScans[index].pendingcommits) {
        this.store.dispatch(new updateTreeData({ tree: 'CLI' }));
        this.store.dispatch(
          new updateCliData({
            cli: this.repoScans[index].pendingcommits.isCommitPushed,
          })
        );
        this.store.dispatch(
          new updateRepoData({
            repo: this.repoScans[index].pendingcommits.repositoryScansId,
          })
        );
      }
      this.router.navigate(
        ['/dashboard/dispute-log/' + this.repoScans[index]._id],
        {
          queryParams: {
            provider: this.projectInfo.provider,
            commitId: this.repoScans[index].commitId,
            commitMessage: this.repoScans[index].commitMessage,
          },
        }
      );
    } else {
      this.toaster.info('No file to supervise');
    }
  }
  onApproveSingleForOTP(id: any): void {
    if (!this.util.isPermission('isViewServerScan')) {
      return;
    }
    this.approvalId = id;
    this.type = 'Approval';
    this.sendOtp(id);
  }
  onDisApproveSingleForOTP(id: any): void {
    if (!this.util.isPermission('isViewServerScan')) {
      return;
    }
    this.notApprovalId = id;
    this.type = 'Not Approval';
    this.sendOtp(id);
  }
  onApproveSingle(otp: number): void {
    // //console.log('onApproveSingle', { resource: 'Project', id: this.id, otp });
    this.api
      .approveServerProject(
        { resource: 'Project', id: this.id, otp },
        this.approvalId
      )
      .subscribe(
        (resp) => {
          if (resp.code === 0) {
            this.toaster.success('Dispute Approved Successfully');
            this.approvalId = undefined;
            // this.afterApproveOrDisapprove.emit();
          } else if (resp.code === 97) {
            this.toaster.error(resp.result);
          } else {
            this.toaster.error(resp.result);
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
  }
  onDisApproveSingle(otp: number): void {
    // //console.log('onDisApproveSingle', {
    //   resource: 'Project',
    //   id: this.id,
    //   otp,
    // });
    this.api
      .notApproveServerProject(
        { resource: 'Project', id: this.id, otp },
        this.notApprovalId
      )
      .subscribe(
        (resp: any) => {
          if (resp.code === 0) {
            this.toaster.success('Dispute Not Approved');
            // this.afterApproveOrDisapprove.emit();
            this.notApprovalId = undefined;
          } else if (resp.code === 97) {
            this.toaster.error(resp.result);
          } else {
            this.toaster.error(resp.result);
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
  }
  sendOtp(id: any): void {
    this.api
      .sendOtpForServerProjectApproval({ resource: 'Project', id: this.id }, id)
      .subscribe(
        (resp) => {
          if (resp.code === 0) {
            this.toaster.success('OTP Sent');
            this.isOTPModalOpen = true;
          } else if (resp.code === 97) {
            this.toaster.error(resp.result);
          } else {
            if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
              this.toaster.error(resp.result);
            } else {
              this.toaster.error('Internal Server Error');
            }
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
  }
  sendOtpForMultiSelect(type: string): void {
    this.type = type;
    this.api
      .sendOtpForMultiSelect(
        {scanIds: this.selectedProjects}
      )
      .subscribe(
        (resp) => {
          if (resp.code === 0) {
            this.toaster.success('OTP Sent');
            this.isOTPMultiModalOpen = true;
          } else if (resp.code === 97) {
            this.toaster.error(resp.result);
          } else {
            if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
              this.toaster.error(resp.result);
            } else {
              this.toaster.error('Internal Server Error');
            }
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );

  }
  modalStatusChange(status: string) {
    if (status == 'close') {
      this.isOTPModalOpen = false;
    } else if (status === 'resendOTP') {
      this.resendOTP();
    }
  }
  resendOTP() {
    if (this.type == 'Approval') {
      this.sendOtp(this.approvalId);
    } else {
      this.sendOtp(this.notApprovalId);
    }
  }

  onApprove(otp: Number) {
    console.log('otp', otp);
    this.api.approveMultiSelect({scanIds: this.selectedProjects, otp: otp}).subscribe(
      (resp) => {
        if (resp.code === 0) {
          this.toaster.success('Dispute Approved Successfully');
          this.selectedProjects = [];
          this.isOTPMultiModalOpen = false;
          this.getAllScaninfo();
        } else if (resp.code === 97) {
          this.toaster.error(resp.result);
        } else {
          this.toaster.error(resp.result);
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  onDisapprove(otp: Number) {
        console.log('otp', otp);
        this.api
          .disapproveMultiSelect({ scanIds: this.selectedProjects, otp: otp })
          .subscribe(
            (resp) => {
              if (resp.code === 0) {
                this.toaster.success('Dispute disapproved Successfully');
                this.selectedProjects = [];
                this.isOTPMultiModalOpen = false;
                this.getAllScaninfo();
              } else if (resp.code === 97) {
                this.toaster.error(resp.result);
              } else {
                this.toaster.error(resp.result);
              }
            },
            (error) => {
              if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
                this.toaster.error(error.error.result);
              } else {
                this.toaster.error('Something Went Wrong');
              }
            }
          );
  }
  onDisapprovel(){

  }
  
}
