import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../../service/authentication.service';
import Keyboard from 'simple-keyboard';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ApiService } from 'src/app/service/api.service';
import { map } from 'rxjs';
import { EventService } from 'src/app/service/event.service';
import * as moment from 'moment';
declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [
    '../../../../../node_modules/simple-keyboard/build/css/index.css',
    './login.component.scss',
  ],
})
export class LoginComponent implements OnInit {
  @Output() changePage = new EventEmitter<string>();
  @Output() modalEvent = new EventEmitter<string>();
  @ViewChild('otpModal') otpModal: ElementRef<HTMLElement> | undefined;
  @ViewChild('detectFace') detectFace: ElementRef<HTMLElement> | undefined;
  @ViewChild('video') video!: ElementRef;
  @ViewChild('canvas') canvas!: ElementRef;
  @ViewChild('faceRecognizeModal') faceRecognizeModal?: ModalDirective;
  autoData: any;
  error: any;
  isCaptured = false;
  faceDetectionAttempts: number = 0;
  orgId: any;
  WIDTH = 640;
  HEIGHT = 480;
  localstream: MediaStream | undefined;
  intervalOp: any;
  timer: number = 59;
  timer_min: number = 4;
  // accoundId: string = '';
  fullScreen = false;
  otp: number | undefined;
  showOtp = false;
  showKeyboard = false;
  keyboard: Keyboard | undefined;
  loginForm = new UntypedFormGroup({
    // isRoot: new FormControl('true', Validators.required),
    accountId: new UntypedFormControl([
      null,
      Validators.required,
      Validators.pattern('^(0|[1-9][0-9]*)$'),
      Validators.min(100000),
      Validators.max(999999)
    ]),
    email: new UntypedFormControl(null, Validators.required),
    password: new UntypedFormControl(null, Validators.required),
  });

  constructor(
    private toaster: ToastrService,
    private router: Router,
    private auth: AuthenticationService,
    private store: Store,
    private api: ApiService,
    private activeRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    // this.auth.logout();
    // this.accoundId = '';
    this.otp = 0;

    this.activeRoute.paramMap
      .pipe(map(() => window.history.state))
      .subscribe((state) => {
        //  this.highlight = state && state.highlight;
        // //console.log('state', state);
        if (state['error']) {
          this.toaster.error(state['message']);
        }
      });
    // this.loginForm?.get('isRoot')?.valueChanges.subscribe((v: any) => {
    //   if (v == 'false') {
    //     // //console.log('vvvvv', v);
    //     this.loginForm.controls['accountId']?.setValidators([
    //       Validators.required,
    //       Validators.pattern('^(0|[1-9][0-9]*)$'),
    //       Validators.min(100000),
    //     ]);
    //   } else {
    //     this.loginForm.controls['accountId']?.clearValidators();
    //   }
    //   this.loginForm.controls['accountId'].updateValueAndValidity();
    // });
  }
  ngOnChanges(): void {
    // this.accoundId = '';
    //this.auth.logout();
    ////console.log("test");
  }
  // isNumberKey(event: any): void {
  //   //console.log(event);

  //   if (event.keyCode === 8) {
  //     this.accoundId = this.accoundId.slice(0, -1);
  //   } else {
  //     this.accoundId = this.accoundId + event.key;
  //   }
  //   //console.log(this.accoundId);
  // }

  startTimer(): void {
    this.intervalOp = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        if (this.timer_min > 0) {
          this.timer = 59;
          this.timer_min--;
        }
      }
    }, 1000);
  }
  resendOtp(): void {
    clearInterval(this.intervalOp);
    this.timer = 59;
    this.timer_min = 4;
    // this.startTimer();
    // this.showOtp = true;
    // this.toaster.success(
    //   'Please Check Your Mail/Phone',
    //   'OTP Sent'
    // );
    this.onLogin();
  }
  // resendOTP(): void {
  //   this.eventService.resendOTP();
  //   this.modalEvent.emit('resendOTP');
  // }
  onLogin(): void {
    if (this.loginForm.valid) {
      const { isRoot, accountId, email, password } =
        this.loginForm.getRawValue();
      let data: any = { email: email.toLowerCase(), password };
      data.accountId = accountId;
      // //console.log(data.accountId);

      // if (isRoot == 'false') {
      // }
      ////console.log("here");
      this.auth.login(data).subscribe(
        (resp) => {
          if (resp.code === 0) {
            let date = moment().add(15, "m");
            ////console.log("resp", resp.result.permissions);
            this.auth.saveToken(
              resp.result.token,
              resp.result.first_name,
              resp.result.last_name,
              resp.result.accountId,
              resp.result.org_id,
              resp.result.org_name,
              resp.result.type,
              resp.result.two_step_on,
              resp.result.permissions,
              date.toISOString()
            );
            this.orgId = resp.result.org_id;
            if (resp.result.verify_face) {
              this.auth.setUserData(resp.result);
              this.fullScreen = true;
              this.faceRecognizeModal?.show();
              this.setupDevices();
              if (this.fullScreen) {
                // this.onDetectFace();
                this.autoData = setTimeout(() => {
                  this.onDetectFace();
                }, 5000);
              }
            }
            else {
              if (resp.result.email_verified) {
                if(resp.result.two_step_on){

                this.startTimer();
                this.showOtp = true;
                this.toaster.success(
                  'Please Check Your Mail/Phone',
                  'OTP Sent'
                );
                }
                else if(!resp.result.two_step_on){
                  this.auth.setUserData(resp.result);
                  this.checkSubStatus();
                }
              } else {
                // this.router.navigate(['/auth/questioner-verification']);
                this.router.navigate(['/auth/email-verification']);
              }
            }



            // //console.log(resp);
            // if (resp.result.is_question_answered) {
            //   if (resp.result.recognition) {
            //     this.auth.setUserData(resp.result);
            //     this.fullScreen = true;
            //     this.faceRecognizeModal?.show();
            //     this.setupDevices();
            //     if (this.fullScreen) {
            //       // this.onDetectFace();
            //       this.autoData = setInterval(() => {
            //         this.onDetectFace();
            //       }, 5000);
            //     }
            //     // this.detectFace?.nativeElement.click();
            //   }
            //    else if (!resp.result.two_step_on) {
            //     if (resp.result.reset_password_on_next_signup) {
            //       this.router.navigate(['reset-password']);
            //     } else if (resp.result.email_verified) {
            //       this.auth.setUserData(resp.result);

            //       this.checkSubStatus();
            //     } else {
            //       // this.changePage.emit('verify-email');
            //       this.router.navigate(['/auth/email-verification']);
            //     }
            //   } else {
            //     this.showOtp = true;
            //     this.toaster.success(resp.result.message);
            //   }
            // } else {
            //   this.router.navigate(['/auth/questioner-verification']);
            // }
          } else if (resp.code === 3) {
            this.toaster.error(resp.result);
          } else {
            if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
              this.toaster.error(resp.result);
            } else {
              this.toaster.error('Internal Server Error');
            }
          }
        },
        (_) => {
          this.toaster.error('Something Went Wrong');
        }
      );
    } else {
      this.markTouch();
    }
  }
  // onSignup(): void {
  //   this.changePage.emit('signup');
  // }
  markTouch(): void {
    Object.keys(this.loginForm.controls).forEach((field) => {
      const control = this.loginForm.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
  }
  onVerifyOtp(): void {
    const { accountId, email, password, ...rest } =
      this.loginForm.getRawValue();
    let data: any = {
      email: email.toLowerCase(),
      ...rest,
      otp: this.otp,
      org_id: this.orgId,
      password,
    };
    // if (isRoot == 'false') {
    //   data.accountId = accountId;
    // }
    //  {
    //     ...this.loginForm.getRawValue(),
    //     email: this.loginForm.getRawValue().email.toLowerCase(),
    //     otp: this.otp,
    //   }
    this.auth.verifyRootUserOtp(data).subscribe(
      (resp) => {
        if (resp.code === 0) {
          ////console.log("next signup: " + resp.result.reset_password_on_next_signup)

          this.auth.setUserData(resp.result);
          if (resp.result.reset_password_on_next_signup) {
            // this.changePage.emit('reset-password');
            this.router.navigate(['/auth/change-password-eula']);
          } else if (resp.result.email_verified) {
            let date = moment().add(15, "m");
            ////console.log("here we are", resp.result.permissions);

            this.auth.saveToken(
              resp.result.token,
              resp.result.first_name,
              resp.result.last_name,
              resp.result.accountId,
              resp.result.org_id,
              resp.result.org_name,
              resp.result.type,
              resp.result.two_step_on,
              resp.result.permissions,
              date.toISOString(),
              resp.result.userId
            );

            this.checkSubStatus();
          } else {
            // this.changePage.emit('verify-email');
            this.router.navigate(['/auth/email-verification']);
          }
        } else if (resp.code === 3) {
          this.toaster.error(resp.result);
        } else {
          if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
            this.toaster.error(resp.result);
          } else {
            this.toaster.error('Internal Server Error');
          }
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  onOtpChange(otp: string): void {
    this.otp = Number(otp);
  }
  async checkSubStatus(): Promise<void> {
    const subStatus = await this.auth.checkSubStatus();
    this.router.navigate(['/home']);
  }
  onKeyBord(): void {
    if (!this.showKeyboard) {
      this.loginForm.patchValue({ password: null });
    }
    this.showKeyboard = !this.showKeyboard;
  }
  ngAfterViewInit(): void {
    // this.keyboard = new Keyboard({
    //   onChange: (input) => this.onChange(input),
    //   onKeyPress: (button: any) => this.onKeyPress(button),
    //   stopMouseUpPropagation: false,
    // });
    // $(document).on('click', (event: Event) => {
    //   if ($(event.target).closest('#simple-keyboard').length === 0) {
    //     if ($('#simple-keyboard').css('display') === 'block') {
    //       $('#simple-keyboard').css('display', 'none');
    //     }
    //   }
    // });
  }

  onChange = (input: string) => {
    this.loginForm.patchValue({ password: input });
  };

  onKeyPress = (button: string) => {
    if (button === '{shift}' || button === '{lock}') {
      this.handleShift();
    }
  };

  onInputChange = (event: any) => {
    this.keyboard?.setInput(event.target.value);
  };

  handleShift = () => {
    const currentLayout = this.keyboard?.options.layoutName;
    const shiftToggle = currentLayout === 'default' ? 'shift' : 'default';

    this.keyboard?.setOptions({
      layoutName: shiftToggle,
    });
  };
  afterDetectFace(ImageId: any): void {
    this.auth
      .verifyFaceId({
        ...this.loginForm.getRawValue(),
        email: this.loginForm.getRawValue().email.toLowerCase(),
        ImageId, org_id: this.orgId
      })
      .subscribe(
        (resp) => {
          if (resp.code === 0) {
            ////console.log(resp.result);
            this.toaster.success('Identity verified!');
            this.auth.setUserData(resp.result);
            if (resp.result.reset_password_on_next_signup) {
              // this.changePage.emit('reset-password');
              this.router.navigate(['/auth/change-password-eula']);
            } else if (resp.result.email_verified) {
              let date = moment().add(15, "m");
              this.auth.saveToken(
                resp.result.token,
                resp.result.first_name,
                resp.result.last_name,
                resp.result.accountId,
                resp.result.org_id,
                resp.result.org_name,
                resp.result.type,
                resp.result.two_step_on,
                resp.result.permissions,
                date.toISOString()
              );
              // if (resp.result.two_step_on) {
              //   // this.fullScreen = false;
              //   this.faceRecognizeModal?.hide();
              //   this.showOtp = true;
              // } else {
              //   this.checkSubStatus();
              // }
              this.startTimer();
              this.faceRecognizeModal?.hide()
              this.showOtp = true;
              this.toaster.success(
                'Please Check Your Mail/Phone',
                'OTP Sent'
              );
            } else {
              // this.changePage.emit('verify-email');
              this.router.navigate(['/auth/email-verification']);
            }
          } else if (resp.code === 3) {
            this.toaster.error(resp.result);
          } else {
            if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
              this.toaster.error(resp.result);
            } else {
              this.toaster.error('Internal Server Error');
            }
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
  }
  afterClose(): void {
    this.fullScreen = false;
  }
  // // forgot password navigation
  // onForgotPassword(): void {
  //   this.changePage.emit('request-change-password');
  //   // this.router.navigate(['/request-change-password']);
  // }

  async setupDevices(): Promise<void> {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (stream) {
          this.video.nativeElement.srcObject = stream;
          this.localstream = stream;
          this.video.nativeElement.play();
          this.error = null;
        } else {
          this.error = 'You have no output video device';
        }
      } catch (e) {
        this.error = e;
      }
    }
  }
  onDetectFace(): void {
    this.drawImageToCanvas(this.video.nativeElement);

    this.api.detectFace({
      photo: this.canvas.nativeElement.toDataURL('image/png'),
      email: this.loginForm.getRawValue().email.toLowerCase(),
      org_id: this.orgId
    })
    .subscribe(
      (resp) => {
        if (resp.code === 0) {
          ////console.log("face detected");
          this.isCaptured = true;
          this.stopVideo();
          this.afterDetectFace("verified");
        } else {
          ////console.log("face not detected");
          this.faceDetectionAttempts++;
          if (this.faceDetectionAttempts < 5) {
            setTimeout(() => {
              this.onDetectFace();
            }, 2000); // 2 seconds delay, adjust as needed
          } else {
            // Reset counter and maybe provide feedback to the user or perform some other action
            this.faceDetectionAttempts = 0;
            this.toaster.error('Face not detected after 5 attempts. Please try again.');
            this.stopVideo();
          }
        }
      },
      (error) => {
        console.error("API Error:", error);
        this.stopVideo();
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong! Try again');
        }
      }

    );
  }

  stopVideo(): void {
    this.localstream?.getTracks()[0].stop();
    this.faceRecognizeModal?.hide();
    clearInterval(this.autoData);
  }
  drawImageToCanvas(image: any): void {
    this.canvas.nativeElement
      .getContext('2d')
      .drawImage(image, 0, 0, this.WIDTH, this.HEIGHT);
    this.isCaptured = false;
  }
}

