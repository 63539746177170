import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Role } from 'src/app/interface/role';
import { ApiService } from 'src/app/service/api.service';
import { Util } from 'src/app/service/utils';
import { CommaSeperateEmailsValidator } from 'src/app/shared/comma-seperate-emails-validator';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss'],
})
export class ManageUserComponent implements OnInit {
  @ViewChild('modalCreateNewAccount', { static: false })
  modalCreateNewAccount?: ModalDirective;
  @ViewChild('modalCreateBulkAccount', { static: true })
  modalCreateBulkAccount?: ModalDirective;
  @ViewChild('modalUserImport', { static: true })
  modalUserImport?: ModalDirective;
  page = 1;
  term = '';
  total = 0;
  orgUsers: any = [];
  deleteUserId: string | any = '';
  editUserId: string | any = '';
  type = 'Add';
  cuser: any;
  bulkUserAddErrors: any;
  subscriptions: any;
  permission = true;
  currentData = new Date().getTime();
  maxSeat: number = 0;
  usedSeat: number = 0;
  remainSeat: number = 0;
  plans: any = [];
  trialPlan: any;
  isProcessing: boolean = false;
  selectedPlanIndex: number = 0;
  subsPlanName: string = '';
  subsPlanId : string = '';
  roles: Role[] = [];
  isInactiveStatus: boolean = false;
  isDisableStatus: boolean = false;
  importEmails: string[] = [];
  importFiles: any;
  countryCodes: any[] = [];
  isWhiteList: boolean = false;
  resource: string | undefined;
  resource_id: string | undefined;
  whitelistEmail: string | undefined;
  notifiedOptions: any = [
    { key: 'email', label: 'Email' },
    { key: 'phone', label: 'Phone' },
    { key: 'both', label: 'Both' },
  ];
  regexPassword =
    /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&()])[A-Za-z\d@#$!%*?&()]{10,}$/;
  addOrgUserForm = new UntypedFormGroup({
    username: new UntypedFormControl(null, Validators.required),
    first_name: new UntypedFormControl(null, Validators.required),
    last_name: new UntypedFormControl(null, Validators.required),
    email: new UntypedFormControl(null, [Validators.required, Validators.email]),
    isAutoGenerate: new UntypedFormControl(true),
    notifyUser: new UntypedFormControl(true),
    notify: new UntypedFormControl('email'),
    phone_no: new UntypedFormControl(null),
    country_code: new UntypedFormControl(null),
    password: new UntypedFormControl(null),
    role: new UntypedFormControl(null),
    resetPassword: new UntypedFormControl(true),
    isEnabled: new UntypedFormControl(true),
  });
  bulkUserAddForm = new UntypedFormGroup({
    emails: new UntypedFormControl(
      '',

      Validators.compose([Validators.required, CommaSeperateEmailsValidator()])
    ),
    message: new UntypedFormControl(null),
  });
  bulkUserImportForm = new UntypedFormGroup({
    emails: new UntypedFormControl(null, Validators.required),
  });


  constructor(
    private api: ApiService,
    private toaster: ToastrService,
    private util: Util,
    private ngxCsvParser: NgxCsvParser,
    private router: Router,
    private location: Location,
    private activeRoute: ActivatedRoute
  ) {}
  ngOnInit(): void {
    if (this.util.isPermission('isViewUser')) {
      this.getAllOrganizationUser(this.page);
      this.getCurrentUser();
      this.getRoles();
      this.getAllCountryCode();
      this.getSubscription();
      let self = this;
      this.activeRoute.queryParams.subscribe((data: any) => {
        this.onWhiteListUserAdd(data);
      });
      this.addOrgUserForm.get('isAutoGenerate')?.valueChanges.subscribe((v) => {
        // //console.log('true', v, v == true);
        if (v == false) {
          this.addOrgUserForm
            .get('password')
            ?.setValidators([
              Validators.required,
              Validators.pattern(this.regexPassword),
            ]);
        } else {
          this.addOrgUserForm.get('password')?.clearValidators();
        }
        this.addOrgUserForm.get('password')?.updateValueAndValidity();
      });
    } else {
      this.location.back();
    }
  }
  getCurrentUser(): void {
    this.api.getUser().subscribe((resp) => {
      if (resp.code === 0) {
        this.cuser = resp.result.user;
      }
    });
  }
  getAllOrganizationUser(page: number = 1): void {
    let isActive = true;
    if (!this.isDisableStatus) {
      isActive = !this.isInactiveStatus;
    } else {
      isActive = true;
    }
    this.api
      .getAllOrganizationUser(this.term, page, isActive, !this.isDisableStatus)
      .subscribe(
        (resp) => {
          if (resp.code === 0) {
            this.orgUsers = resp.result.users;
            this.total = resp.result.count;
          } else if (resp.code === 97) {
            this.toaster.error('You are Not Authorized to View This Page');
          } else {
            if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
              this.toaster.error(resp.result);
            } else {
              this.toaster.error('Internal Server Error');
            }
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
  }
  onChangePage(page: number): void {
    if (this.page === page) {
      return;
    }
    this.page = page;
    this.getAllOrganizationUser(page);
  }
  onCreateNew(): void {
    this.bulkUserAddErrors = null;
    if (this.util.isPermission('isAddUser')) this.modalCreateNewAccount?.show();
  }
  onCreateBulkAccount(): void {
    this.bulkUserAddErrors = null;
    // this.bulkUserAddForm.reset();
    if (this.util.isPermission('isAddUser'))
      this.modalCreateBulkAccount?.show();
  }
  closeBulkUserCreateModal(): void {
    this.modalCreateBulkAccount?.hide();
    this.bulkUserAddForm.reset();
  }
  onDelete(id: string): void {
    this.deleteUserId = id;
    // this.deleteConfirmModal.nativeElement.click();
  }
  onDeleteModalClose(): void {
    this.deleteUserId = null;
  }
  onDeleteConfirm(): void {
    this.api.removeOrganizationUser(this.deleteUserId).subscribe(
      (resp) => {
        if (resp.code === 0) {
          this.toaster.success(resp.result);
          this.deleteUserId = null;
          this.getAllOrganizationUser(this.page);
        } else if (resp.code === 12) {
          this.toaster.error(resp.result);
        } else {
          if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
            this.toaster.error(resp.result);
          } else {
            this.toaster.error('Internal Server Error');
          }
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  onSubmit(): void {
    if (this.addOrgUserForm.valid) {
      const { phone_no, country_code, notify, ...rest } =
        this.addOrgUserForm.getRawValue();
      let data = { ...rest };
      if (notify == 'phone' || notify == 'both') {
        data.phone_no = country_code + phone_no;
        data.country_code = country_code;
      }
      if (this.isWhiteList) {
        data.isWhitelistUser = true;
      }

      this.api
        .addOrganizationUser({
          ...data,
          email: this.addOrgUserForm.get('email')?.value.toLowerCase(),
        })
        .subscribe(
          (resp) => {
            if (resp.code === 0) {
              this.toaster.success('User Added');
              this.createUserModalClose();
              this.getAllOrganizationUser(this.page);
              if (this.isWhiteList) {
                this.router.navigate(['/dashboard/members'], {
                  queryParams: {
                    id: this.resource_id,
                    resource: this.resource,
                    u: resp.result._id,
                    isWhitelist: this.isWhiteList,
                  },
                });
              }
            } else if (resp.code === 4) {
              this.toaster.error(resp.result);
            } else if (resp.code === 5) {
              this.toaster.error(resp.result);
            } else if (resp.code === 96) {
              this.toaster.error(resp.result);
            } else if (resp.code === 100) {
              this.toaster.error(resp.result);
            } else {
              if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
                this.toaster.error(resp.result);
              } else {
                this.toaster.error('Internal Server Error');
              }
            }
          },
          (error) => {
            if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
              this.toaster.error(error.error.result);
            } else {
              this.toaster.error('Something Went Wrong');
            }
          }
        );
    } else {
      this.addOrgUserForm.markAllAsTouched();
    }
  }
  createUserModalClose(): void {
    this.modalCreateNewAccount?.hide();
    this.addOrgUserForm.reset();
  }
  getRoles(): void {
    this.api.getRoles().subscribe(
      (resp: any) => {
        // //console.log('roles>>>', resp);
        if (resp.code === 0) {
          this.roles = resp.result.data;
        }
      },
      (error: HttpErrorResponse) => {
        // //console.log('error>>', error);
      }
    );
  }

  // onEdit(id: string): void {
  //   this.editUserId = id;
  //   this.type = 'Edit';
  //   $('#profile__add-user').addClass('active');
  // }
  // onEditModalClose(): void {
  //   this.editUserId = null;
  //   this.type = 'Add';
  //   this.getAllOrganizationUser();
  // }
  // onAddNewUser(): void {
  //   this.type = 'Add';
  // }
  search(): void {
    // //console.log('user>>', this.isInactiveStatus);
    this.page = 1;
    this.getAllOrganizationUser(this.page);
  }
  onImport(): void {
    this.bulkUserAddErrors = null;
    this.importEmails = [];
    if (this.util.isPermission('isAddUser')) this.modalUserImport?.show();
  }
  bulkSubmit(): void {
    if (this.bulkUserAddForm.valid) {
      const bulkUserEmails = this.bulkUserAddForm
        .getRawValue()
        .emails.split(',')
        .map((e: any) => e.trim());
      this.api.addBulkUser({ emails: bulkUserEmails }).subscribe(
        (resp: any) => {
          // //console.log('resp>>', resp, resp.code);
          if (resp.code === 0) {
            this.toaster.success('Users have been added successfully');
            // this.modalCreateBulkAccount?.hide();
            this.bulkAddModalClose();
            this.bulkUserAddErrors = null;
            this.page = 1;
            this.getAllOrganizationUser(this.page);
          } else if (resp.code === 5) {
            // this.toaster.error(resp?.message);
            // this.modalCreateBulkAccount?.hide();
            this.bulkAddModalClose();
            this.bulkUserAddErrors = resp?.result;
          } else if (resp.code === 96) {
            // this.toaster.error(resp?.message);
            // this.modalCreateBulkAccount?.hide();
            // //console.log('error', resp?.message);
            this.toaster.error(resp?.message);
            this.bulkAddModalClose();
          } else {
            this.bulkAddModalClose();
            // this.modalCreateBulkAccount?.hide();
            if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
              this.toaster.error(resp.result);
            } else {
              this.toaster.error('Internal Server Error');
            }
          }
          // $('#add_user_bulk').modal('hide');
        },
        (error: any) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
    } else {
      this.bulkUserAddForm.markAllAsTouched();
    }
  }
  bulkUserImportSubmit(): void {
    if (this.bulkUserImportForm.valid) {
      if (this.importEmails.length) {
        this.api.addBulkUser({ emails: this.importEmails }).subscribe(
          (resp: any) => {
            // //console.log('resp>>', resp);
            if (resp.code === 0) {
              this.toaster.success('Users have been added successfully');
              this.importModalClose();
              this.bulkUserAddErrors = null;
              this.getAllOrganizationUser(this.page);
            } else if (resp.code === 5) {
              // this.toaster.error(resp?.message);
              this.importModalClose();
              this.bulkUserAddErrors = resp?.result;
            } else if (resp.code === 96) {
              // this.toaster.error(resp?.message);
              this.importModalClose();
              this.toaster.error(resp?.message);
            } else {
              this.importModalClose();
              if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
                this.toaster.error(resp.result);
              } else {
                this.toaster.error('Internal Server Error');
              }
            }
            // $('#add_user_bulk').modal('hide');
          },
          (error: any) => {
            if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
              this.toaster.error(error.error.result);
            } else {
              this.toaster.error('Something Went Wrong');
            }
          }
        );
      }
    } else {
      this.bulkUserImportForm.markAllAsTouched();
    }
  }
  removeImport(): void {
    this.importFiles = null;
    this.importEmails = [];
    this.bulkUserImportForm.reset();
  }
  onFileChange(e: any): void {
    this.importEmails = [];

    if (e.target.files.length) {
      this.importFiles = e.target.files[0];
      // //console.log('import>>', this.importFiles);
      this.ngxCsvParser
        .parse(this.importFiles, { header: true, delimiter: ',' })
        .pipe()
        .subscribe(
          (result: any) => {
            this.importEmails = result.map((r: any) => {
              if (
                Object.prototype.hasOwnProperty.call(r, 'Email') ||
                Object.prototype.hasOwnProperty.call(r, 'email')
              ) {
                return r['email'] || r['Email'];
              }
            });
            // //console.log('impo>>', this.importEmails);
            // this.csvRecords = result;
          },
          (error: NgxCSVParserError) => {
            // //console.log('Error', error);
            this.importFiles = null;
            this.toaster.error(error.message);
          }
        );
    }
  }
  bulkAddModalClose(): void {
    this.modalCreateBulkAccount?.hide();
    this.bulkUserAddForm.patchValue({ emails: null, message: '' });
    // this.bulkUserAddForm.reset();
  }
  importModalClose(): void {
    this.modalUserImport?.hide();
    this.importEmails = [];
    this.bulkUserImportForm.reset();
  }
  getAllCountryCode(): void {
    this.api.getAllCountryCode().subscribe((resp) => {
      if (resp.code === 0) {
        this.countryCodes = resp.result.map((c: any) => {
          return { ...c, label: c.code + ' (' + c.name + ')' };
        });
      }
    });
  }
  onNotificationTypeChange(e: any): void {
    if (e.key == 'email') {
      this.addOrgUserForm.patchValue({ phone_no: null, country_code: null });
      this.addOrgUserForm.controls['phone_no'].clearValidators();
      this.addOrgUserForm.controls['country_code'].clearValidators();
    } else {
      this.addOrgUserForm.controls['phone_no'].addValidators([
        Validators.required,
      ]);
      this.addOrgUserForm.controls['country_code'].addValidators([
        Validators.required,
      ]);
    }
    this.addOrgUserForm.controls['country_code'].updateValueAndValidity();
    this.addOrgUserForm.controls['phone_no'].updateValueAndValidity();
  }
  onWhiteListUserAdd(data: any) {
    if (Object.prototype.hasOwnProperty.call(data, 'isWhitelistUser')) {
      this.isWhiteList = true;
      this.resource = data.resource;
      this.resource_id = data.gt_id;
      this.whitelistEmail = data.u;
      setTimeout(() => {
        this.modalCreateNewAccount?.show();
      }, 1000);
      this.addOrgUserForm.patchValue({ email: data.u });
    } else {
      this.isWhiteList = false;
      this.resource = undefined;
      this.resource_id = undefined;
      this.whitelistEmail = undefined;
    }
  }
  getSubscription(): void {
    this.api.getAllSubscriptionForOrg().subscribe(
      (resp: any) => {
        if (resp.code === 0) {
          this.maxSeat = resp?.result?.max_seat;
          this.remainSeat = resp?.result?.max_seat_remaining;
          this.usedSeat = this.maxSeat - this.remainSeat;
          this.subscriptions = resp.result.subs 
          this.isProcessing = true;

          this.subsPlanId = resp?.result.subs.plan_id
          // //console.log(this.subsPlanId)
          var subsPlan = this.plans.filter((plan: any) => plan.plan_id == this.subsPlanId)[0] || this.trialPlan 
          // //console.log(subsPlan)
          this.subsPlanName = subsPlan.invoice_name
          // //console.log(this.subsPlanName)
         
          // this.getSelectedPlanIndex();
          // //console.log('sub>>>', this.subscriptions);
          // .map((e: any) => {
          //   return {
          //     ...e,
          //     subs_end_date: new Date(e.sub_end_date).getTime(),
          //   };
          // });
        } else if (resp.code === 97) {
          this.permission = false;
          this.toaster.error('You are Not Authorized to View This Page');
        } else {
          if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
            this.toaster.error(resp.result);
          } else {
            this.toaster.error('Internal Server Error');
          }
        }
      },
      (error: HttpErrorResponse) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  numberOnly(event : any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  onPastePhoneNumber(){
    let pastedText = this.addOrgUserForm.get('phone_no')?.value; //clipboardData?.getData('text');
    pastedText = pastedText?.replace(/[^0-9]/g, '');
    // //console.log(pastedText)
    // this.signupForm.reset({phone_no: ''});
    this.addOrgUserForm.get('phone_no')?.patchValue(pastedText)
  }
}
