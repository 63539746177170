<div class="site__authentication site__signin">
  <div class="container-fluid align-self-center">
    <div class="row justify-content-center">
      <div class="col-md-4 text-center">
        <div class="logo">
          <img src="/assets/images/logo__icon.svg" alt="" />
        </div>
        <h5 class="mb-4">
          Please fillup our questionary details before continuing.
        </h5>
        <div class="button-group justify-content-center">
          <button
            type="submit"
            class="button button--secondary"
            (click)="onResend()"
          >
            Resend questionary link
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
