<div class="text-center mb-5" *ngIf="!subscriptions && isProcessing">
  <p class="mb-4">
    <span class="weight-700 d-block pb-1">CodeLock is currently using the Free Plan.</span>
    Learn more about each plan by reading our frequently asked questions, or
    start a free 30-day trial
  </p>
  <a href="javascript:void(0)" class="button button--primary" (click)="startTrial()">Start your free trial</a>
</div>
<div class="row mb-5" *ngIf="isProcessing">
  <div class="col-md-4">
    <div class="plan">
      <span class="plan__title">Compliance Starter Plan</span>
      <div class="plan__pricing">
        <div class="d-flex align-items-center">
          <strong>$19</strong>
          <span>per user <br>monthly</span>
        </div>
        <span class="d-block weight-600">billed annually at $228</span>
      </div>
      <div class="plan__features">

        <ul class="mb-4">
          <li>NIST 800-218 SSDF Dashboard</li>
          <li>SSDF Tracking & Tracing</li>
          <li>User Role Management</li>
          <li>Multi-Factor Authentication</li>
          <li>Conditional Access Control</li>
          <li>Least Privilege Access Control</li>
          <li>Session Activity Logs (Encrypted)</li>
        </ul>

      </div>
      <div class="plan__buttons">
        <div class="d-flex align-items-center">
          <p class="p--xs m-0 me-3">Click for full features</p>
          <div class="button-group flex-shrink-0 ms-auto">
            <a href="javascript:void(0)" class="button button--primary button--sml"
              (click)="modalContactSales.show()">Contact Sales</a>

            <a href="javascript:void(0)"
              class="button button--primary button--sml" (click)="upgradePlan('starter-saas-plan')">Upgrade</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="plan">
      <span class="plan__title">Security & Compliance Plan</span>
      <div class="plan__pricing">
        <div class="d-flex">
          <div class="d-flex align-items-center flex-shrink-0 me-3">
            <strong>$49</strong>
            <span>per user <br>monthly</span>
          </div>
          <p class="p--xs weight-600 m-0 ms-auto text-end">All the Benefits of Compliance</p>
        </div>
        <span class="d-block weight-600">billed annually at $588</span>
      </div>
      <div class="plan__features">
        <ul class="mb-4">
          <li>Repository Connection</li>
          <li>Repository Monitoring</li>
          <li>Code Scanning</li>
          <li>Code Review</li>
          <li>Continuous Monitoring</li>
          <li>Cryptographic Hashes</li>
          <li>Code Signing</li>
          <li>Integrity Verification</li>
          <li>Provenance Data Chain</li>
          <li>SBOM</li>
          <li>IDE Integration</li>
        </ul>
      </div>
      <div class="plan__buttons">
        <div class="d-flex align-items-center">
          <p class="p--xs m-0 me-3">Click for full features</p>
          <div class="button-group flex-shrink-0 ms-auto">
            <a href="javascript:void(0)" class="button button--primary button--sml"
              (click)="modalContactSales.show()">Contact Sales</a>

            <a href="javascript:void(0)"
              class="button button--primary button--sml" (click)="upgradePlan('premium-saas-plan')">Upgrade</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="plan">
      <span class="plan__title">Enterprize SaaS Plan</span>
      <div class="plan__pricing">
        <div class="d-flex">
          <div class="d-flex align-items-center flex-shrink-0 me-3">
            <strong>$99</strong>
            <span>per user <br>monthly</span>
          </div>
          <p class="p--xs weight-600 m-0 ms-auto text-end">All the Benefits of Security & Compliance+</p>
        </div>
        <span class="d-block weight-600">billed annually at $1,188</span>
      </div>
      <div class="plan__features">
        <ul class="mb-4">
          <li>Behavioral Modeling (AI/ML)</li>
          <li>On Premise Installation or SaaS</li>
          <li>Customized Data Fields</li>
          <li>Customized Reporting</li>
          <li>Log Integration</li>
          <li>Threat Intelligence Feeds</li>
        </ul>
      </div>
      <div class="plan__buttons">
        <div class="d-flex align-items-center">
          <p class="p--xs m-0 me-3">Click for full features</p>
          <div class="button-group flex-shrink-0 ms-auto">
            <a href="javascript:void(0)" class="button button--primary button--sml"
              (click)="modalContactSales.show()">Contact Sales</a>

            <!-- <a href="javascript:void(0)"
              class="button button--primary button--sml disabled" (click)="upgradePlan('ultimate-saas-plan')">Upgrade</a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Old Pricing Blocks -->
  <div class="col-md-4 d-none" *ngFor="let p of plans; index as i">
    <div class="plan plan--free">
      <span class="plan__title">{{ p.invoice_name }}</span>
      <div class="plan__pricing">
        <div class="d-flex align-items-center">
          <strong>${{ format(p.amount / 100) }}</strong>
          <span>per year</span>
          <!-- <span>per 10 user <br />yearly</span> -->
        </div>
      </div>
      <div class="plan__features">
        <!-- <h6>Includes</h6> -->
        <ul class="mb-4">
          <!-- <li *ngFor="let f of p.features">{{ f }}</li> -->
          <li>
            Up to
            <strong>{{ p.order == 1 ? 10 : p.order == 2 ? 100 : 250 }}</strong>
            Developer Users
          </li>
          <li>
            Up to
            <strong>{{ p.order == 1 ? 1 : p.order == 2 ? 5 : 10 }}</strong>
            Group Project
          </li>
        </ul>
        <span>{{ p.details }}</span>
        <!--        <a href="javascript:void(0)">See all features</a>-->
      </div>
      <div class="plan__buttons">
        <div class="button-group justify-content-end">
          <a *ngIf="p.order === 3" href="javascript:void(0)" class="button button--primary button--sml"
            (click)="modalContactSales.show()">Contact Sales</a>

          <a *ngIf="selectedPlanIndex < p.order && p.order > 0" href="javascript:void(0)"
            class="button button--primary button--sml" (click)="upgradePlan(p)">Upgrade</a>
        </div>
      </div>
    </div>
  </div>
  <!-- Old Pricing Blocks -->
</div>

<div class="table-main" *ngIf="subscriptions">
  <div class="block__title">
    <h6 *ngIf="subscriptions">
      CodeLock: {{ subsPlanName }}
    </h6>
    <!--  <a href="javascript:void(0)" class="button button&#45;&#45;open ms-auto">-->
    <!--            <i class="fa fa-plus me-2" aria-hidden="true"></i>-->
    <!--            Upgrade-->
    <!--        </a>-->
  </div>
  <div class="table-responsive">
    <table class="table table-bordered table-vmiddle weight-500 text-center m-0">
      <tbody>
        <tr>
          <td class="row-title" rowspan="2">
            <span class="icon d-flex justify-content-center mb-2">
              <img src="/assets/images/icon__usage.svg" alt="" />
            </span>
            Usage
          </td>
          <td class="weight-600">Seats in subscription</td>
          <td class="weight-600">Seats currently in use</td>
          <td class="weight-600">Remaining seats</td>
        </tr>
        <tr>
          <td>{{ maxSeat }}</td>
          <td>
            {{ usedSeat }}
            <!-- <a href="javascript:void(0)" class="button button--primary button--xsml ms-3">See Usage</a> -->
          </td>
          <td>{{ remainSeat }}</td>
        </tr>
        <tr>
          <td class="row-title" rowspan="2">
            <span class="icon d-flex justify-content-center mb-2">
              <img src="/assets/images/icon__bill.svg" alt="" />
            </span>
            Billing
          </td>
          <td class="weight-600">Subscription start date</td>
          <td class="weight-600">Subscription end date</td>
          <td class="weight-600"></td>
        </tr>
        <tr>
          <td>{{ subscriptions?.createdAt | date: "medium" }}</td>
          <td>{{ subscriptions?.sub_end_date | date: "medium" }}</td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<!-- </div> -->
<!-- </div> -->
<div bsModal #modalContactSales="bs-modal" class="modal fade" tabindex="-1" role="dialog">
  <app-contact-sales (closeModal)="modalContactSales.hide()"></app-contact-sales>
</div>
