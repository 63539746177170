import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SideFilter } from 'src/app/interface/SideFilter';
import { MonitorApiService } from 'src/app/service/monitor-api.service';
import { Util } from 'src/app/service/utils';
import { ChartConfiguration } from 'chart.js';
import { createTextChangeRange } from 'typescript';


@Component({
  selector: 'app-analytics-page',
  templateUrl: './analytics-page.component.html',
  styleUrls: ['./analytics-page.component.scss']
})
export class AnalyticsPageComponent implements OnInit {
  allFilter: any;
  pieChartData: any;
  countryChart?: ChartConfiguration<'bar'>['data'];
  barGraphData: any;
  lineChartData: any;
  bubbleChartData: any;
  bubbleFullData: any;
  lineChartDataRepoWiseUser: any;
  iscountryChart : boolean = false;
  isShowPieChartData: boolean = false;
  isShowBubbleChartData: boolean = false;
  isShowLineChartData: boolean = false;
  isShowBarGraphData: boolean = false;
  isShowLineChartDataRepoWiseUser: boolean = false;

  // Test Graph variable init
  TestbarChartData?: ChartConfiguration<'bar'>['data']
  testBarChartLegend = true
  testBarChartPlugins: any
  countryBarChartOptions: ChartConfiguration<'bar'>['options']
  
  
  type = 'Group';
  config = {
    displaylogo: false,
  };
  layout = {
    yaxis: { tickformat: ',d' },
  };

  constructor(
    private store: Store<{ sideBarFilter: SideFilter }>,
    private api: MonitorApiService,
    private location: Location,
    private router: Router,
    private util: Util
  ) {}

  ngOnInit(): void {
    if (this.util.isPermission('isViewMonitor')) {
      this.store.select('sideBarFilter').subscribe((resp: any) => {
        this.allFilter = resp;

        this.getDataForPieChart();
        this.getDataForBarChart();
        this.getDataForLineChart();
        this.getDataForBubble();
        this.getDataForLineChartRepoWiseUser();
        this.getCountryData()
      });
    } else {
      this.location.back();
    }
  }
  ngAfterViewInit(): void {}


  getCountryData():void{

    // const img1 = new Image()
    // img1.src = "https://i.pinimg.com/564x/b4/ea/3e/b4ea3e33b0f81f35aef1bc2914ff58b4.jpg"

    // const img2 = new Image()
    // img2.src = "https://media.istockphoto.com/photos/flag-round-icon-picture-id697849754"
    
    // const img3 = new Image()
    // img3.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Circle_Flag_of_the_People%27s_Republic_of_China.svg/2048px-Circle_Flag_of_the_People%27s_Republic_of_China.svg.png"

    // const img4 = new Image()
    // img4.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Circle_Flag_of_the_People%27s_Republic_of_China.svg/2048px-Circle_Flag_of_the_People%27s_Republic_of_China.svg.png"


    this.api.getCountryData().subscribe((resp: any) => {
      // //console.log('getAllDataForPieChart>>>', resp);
      if (resp.code === 0) {
        if (JSON.stringify(resp.result) == "{}") {
          this.iscountryChart = false;
        } else {
          this.iscountryChart = true;
        }
        this.countryChart = {
          labels: resp.result.locs.map((loc: any) => (loc.country || "Bogon")),
          datasets: [{ data: resp.result.locs.map((loc: any) => (loc.count)),backgroundColor: '#09bbfd'}],
          // datasets: [{ data: [5,6],backgroundColor: 'blue' }]
        }
      }
    });

    // const dataValue = 

    // const barAvatar = {
    //   id: 'barAvatar',
    //   afterDraw(chart:any, args: any, options: any){
    //     const {ctx, chartArea : { top, bottom, left, right, width, height},
    //       scales: {x,y}} = chart;
    //     ctx.save()
    //     ctx.drawImage(img2, x.getPixelForValue(0)-(26/2), y.getPixelForValue(15) ,30,30)
    //     ctx.drawImage(img1, x.getPixelForValue(1)-(26/2), y.getPixelForValue(15) ,30,30)
    //     ctx.drawImage(img3, x.getPixelForValue(2)-(26/2), y.getPixelForValue(15),30,30)
    //     ctx.drawImage(img4, x.getPixelForValue(3)-(26/2), y.getPixelForValue(15),30,30)
    //   }
    // }

    // this.TestbarChartData = {
    //   labels: ['United States', 'India', 'China'],
    //   datasets: [{ data: dataValue,backgroundColor: 'blue' }],
    // }

    this.countryBarChartOptions= {
      responsive: true,
      backgroundColor: '#09bbfd',
      // animation: {
      //   duration: 1,
      //   onComplete: function({ chart }) {
      //     const ctx = chart.ctx;
      
      //     chart.config.data.datasets.forEach(function(dataset, i) {
      //       const meta = chart.getDatasetMeta(i);
      
      //       meta.data.forEach(function(bar, index) {
      //         const data = dataset.data[index];
      
      //         ctx.fillText(String(data), bar.x, bar.y - 5);
      //       });
      //     });
      //   }
      // },
    };
    // this.testBarChartPlugins = [barAvatar]
  }

  getDataForPieChart(): void {
    // this.pieChartData = undefined;

    this.api.getAllDataForPieChart(this.allFilter).subscribe((resp: any) => {
      // //console.log('getAllDataForPieChart>>>', resp);
      if (resp.code === 0) {
        if (resp.result.approved == 0 && resp.result.notApproved == 0) {
          this.isShowPieChartData = false;
        } else {
          this.isShowPieChartData = true;
        }
        this.pieChartData = {
          data: [
            {
              values: [resp.result.approved, resp.result.notApproved],
              labels: ['Approved', 'Not Approved'],
              type: 'pie',
              marker: {
                colors: ['#5942eb', '#09bbfd'],
              },
            },
          ],
        };
        // //console.log('pieChartData', this.pieChartData, this.isShowPieChartData);
      }
    });
  }
  getDataForBarChart(): void {
    this.barGraphData = undefined;

    this.api.getAllDataForBarChart(this.allFilter).subscribe((resp: any) => {
      if (resp.code === 0) {
        // //console.log('getAllDataForBarChart', resp);
        if (
          resp.result.commits == 0 &&
          resp.result.disputes == 0 &&
          resp.result.users == 0
        ) {
          this.isShowBarGraphData = false;
        } else {
          this.isShowBarGraphData = true;

          this.barGraphData = {
            data: [
              {
                x: resp.result.map((e: any) => e._id),
                y: resp.result.map((e: any) => e.count),
                type: 'bar',
                marker: {
                  color: '#09bbfd',
                },
              },
            ],
          };
        }
      }
    });
  }
  getDataForLineChart(): void {
    this.lineChartData = undefined;
    this.api.getAllDataForLineChart(this.allFilter).subscribe((resp: any) => {
      // //console.log('lineChartData', resp);
      if (resp.code === 0) {
        if (
          resp.result.commits == 0 &&
          resp.result.disputes == 0 &&
          resp.result.users == 0
        ) {
          this.isShowLineChartData = false;
        } else {
          this.isShowLineChartData = true;

          this.lineChartData = {
            data: [
              {
                x: resp.result.commits.map((e: any) => e.repo),
                y: resp.result.commits.map((e: any) => e.count),
                type: 'scatter',
                name: 'Commit',
                line: {
                  color: '#09bbfd',
                  width: 3,
                },
              },
              {
                x: resp.result.disputes.map((e: any) => e.repo),
                y: resp.result.disputes.map((e: any) => e.disputes),
                type: 'scatter',
                name: 'Dispute',
                line: {
                  color: '#5942eb',
                  width: 3,
                },
              },
            ],
          };
        }
      }
    });
  }
  getDataForLineChartRepoWiseUser(): void {
    this.lineChartDataRepoWiseUser = undefined;
    this.api
      .getAllDataForLineChartRepoWise(this.allFilter)
      .subscribe((resp: any) => {
        if (resp.code === 0) {
          // //console.log('lineChartDataRepoWiseUser', resp);
          if (resp.result.length == 0) {
            this.isShowLineChartDataRepoWiseUser = false;
          } else {
            this.isShowLineChartDataRepoWiseUser = true;

            this.lineChartDataRepoWiseUser = {
              data: [
                {
                  x: resp.result.map((e: any) => e.repo_name),
                  y: resp.result.map((e: any) => e.distinct_user.length),
                  type: 'bar',
                  text: resp.result.map((e: any) => e.distinct_user.join(',')),
                  name: 'Repo Wise Users',
                  line: {
                    color: '#09bbfd',
                    width: 3,
                  },
                },
              ],
            };
          }
        }
      });
  }

  getDataForBubble(): void {
    this.bubbleChartData = undefined;
    this.api.getAllDataForBubbleChart({ id: null }).subscribe((resp: any) => {
      if (resp.code === 0) {
        // //console.log(
        //   'bubbleChartData 0',
        //   resp,
        //   this.bubbleChartData,
        //   this.isShowBubbleChartData
        // );
        if (resp.result.length == 0) {
          this.isShowBubbleChartData = false;
        } else {
          this.isShowBubbleChartData = true;
          this.bubbleChartData = {
            data: [
              {
                x: resp.result.map((e: any) => e.group?.name),
                y: resp.result.map((e: any) => e.count),
                mode: 'markers',
                marker: {
                  size: resp.result.map((e: any) => e.count * 100),
                  sizemode: 'area',
                  color: '#5942eb',
                },
              },
            ],
          };
          this.bubbleFullData = resp.result;
        }
      }
      // //console.log(
      //   'bubbleChartData',
      //   resp,
      //   this.bubbleChartData,
      //   this.isShowBubbleChartData
      // );
    });
  }
  onPointClick(event: any): void {
    if (this.type === 'Group') {
      const groupData = this.bubbleFullData.find(
        (e: any) => e.group.name === event.points[0].x
      );
      // //console.log(groupData);
      this.type = 'Repository';
      this.bubbleChartData = undefined;
      this.api
        .getAllDataForLineChart({
          ...this.allFilter,
          groups: [groupData._id],
          repos: [],
        })
        .subscribe((resp) => {
          if (resp.code === 0) {
            this.bubbleChartData = {
              data: [
                {
                  x: resp.result.disputes.map((e: any) => e.repo),
                  y: resp.result.disputes.map((e: any) => e.disputes),
                  mode: 'markers',
                  marker: {
                    size: resp.result.disputes.map(
                      (e: any) => e.disputes * 100
                    ),
                    sizemode: 'area',
                    color: '#5942eb',
                  },
                },
              ],
            };
            this.bubbleFullData = resp.result;
          }
        });
    } else {
      this.type = 'Group';
      this.getDataForBubble();
    }
  }
  onBack(): void {
    this.location.back();
  }

}
