import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { ConnectionService } from 'src/app/service/connection.service';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-github-connection-setting',
  templateUrl: './github-connection-setting.component.html',
  styleUrls: ['./github-connection-setting.component.scss'],
})
export class GithubConnectionSettingComponent implements OnInit {
  connectionName = '';
  provider = '';
  personalAccessToken = '';
  code = '';
  userName = '';
  selectdConnection: any;
  apps = [];
  constructor(
    private connection: ConnectionService,
    private router: Router,
    private api: ApiService,
    private toaster: ToastrService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getSetConnectionName();
  }
  getAllInstalledApps(): void {
    this.api.getAllInstalledApp(this.provider).subscribe(
      (resp) => {
        if (resp.code === 0) {
          this.apps = resp.result.installations;
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }
  getSetConnectionName(): void {
    this.api.getSetConnectionName(this.connectionName, this.provider, this.personalAccessToken, this.userName).subscribe(
      (resp) => {
        // //console.log(resp.result);
        // //console.log(resp.result)
        this.connectionName = resp.result.connectionName;
        this.provider = resp.result.provider;
        this.personalAccessToken = resp.result.personalAccessToken;
        this.userName = resp.result.username
        this.getAllInstalledApps();
        
      },
      (error) => {
        this.toaster.error(
          'Type the connection name again',
          'Something Went Wrong'
        );
      }
    );
  }
  onInstallApp(): void {
    const link = document.createElement('a');
    if (this.provider === 'GitHub') {
      link.href = environment.GUIHUB_APP_URL + '/installations/new';
    } else if (this.provider === 'Bitbucket') {
      if(this.api.isProd()){
        link.href = `https://bitbucket.org/site/addons/authorize?addon_key=codelock`;
      }else{
        link.href = `https://bitbucket.org/site/addons/authorize?addon_key=codelock-dev`;
      }
      
    }
    document.body.appendChild(link);
    this.api.getSetConnectionName(this.connectionName, this.provider, this.personalAccessToken, this.userName).subscribe(
      (resp) => {
        if (resp.code === 0) {
          // //console.log(resp);
          
          link.click();
          document.body.removeChild(link);
        }
      },
      (error) => {
        this.toaster.error('Application Installation is Failed');
      }
    );
  }
  createConnection(): void {
    // //console.log(this.selectdConnection);
    const data = {
      connection_name: this.connectionName,
      provider: this.provider,
      github_installed_app_installation_id:
        this.provider === 'GitHub' ? this.selectdConnection._id : null,
      account_name: this.selectdConnection.account,
      connection_type: this.selectdConnection.type,
      personalAccessToken: this.personalAccessToken,
      username : this.userName
    };
    // //console.log('createConnection>>', data);
    this.api.createConnection(data).subscribe(
      (resp) => {
        if (resp.code === 0) {
          this.router.navigate(['/manage/repo-connection']);
        }
      },
      (error) => {
        this.toaster.error('Failed to Create Connection');
      }
    );
  }
  onSelectValueChange(e: any): void {
    this.selectdConnection = e;
  }
}
