import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-questionery',
  templateUrl: './questionery.component.html',
  styleUrls: ['./questionery.component.scss'],
})
export class QuestioneryComponent implements OnInit {
  constructor(private router: Router, private activeRoute: ActivatedRoute) {}
  token: string | null = null;
  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((params: any) => {
      this.token = params['token'];
      if (this.token) {
        this.router.navigate(['/auth/questioner'], {
          state: { token: this.token },
        });
      } else {
        this.router.navigate(['/auth/login'], {
          state: { error: true, message: 'Invalid url' },
        });
      }
    });
  }
}
