import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'app-add-face',
  templateUrl: './add-face.component.html',
  styleUrls: ['./add-face.component.scss'],
})
export class AddFaceComponent implements OnChanges {
  @Input('full') full: any;
  @Output('onAdd') onAdd = new EventEmitter<void>();
  @Output('onClose') onClose = new EventEmitter<void>();
  @ViewChild('video') video!: ElementRef;
  @ViewChild('canvas') canvas!: ElementRef;
  @ViewChild('addFaceModal') addFaceModal?: ModalDirective;
  error: any;
  isCaptured = false;
  WIDTH = 640;
  HEIGHT = 480;
  localstream: MediaStream | undefined;
  constructor(private api: ApiService, private toaster: ToastrService) {}

  ngOnChanges(): void {
    this.isCaptured = false;
    if (this.full) {
      this.addFaceModal?.show();
      this.setupDevices();
    } else {
      this.addFaceModal?.hide();
      this.stopVideo();
    }
  }
  closeModal(): void {}
  async setupDevices(): Promise<void> {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (stream) {
          this.video.nativeElement.srcObject = stream;
          this.localstream = stream;
          this.video.nativeElement.play();
          this.error = null;
        } else {
          this.error = 'You have no output video device';
        }
      } catch (e) {
        this.error = e;
      }
    }
  }
  onFaceAdd(): void {
    this.drawImageToCanvas(this.video.nativeElement);
    this.api
      .addNewFace({
        photo: this.canvas.nativeElement.toDataURL('image/png'),
      })
      .subscribe(
        (resp) => {
          if (resp.code === 0) {
            this.stopVideo();
            this.toaster.success(resp.result);
          } else {
            this.isCaptured = false;
            this.toaster.error(resp.result);
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong! Try again');
          }
        }
      );
  }
  drawImageToCanvas(image: any): void {
    this.canvas.nativeElement
      .getContext('2d')
      .drawImage(image, 0, 0, this.WIDTH, this.HEIGHT);
    this.isCaptured = true;
  }
  stopVideo(): void {
    this.localstream?.getTracks()[0].stop();
  }
}
