import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
import { UserState } from '../interface/user';
import { Store } from '@ngrx/store';
import {subtier} from '../constants';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class Util {
  // permissions: any;
  currentUser: any;
  permissions: any;
  userData: UserState | any;

  //  = [
  //   { key: 'isAddUser', label: 'Add User' },
  //   { key: 'isDisableUser', label: 'Disable User' },
  //   { key: 'isResetPassword', label: 'Reset Password' },
  //   { key: 'isCaseCreation', label: 'Case Creation' },
  //   { key: 'isCaseAssignment', label: 'Case Assignment' },
  //   { key: 'isCaseReassignment', label: 'Case Reaasignment' },
  //   { key: 'isCaseModification', label: 'Case Modification' },
  //   { key: 'isFileUpload', label: 'File Upload' },
  //   { key: 'isCaseDownload', label: 'Case Download' },
  //   { key: 'isCaseClose', label: 'Case Close' },
  //   { key: 'isCaseShare', label: 'Case Share' },
  //   { key: 'isCaseView', label: 'CaseView' },
  //   { key: 'isCustomPrinting', label: 'Custom Printing' },
  // ];
  constructor(
    private api: ApiService,
    private auth: AuthenticationService,
    private toaster: ToastrService,
    private store: Store<{ userData: UserState }>,

  ) {
    // this.api.getPermissionList().subscribe((resp: any) => {
    //   if (resp.code === 0) {
    //     this.permissions = resp.result.data;
    //   }
    // });
    this.currentUser = this.auth.getUserData();
    this.permissions = this.currentUser.permissions;
    // //console.log('currentUser>>>', this.currentUser);
  }
  refreshUser() {
    this.api.getUserPermissions().subscribe((resp) => {
      if (resp.code === 0) {
        //this.currentUser.permissions = resp.result;
        ////console.log("permission updated: ", resp.result);
        this.permissions = resp.result;
      }
    })
  }
  // getPermissions(): any[] {
  //   return this.permissions;
  // }
  // getPermissionStatus(pp: any[]) {
  //   // return this.permissions.map((p: any) => {
  //   //   if (pp.filter((per) => per.key == p.key).length > 0)
  //   //     return { ...p, isExist: true };
  //   //   else return { ...p, isExist: false };
  //   // });
  // }
  isPermission(p: string, showError: boolean = true): boolean {
    this.refreshUser();
    if (this.permissions?.includes(p)) return true;
    ////console.log("here", this.permissions);
    if (showError){
    this.toaster.error('Access denied');
    }
    return false;
  }
  canNavigate(route: string): boolean {
    //yes, i hate this too. but it's a quick solution. 
    
    this.store.select('userData').subscribe((userData) => {
      this.userData = userData;
    });
    // console.log("userData", this.userData, "route", route)
    const subscription = this.userData.subscriptionType;
    const subscriptions = {
      "free": subtier.FREE,
      "starter": subtier.STARTER,
      "starter-saas-plan": subtier.COMPLIANCE,
      "premium-saas-plan": subtier.COMPLIANCE_PREMIUM,
      "enterprise-saas-plan": subtier.ULTIMATE,
      "ultimate-saas-plan": subtier.ULTIMATE,
    } as const;
  
    const routeTiers = {
      "create-connection": subtier.ULTIMATE, // ULTIMATE
      "activity": subtier.STARTER, // COMPLIANCE
      "projects": subtier.ULTIMATE, // ULTIMATE
      "create-project": subtier.ULTIMATE, // ULTIMATE
      "update-project/:id": subtier.ULTIMATE, // ULTIMATE
      "project-details/:id": subtier.ULTIMATE, // ULTIMATE
      "groups": subtier.ULTIMATE, // ULTIMATE
      "members": subtier.COMPLIANCE, // COMPLIANCE
      "profile": subtier.COMPLIANCE, // COMPLIANCE
      "dispute-log/:id": subtier.ULTIMATE, // ULTIMATE
      "alert-settings": subtier.ULTIMATE, // ULTIMATE
      "sbom": subtier.COMPLIANCE, // COMPLIANCE
      "threat": subtier.ULTIMATE, // ULTIMATE
      "map": subtier.ULTIMATE, // ULTIMATE
      "analytics": subtier.ULTIMATE, // ULTIMATE
      "monitor": subtier.ULTIMATE, // ULTIMATE
      "compliance": subtier.STARTER, // STARTER
      "repository": subtier.ULTIMATE, // ULTIMATE
      "repo": subtier.ULTIMATE, // ULTIMATE
      "nist": subtier.STARTER, // STARTER
    };
  
    const userTier = subscriptions[subscription as keyof typeof subscriptions];
  

    // @ts-ignore
    const requiredTier = routeTiers[route];
    if (userTier >= requiredTier) {
      return true;
    } else {
      return false;
    }
  }
  getSubscriptionTier() {
    return this.store.select('userData').pipe(
      map((userData: any) => {
        if (userData) {
          const subscriptions = {
            "free": subtier.FREE,
            "starter": subtier.STARTER,
            "starter-saas-plan": subtier.COMPLIANCE,
            "premium-saas-plan": subtier.COMPLIANCE_PREMIUM,
            "enterprise-saas-plan": subtier.ULTIMATE,
            "ultimate-saas-plan": subtier.ULTIMATE,
          } as const;
          return subscriptions[userData.subscriptionType as keyof typeof subscriptions];
        }
        return undefined; // or an appropriate default value or error handling
      })
    );
  }
  
  
}
