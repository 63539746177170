import { Action } from '@ngrx/store';

export const SELECTED_REPO = 'SELECTED_REPO';
export const RESET_SELECTED_REPO = 'RESET_SELECTED_REPO';

export class selectedRepo implements Action {
  readonly type = SELECTED_REPO;
  constructor(public payload: any) {}
}
export class ResetSelectedRepo implements Action {
  readonly type = RESET_SELECTED_REPO;
  constructor() {}
}

export type RepoAction = selectedRepo | ResetSelectedRepo;
