<div class="modal-dialog modal-xl modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title color-white">
        <img src="/assets/images/icon__report.svg" alt="" />
        User Push Log Information
      </h4>
      <a
        class="modal-close"
        href="javascript:void(0)"
        aria-label="Close"
        (click)="onCloseModal()"
      >
        <img src="/assets/images/icon__close.svg" alt="" />
      </a>
    </div>
    <div class="modal-body">
      <div class="d-flex align-items-center ms-auto w-100 mb-4">
        <div class="form-search flex-grow-1">
          <input
            type="text"
            class="form-control"
            placeholder="Search (User, IP, Action, Resource & Resource Type)"
            [(ngModel)]="term"
            (keyup.enter)="getLogs()"
            (ngModelChange)="onClearSearch()"
          />
          <button type="submit">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 27.18 27.9"
              style="enable-background: new 0 0 27.18 27.9"
              xml:space="preserve"
            >
              <path
                d="M17.76,20.63c-5.5,3.82-12.7,1.62-15.85-3.11C-1.21,12.83-0.48,6.56,3.94,2.76 c4.09-3.51,10.17-3.77,14.64-0.19c4.26,3.4,6.04,10.45,1.52,15.96c0.36,0.37,0.72,0.75,1.08,1.12c1.83,1.88,3.67,3.76,5.49,5.65 c0.87,0.91,0.57,2.22-0.58,2.54c-0.64,0.18-1.14-0.07-1.58-0.52c-1.58-1.64-3.16-3.28-4.76-4.9C19.13,21.8,18.44,21.24,17.76,20.63z M11.32,19.59c4.54,0.07,8.36-3.63,8.44-8.19c0.08-4.58-3.62-8.37-8.26-8.44c-4.67-0.07-8.48,3.61-8.54,8.26 C2.9,15.75,6.65,19.51,11.32,19.59z"
              />
            </svg>
          </button>
        </div>
        <button
          class="button button--secondary ms-3"
          type="button"
          (click)="downloadUserLog()"
          *ngIf="permissionGranted"
        >
          <i class="fa fa-download me-2" aria-hidden="true"></i>
          Download
        </button>
      </div>
      <!-- <label
        >Please enter your info here and a member of our team will get back to
        you as quickly as possible.</label
      > -->
      <!-- <br />
      <br /> -->
      <div class="table-main">
        <div class="table-responsive">
          <table class="table table-nowrap m-0">
            <thead>
              <tr>
                <th scope="col">Repository Name</th>
                <th scope="col">Log Time</th>
                <th scope="col">IP</th>
                <!-- <th scope="col">Organization ID</th> -->
                <th scope="col">Resource</th>
                <th scope="col">Resource Type</th>
                <th scope="col">Resource ID</th>
                <!-- <th scope="col">User</th> -->
                <th scope="col">MFA</th>
                <th scope="col">Commit Message</th>
                <th scope="col">Commit ID</th>


                <!-- <th scope="col">Approved by Admin</th> -->
                <!-- <th scope="col" class="text-center">User</th> -->
                <!-- <th scope="col" class="text-center">ID</th> -->
                <!-- <th scope="col">Files</th> -->
              </tr>
            </thead>
            <tbody>
              <tr>
                <!-- <td colspan="9" class="text-center">No records found</td> -->
              </tr>
              <tr *ngFor="let log of logs; index as i">
                <td>{{ log.repoName }}</td>
                <td>{{ log?.timestamp | date: "full" }}</td>
                <td>{{ log?.ip }}</td>
                <!-- <td>{{ log.org_id }}</td> -->
                <td>{{ log.resource }}</td>
                <td>{{ log.resourceType }}</td>
                <td>{{ log.resource_id }}</td>
                <td>{{ log.mfa }}</td>
                <td>{{ log.commitMessage }}</td>
                <td>
                  <a [href]="'https://codelock.ai/dashboard/dispute-log/' + (log?.repositoryScansId || '') + '?provider=' + (log?.provider || '') + '&commitId=' + (log?.commitId || '')">
                    {{ log.commitId }}
                  </a>
                </td>
                

                <!-- <td>{{ log.browser }}</td> -->
                <td class="text-center">
                  <img
                    class="browser"
                    src="/assets/images/browsers/chrome.svg"
                    alt=""
                    *ngIf="log.browser === 'Chrome'"
                  />
                  <img
                    class="browser"
                    src="/assets/images/browsers/edge.svg"
                    alt=""
                    *ngIf="log.browser === 'Edge'"
                  />
                  <img
                    class="browser"
                    src="/assets/images/browsers/firefox.svg"
                    alt=""
                    *ngIf="log.browser === 'Firefox'"
                  />
                  <img
                    class="browser"
                    src="/assets/images/browsers/ie.svg"
                    alt=""
                    *ngIf="log.browser === 'Internet Explorer'"
                  />
                  <img
                    class="browser"
                    src="/assets/images/browsers/opera.svg"
                    alt=""
                    *ngIf="log.browser === 'Opera'"
                  />
                  <img
                    class="browser"
                    src="/assets/images/browsers/saferi.svg"
                    alt=""
                    *ngIf="log.browser === 'Safari'"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div class="d-flex justify-content-end">
          <app-pagination
            [count]="total"
            (changePage)="onChangePage($event)"
            [perPage]="10"
          ></app-pagination>
        </div>
      </div>
      <!-- <form [formGroup]="viewLogsForm">
        <div class="mb-4">
          <label for="" class="form-label">First Name *</label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter first name"
            formControlName="resource"
          />
          <span
            class="form-text form-error"
            *ngIf="
              viewLogsForm.get('resource')?.getError('required') &&
              viewLogsForm.get('resource')?.touched
            "
            >First Name is Required</span
          >
        </div>
        <div class="mb-4">
          <label for="" class="form-label">Last Name</label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter last name"
            formControlName="createdAt"
          />
          <span
            class="form-text form-error"
            *ngIf="
              viewLogsForm.get('createdAt')?.getError('required') &&
              viewLogsForm.get('createdAt')?.touched
            "
            >Last Name is Required</span
          >
        </div>
        <div class="mb-4">
          <label for="" class="form-label">Company Name</label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter company name"
            formControlName="logoutTime"
          />
          <span
            class="form-text form-error"
            *ngIf="
              viewLogsForm.get('logoutTime')?.getError('required') &&
              viewLogsForm.get('logoutTime')?.touched
            "
            >Company Name is Required</span
          >
        </div>
        <div class="mb-4">
          <label for="" class="form-label">Job Title *</label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter job title"
            formControlName="ip"
          />
          <span
            class="form-text form-error"
            *ngIf="
              viewLogsForm.get('ip')?.getError('required') &&
              viewLogsForm.get('ip')?.touched
            "
            >Job Title is Required</span
          >
        </div>
        <div class="mb-4">
          <label for="" class="form-label">Email *</label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter email"
            formControlName="description"
          />
          <span
            class="form-text form-error"
            *ngIf="
              viewLogsForm.get('description')?.getError('required') &&
              viewLogsForm.get('description')?.touched
            "
            >Email is Required</span
          >
        </div>
        <div class="mb-4">
          <label for="" class="form-label">Phone</label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter phone number"
            formControlName="browser"
          />
          <span
            class="form-text form-error"
            *ngIf="
              viewLogsForm.get('browser')?.getError('required') &&
              viewLogsForm.get('browser')?.touched
            "
            >Phone No is Required</span
          >
        </div>
        <div class="button-group">
          <button type="submit" class="button button--primary">Send</button>
        </div>
      </form> -->
    </div>
  </div>
</div>
