<div class="row">
  <div class="col-md-12 mb-4">
    <div class="monitor__graph text-center pb-4">
      <div class="symbol" *ngIf="showStatus">
        <ng-lottie [options]="options"></ng-lottie>
      </div>
      <h4 class="m-0">Development Environment Status</h4>
    </div>
  </div>
  <div class="col-md-6 mb-4">
    <div class="monitor__graph text-center py-4">
      <p class="p--xl">{{ disputes }}</p>
      <h4 class="m-0">Disputes</h4>
    </div>
  </div>
  <div class="col-md-6 mb-4">
    <div class="monitor__graph text-center py-4">
      <p class="p--xl">{{ commits }}</p>
      <h4 class="m-0">Commits</h4>
    </div>
  </div>
  <div class="col-md-6 mb-4">
    <div class="monitor__graph text-center py-4">
      <p class="p--xl">{{ users }}</p>
      <h4 class="m-0">Number of Authorized Users</h4>
    </div>
  </div>
  <div class="col-md-6 mb-4">
    <div class="monitor__graph text-center py-4">
      <p class="p--xl">{{ serverDisputes }}</p>
      <h4 class="m-0">Server Disputes</h4>
    </div>
  </div>
  <div class="col-md-6 mb-4">
    <div class="monitor__graph text-center py-4">
      <p class="p--xl">{{ monthlyActiveUser }}</p>
      <h4 class="m-0">Monthly Active User</h4>
    </div>
  </div>
</div>
