import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../../service/api.service';

@Component({
  selector: 'app-manage-configuration',
  templateUrl: './manage-configuration.component.html',
  styleUrls: ['./manage-configuration.component.scss'],
})
export class ManageConfigurationComponent implements OnInit {
  developmentEnvironmentStatusForm = new UntypedFormGroup({
    stable: new UntypedFormControl(null, Validators.required),
    warning: new UntypedFormControl(null, Validators.required),
  });

  constructor(private api: ApiService, private toaster: ToastrService) {}

  ngOnInit(): void {
    this.getDevelopmentStatus();
  }
  getDevelopmentStatus(): void {
    this.api.getOrganizationDevelopmentStatusParameters().subscribe(
      (resp) => {
        // //console.log('API keys', resp);
        if (resp.code === 0) {
          this.developmentEnvironmentStatusForm.patchValue({
            stable: resp.result.stable,
            warning: resp.result.warning,
          });
          // //console.log(
          //   'Development Status >>> ',
          //   this.developmentEnvironmentStatusForm.getRawValue()
          // );
          // //console.log('Secret keys >>> ', this.secretAccessKey);
        } else {
          if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
            this.toaster.error(resp.result);
          } else {
            this.toaster.error('Internal Server Error');
          }
        }
      },
      (error) => {
        if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
          this.toaster.error(error.error.result);
        } else {
          this.toaster.error('Something Went Wrong');
        }
      }
    );
  }

  updateDevelopmentStatus(): void {
    this.api
      .updateOrganizationDevelopmentStatusParameters(
        this.developmentEnvironmentStatusForm.getRawValue()
      )
      .subscribe(
        (resp) => {
          // //console.log('API keys', resp);
          if (resp.code === 0) {
            this.toaster.success('Successfully updated development status');
            this.getDevelopmentStatus();
            // this.developmentEnvironmentStatusForm.patchValue({
            //   stable: resp.result.stable,
            //   warning: resp.result.warning,
            // });
            // //console.log(
            //   'Development Status >>> ',
            //   this.developmentEnvironmentStatusForm.getRawValue()
            // );
            // //console.log('Secret keys >>> ', this.secretAccessKey);
          } else {
            if (Object.prototype.hasOwnProperty.call(resp, 'code')) {
              this.toaster.error(resp.result);
            } else {
              this.toaster.error('Internal Server Error');
            }
          }
        },
        (error) => {
          if (Object.prototype.hasOwnProperty.call(error.error, 'code')) {
            this.toaster.error(error.error.result);
          } else {
            this.toaster.error('Something Went Wrong');
          }
        }
      );
  }
}
